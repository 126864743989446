export default function throttle(f, t = 500) {
  return function (args) {
    let previousCall = this && this.lastCall ? this.lastCall : undefined;
    this.lastCall = Date.now();
    if (
      previousCall === undefined || // function is being called for the first time
      this.lastCall - previousCall > t
    ) {
      // throttle time has elapsed
      f(args);
    }
  };
}
