<template>

    <el-dialog
        :visible.sync="drawer"
        :close-on-click-modal="false"
        :show-close="false"
        width="496px"
        top="0"
        class="project_drawer"
        ref="AddRenameProjectAddUser"
        @close="closeModal"
    >

        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
        >

        <!-- @submit="submitForm('ruleForm')" -->

            <!-- Начало верстки "form_header"-->
            <div class="form_header">
                <el-button type="info" @click.prevent="closeForm"> Cancel</el-button>
                <el-radio-group v-model="ruleForm.type" size="mini" v-if="!change_data">
                    <el-radio-button label="list">List</el-radio-button>
                    <el-radio-button label="chat">Chat</el-radio-button>
                </el-radio-group>
                <p v-else>{{title}}</p>
                <el-button
                    :disabled="disabled()"
                    type="primary"
                    @click.prevent="submitForm('ruleForm')"
                >
                    {{ ruleForm.type == 'shortcut' ? 'Done' : submit_btn }}
                </el-button>
            </div>
            <!-- Конец верстки "form_header"-->

            <div>

                <el-form-item prop="project_name">
                    <el-input
                        v-model="ruleForm.project_name"
                        :placeholder="ruleForm.type == 'list' ? 'List name' : ruleForm.type == 'chat' ? 'Chat name' : ''"
                        ref="projectName"
                        class="project_name_input"
                    ></el-input>
                </el-form-item>

                <el-form-item
                    :class="activeFlag || ruleForm.user != '' ? 'active' : ''"
                    class="with_png"
                    label="Add Members"
                    prop="user"
                    ref="ruleFormUser"
                >
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.2 20.2">
                        <path class="cls-1" d="M19,8.46a9,9,0,0,1,.19,1.88A9.31,9.31,0,1,1,9.91,1.07a9.67,9.67,0,0,1,1.88.19"/>
                        <line class="cls-1" x1="16.87" y1="0.6" x2="16.87" y2="6.04"/>
                        <line class="cls-1" x1="14.13" y1="3.32" x2="19.6" y2="3.32"/>
                        <ellipse class="cls-1" cx="9.91" cy="9.63" rx="4.08" ry="4.06"/>
                        <path class="cls-1" d="M4.29,17.44a5.78,5.78,0,0,1,5.63-3.73,6,6,0,0,1,5.7,3.8"/>
                    </svg>
                    <el-autocomplete
                        v-if="activeFlag"
                        v-model="ruleForm.user"
                        :fetch-suggestions="querySearch"
                        placeholder="Name or email address"
                        @select="handleSelect"
                        @focus="activeFlag = true"
                        class="autocomplete_users"
                    >
                        <template slot-scope="{ item }" class="drawer_users_in_project">
                            <div class="drawer_users_in_project_item" :class="item.is_active ? 'is_active' : ''" :id="`drawer_users_in_project_${item._id}`" :style="item.hide ? 'display: none' : ''">
                                <div class="user_icon" v-if="item.name == 'User not found'">
                                    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="21.5" cy="21.5" r="21.5" fill="#FF9D9D"/>
                                        <path d="M21.9488 27.4922C21.4096 27.4922 20.9509 27.6621 20.5707 28.0027C20.1901 28.3433 20 28.757 20 29.2418C20 29.7908 20.1924 30.2209 20.5766 30.5342C20.9622 30.8472 21.4096 31.0039 21.9229 31.0039C22.4449 31.0039 22.8997 30.8495 23.2889 30.5398C23.6782 30.231 23.8717 29.7987 23.8717 29.2421C23.8717 28.757 23.6861 28.3435 23.3145 28.0029C22.9422 27.6621 22.4874 27.4922 21.9488 27.4922Z" fill="white"/>
                                        <path d="M27.4449 15.4373C26.951 14.6864 26.2437 14.0842 25.3412 13.65C24.4451 13.2177 23.4011 13 22.2362 13C20.9829 13 19.8677 13.2618 18.9227 13.7791C17.9758 14.296 17.2459 14.9597 16.7504 15.7534C16.2514 16.5497 16 17.3501 16 18.1331C16 18.5522 16.1732 18.9422 16.515 19.293C16.8584 19.6457 17.2859 19.8245 17.7848 19.8245C18.6342 19.8245 19.2164 19.3239 19.512 18.3449C19.7811 17.5329 20.1127 16.915 20.4963 16.5052C20.8575 16.1193 21.443 15.9242 22.2364 15.9242C22.9207 15.9242 23.4604 16.116 23.8888 16.5108C24.3107 16.9012 24.5157 17.367 24.5157 17.9334C24.5182 18.2077 24.4491 18.4779 24.3152 18.7172C24.1728 18.9728 23.9956 19.2074 23.7887 19.4142C23.5693 19.6336 23.2076 19.9646 22.7132 20.3966C22.1372 20.9014 21.6723 21.345 21.3328 21.7109C20.9738 22.1027 20.6855 22.5537 20.4806 23.044C20.2666 23.5514 20.1583 24.1555 20.1583 24.8403C20.1583 25.4256 20.3214 25.8787 20.644 26.1875C20.9632 26.4941 21.3606 26.6496 21.8261 26.6496C22.716 26.6496 23.2748 26.1633 23.4407 25.2538C23.5268 24.8755 23.5895 24.6111 23.632 24.4601C23.6705 24.3234 23.7262 24.1833 23.7979 24.0433C23.8674 23.9077 23.9777 23.7533 24.1253 23.5834C24.2797 23.406 24.4898 23.1964 24.746 22.9627C25.7048 22.106 26.3809 21.4853 26.7561 21.118C27.1431 20.7386 27.4806 20.2844 27.7605 19.7663C28.0462 19.2348 28.1916 18.6107 28.1916 17.9106C28.1911 17.0236 27.9396 16.1922 27.4432 15.4382" fill="white"/>
                                    </svg>
                                </div>
                                <div class="user_icon" :style="'background-color:' + item.color" v-else>
                                    <span v-if="$w_name_reduction(item.name) == $w_user_image(item)" v-html="$w_user_image(item)"></span>
                                    <div v-else v-html="$w_user_image(item)"></div>
                                </div>
                                <div class="user_info">
                                    <div class="user_name">
                                        <span>{{ item.name }}</span>
                                    </div>
                                    <div class="user_email">
                                        <span>{{ item.email }}</span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </el-autocomplete>
                    <div ref="invite_user_success" id="invite_user_to_system__success" class="is_hide">
                        <span>
                            <img :src="require('@/assets/static/images/arrow_check.svg')" alt="arrow"/>
                        </span>
                        <span>Invitation sent</span>
                    </div>
                </el-form-item>

                <!-- Начало верстки "form_footer"-->
                <ul class="form_footer drawer_users_in_project">
                    <li
                        v-for="user in users_in_project"
                        :key="user._id"
                        class="drawer_users_in_project_item"
                    >
                        <div class="user_icon" :style="'background-color:' + user.color">
                            <span v-if="$w_name_reduction(user.name) == $w_user_image(user)" v-html="$w_user_image(user)"></span>
                            <div v-else v-html="$w_user_image(user)"></div>
                        </div>
                        <div class="user_info">
                            <div class="user_name">
                                <span>{{ user.name }}</span>
                            </div>
                            <div class="user_email">
                                <span>{{ user.email }}</span>
                            </div>
                            <span v-if="user.has_delete && !user.owner" @click="delete_user_in_project_list(user)" class="delete_user_from_list">
                                <img :src="require(`@/assets/static/images/backspace.svg`)" alt=""/>
                            </span>
                            <span v-if="user.owner" class="user_project_owner" :style="user.users ? 'right: 22px;' : ''">
                                owner
                            </span>
                        </div>
                    </li>
                </ul>
                <!-- Конец верстки "form_footer"-->

            </div>

        </el-form>

    </el-dialog>

</template>

<script>

import { mapActions, mapGetters, mapMutations } from "vuex"
import { EventEmitter } from "../../main"

export default {
    name: "AddRenameProjectAddUser",
    data() {
        return {
            show: false,
            invitation_user: '',
            invitation_bool: true,
            invitation_bool2: true,
            drawer: false,
            formActive: false,
            addUserInputActive: false,
            submit_btn: "Create",
            project_name: "",
            project_id: "",
            new_project_id: "",
            users_to_add: [],
            users_to_delete: [],
            users_in_project: [],
            known_users: [],
            last_result: {},
            activeFlag: false,
            editChat: false,
            chat_data: '',
            reset_shortcuts: [],
            change_data: false,
            title: '',
            new_teams: [],
            chat_add_user: false,
            ruleForm: {
                project_name: "",
                user: "",
                type: "list"
            },
            rules: {
                project_name: [
                    {
                        required: true,
                        message: "Enter project title.",
                        trigger: "blur"
                    },
                    {
                        max: 160,
                        message: "Length should be 160",
                        trigger: "blur",
                    }
                ]
            },
            checkbox: {
                high_priority: false,
                medium_priority: false,
                assigned_to_me: false,
                today: false,
                inbox: false
            }
        }
    },
    sockets: {
        OUT_CheckUserTeams(data) {
            if (data.approved) {
                this.new_teams.push(data.user._id)
                this.add_user_in_project_list({
                    is_not_in_project: true,
                    ...data.user,
                    has_delete: true
                })
                
                if (this.$route.params.project_id) {
                    if (this.project_id) {
                        let list = this.getProjects.find(e => e._id == this.$route.params.project_id)
                        if (list.author == this.getUserData._id) {
                            this.addKnownUser({
                                email: data.user.email,
                                _id: data.user._id,
                                name: data.user.name,
                                color: data.user.color,
                                image: data.user.image
                            })
                            this.add_user_in_add_users_list({
                                email: data.user.email,
                                _id: data.user._id,
                                name: data.user.name,
                                color: data.user.color,
                                image: data.user.image
                            })
                        } else {
                            this.add_user_in_add_users_list({
                                email: data.user.email,
                                _id: data.user._id,
                                name: data.user.name,
                                color: data.user.color,
                                image: data.user.image,
                                hide: true
                            })
                        }
                    } else {
                        this.addKnownUser({
                            email: data.user.email,
                            _id: data.user._id,
                            name: data.user.name,
                            color: data.user.color,
                            image: data.user.image
                        })
                        this.add_user_in_add_users_list({
                            email: data.user.email,
                            _id: data.user._id,
                            name: data.user.name,
                            color: data.user.color,
                            image: data.user.image
                        })
                    }
                }

                if (this.$route.params.chat_id) {
                    let chat = this.getChats.find(e => e._id == this.$route.params.chat_id)
                    this.addKnownUser({
                        email: data.user.email,
                        _id: data.user._id,
                        name: data.user.name,
                        color: data.user.color,
                        image: data.user.image
                    })
                    if (chat.auth == this.getUserData._id) {
                        this.add_user_in_add_users_list({
                            email: data.user.email,
                            _id: data.user._id,
                            name: data.user.name,
                            color: data.user.color,
                            image: data.user.image
                        })
                    } else {
                        this.add_user_in_add_users_list({
                            email: data.user.email,
                            _id: data.user._id,
                            name: data.user.name,
                            color: data.user.color,
                            image: data.user.image,
                            hide: true
                        })
                    }
                }

            }
        },
        OUT_CheckUserPlan(data) {
            if (data.approved) {
                let new_user_add = this.getTeams.filter((e, i) => e._id == data.user_id)

                if (new_user_add.length > 0) {
                    this.add_user_in_project_list({
                        is_not_in_project: true,
                        ...new_user_add[0],
                        has_delete: true
                    })

                    this.add_user_in_add_users_list({
                        email: new_user_add[0].email,
                        _id: new_user_add[0]._id,
                        name: new_user_add[0].name
                    })
                } else {
                    this.$socket.emit('IN_CheckUserTeams', {
                        _id: this.new_project_id ? this.new_project_id : this.project_id,
                        user_id: data.user_id,
                        type: this.ruleForm.type,
                        new: this.new_project_id ? true : false
                    })
                }
            }
        }
    },
    mounted() {

        EventEmitter.$on('replyAddList', (data) => {
            setTimeout(() => {
                this.chat_add_user = false
                this.submitForm(data)
            }, 500)
        })

        this.$root.$on("addRenameChatsAddUser", (data) => {
            this.show = true

            if (data) {
                this.change_data = true
                this.title = 'Edit Chat'
            } else {
                this.change_data = false
            }

            this.formActive = true
            this.drawer = true
            this.submit_btn = "Save"
            this.ruleForm.project_name = data.name
            this.ruleForm.type = 'chat'
            this.project_id = data._id
            this.project_name = data.name
            this.editChat = true
            this.users_in_project = []
            this.chat_data = data
            this.activeFlag = true
            this.chat_add_user = data.project_id ? true : false

            data.users.forEach((user) => {

                let has_delete = true
                // let users = false

                if (data.auth == user.user_id) {
                    // if (data.auth == this.getUserData._id) {
                    //     users = true
                    // }
                    this.add_user_in_project_list({ owner: true, ...user.user_data, has_delete })
                } else {
                    this.add_user_in_project_list({ ...user.user_data, has_delete })
                }

            })

        })

        // add new chat and list
        this.$root.$on("openEditLists", (data) => {
            // console.log(data)
            this.show = true
            this.ruleForm.type = data

            setTimeout(() => {
                document.querySelector('.project_name_input input').focus()
            }, 500)

            this.submit_btn = "Create"
            this.project_id = ""
            this.project_name = ""
            this.ruleForm.project_name = ""
            this.users_in_project = []
            this.invitation_bool2 = true
            this.editChat = false
            this.change_data = false
            this.activeFlag = true
            this.new_project_id = this.$w_decrypt(this.getUserData._id)

            this.add_user_in_project_list({
                name: this.getUserData.name,
                email: this.getUserData.email,
                _id: this.getUserData._id,
                owner: true,
                has_delete: false
            })

            this.formActive = true
            this.drawer = true
        })

        // edit chat and list
        this.$root.$on("addRenameProjectAddUser", (data) => {
            this.show = true
            this.ruleForm.type = data
            this.submit_btn = "Create"
            this.project_id = ""
            this.project_name = ""
            this.ruleForm.project_name = ""
            this.users_in_project = []
            this.editChat = false
            this.users_to_add = []
            this.activeFlag = true

            if (data) {

                this.title = 'Edit List'
                this.change_data = true
                this.submit_btn = "Submit"
                const project = this.getProjects.find((pr) => pr._id == data)
                this.project_id = data
                this.project_name = project.name
                this.ruleForm.project_name = project.name

                project.users.forEach((user) => {

                    let has_delete = user._id == this.getUserData._id ? false : true

                    if (project.author == user._id) {
                        has_delete = false
                        this.add_user_in_project_list({ owner: true, ...user, has_delete })
                    } else {
                        if (user._id) {
                            this.add_user_in_project_list({ ...user, has_delete })
                        }
                    }

                })

            } else {

                this.change_data = false

                this.add_user_in_project_list({
                    name: this.getUserData.name,
                    email: this.getUserData.email,
                    _id: this.getUserData._id,
                    owner: true,
                    has_delete: false
                })

            }
            this.formActive = true
            this.drawer = true
        })

        this.known_users = this.getTeams

        setTimeout(() => {
            if (document.querySelector('.project_drawer')) {
                // console.log(document.querySelector('.project_drawer'))
                document.querySelector('.project_drawer').onkeypress = (event) => {
                    if (event.key == "Enter") {
                        event.preventDefault()
                        if (this.formActive && this.addUserInputActive) {
                            if (this.last_result._id) {
                                document.getElementById(`drawer_users_in_project_${this.last_result._id}`)
                                    .parentElement.click()
                                this.$refs.ruleFormUser.$el.querySelector("input").blur()
                            }
                        } else if (this.formActive && !this.addUserInputActive && this.ruleForm.project_name != "") {
                            this.submitForm("ruleForm")
                        }
                    }
                }

            }
        }, 1500)

    },
    methods: {
        ...mapActions([
            "socket_add_project",
            "fetch_request",
            "socket_rename_project",
            "socket_add_user_to_project",
            "socket_remove_user_from_project",
            "socket_IN_NewUser_sendEmail"
        ]),
        ...mapMutations([
            "set_users_to_add_in_project",
            "addKnownUser",
            "setMessage",
            "addTeamsUser"
        ]),
        disabled() {
            if (this.ruleForm.project_name == '') {
                return true
            }
            return false
        },
        changeBox(e) {

            let array = []

            if (this.checkbox.inbox) {
                let project_id = this.getUserData.shortcut_inbox
                if (!this.getUserData.shortcut_inbox) {

                    project_id = this.$w_decrypt(this.getUserData._id)

                    EventEmitter.$emit('InboxAdd', project_id)

                    this.socket_add_project({
                        name: 'Inbox',
                        _id: project_id
                    })

                } else if (this.getProjects.length > 0) {

                    let list = this.getProjects.filter(e => e._id == this.getUserData.shortcut_inbox)

                    if (list.length == 0) {

                        project_id = this.$w_decrypt(this.getUserData._id)

                        EventEmitter.$emit('InboxAdd', project_id)

                        this.socket_add_project({
                            name: 'Inbox',
                            _id: project_id
                        })

                    }

                }

                array.push({type: 'inbox', color: "#6CB2CE", project_id: project_id})

            }

            if (this.checkbox.today) {
                array.push({type: 'today', color: "#6DA67A"})
            }

            if (this.checkbox.assigned_to_me) {
                array.push({type: 'assigned_to_me', color: "#E1B26B"})
            }

            if (this.checkbox.medium_priority) {
                array.push({type: 'medium_priority', color: "#E8CCA2"})
            }

            if (this.checkbox.high_priority) {
                array.push({type: 'high_priority', color: "#FC8785"})
            }

            this.$socket.emit('IN_Shortcuts', array)

        },
        nowDay() {
            let now = new Date()
            return now.getDate()
        },
        async submitForm(formName) {
            let current_stage = this.ruleForm;

            // e.preventDefault()

            if (window.navigator.onLine) {

                if (this.ruleForm.type == 'list') {

                    this.$refs[formName].validate(async (valid) => {

                        if (valid) {

                            let project_id = ""

                            if (this.project_id == "") {

                                // Добавление нового проекта
                                project_id = this.new_project_id

                                if (this.users_to_add.length > 0) {
                                    this.users_to_add.forEach((e, i) => {
                                        this.users_to_delete.forEach((a, i2) => {
                                            if (a._id == e._id) {
                                                this.users_to_add.splice(i, 1)
                                                this.users_to_delete.splice(i2, 1)
                                            }
                                        })
                                    })
                                    this.users_to_add.forEach((e) => {
                                        this.addTeamsUser(e)
                                    })
                                    this.set_users_to_add_in_project({
                                        project_id: project_id,
                                        users: this.users_to_add
                                    })
                                }

                                this.socket_add_project({
                                    name: this.ruleForm.project_name,
                                    _id: project_id,
                                    order: this.getLists['list'].length > 0 ? this.getLists['list'].length : 0,
                                    color: this.$w_hue_color[Math.floor(Math.random() * (15 - 1 + 1) + 1)],
                                    users_to_add: this.users_to_add
                                })

                                if (!this.invitation_bool) {
                                    setTimeout(() => {
                                        this.socket_IN_NewUser_sendEmail({
                                            email: this.invitation_user.email_to_send,
                                            list_id: project_id
                                        })
                                    }, 500)
                                }

                            } else {

                                // если проект уже имеется и пользователь хочет сделать изменения
                                if (this.project_name != this.ruleForm.project_name) {
                                    this.socket_rename_project({
                                        name: this.ruleForm.project_name,
                                        _id: this.project_id
                                    })
                                }

                                this.users_to_add.forEach((e, i) => {
                                    this.users_to_delete.forEach((a, i2) => {
                                        if (a._id == e._id) {
                                            this.users_to_add.splice(i, 1)
                                            this.users_to_delete.splice(i2, 1)
                                        }
                                    })
                                })

                                if (this.users_to_delete.length > 0 && this.users_to_add.length > 0) {
                                    const remove_users = {
                                        users: this.users_to_delete,
                                        project_id: this.project_id,
                                        users_to_add: this.users_to_add
                                    }
                                    this.socket_remove_user_from_project(remove_users)
                                } else {
                                    if (this.users_to_delete.length > 0) {
                                        const remove_users = {
                                            users: this.users_to_delete,
                                            project_id: this.project_id
                                        }
                                        this.socket_remove_user_from_project(remove_users)
                                    }

                                    if (this.users_to_add.length > 0) {
                                        const add_users = {
                                            users: this.users_to_add,
                                            project_id: this.project_id,
                                            users_to_delete: this.users_to_delete,
                                            teams: this.new_teams,
                                            type: "add"
                                        }
                                        this.socket_add_user_to_project(add_users)
                                    }

                                }

                            }

                            this.users_to_delete = []
                            this.users_to_add = []
                            this.ruleForm.project_name = ""
                            this.project_name = ""
                            this.project_id = ""
                            this.ruleForm.project_name = ""
                            this.ruleForm.user = ""
                            this.ruleForm.type = ""
                            this.chat_data = ''
                            this.closeForm()

                        }
                    })

                    this.known_users = this.known_users.filter(e => e.hide != true)

                } else if (this.ruleForm.type == 'chat') {

                    if (!this.ruleForm.project_name) return

                    if (this.editChat) {

                        this.users_to_add.forEach((e, i) => {
                            this.users_to_delete.forEach((a, i2) => {
                                if (a._id == e._id) {
                                    this.users_to_add.splice(i, 1)
                                    this.users_to_delete.splice(i2, 1)
                                }
                            })
                        })

                        if (this.chat_add_user) {
                            if (this.users_to_add.length > 0) {
                                let data = {
                                    type: "add_list_offline",
                                    data: {
                                        text: "There is a list linked to this chat. The user(s) you are adding will automtically be added to the linked list, too.",
                                        cancel: "Cancel",
                                        delete: "Proceed",
                                        class: {
                                            cancel: 'red',
                                            delete: 'blue'
                                        }
                                    },
                                    list: formName
                                }
                                EventEmitter.$emit("OpenPopup", data)
                                return
                            }
                        }

                        if (this.ruleForm.project_name != this.project_name) {
                            this.$socket.emit('IN_RenameChats', this.ruleForm.project_name, this.project_id)
                        }

                        if (this.users_to_add.length > 0) {

                            setTimeout(() => {
                                this.users_to_add.forEach((user) => {
                                    if (this.chat_data.auth == this.getUserData._id) {
                                        this.addTeamsUser(user)
                                        this.known_users.push(user)
                                        let res = this.known_users.reduce((o, i) => {
                                            if (i._id) {
                                                if (!o.find(v => v._id == i._id)) {
                                                    o.push(i)
                                                }
                                            } else {
                                                o.push(i)
                                            }
                                            return o
                                        }, [])
                                        this.known_users = res
                                    }
                                    this.$socket.emit('IN_AddUserChats', user._id, this.project_id, this.users_to_add)
                                })

                                if (this.chat_data.project_id) {
                                    const add_users = {
                                        users: this.users_to_add,
                                        project_id: this.chat_data.project_id,
                                        users_to_delete: this.users_to_delete,
                                        teams: this.new_teams,
                                        type: "add"
                                    }
                                    this.socket_add_user_to_project(add_users)
                                }

                            }, 1000)

                        }

                        if (this.users_to_delete.length > 0) {
                            let remove_users = []
                            for await (let user of this.users_to_delete) {
                                remove_users.push(user._id)
                            }
                            this.$socket.emit('IN_RemoveUserChats', {
                                chat_id: this.project_id,
                                remove_users: remove_users
                            })
                        }

                    } else {

                        let w_bool = false

                        this.$w_plan(this, this.getPlan, 'chat', () => {
                            this.setMessage({
                                key_message: "warning",
                                message: "You can't add more chats",
                                plan: true
                            })
                            w_bool = true
                        })

                        if (w_bool) {
                            return
                        }

                        const id = this.$w_decrypt(this.getUserData._id)
                        this.$socket.emit('IN_AddChats', id, this.ruleForm.project_name)

                        if (this.users_to_add.length > 0) {
                            setTimeout(() => {
                                this.users_to_add.forEach((user) => {
                                    this.$socket.emit('IN_AddUserChats', user._id, id, this.users_to_add)
                                })
                            }, 500)
                        }
                    }

                    this.closeForm()

                }

            } else {
                let data = {
                    type: 'add_list_offline',
                    list: formName,
                    data: {
                        text: `Creating the list "${this.ruleForm.project_name}" did not work as expected. This feature is not available offline. Please connect to the internet and try again.`,
                        cancel: 'Close'
                    }
                }
                EventEmitter.$emit('OpenPopup', data)
            }

            return false

        },
        closeForm() {
            this.show = false
            this.drawer = false
            this.formActive = false
            this.addUserInputActive = false

            if (this.ruleForm.type != 'chat')
            {
                this.project_name = ""
                this.ruleForm.project_name = ""
                this.users_to_delete = []
                this.users_to_add = []
                this.new_project_id = ''
            }

            setTimeout(() => {

                this.users_to_delete = []
                this.users_to_add = []
                this.project_id = ""
                this.ruleForm.user = ""
                this.ruleForm.type = ""
                this.chat_data = ''
                this.invitation_bool = true
                this.invitation_bool2 = true
                this.invitation_user = ''
                this.new_teams = []

            }, 1000)

        },
        async querySearch(queryString, cb) {
            
            let known_users = this.known_users
            this.last_result = {}
            this.addUserInputActive = false

            if (queryString) {

                let resultName = known_users.filter(this.createFilter(queryString))
                let resultEmail = known_users.filter(this.createFilterEmail(queryString))
                let resultSearch = resultEmail.concat(resultName)

                let results = resultSearch.reduce((o, i) => {
                    if (i._id) {
                        if (!o.find(v => v._id == i._id)) {
                            o.push(i)
                        }
                    } else {
                        o.push(i)
                    }
                    return o
                }, [])

                if (results.length > 0) {

                    if (results.length == 1) {

                        // results[0].hide = false
                        results[0].is_active = true
                        this.last_result = results[0]
                        this.addUserInputActive = true

                    } else {

                        this.last_result = {}
                        this.addUserInputActive = false

                        results.forEach((user_res) => {
                            user_res.is_active = false
                        })

                    }

                    cb(results)

                } else {

                    if (queryString && this.$w_email_validate(queryString)) {
                        this.$w_throttle(this.userRequest({ queryString, cb, vue: this }), 1000)
                    } else {
                        this.last_result = {}
                        this.addUserInputActive = false
                        cb([])
                    }

                }

            } else {
                cb(known_users)
            }

        },
        createFilter(queryString) {
            return (known_user) => {
                return (known_user.name.toLowerCase().indexOf(queryString.toLowerCase()) >= 0)
            }
        },
        createFilterEmail(queryString) {
            return (known_user) => {
                return (known_user.email.toLowerCase().indexOf(queryString.toLowerCase()) >= 0)
            }
        },
        async userRequest({ queryString, cb, vue }) {

            const req = {
                token: true,
                method: "POST",
                body: {
                    email: queryString
                },
                url: vue.$w_api.SEARCH_USER_FROM_EMAIL
            }

            const res = await vue.fetch_request(req)

            if (res.ok) {

                const resArr = await res.json()

                if (resArr.length > 0) {

                    if (resArr.length == 1) {
                        resArr[0].is_active = true
                        vue.last_result = resArr[0]
                        vue.addUserInputActive = true
                    }

                    return cb(resArr)

                } else {

                    let unf = [{
                        email: "Click here to send an invitation to this user",
                        name: "User not found",
                        _id: "UNF",
                        error: true,
                        is_active: true,
                        email_to_send: this.ruleForm.user
                    }]

                    vue.last_result = unf[0]
                    vue.addUserInputActive = true

                    return cb(unf)

                }
            } else {

                vue.last_result = {}
                vue.addUserInputActive = false

                return cb([{
                    email: "",
                    name: "Произошла ошибка на сервере",
                    _id: "SE",
                    error: true
                }])

            }

        },
        handleSelect(user) {

            let data = this.ruleForm.type == 'list' ? 'max_users_list' : 'max_users_chat'

            if (this.getPlan[data] == this.getTeams.length) {
                this.setMessage({
                    key_message: "warning",
                    message: "Team max amount " + this.getPlan[data],
                    plan: true
                })
                return
            }

            if (!user.error) {

                let bool = true

                this.$socket.emit('IN_CheckUserPlan', {
                    user_id: user._id,
                    type: this.ruleForm.type
                })

                let new_user_add = this.getTeams.filter((e, i) => e._id == user._id)

            } else if (user._id == "UNF") {
                if (this.invitation_bool2) {

                    this.$refs.invite_user_success.classList.remove("is_hide")

                    setTimeout(() => {
                        this.$refs.invite_user_success.classList.add("is_hide")
                    }, 1500)

                    this.socket_IN_NewUser_sendEmail({
                        name: this.ruleForm.project_name,
                        email: user.email_to_send,
                        list_id: this.new_project_id ? this.new_project_id : this.project_id,
                        chat_id: this.chat_data ? this.chat_data._id : ''
                    })

                } else {
                    this.invitation_bool = false
                    this.invitation_user = user
                }

            }

            this.known_users = this.getTeams
            this.last_result = {}
            this.addUserInputActive = false
            this.ruleForm.user = ""

        },
        add_user_in_project_list(user) {
            if (!this.users_in_project.some((u) => u._id == user._id)) {
                this.users_in_project.push(user)
            }
        },
        add_user_in_add_users_list(user) {

            if (!this.users_to_add.some((u) => u._id == user._id)) {
                this.users_to_add.push(user)
            }

        },
        delete_user_in_project_list(user) {

            if (this.ruleForm.type == 'list') {
                let isLinkList = this.getChats.find(e => e.project_id == this.project_id)
                if (isLinkList) {
                    let data = {
                        type: "link_list_chat_remove_user",
                        data: {
                            text: "You can't remove this user as he is a member of a chat this list is linked to",
                            cancel: 'Ok',
                            delete: ''
                        }
                    }
                    EventEmitter.$emit('OpenPopup', data)
                } else {
                    this.users_to_delete.push(user)

                    this.users_in_project = this.users_in_project.filter((u) => u._id != user._id)
                }
            } else {
                this.users_to_delete.push(user)

                this.users_in_project = this.users_in_project.filter((u) => u._id != user._id)
            }

            // this.users_to_add = this.users_to_add.filter((u) => u.id != user.id)

        },
        closeModal() {
            this.activeFlag = false
        }
    },
    computed: {
        ...mapGetters([
            "getUserData",
            "getKnownUsers",
            "getTeams",
            "getProjects",
            "getRenderKnownUsers",
            "getRenderUserData",
            "getLists",
            "getPlan",
            "getChats",
            "get_flag_for_last_chat"
        ])
    },
    watch: {
        async drawer(e) {
            await this.$nextTick()
        },
        getRenderUserData() {

            this.checkbox = {
                high_priority: false,
                medium_priority: false,
                assigned_to_me: false,
                today: false,
                inbox: false
            }

            this.reset_shortcuts = this.getUserData.shortcuts

            this.getUserData.shortcuts.forEach((e) => {

                if (e.type == 'high_priority') {
                    this.checkbox.high_priority = true
                }
                if (e.type == 'medium_priority') {
                    this.checkbox.medium_priority = true
                }
                if (e.type == 'assigned_to_me') {
                    this.checkbox.assigned_to_me = true
                }
                if (e.type == 'today') {
                    this.checkbox.today = true
                }
                if (e.type == 'inbox') {
                    this.checkbox.inbox = true
                }

            })

        },
        getRenderKnownUsers() {
            this.known_users = this.getTeams
        },
        get_flag_for_last_chat() {

            this.users_in_project = []
            const project = this.getProjects.find((pr) => pr._id == this.$route.params.project_id)

            if (project) {

                this.project_name = project.name
                this.ruleForm.project_name = project.name

                let users = []

                // if (this.users_to_add) {
                //     if (this.users_to_add.length > 0) {
                //         this.users_to_add.forEach(e => {
                //             users.push(e)
                //         })
                //     }
                // }

                // project.users.forEach(e => {
                //     users.push(e)
                // })

                project.users.forEach((user) => {

                    let has_delete = user._id == this.getUserData._id ? false : true

                    if (project.author == user._id) {
                        has_delete = false
                        this.add_user_in_project_list({ owner: true, ...user, has_delete })
                    } else {
                        if (user._id) {
                            this.add_user_in_project_list({ ...user, has_delete })
                        }
                    }

                })

            }

        }
    }

}

</script>
