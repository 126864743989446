export default function (date, type = "chat", format = {day: "numeric",month: "long"}) {
    // console.log('date', date)
  try {

    if (!isNaN(date)) {
      date = new Date(+date).toISOString()
    }

    const today = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    ).getTime();
    const yestarday = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 1
    ).getTime();
    const tomorrow = new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    ).getTime();
    const date_from_comment = new Date(
      new Date(date).getFullYear(),
      new Date(date).getMonth(),
      new Date(date).getDate()
    ).getTime();
    if (date_from_comment == today) return "Today";
    else if (date_from_comment == yestarday) return "Yesterday";
    else if (date_from_comment == tomorrow) return "Tomorrow";
    else if (type == "chat")
      if(new Date().getFullYear() == new Date(date).getFullYear()){
          return new Intl.DateTimeFormat("en-GB", format).format(new Date(date))
      }else{
          return new Intl.DateTimeFormat("en-GB", format).format(new Date(date)) + ', ' + new Date(date).getFullYear()
      }
    else if (type == "history")
      return new Intl.DateTimeFormat("en-GB", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
      }).format(new Date(date));
  } catch (error) {
    
  }
    // return new Date(date).getDate() + '/' + new Date(date).getMonth() + '/' + new Date(date).getFullYear()
}
