<template>
  <div v-if="getAppLoader && isNetwork">
    <Loader />
  </div>

  <div class="content" v-else>
    <div class="content__bg" @click="clickBgComment"></div>
    <router-view />

    <AddRenameProjectAddUser />
    <LightBox />
    <Popup />

    <div class="duplicate"></div>

    <div class="alert" v-if="alerts.length > 0">
      <div v-for="(alert, i) of alerts" :key="i" :class="alert.class"
        :style="alert.icon ? '' : 'justify-content: center;'">
        <div class="alert-message__icon" v-if="alert.icon">
          <div :class="alert.text == 'Synced' ? 'loader stop' : 'loader'"></div>
        </div>
        <div class="alert-message__text">
          {{ alert.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeftSidebar from '@/components/app/LeftSidebar.vue';
import LightBox from '@/components/app/LightBox.vue';
import Popup from '../components/app/Popup.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import AddRenameProjectAddUser from '@/components/projects/AddRenameProjectAddUser';
import SettingsTemplate from '../components/app/SettingsTemplate.vue';
import { EventEmitter } from '../main';

export default {
  name: 'MainLayout',
  data() {
    return {
      sideSpan: 232,
      mainSpan: 1625,
      sideClass: '',
      interval: '',
      alerts: [],
      connect_bool: true,
      isNetwork: false,
    };
  },
  sockets: {
    connect: async function () {
      console.log('Socket Connect');

      this.alerts = [
        {
          icon: true,
          text: 'Syncing',
          class: 'alert-message',
        },
      ];

      await this.handleGetData();

      if (this.interval) {
        clearInterval(this.interval);
      }

      this.$socket.emit('OUT_Device', {
        electron: window.isElectron,
        mobile_web: true,
        desktop_web: false,
      });
    },
    disconnect: async function (e) {
      console.log('Socket Disconnect');

      this.handleSocketDisconnected();

      let task_message = localStorage.getItem('task_message');

      if (task_message) {
        let task_message_array = JSON.parse(task_message);
        EventEmitter.$emit('noSendMessages', task_message_array);
        this.changeCommentLoading();
      }

      setTimeout(() => {
        this.$socket.connect();
      }, 5000);

      this.interval = setInterval(() => {
        this.$socket.connect();
      }, 30000);
    },
    connect_error: async function (e) {
      console.log('Socket Connect Error');
      console.log(e.message);
      if (e.message == 'jwt expired' || e.message == 'invalid signature') {
        localStorage.removeItem('w_token');
        let check_token = await this.check_token();
        if (check_token) {
          this.$socket.disconnect();
          this.$socket.io.opts.transportOptions.polling.extraHeaders.Authorization =
            check_token;
          this.$socket.connect();
        } else {
          localStorage.clear();
          window.location.href = '/';
        }
      }
      if (e.message == 'invalid token') {
        localStorage.clear();
        window.location.href = '/';
      }
    },
  },
  components: {
    LeftSidebar,
    LightBox,
    AddRenameProjectAddUser,
    SettingsTemplate,
    Popup,
  },
  async mounted() {
    this.$socket.io.opts.transportOptions.polling.extraHeaders.Authorization =
      localStorage.getItem('w_token');
    this.$socket.connect();

    window.addEventListener('offline', () => {
      this.$socket.disconnect();
      this.handleSocketDisconnected();
    });

    window.addEventListener('online', async () => {
      this.$socket.connect();
      this.$router.push('/');
      const curUser = localStorage.getItem("w_token");
      if (curUser) {
        location.reload();
      }
    });

    if (navigator.onLine) {
      this.isNetwork = true;
      this.onAppLoader();
    }

    this.watchWindowWidth();

    setTimeout(() => {
      this.changeTitle(this.$route);
    }, 1000);

    EventEmitter.$on('SOCKET_OUT_ImportData', (data) => {
      if (data) {
        setTimeout(() => {
          window.location.href = window.location.href;
        }, 3000);
      }
    });
  },
  methods: {
    ...mapActions([
      'check_token',
      'fetchUserData',
      'socket_send_firebase_token',
      'SOCKET_IN_Company',
      'getData',
      'addChatUnreadMessagesRecievedWhileUpdating',
    ]),
    ...mapMutations([
      'onAppLoader',
      'setShowSmalActivityLog',
      'changeCommentLoading',
      'renderImageTeamsAndKnow',
    ]),
    clickBgComment() {
      this.$root.$emit('clickBgComment')
    },
    changeTitle(to) {
      let title = 'TaskPal';

      if (to.params.project_id) {
        this.getProjects.forEach((e) => {
          if (e._id == to.params.project_id) {
            title = e.name;
          }
        });
      }

      if (to.params.chat_id) {
        this.getChats.forEach((e) => {
          if (e._id == to.params.chat_id) {
            title = e.name;
          }
        });
      }

      if (to.name == 'shortcuts') {
        if (to.params.shortcuts == 'high_priority') {
          title = 'High priority';
        }
        if (to.params.shortcuts == 'today') {
          title = 'Today';
        }
        if (to.params.shortcuts == 'assigned_to_me') {
          title = 'Assigned to me';
        }
        if (to.params.shortcuts == 'medium_priority') {
          title = 'Medium priority';
        }
      }

      document.title = title + ': TaskPal';
    },
    watchWindowWidth() {
      const width = window.innerWidth;
      if (width > 1024) {
        this.sideSpan = 232;
        this.sideClass = '';
      } else if (width <= 1024) {
        this.sideSpan = 40;
      }
      this.mainSpan = width - this.sideSpan;

      if (width <= 1024) {
        this.sideClass = 'sidebar_collapse active';
      }
    },
    toggle_collapse() {
      this.$refs.collapse_sidebar.classList.toggle('active');
    },
    resetSubtaskOpen() {
      const len = Object.keys(this.getTasks).length;
      if (len > 0) {
        for (let list in this.getTasks) {
          const currentProject = this.getTasks[list];
          for (let i = 0; i < currentProject.length; i++) {
            if (currentProject[i].parent && currentProject[i].unread_message) {
              localStorage.setItem(currentProject[i].parent, true);
              EventEmitter.$emit('openSubtask', currentProject[i]._id);
              i = currentProject.length + 5;
            }
          }
        }
      }
    },
    async handleGetData() {
      return await this.getData()
        .then((res) => {
          this.resetSubtaskOpen();
          this.alerts = this.alerts.filter((alert) => alert.text !== 'Syncing');

          this.alerts.push({
            icon: true,
            text: 'Synced',
            class: 'alert-message',
          });

          setTimeout(() => {
            this.addChatUnreadMessagesRecievedWhileUpdating();
          }, 0);

          setTimeout(() => {
            this.alerts = this.alerts.filter(
              (alert) => alert.text !== 'Synced'
            );
          }, 1000);
        })
        .catch((err) => {
          console.log('Get Data Error', err);
          this.$socket.disconnect();
        });
    },
    handleSocketDisconnected() {
      this.alerts = [];
      this.alerts.push({
        icon: true,
        text: 'Connecting',
        class: 'alert-message',
      });
    },
  },
  created() {
    if (
      !localStorage.getItem('showSmallActivityLog') ||
      localStorage.getItem('showSmallActivityLog') == 'true'
    )
      window.addEventListener('resize', this.watchWindowWidth);
  },
  computed: {
    ...mapGetters([
      'getAppLoader',
      'getUserData',
      'get_flag_for_send_firebase_token',
      'getProjects',
      'getTasks',
      'getChats',
      'getCompletedTasks',
      'get_reloadData',
    ]),
  },
  watch: {
    get_reloadData() {
      setTimeout(() => {
        this.renderImageTeamsAndKnow();
      }, 500);
    },
    get_flag_for_send_firebase_token(e) { },
    $route(to) {
      this.changeTitle(to);
    },
  },
};
</script>
