<template>
    <div class="setting_menu" v-if="show">
        <div class="setting_menu__profile" @click="openSettings('profile')">
            <!-- <img v-if="getUserData.image" class="setting_menu__profile__avatar" :src="$w_user_image(getUserData)" alt=""> -->
            <div class="setting_menu__profile__avatar" :style="'background-color: ' + getUserData.color" v-html="$w_user_image(getUserData)"></div>
            <div class="setting_menu__profile__name">
                <p>{{getUserData.name}}</p>
                <span>{{getUserData.email}}</span>
            </div>
        </div>
        <div class="setting_menu__link">
            <div @click="openSettings('setting')">
                Settings
            </div>
            <!-- <div @click="openSettings('upgrade')">
                Upgrade
            </div> -->
            <div @click="update" v-if="new_vertion">
                Update available ({{version}})
            </div>
            <div class="logout" @mouseover="hoverChangeBackground" @mouseout="hoverChangeBackground" @click="logout">
                Logout
            </div>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapMutations } from "vuex"
    import { EventEmitter } from '../../main'
    import AutoReload from '../../plugins/AutoReload/AutoReload'
    import { getVersion } from '../../plugins/AutoReload/utils'

    export default {
        props: {
            show: [Boolean]
        },
        data() {
            return {
                serviceWorkerRegistration: '',
                new_vertion: false,
                version: ''
            }
        },
        computed: {
            ...mapGetters([
                "getUserData",
            ])
        },
        mounted() {
            document.addEventListener('swUpdated', (event) => {{
                this.serviceWorkerRegistration = event.detail
            }}, { once: true })
            // document.onclick = () => {
            //     console.log('11')
            //     if (this.show) {
            //         EventEmitter.$emit('closeMenuSettings', false)
            //     }
            // }
            EventEmitter.$on('new_vertion', (data) => {
                this.new_vertion = true
                this.version = data
            })
        },
        methods: {
            ...mapMutations([
                "setProjects",
                "clearComments",
                "clearTasks",
                "clearUserUsedFileSize",
                "clearUserAllFileSize",
                "clearChat",
                "clearLists"
            ]),
            logout() {
                this.$socket.disconnect();
                localStorage.removeItem("w_token");
                localStorage.removeItem("w_clone_token");
                // window.location.reload()
                if (window.isElectron)
                {
                    this.$router.push({
                        path: "/login",
                        query: {
                            app_logout: 1
                        }
                    });
                }
                else
                {
                    this.$router.push("/login")
                }
                setTimeout(() => {
                    this.clearChat()
                    this.clearLists()
                    this.clearComments();
                    this.clearUserUsedFileSize();
                    this.clearUserAllFileSize();
                    this.clearTasks();
                    localStorage.clear();
                }, 1500);
                console.log("User Logout");
            },
            hoverChangeBackground() {
                if (document.querySelector('.setting_menu').classList.contains('color-red')){
                    document.querySelector('.setting_menu').classList.remove('color-red')
                } else {
                    document.querySelector('.setting_menu').classList.add('color-red')
                }
            },
            openSettings(e) {
                EventEmitter.$emit('openSettings', e)
            },
            async update() {
                let update_version = new AutoReload({
                    config: {
                        Enabled: true,
                        CheckInterval: 1
                    },
                    router: this.$router,
                    vm: this
                })
                update_version.reload()
            }
        }
    }

</script>