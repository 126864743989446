export default function (getKnownUsers, getUserData, user_id) {
    let data = ''
    if (getUserData._id == user_id) {
        data = {
            _id: getUserData._id,
            name: getUserData.name,
            email: getUserData.email,
            image: getUserData.image,
            color: getUserData.color
        }
    } else {
        getKnownUsers.forEach((e, i) => {
            if (e._id == user_id) {
                data = e
            }
        })
    }
    return data
}