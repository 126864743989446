<template>
  <div class="load">
    <svg
      width="200"
      height="45"
      viewBox="0 0 200 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2913 24.1137L33.1036 32.3415L34.1862 27.2511L13.8511 21.4805L13.2913 24.1137Z"
        fill="#0077FF"
      />
      <path
        d="M37.8078 10.2172L16.6495 8.32227L15.9653 11.54L36.753 15.1802L37.8078 10.2172Z"
        fill="#0077FF"
      />
      <path
        d="M14.6697 17.6306L34.9214 23.7951L35.977 18.8283L15.2342 14.9766L14.6697 17.6306Z"
        fill="#0077FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M42.5337 1.81057C45.1769 1.98431 46.8719 4.25767 46.3012 6.86308L40.3399 32.5256C38.502 40.9179 33.1159 45.3071 24.8294 44.6924C22.4557 44.5163 20.1322 43.9148 17.9406 42.9851L7.27425 38.46C5.93216 37.8909 4.67425 37.1287 3.58931 36.1549C1.20552 34.016 -0.338104 31.0376 0.0634403 26.9858C0.146066 26.1511 0.980814 25.6075 1.77309 25.8816C8.27116 28.1233 14.7692 30.365 21.2673 32.6067C22.0101 32.8631 22.5167 33.5472 22.5615 34.3318C22.8302 39.0731 25.8055 40.5256 28.7684 40.4306C32.8781 40.2986 35.4333 36.6986 36.3298 32.6059L42.3453 6.66153C42.458 6.1681 42.102 5.6901 41.5962 5.65767L15.1592 3.92254C14.902 3.90555 14.6712 4.08007 14.6171 4.3318L9.74529 27.2445L6.18004 25.9789L10.8781 3.1681C11.2858 1.30555 13.1654 -0.116071 15.0542 0.00748154L42.5337 1.81057Z"
        fill="#0077FF"
      />
      <path
        d="M68.377 16.5827V34.636H63.0843V16.5827H55.95V12.3086H75.5445V16.5827H68.377Z"
        fill="#0077FF"
      />
      <path
        d="M78.8942 30.2978L77.0517 34.636H71.5923L81.7745 12.3086H87.234L97.2494 34.636H91.5884L89.7467 30.2978H78.8942ZM84.3537 17.5711L80.6355 26.2468H88.0378L84.3537 17.5711Z"
        fill="#0077FF"
      />
      <path
        d="M115.37 14.5099L113.327 18.6883C110.848 17.2852 107.901 16.3917 106.025 16.3917C104.25 16.3917 103.044 16.9979 103.044 18.2095C103.044 22.3238 115.538 20.0272 115.504 28.0659C115.504 32.4357 111.518 34.7956 106.327 34.7956C102.475 34.7956 98.6226 33.3925 95.9771 31.255L98.0875 27.1408C100.499 29.1825 103.916 30.4898 106.395 30.4898C108.572 30.4898 109.878 29.7245 109.878 28.3531C109.878 24.1431 97.3848 26.5987 97.3848 18.6883C97.3848 14.6373 101.036 12.0859 106.462 12.0859C109.742 12.0852 113.025 13.0427 115.37 14.5099Z"
        fill="#0077FF"
      />
      <path
        d="M126.222 25.5457L122.839 29.1179V34.636H117.546V12.3086H122.839V22.4514L132.586 12.3086H138.716L129.907 21.6221L139.318 34.636H132.955L126.222 25.5457Z"
        fill="#0077FF"
      />
      <path
        d="M150.505 12.3086C156.467 12.3086 159.883 15.1155 159.883 20.0592C159.883 25.2584 156.467 28.2568 150.505 28.2568H145.85V34.636H140.558V12.3086H150.505ZM145.85 24.046H150.237C153.185 24.046 154.86 22.7696 154.86 20.2182C154.86 17.7302 153.185 16.4862 150.237 16.4862H145.85V24.046Z"
        fill="#0077FF"
      />
      <path
        d="M164.572 30.2978L162.73 34.636H157.27L167.452 12.3086H172.913L182.927 34.636H177.266L175.424 30.2978H164.572ZM170.032 17.5711L166.314 26.2468H173.717L170.032 17.5711Z"
        fill="#0077FF"
      />
      <path
        d="M200 30.2337V34.6352H184.659V12.3086H189.951V30.2337H200Z"
        fill="#0077FF"
      />
    </svg>
    <div class="load__loading">
      Loading
      <div class="load__spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Loader',
  computed: {
    ...mapGetters(['getAppLoader']),
  },
};
</script>
