<template>
    <div id="app">
        <component :is="layout">
            <router-view />
        </component>

        <div class="alert" v-if="messages.length > 0">
            <div v-for="(alert, i) of messages" :key="i" :class="alert.class"
                :style="alert.icon ? '' : 'justify-content: center;'">
                <div class="alert-message__icon" v-if="alert.icon">
                    <div :class="alert.text == 'Synced' ? 'loader stop' : 'loader'"></div>
                </div>
                <div class="alert-message__text">
                    {{ alert.text }}
                    <a href="" v-if="alert.plan" @click="openSettingPlan">Upgrade</a>
                    <span v-if="alert.after_text_upgrade" style="margin-left: 4px;">{{ alert.after_text_upgrade }}</span>
                    <i v-if="alert.plan" @click="closeAlert(i)" class="el-icon-close"></i>
                </div>
            </div>
        </div>

        <MenuAction />
        <ProjectLists :chat_id="chat._id" :chat_users="chat ? chat.users : []" :project_id="chat.project_id">
        </ProjectLists>
    </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations } from "vuex"
import AuthLayout from "@/layouts/AuthLayout"
import AuthTokenLayout from "@/layouts/AuthTokenLayout"
import MainLayout from "@/layouts/MainLayout"
import ImportLayout from "@/layouts/ImportLayout"
import VerificationLayout from "@/layouts/VerificationLayout"
import NewPasswordLayout from "@/layouts/NewPasswordLayout"
import TokenLayout from "@/layouts/TokenLayout"
import ForGotPasswordLayout from "@/layouts/ForGotPasswordLayout"
import { EventEmitter } from './main'
import { getVersion } from './plugins/AutoReload/utils'
import MenuAction from '@/components/comment/MenuAction.vue'
import ProjectLists from "./components/chats/ProjectLists.vue"

import { PushNotifications } from "@capacitor/push-notifications";
import { LocalNotifications } from '@capacitor/local-notifications';
import { App as CapacitorApp } from '@capacitor/app';

export default {
    name: "App",
    data() {
        return {
            interval: '',
            alerts: [],
            messages: [],
            hasOrderChanged: false,
            firstMount: true,
            chat: {},
            getDataInterval: '',
            fetchController: { abort() { return } },
            isAppInForeground: false,
        }
    },
    methods: {
        ...mapMutations([
            "clearMessage",
            "changeCommentLoading",
            "setApplicationStatus",
            "authLoaderCheck",
            "changeAppInForeground",
        ]),
        ...mapActions([
            "check_token",
            "socket_send_firebase_token",
            "connectSocket",
            "getData",
            "addChatUnreadMessagesRecievedWhileUpdating",
        ]),
        closeAlert(i) {
            this.messages = this.messages.filter((e, index) => index != i)
        },
        setClass(e) {
            if (e.new) {
                return 'alertShow alert-message'
            }
            if (e.remove) {
                return 'alertHide alert-message'
            }
        },
        showMessage(e) {
            if (e.key_message) {

                const msg = e.message != "" ? e.message : this.$w_alert[e.key_message] ? this.$w_alert[e.key_message].text : ''

                if (msg) {
                    this.messages.push({
                        icon: '',
                        text: msg,
                        class: 'alertShow alert-message',
                        plan: e.plan ? true : false,
                        after_text_upgrade: e.after_text_upgrade,
                        upgrade_text: e.upgrade_text
                    })

                    if (e.plan) {
                        setTimeout(() => {
                            this.messages[0].class = 'alertHide alert-message'
                            this.messages.shift()
                        }, 10000)
                    } else {
                        setTimeout(() => {
                            this.messages[0].class = 'alertHide alert-message'
                            this.messages.shift()
                        }, 3500)
                    }
                }

            } else {

                if (this.$w_alert["no key_message"]) {
                    const msg = this.$w_alert["no key_message"].text

                    this.messages.push({
                        icon: '',
                        text: msg,
                        class: 'alertShow alert-message'
                    })

                    setTimeout(() => {
                        this.messages[0].class = 'alertHide alert-message'
                        this.messages.pop()
                    }, 2000)
                }

            }
        },
        openSettingPlan(e) {
            e.preventDefault()
            this.$router.push('/setting?tab=upgrade')
        },
    },

    mounted() {
        async function start() {
            await PushNotifications.requestPermissions();
            await PushNotifications.register();
        }

        start();

        CapacitorApp.addListener('backButton', () => {
            if (this.$route.query.isQuit) {
                CapacitorApp.exitApp();
            } else {
                window.history.back();
            }
        });

        CapacitorApp.addListener('appStateChange', (state) => {
            if (state.isActive) {
                this.changeAppInForeground(false);
            } else {
                this.changeAppInForeground(true);
            }
        });

        PushNotifications.addListener('registrationError', err => {
            alert(`'Registration error: ', ${err.error}`);
        });

        PushNotifications.addListener('pushNotificationActionPerformed', payload => {
            if (payload.actionId === 'tap') {
                const { url: link } = payload.notification.data;
                if (String(link)) {
                    this.$router.push({ path: String(link), query: { isQuit: 'true' } })
                }
            }
        })

        LocalNotifications.addListener('localNotificationActionPerformed', payload => {
            if (payload.actionId === 'tap') {
                const { url } = payload.notification.extra;
                this.$router.push(String(url))
            }
        },
        );

        if (!localStorage.getItem('w_token')) {
            this.authLoaderCheck()
        }

        if (Notification.permission != 'granted') {
            Notification.requestPermission()
        }

        EventEmitter.$on('fetchRequestController', (controller) => this.fetchController = controller)
        EventEmitter.$on('mainLayoutMounted', this.firstMount = true)
        EventEmitter.$on('openProjectsList', chat => this.chat = chat)

        const interval = setInterval(() => {
            const len = Object.keys(this.getTasks).length
            if (len > 0) {
                for (let list in this.getTasks) {
                    const currentProject = this.getTasks[list]
                    for (let i = 0; i < currentProject.length; i++) {
                        if (currentProject[i].parent && currentProject[i].unread_message) {
                            localStorage.setItem(currentProject[i].parent, true)
                            i = currentProject.length + 5
                        }
                    }
                }
                clearInterval(interval)
            }
        }, 1000)

        EventEmitter.$on('toggleOrderChanged', () => {
            if (this.$socket.disconnected) {
                this.hasOrderChanged = true
            }
        })

        EventEmitter.$on('taskOrderChangeComplate', async () => {
            if (this.hasOrderChanged) {
                this.hasOrderChanged = false
            }
        })

        EventEmitter.$on('open_notification_direction', (link = '') => {
            if (link) {
                this.$router.push(link)
            }
        })

        EventEmitter.$on('openPushNotificationLink', link => {
            if (this.$route.fullPath == link) return
            this.$router.push(link)
        })
    },
    computed: {
        ...mapGetters([
            "getAppLoader",
            "getTasks",
            "getProjects",
            "getMessage",
            "getUserData",
            "getChats",
            "getCompletedTasks",
            "getChatUnreadMessagesWhileUpdating",
            "getApplicationStatus"
        ]),
        layout() {
            return (this.$route.meta.layout || "main") + "-layout"
        }
    },
    watch: {
        getMessage(e) {
            if (e && e.key_message != "") this.showMessage(e)
        },
        getAppLoader(e) {
            if (e == false) {
                setTimeout(async () => {
                    if (localStorage.getItem('new_version')) {
                        let data = await getVersion()
                        if (data.Description) {
                            let data2 = {
                                type: 'Description new vertion',
                                data: {
                                    text: data.Description,
                                    cancel: 'Close',
                                    delete: ''
                                }
                            }
                            EventEmitter.$emit('OpenPopup', data2)
                        }
                    }
                }, 1000)
            }
        },
        $route() {
            setTimeout(() => {
                if (document.querySelector('.main-content')) {
                    if (this.$route.params.chat_id) {
                        document.querySelector('.main-content').classList.add('chat-background')
                    } else {
                        document.querySelector('.main-content').classList.remove('chat-background')
                    }
                }
            }, 100)
        }
    },
    components: {
        AuthLayout,
        MainLayout,
        VerificationLayout,
        NewPasswordLayout,
        ForGotPasswordLayout,
        ImportLayout,
        AuthTokenLayout,
        MenuAction,
        ProjectLists,
        TokenLayout
    }
}

</script>