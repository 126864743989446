<template>
  <div class="auth" ref="auth">
    <header class="header">
      <div class="container">
        <a href="#">
          <img :src="require('@/assets/static/images/auth-logo.svg')" alt="Logo">
        </a>
      </div>
    </header>
    <router-view :span="span" />
  </div>
</template>

<script>
export default {
  name: "AuthLayout",
  data() {
    return {
      span: 8,
    };
  },
  methods: {
    editSpan() {
      if (window.innerWidth > 1280) {
        this.span = 8;
      } else if (window.innerWidth <= 380) {
        this.span = 22;
      } else if (window.innerWidth <= 540) {
        this.span = 20;
      } else if (window.innerWidth <= 768) {
        this.span = 14;
      } else if (window.innerWidth <= 1280) {
        this.span = 10;
      }
    },
  },
  mounted() {
    this.editSpan();
  },
  created() {
    window.addEventListener("resize", this.editSpan);
  },
  unmounted() {
    window.removeEventListener("resize", this.editSpan);
  }
};
</script>

<style scoped lang="scss">
.header {
  padding-top: 20px;
  padding-bottom: 10px;
}

a {
  display: block;
  width: 140px;
}

img {
  max-width: 100%;
  height: auto;
}
</style>
