export default function(text = '', arrayButtons = []) {
  let delete_message_popup

  if (document.querySelector(".alert_popup"))
    delete_message_popup = document.querySelector(".alert_popup")
  else {
    delete_message_popup = document.createElement("div")
    delete_message_popup.classList.add("custom_message_popup")
    delete_message_popup.classList.add("alert_popup")
    this.$root.$el.insertAdjacentElement("beforeend", delete_message_popup)
  }

  delete_message_popup.innerHTML = ""
  const body = document.createElement("div")
  delete_message_popup.insertAdjacentElement("beforeend", body)
  const title = document.createElement("div")
  title.classList.add('text')
  title.innerHTML = text
  body.insertAdjacentElement("beforeend", title)
  const call_to_action_footer = document.createElement("div")
  call_to_action_footer.classList.add("call_to_action_footer")
  body.insertAdjacentElement("beforeend", call_to_action_footer)

  arrayButtons.forEach(button => {
    const btn = document.createElement("button")
    if (button.className) {
      btn.classList.add(button.className)
    }
    btn.innerHTML = button.buttonText || 'Cancel'
    call_to_action_footer.insertAdjacentElement("beforeend", btn)

    btn.addEventListener("click", (e) => {
      e.preventDefault()
      if (button.callback) {
        button.callback(close)
      }
    })
  })

  function close() {
    delete_message_popup.classList.remove("active")
    delete_message_popup.remove()
  }

  delete_message_popup.classList.add("active")

}