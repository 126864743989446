export default {
  LOGIN: `${process.env.VUE_APP_API_BASE_URL}/users/login`,
  REGISTER: `${process.env.VUE_APP_API_BASE_URL}/users/register`,
  SIGN_IN: `${process.env.VUE_APP_API_BASE_URL2}/api/users/sign-in`,
  SIGN_UP: `${process.env.VUE_APP_API_BASE_URL2}/api/users/sign-up`,
  GET_TOKEN: `${process.env.VUE_APP_API_BASE_URL2}/gettoken`,
  EXPORT_BACKUP: `${process.env.VUE_APP_API_BASE_URL2}/backup/export`,
  IMPORT_BACKUP: `${process.env.VUE_APP_API_BASE_URL2}/backup/import`,
  DOWNLOAD_BACKUP: `${process.env.VUE_APP_API_BASE_URL2}/backup/download`,
  CHANGE_VERSION: `${process.env.VUE_APP_API_BASE_URL2}/update_versions`,
  AUTHORIZATION: `${process.env.VUE_APP_API_BASE_URL2}/api/users/autorisation`,
  GET_USER_DATA: `${process.env.VUE_APP_API_BASE_URL2}/api/users/profile`,
  POST_IMPORT_CSV: `${process.env.VUE_APP_API_BASE_URL2}/api/import/csv`,
  GET_DATA: `${process.env.VUE_APP_API_BASE_URL2}/api/users/data`,
  POST_Invitation: `${process.env.VUE_APP_API_BASE_URL2}/api/users/invitation`,
  POST_InvitationDecrypt: `${process.env.VUE_APP_API_BASE_URL2}/api/users/invitation_decrypt`,
  POST_Verification: `${process.env.VUE_APP_API_BASE_URL2}/api/users/verification`,
  ForGotPassword: `${process.env.VUE_APP_API_BASE_URL2}/api/users/for_got_password`,
  NewPassword: `${process.env.VUE_APP_API_BASE_URL2}/api/users/new_password`,
  LOGOUT: `${process.env.VUE_APP_API_BASE_URL2}/api/users/logout`,
  GET_PROJECTS: `${process.env.VUE_APP_API_BASE_URL}/projects`,
  ADD_PROJECT: `${process.env.VUE_APP_API_BASE_URL}/projects/add`,
  DELETE_PROJECT: `${process.env.VUE_APP_API_BASE_URL}/projects/delete`,
  UPDATE_PROJECT: `${process.env.VUE_APP_API_BASE_URL}/projects/update`,
  SEARCH_PROJECT: `${process.env.VUE_APP_API_BASE_URL}/projects/search`,
  GET_TASK: `${process.env.VUE_APP_API_BASE_URL}/task`,
  ADD_TASK: `${process.env.VUE_APP_API_BASE_URL}/task/add`,
  ASSIGN_USER_TO_TASK: `${process.env.VUE_APP_API_BASE_URL}/task/add-user`,
  DELETE_TASK: `${process.env.VUE_APP_API_BASE_URL}/task/delete`,
  UPDATE_TASK: `${process.env.VUE_APP_API_BASE_URL}/task/update`,
  DOWNLOAD_FILE: `${process.env.VUE_APP_API_BASE_URL2}/api/file`,
  UPLOAD_FILE: `${process.env.VUE_APP_API_BASE_URL2}/api/file/upload`,
  END_TYPING: `${process.env.VUE_APP_API_BASE_URL2}/api/typing/end`,
  SEARCH_USER_FROM_EMAIL: `${process.env.VUE_APP_API_BASE_URL2}/api/users/search_user`,
  STRIPE: `${process.env.VUE_APP_API_BASE_URL2}/api/stripe`,
  STRIPE_PLAN_CANCEL: `${process.env.VUE_APP_API_BASE_URL2}/api/stripe/cancel_subscription`,
  STRIPE_DOWNGRADE_CHECK: `${process.env.VUE_APP_API_BASE_URL2}/api/stripe/check_subscription`,
  STRIPE_COUPON_CHECK: `${process.env.VUE_APP_API_BASE_URL2}/api/stripe/check_coupon`,
  STRIPE_WEBHOOK: `${process.env.VUE_APP_API_BASE_URL2}/api/stripe/webhook`,
  FETCH_COMMENTS: `${process.env.VUE_APP_API_BASE_URL2}/api/list/task/comment`,
  FETCH_COMMENTS_CHAT: `${process.env.VUE_APP_API_BASE_URL2}/api/chat/comment`,
};
