import {EventEmitter} from "../../../main"
import moment from 'moment'
import router from "@/router"
import pushNotification from "@/utils/pushNotificationCustom";

const last_time = (e) => {
    let time = new Date(parseInt(e))
    let nowDate = new Date()

    console.log('last_time function')
    console.log(e)

    // let prefix = 'last seen '
    let prefix = ''

    if (time.getDay() == nowDate.getDay()) {
        let now = moment(new Date())
        let time = moment(new Date(parseInt(e)))
        let ago = moment.duration(now.diff(time)).humanize({m: 60, h: 24, d: 7, w: 4}) + " ago"
        console.log('case 1')
        return ago == 'a few seconds ago'? prefix + 'just now': prefix + ago
    } else if(this) {
        if (this.$w_date_parse)
        {
            console.log('case 2')
            if (this.$w_date_parse(time) == 'Yesterday') {
                console.log('case 2 1')
                return prefix + this.$w_date_parse(time)
            } else {
                console.log('case 2 2')
                return prefix + moment(e).format('DD.MM.YY')
            }
        }
    } else {
        console.log('case 3')
        let now = moment(nowDate)
        let time = moment(new Date(parseInt(e)))
        let ago = moment.duration(now.diff(time)).humanize({m: 60, h: 24, d: 7, w: 4}) + " ago"
        return ago == 'a few seconds ago'? prefix + 'just now': prefix + ago
    }
}


let render = true

export default {
    state: {
        chats: [],
        render: true,
        usersByChat: {},
        chatFetchedComments: {},
        chatsOldMessages: {},
        tempChatsIdsWithMessages: {}
    },
    actions: {
        connectSocket() {
            render = false
        },
        SOCKET_OUT_ChatChangeSidebar({commit, getters}, data) {
            commit('setChatSidebar', {...data, currentUserId: getters.getUserData._id})
            EventEmitter.$emit('chatSidebarWidthChange', data)
        },
        SOCKET_OUT_ChatStartTyping({commit, getters, dispatch}, data) {
            commit("add_typing_user", data);
            commit('render')
        },
        SOCKET_OUT_ChatEndTyping({commit, getters, dispatch}, data) {
            commit("delete_typing_user", data);
            commit('render')
        },
        SOCKET_OUT_AddChats({commit, getters, dispatch}, chats) {

            let new_user = null;
            let inviter_user = null;
            if (chats.length === 3)
            {
                inviter_user = chats[2];
                new_user = chats[1];
                chats = chats[0];
            }

            commit('setAddChats', chats)
            const users = chats.users
            if (users.length > 1) {
                for (let i = 0; i < users.length; i++) {
                    users[i].user_data = users[i]
                }
                commit("setUsersByChat", {chat_id: chats._id, users: users, type: ''})
            }

            // show notification
            if (new_user == getters.getUserData._id && inviter_user)
            {
                setTimeout(() => {

                    let chat_id = chats._id;
                    let inviter = '';
                    for ( let user of getters.getKnownUsers )
                    {
                        if (user._id == inviter_user)
                        {
                            inviter = user.name
                            console.log('inviter found')
                            console.log(user)
                            break;
                        }
                    }
                    const msg = inviter + ' added you to a chat'
                    pushNotification({type: 'addedToChat', chat_id, comment: msg })
                }, 500)
            }

            if (new_user !== getters.getUserData._id)
            {
                router.push(`/chats/${chats._id}`)
                EventEmitter.$emit('changeSidebarTabs', 'Chats')
                EventEmitter.$emit('changeTab', 'Chats')
            }
        },
        SOCKET_OUT_RenameChats({commit, getters, dispatch}, chats) {
            commit('setRenameChats', chats)
        },
        SOCKET_OUT_RemoveChats({commit, getters, dispatch}, chat_id) {
            commit('setRemoveChats', chat_id)
            commit('render')
            if (router.app.$route.params.chat_id == chat_id) {
                if (getters.getChats.length == 0) {
                    router.push('/inbox/' + getters.getUserData.shortcut_inbox).catch(()=>{})
                } else {
                    const firstChat = getters.getChats[0]
                    router.push(`/chats/${firstChat._id}`).catch(()=>{})
                }
            }
        },
        SOCKET_OUT_AddListsChats({commit, getters, dispatch}, chats) {
            commit('setAddListsChats', chats)
            EventEmitter.$emit('linkedProjectInChat', true)
            setTimeout(() => {
                EventEmitter.$emit('ChatAddList', chats.list_id)
            }, 100)
        },
        SOCKET_OUT_AddUserChats({commit, getters, dispatch}, chats) {
            commit("setUsersByChat", {chat_id: chats.chat_id, users: {...chats.users, user_data: chats.users}, type: 'add'})
            commit('setAddUserChats', chats)
            if (getters.getChats.find(e => e._id == chats.chat_id).auth == getters.getUserData._id) {
                commit('addTeamsUser', chats.users)
            }
        },
        SOCKET_OUT_RemoveUserChats({commit, getters, dispatch}, chats) {
            if (chats.remove_users.some(e => e == getters.getUserData._id)) {
                commit('setRemoveChats', chats.chat_id)
                if (router.app.$route.params.chat_id == chats.chat_id) {
                    if (getters.getChats.length == 0) {
                        router.push('/inbox/' + getters.getUserData.shortcut_inbox).catch(()=>{})
                    } else {
                        const firstChat = getters.getChats[0]
                        router.push(`/chats/${firstChat._id}`).catch(()=>{})
                    }
                }
            } else {
                commit('setRemoveUserChats', chats)
            }
        },
        SOCKET_OUT_LastSeen_time({commit, getters, dispatch}, data) {
            if (getters.getUserData._id != data.user_id) {
                if (getters.getPlan.show_online == 'on') {
                    commit('setLastSeen_time', data)
                    EventEmitter.$emit('ChatLastSeen', data )
                }
            }
        },
        SOCKET_OUT_RemoveMessages({commit, getters, dispatch}, messages) {
            EventEmitter.$emit('deleteComment', messages.message_id)
            commit('setRemoveMessages', messages)
            commit('render')
            commit("setCommentAdd")
        },
        SOCKET_OUT_RenameMessages({commit, getters, dispatch}, messages) {
            commit('setRenameMessages', messages)
            commit('render')
        },
        SOCKET_OUT_ReadMessages({commit, getters, dispatch}, messages) {
            commit('setReadMessages', messages)
            commit('render')
        },
        SOCKET_OUT_ReorderChats({commit}, data) {
            commit('renderChatOrder', data)
        },
        ChatsReorder({commit}, data) {
            commit('ChatsReorder', data)
        }
    },
    mutations: {
        renderChatOrder(state, data) {
            let chats = state.chats
            chats.forEach(e => {
                let obj = data.new_order.find(a => a.chat_id == e._id)
                if (obj) {
                    e.order = obj.order
                }
            })
            chats.sort( function (a, b) {
                return a.order - b.order
            })
            state.chats = chats
        },
        ChatsReorder(state, data) {
            state.chats = data
        },
        clearTempChatsIdsWithMessages(s, v) {
            s.tempChatsIdsWithMessages = {}
        },
        setUsersByChat(state, {chat_id, users, type}) {
            if (type == 'add') {
                if (!state.usersByChat[chat_id]) state.usersByChat[chat_id] = []
                else if (users.length) state.usersByChat[chat_id] = users
                else {}
            } else {
                state.usersByChat[chat_id] = users
            }
        },
        setChatSidebar(state, data) {
            const chat = state.chats.find(chat => chat._id == data.chat_id)
            chat.users.some(user => {
                if (user.user_id == data.currentUserId) {
                    user.sidebar_width = data.sidebar_width
                    return true
                }
            })
        },
        clearChat(s, v) {
            s.chatsOldMessages = {}
            s.chats.forEach(chat => s.chatsOldMessages[chat._id] = chat.messages)
            s.chats = []
        },
        setAllChatMessages(state, { chat_id, comments }) {
            const chat = state.chats.find(chat => chat._id == chat_id)
            chat.messages = mergeTwoCommentsWihtUniqueIds({ chatCurrentComments: chat.messages, chatComingComments: comments })
            state.chatFetchedComments[chat_id] = true
        },
        setChats(s, value) {

            const { getters, v } = value

            v.forEach((chat, i) => {

                v[i].color = this._vm.$w_hue_color[i]

                if (chat.messages.length > 0) {

                    v[i].hasMessage = chat.messages.length == 1 ? false : true

                    v[i].last_message = {
                        user_id: chat.messages[chat.messages.length - 1].user_id,
                        message: chat.messages[chat.messages.length - 1].message
                    }

                    const len = v[i].messages.length

                    s.tempChatsIdsWithMessages[chat._id] = chat.messages

                    if (!(chat._id in s.chatFetchedComments)) {
                        v[i].messages = v[i].messages.splice(len - 1)
                    } else {
                        v[i].messages = mergeTwoCommentsWihtUniqueIds({ chatCurrentComments: s.chatsOldMessages[v[i]._id], chatComingComments: v[i].messages })
                    }

                } else {
                    v[i].hasMessage = false
                    v[i].last_message = {
                        user_id: '',
                        message: ''
                    }
                }
                
                s.usersByChat[chat._id] = chat.users
                
                chat.users.some((user) => {
                    if (user.user_id == this.getters.getUserData._id) {
                        chat.order = user.order
                        chat.unread_messages = user.unread_number
                        user.last_seen = 'Online'
                        return true
                    }
                })

                if (chat.users.length > 0) {
                    chat.users.forEach((user, i) => {
                        if (getters.getUserData._id == user.user_id) {
                            chat.users[i].user_data = getters.getUserData
                            chat.users[i].email = getters.getUserData.email
                            chat.users[i].time = "Online"
                            chat.users[i].order = 0
                        } else {
                            getters.getKnownUsers.forEach((know_user) => {
                                if (know_user) {
                                    if (know_user._id == user.user_id) {
                                        chat.users[i].user_data = know_user
                                        chat.users[i].email = know_user.email
                                    }
                                }
                            })
                        }
                    })
                }

            })

            v.sort( function (a, b) {
                return a.order - b.order
            })

            s.chats = v

        },
        setAddChats(s, v) {
            v.color = this._vm.$w_hue_color[s.chats.length]
            if (v.messages.length > 0) {
                v.last_message = {
                    user_id: v.messages[v.messages.length - 1].user_id,
                    message: v.messages[v.messages.length - 1].message
                }
            } else {
                v.last_message = {
                    user_id: '',
                    message: ''
                }
            }
            s.chats.push(v)
        },
        setRenameChats(state, { chat_id, chat_name }) {
            state.chats.find(chat => chat._id == chat_id).name = chat_name
        },
        setRemoveChats(s, v) {
            s.chats.forEach((chat, i) => {
                if (chat._id == v) {
                    s.chats.splice(i, 1)
                }
            })
        },
        setAddListsChats(s, v) {
            s.chats.forEach((chat, i) => {
                if (chat._id == v.chat_id) {
                    s.chats[i].project_id = v.list_id
                }
            })
        },
        setAddUserChats(s, v) {
            s.chats.forEach((chat, i) => {
                if (v.chat_id == chat._id) {

                    let user_already_added = false

                    s.chats[i].users.forEach(user => {
                        if (user.user_id == v.users._id)
                        {
                            user_already_added = true
                        }
                    })

                    if (! user_already_added)
                    {
                        s.chats[i].users.push({
                            user_id: v.users._id,
                            user_data: v.users,
                            last_seen: v.users.last_seen,
                            time: v.users.last_seen == 'Online' ? 'Online' : last_time(v.users.last_seen)
                        })
                    }
                }
            })
        },
        setRemoveUserChats(s, v) {
            s.chats.forEach((chat, i) => {
                if (v.chat_id == chat._id) {
                    v.remove_users.forEach((user, i2) => {
                        s.chats[i].users = s.chats[i].users.filter(e => e.user_id != user)
                    })
                }
            })
        },
        set_last_seen(s, v) {
            s.chats.forEach((chat, i) => {
                chat.users.forEach((user, i2) => {
                    if (user.user_id == v) {
                        s.chats[i].users[i2].last_seen = 'Online'
                    }
                })
            })
        },
        setLastSeen_time(s, v) {
            let newChats = s.chats
            newChats.forEach((chat, i) => {
                chat.users.forEach((user, i2) => {
                    if (user.user_id == v.user_id) {
                        newChats[i].users[i2].last_seen = v.time
                    }
                })
            })
            s.chats = newChats
        },
        setAddMessages(s, v) {
            s.chats.forEach((chat, i) => {
                if (chat._id == v.chat_id) {
                    
                    let bool = true

                    s.chats[i].messages.forEach((msg, msg_index) => {
                        if (msg._id == v.message._id) {
                            bool = false
                            s.chats[i].messages[msg_index] = v.message
                        }
                    })

                    if (bool) {
                        s.chats[i].messages.push(v.message)
                    }

                }
            })
        },
        setLastMessage(s, v) {
            s.chats.forEach((chat, i) => {
                if (chat._id == v.chat_id) {
                    chat.users.forEach((user, user_i) => {
                        if (user.user_id != v.user_id) {
                            s.chats[i].users[user_i].unread_number = Number(s.chats[i].users[user_i].unread_number) + 1
                        }
                    })
                    if (chat.messages[chat.messages.length - 1]) {
                        s.chats[i].last_message = {
                            user_id: chat.messages[chat.messages.length - 1].user_id,
                            message: chat.messages[chat.messages.length - 1].message
                        }
                    }
                }
            })
        },
        setChangeReadLastMessage(s, v) {
            s.chats.forEach((chat, i) => {
                if (chat._id == v.chat_id) {
                    s.chats[i].users.forEach((user, user_i) => {
                        if (user.user_id == v.user_id) {
                            s.chats[i].users[user_i].read_last_message = v._id
                        }
                    })
                }
            })
        },
        setRemoveMessages(s, v) {

            s.chats.forEach((chat, i) => {

                if (chat._id == v.chat_id) {
                    if (s.chats[i].messages.length > 0) {

                        let msg = s.chats[i].messages.filter(e => e._id == v.message_id)[0]
                        if (msg) {
                            if (msg.users) {
                                if (!msg.users.filter(u => u == this.getters.getUserData._id)[0]) {
                                    s.chats[i].users.forEach((user, index) => {
                                        if (user.user_id == this.getters.getUserData._id) {
                                            if (s.chats[i].users[index].unread_number > 0) {
                                                s.chats[i].users[index].unread_number = Number(user.unread_number) - 1
                                            }
                                        }
                                    })
                                }
                            }
                        }

                        s.chats[i].messages.forEach((msg, msg_i) => {
                            if (v.message_id == msg._id) {
                                s.chats[i].messages.splice(msg_i, 1)
                            }
                        })

                    } else {

                        s.chats[i].users.forEach((user, index) => {
                            if (user.user_id == this.getters.getUserData._id) {
                                if (s.chats[i].users[index].unread_number > 0) {
                                    s.chats[i].users[index].unread_number = Number(user.unread_number) - 1
                                }
                            }
                        })

                    }
                }

            })

        },
        setRenameMessages(s, v) {
            s.chats.forEach((chat, i) => {
                if (chat._id == v.chat_id) {
                    s.chats[i].messages.forEach((msg, msg_i) => {
                        if (v.message_id == msg._id) {
                            s.chats[i].messages[msg_i].message = v.message
                        }
                    })
                }
            })
        },
        setReadMessages(s, v) {
            s.chats.forEach((chat, i) => {
                if (chat._id == v.chat_id) {
                    s.chats[i].users.forEach((user, user_i) => {
                        if (user.user_id == v.user_id) {
                            s.chats[i].users[user_i].read = true
                            s.chats[i].users[user_i].unread_number = 0
                            s.chats[i].users[user_i].read_last_message = v.msg_id
                        }
                    })
                    s.chats[i].messages.forEach((msg, msg_i) => {
                        s.chats[i].messages[msg_i].read = true
                        if (!msg.users) {
                            msg.users = []
                            msg.users.push(v.user_id)
                        } else {
                            let is_user = msg.users.filter(e => e == v.user_id) 
                            if (is_user.length == 0) {
                                msg.users.push(v.user_id)
                            }
                        }
                    })
                }
            })
        },
        render(s) {
            s.render = !s.render
        }
    },
    getters: {
        getChats: (s) => s.chats,
        getRender: (s) => s.render,
        getUsersByChat: s => s.usersByChat,
        getChatFetchedComments: s => s.chatFetchedComments,
        getTempChatsIdsWithMessages: s => s.tempChatsIdsWithMessages
    },
}

function mergeTwoCommentsWihtUniqueIds({ chatCurrentComments, chatComingComments }) {
    let commentsToAdd = []
    
    if (!chatCurrentComments) {
        return chatComingComments
    }
    
    if (chatCurrentComments && chatCurrentComments.length > chatComingComments.length) {
        let allCommentsById = new Map()
        chatComingComments.forEach(({ _id }) => allCommentsById.set(_id, true))
        chatCurrentComments.forEach(comment => {
            const { _id } = comment
            if (!allCommentsById.has(_id)) commentsToAdd.push(comment)
        })
    }

    return chatComingComments.concat(commentsToAdd)
}