import { EventEmitter } from "../../../main";
import router from "@/router";
import foregroundLocalNotification from "../../../utils/foreground_local_notification";

let global_render = true;
let this_is_user_create_new_list = true;
let remove_section = true;
let this_user_delete_list = true;
let leave_user_list = true;
let users_to_add = [];
let change_order_list = true;

function redirect(id, getters) {
  if (router.history.current.params.project_id == id) {
    let bool = true;
    if (getters.getLists["list"]) {
      getters.getLists["list"].forEach((e, i) => {
        if (e._id == id) {
          bool = false;
          if (getters.getLists["list"][i + 1]) {
            router
              .push("/project/" + getters.getLists["list"][i + 1]._id)
              .catch(() => true);
          } else {
            if (getters.getLists["list"][i - 1]) {
              router
                .push("/project/" + getters.getLists["list"][i - 1]._id)
                .catch(() => true);
            } else {
              router.push("/no-lists").catch(() => true);
            }
          }
        }
      });
    }
    if (bool) {
      if (getters.getLists["folder"]) {
        getters.getLists["folder"].forEach((folder) => {
          folder.lists.forEach((list, i) => {
            if (list._id == id) {
              if (folder.lists[i + 1]) {
                router
                  .push("/project/" + folder.lists[i + 1]._id)
                  .catch(() => true);
              } else {
                if (folder.lists[i - 1]) {
                  router
                    .push("/project/" + folder.lists[i - 1]._id)
                    .catch(() => true);
                } else {
                  if (getters.getLists["list"].length > 0) {
                    router
                      .push(
                        "/project/" +
                          getters.getLists["list"][
                            getters.getLists["list"].length - 1
                          ]._id
                      )
                      .catch(() => true);
                  } else {
                    router.push("/no-lists").catch(() => true);
                  }
                }
              }
            }
          });
        });
      } else {
        router.push("/no-lists").catch(() => true);
      }
    }
  }
}

export default {
  state: {
    projects: [],
    lists: [],
    folder_lists: [],
    hue_colors: [],
    colorIndex: 0,
    last_chat: [],
    flag_for_last_chat: true,
    user_to_add_in_project: {},
    usersByProjects: {},
    numberOfTasks: {},
    sortedLists: { lists: [], folders: [], all: [] },
  },
  actions: {
    socket_add_project({ getters, commit }, data) {
      let a = false;

      this._vm.$w_plan(getters, getters.getPlan, "list", () => {
        commit("setMessage", {
          key_message: "warning",
          message: "You can't add more lists.",
          plan: true,
        });
        a = true;
      });

      if (a) {
        return;
      }

      users_to_add = data.users_to_add;

      const new_project_body = {
        date: new Date().getTime(),
        name: data.name,
        _id: data._id,
        author: getters.getUserData._id,
        color: data.color,
        users: [
          {
            email: getters.getUserData.email,
            name: getters.getUserData.name,
            _id: getters.getUserData._id,
            order: data.order,
          },
        ],
      };
      if (
        getters.get_users_to_add_in_project[data._id] &&
        getters.get_users_to_add_in_project[data._id].length > 0
      ) {
        getters.get_users_to_add_in_project[data._id].forEach((user) => {
          commit("addKnownUser", user);
          new_project_body.users.push(user);
        });
      }
      this_is_user_create_new_list = false;
      commit("add_new_project", new_project_body);
      commit("set_last_chat", [new_project_body]);
      commit("add_list_lists", new_project_body);
      commit("set_task_project_id", data);
      commit("change_flag_for_last_chat");
      this._vm.$socket.emit("IN_ProjectAdd", data);
      router.push(`/project/${data._id}`);
      EventEmitter.$emit("changeSidebarTabs", "Lists");
      EventEmitter.$emit("changeTab", "Lists");
    },
    SOCKET_OUT_ProjectAdd({ commit, getters, dispatch }, pr) {
      let data = pr ? pr : [];

      commit("setUsersByProject", { project_id: data._id, users: data.users });

      if (this_is_user_create_new_list) {
        commit("add_new_project", data);
        commit("set_last_chat", [data]);
        commit("add_list_lists", data);

        if (data.bool) {
          commit("set_new_order_list");
        }

        commit("change_flag_for_last_chat");
      } else {
        this_is_user_create_new_list = true;
      }

      if (users_to_add) {
        if (users_to_add.length > 0) {
          dispatch("socket_add_user_to_project", {
            users: users_to_add,
            project_id: data._id,
            users_to_delete: [],
            type: "add",
          });
        }
      }

      // send message to user
      /*
                data.author
                data.name

                data.users[]

                data.users[i]._id == data.author
                data.users[i].name + ' added you to "' + data.name +'"';
            */

      // prevent seld sending
      //---------------------------------------------

      if (data.author != getters.getUserData._id) {
        // foreground push notification
        let showNotification = false;
        if (window.localStorage.getItem("mutedObjects")) {
          let mutedObjects = window.localStorage
            .getItem("mutedObjects")
            .split(",");
          if (!mutedObjects.includes(data.project_id)) {
            showNotification = true;
          }
        } else {
          showNotification = true;
        }
        if (showNotification) {
          let message_settings = {
            body: "",
            dir: "ltr",
            lang: "en-US",
            tag: "comment_" + data._id + "_notification",
            renotify: true,
          };

          let welcome_message = " shared a list with you";

          if (data.users.length) {
            data.users.forEach((user) => {
              if (user._id == data.invitor) {
                welcome_message = user.name + welcome_message;
              }
            });
          } else {
            welcome_message = "Someone shared a list with you";
          }

          // message_settings.body = welcome_message;

          foregroundLocalNotification({
            title: welcome_message,
            body: message_settings.body,
            url: "/project/" + data._id,
          });
        }
      }

      //------------------------------------
    },
    SOCKET_OUT_AddSection({ commit, getters, dispatch }, section) {
      commit("add_section", section);
      commit("change_flag_for_tasks");
    },
    SOCKET_OUT_RenameSection({ commit, getters, dispatch }, section) {
      commit("rename_section", section);
    },
    SOCKET_OUT_SectionOrder({ commit, getters, dispatch }, section) {
      commit("order_section", section);
      EventEmitter.$emit("changeSectionOrder", section);
    },
    SOCKET_OUT_ListsOrderBy({ commit, getters, dispatch }, section) {
      // console.log('projec order has been changed');
      // console.log(section);

      commit("reorder_tasks", section);
      // console.log('updated --------');
      // console.log(getters.getProjects);
    },
    SOCKET_OUT_MutedProject({ commit, getters, dispatch }, data) {
      commit("mute_project", data);
      EventEmitter.$emit("ProjectMuted", data);
      if (data.muted) {
        commit("setMessage", {
          message: "Muted",
          key_message: "alert",
        });
      } else {
        commit("setMessage", {
          message: "Unmuted",
          key_message: "alert",
        });
      }
    },
    SOCKET_OUT_MutedChats({ commit, getters, dispatch }, data) {
      // console.log('SOCKET_OUT_MutedChats ----------------------------')
      // console.log(data)
      commit("mute_chat", data);
      // console.log('--------------------------------------------------')
      if (data.muted) {
        commit("setMessage", {
          message: "Muted",
          key_message: "alert",
        });
      } else {
        commit("setMessage", {
          message: "Unmuted",
          key_message: "alert",
        });
      }
    },
    socket_add_user_to_project({ commit, getters }, data) {
      // console.log('socket_add_user_to_project')

      users_to_add = [];

      const add_users = {
        email: [],
        _id: data.project_id,
        users_to_delete: data.users_to_delete,
        teams: data.teams,
      };

      data.users.forEach((user) => {
        add_users.email.push(user.email);

        if (data.type && data.type == "add") {
          let bool = true;

          getters.getProjects.forEach((e) => {
            if (e._id == data.project_id) {
              if (e.author == getters.getUserData._id) {
                bool = false;
                commit("addTeamsUser", user);
              }
            }
          });

          if (bool) {
            commit("addTeamsUser", user);
          }

          commit("add_user_from_user_key_in_project", {
            project_id: data.project_id,
            user,
          });
        }
      });

      this._vm.$socket.emit("IN_ProjectAddUser", add_users);
    },
    SOCKET_OUT_ProjectAllUsers({ commit, getters }, data) {
      // if (data.added_user_id != getters.getUserData._id) {
      // console.log('SOCKET_OUT_ProjectAllUsers', data)
      data.users.forEach((user) => {
        commit("addKnownUser", user);
        getters.getProjects.forEach((e, i) => {
          if (e._id == data._id) {
            let a = e.users.filter((r) => r._id == user._id);
            if (a.length == 0) {
              commit("add_user_from_user_key_in_project", {
                project_id: data._id,
                user,
              });
            }
          }
        });
      });
      commit("change_user_key_in_project", data);
      commit("change_flag_for_last_chat");
      commit("setUsersByProject", { project_id: data._id, users: data.users });
      // }
    },
    socket_remove_user_from_project({ commit }, data) {
      const remove_users = {
        email: [],
        project_id: data.project_id,
        delete_all_files: data.delete_all_files,
        leave: data.leave ? true : false,
      };

      users_to_add = data.users_to_add;
      leave_user_list = false;

      data.users.forEach((user) => {
        // так же здесь удалить пользователей из проекта из ключа users
        commit("delete_user_from_user_key_in_project", {
          project_id: data.project_id,
          email: user.email,
        });
        remove_users.email.push(user.email);
      });

      commit("setCompletedTasksClear", data.project_id);

      this._vm.$socket.emit("IN_ProjectDeleteUser", remove_users);
    },
    SOCKET_OUT_Project_deleteUser({ getters, commit, dispatch }, data) {
      // console.log('SOCKET_OUT_Project_deleteUser')

      commit("removeUserFromProject", {
        project_id: data.project_id,
        emails: data.email,
      });

      if (data.delete_all_files) {
        getters.getProjects.forEach((list) => {
          if (list._id == data.project_id) {
            if (getters.getTasks[list._id]) {
              getters.getTasks[list._id].forEach((task) => {
                if (getters.getComments[task._id]) {
                  getters.getComments[task._id].forEach((msg) => {
                    if (msg.user_id == data.deleted_user_id) {
                      // msg.files.forEach((file) => {
                      //     commit('setUserFileSize_minus', +file.size)
                      // })
                      if (msg.files.length > 0) {
                        let data_msg = {
                          user_id: msg.user_id,
                          user_name: msg.user_name,
                          project_id: list._id,
                          task_id: task._id,
                          chat_id: msg._id,
                          message: "The file was removed by its owner",
                        };
                        commit("edit_message", data_msg);
                        commit("edit_files", data_msg);
                        commit("changeCommentLoading");
                      }
                    }
                  });
                }
              });
            }
            if (getters.getCompletedTasks[list._id]) {
              getters.getCompletedTasks[list._id].forEach((task) => {
                if (getters.getComments[task._id]) {
                  getters.getComments[task._id].forEach((msg) => {
                    if (msg.user_id == data.deleted_user_id) {
                      // msg.files.forEach((file) => {
                      //     commit('setUserFileSize_minus', +file.size)
                      // })
                      if (msg.files.length > 0) {
                        let data_msg = {
                          user_id: msg.user_id,
                          user_name: msg.user_name,
                          project_id: list._id,
                          task_id: task._id,
                          chat_id: msg._id,
                          message: "The file was removed by its owner",
                        };
                        commit("edit_message", data_msg);
                        commit("edit_files", data_msg);
                        commit("changeCommentLoading");
                      }
                    }
                  });
                }
              });
            }
          }
        });
      }

      // придет всем кроме того кого удалили
      // console.log(data.email)
      // if (data.deleted_user_id != getters.getUserData._id) {
      data.email.forEach((email) => {
        commit("delete_user_from_user_key_in_project", {
          project_id: data.project_id,
          email: email,
        });
      });
      // }

      commit("change_flag_for_last_chat");
    },
    SOCKET_OUT_ProjectDeleteUser({ dispatch }, data) {
      if (users_to_add) {
        if (users_to_add.length > 0) {
          setTimeout(() => {
            dispatch("socket_add_user_to_project", {
              users: users_to_add,
              project_id: data.project_id,
              users_to_delete: [],
              type: "add",
            });
          }, 1000);
        }
      }
    },
    SOCKET_OUT_User_projectDelete({ commit, getters }, data) {
      // console.log('SOCKET_OUT_User_projectDelete', data)
      // придет тому кого удалили
      commit("removeUserFromProject", {
        project_id: data.project_id,
        emails: data.email,
      });
      // leave_user_list = true

      // EventEmitter.$emit('RedirectLists', data.project_id)
      redirect(data.project_id, getters);

      // setTimeout(() => {

      getters.getProjects.forEach((list) => {
        if (list._id == data.project_id) {
          if (getters.getTasks[list._id]) {
            getters.getTasks[list._id].forEach((task) => {
              if (data.delete_all_files) {
                if (getters.getComments[task._id]) {
                  getters.getComments[task._id].forEach((msg) => {
                    if (msg.user_id == getters.getUserData._id) {
                      if (msg.files.length > 0) {
                        msg.files.forEach((file) => {
                          commit("setUserFileSize_minus", +file.size);
                        });
                      }
                    }
                  });
                }
              }
              commit("clear_comments_task", task._id);
            });
          }
          if (getters.getCompletedTasks[list._id]) {
            getters.getCompletedTasks[list._id].forEach((task) => {
              if (data.delete_all_files) {
                if (getters.getComments[task._id]) {
                  getters.getComments[task._id].forEach((msg) => {
                    if (msg.user_id == getters.getUserData._id) {
                      if (msg.files.length > 0) {
                        msg.files.forEach((file) => {
                          commit("setUserFileSize_minus", +file.size);
                        });
                      }
                    }
                  });
                }
              }
              commit("clear_comments_task", task._id);
            });
          }
        }
      });

      EventEmitter.$emit("RequestServerRemoveLists", {
        _id: data.project_id,
      });
      commit("delete_project", data.project_id);
      commit("set_remove_list", data);
      commit("set_new_order_list");
      commit("change_flag_for_last_chat");
      // }, 500)
    },
    async socket_IN_NewUser_sendEmail({ commit, dispatch, getters }, data) {
      // console.log("-----------------");
      // console.log(new Date());
      // console.log("socket_IN_NewUser_sendEmail", data);
      // console.log("-----------------");
      // this._vm.$socket.emit("IN_NewUser_sendEmail", email);
      // alert('1')

      data.user_id = getters.getUserData._id;

      const res = {
        token: true,
        method: "POST",
        url: this._vm.$w_api.POST_Invitation,
        body: data,
      };

      const req = await dispatch("fetch_request", res);

      if (req.ok) {
        const reqJSON = await req.json();

        const message = {
          key_message: "success",
          message: reqJSON.message.En,
        };

        commit("setMessage", message);
      }
    },
    SOCKET_OUT_AddUserTeams({ commit, dispatch, getters }, data) {
      // if (data.author == getters.getUserData._id) {
      //     commit('addTeamsUser', data.user)
      //     commit('addKnownUser', data.user)
      //     // commit('add_user_in_list', data)
      // } else {
      //     commit('addKnownUser', data.user)
      // }
      // commit('add_user_in_list', data)
      // commit("change_flag_for_last_chat")
    },
    SOCKET_OUT_NewUser_sendEmail(ctx, data) {
      // console.log("-----------------");
      // console.log(new Date());
      // console.log("SOCKET_OUT_NewUser_sendEmail", data);
      // console.log("-----------------");
    },
    socket_send_project_id(ctx, project_id) {
      // this._vm.$socket.emit("IN_Project", project_id);
    },
    socket_delete_project({ commit, getters }, _id) {
      this_user_delete_list = false;

      if (getters.getTasks[_id]) {
        getters.getTasks[_id].forEach((task) => {
          if (getters.getComments[task._id]) {
            getters.getComments[task._id].forEach((msg) => {
              if (getters.getUserData._id == msg.user_id) {
                if (msg.files.length > 0) {
                  msg.files.forEach((f) => {
                    commit("setUserFileSize_minus", +f.size);
                  });
                }
              }
            });
          }
        });
      }

      if (getters.getCompletedTasks[_id]) {
        getters.getCompletedTasks[_id].forEach((task) => {
          if (getters.getComments[task._id]) {
            getters.getComments[task._id].forEach((msg) => {
              if (getters.getUserData._id == msg.user_id) {
                if (msg.files.length > 0) {
                  msg.files.forEach((f) => {
                    commit("setUserFileSize_minus", +f.size);
                  });
                }
              }
            });
          }
        });
      }

      if (getters.getTasks[_id]) {
        let filesSize = 0;
        getters.getTasks[_id].forEach((task) => {
          if (task.file_size > 0) {
            filesSize += +task.file_size;
            commit("delete_files", { task_id: task._id });
          }
        });
        // this.commit("setUserUsedFileSize", filesSize * -1);
      }

      this._vm.$socket.emit("IN_ProjectDelete", { _id });
      commit("delete_project", _id);
    },
    SOCKET_OUT_ProjectDelete({ commit, getters }, data) {
      // console.log('SOCKET_OUT_ProjectDelete', data)
      // EventEmitter.$emit('RedirectLists', data.project_id)
      // if (data.user_id != getters.getUserData._id) {}

      // EventEmitter.$emit('RedirectLists', data.project_id)

      setTimeout(() => {
        if (this_user_delete_list) {
          if (getters.getCompletedTasks[data._id]) {
            getters.getCompletedTasks[data._id].forEach((task) => {
              if (getters.getComments[task._id]) {
                getters.getComments[task._id].forEach((msg) => {
                  if (getters.getUserData._id == msg.user_id) {
                    if (msg.files.length > 0) {
                      msg.files.forEach((f) => {
                        commit("setUserFileSize_minus", +f.size);
                      });
                    }
                  }
                });
              }
              commit("delete_files", { task_id: task._id });
            });
          }

          if (getters.getTasks[data._id]) {
            getters.getTasks[data._id].forEach((task) => {
              if (getters.getComments[task._id]) {
                getters.getComments[task._id].forEach((msg) => {
                  if (getters.getUserData._id == msg.user_id) {
                    if (msg.files.length > 0) {
                      msg.files.forEach((f) => {
                        commit("setUserFileSize_minus", +f.size);
                      });
                    }
                  }
                });
              }
              commit("delete_files", { task_id: task._id });
            });
          }

          redirect(data._id, getters);
        }

        this_user_delete_list = true;

        EventEmitter.$emit("RequestServerRemoveLists", data);
        commit("delete_project", data._id);
        commit("set_remove_list", data);
        commit("change_flag_for_last_chat");
      }, 1000);
    },
    socket_rename_project(ctx, data) {
      this._vm.$socket.emit("IN_ProjectRename", data);
    },
    SOCKET_OUT_ProjectRename({ commit }, data) {
      commit("rename_project", data);
      commit("change_flag_for_last_chat");
    },
    SOCKET_OUT_RenameFolder({ commit }, data) {
      commit("rename_folder", data);
      commit("change_flag_for_last_chat");
    },
    SOCKET_OUT_CreateFolder({ commit, getters }, data) {
      // setTimeout(() => {
      //     commit("set_lists", getters.getProjects);
      //     commit("change_flag_for_last_chat");
      // }, 500);
      let folder = getters.getLists["folder"].filter((e) => e._id == data._id);
      if (folder.length == 0) {
        commit("add_folder_lists", data);
        commit("change_flag_for_last_chat");
      }
    },
    SOCKET_OUT_AddList_toFolder({ commit, getters }, data) {
      commit("set_lists_change_order", data);
      commit("change_flag_for_last_chat");
    },
    change_lists_order({}, data) {
      this._vm.$socket.emit("IN_ChangeOrderLists", {
        new_order_lists: data,
        no_flag_change: true,
      });
    },
    SOCKET_OUT_ChangeOrderLists({ commit, getters }, data) {
      if (change_order_list) {
        commit("set_lists_change_order", data);
        if (!data.no_flag_change) {
          commit("change_flag_for_last_chat");
        }
      }
      change_order_list = true;
    },
    SOCKET_OUT_ChangeOrderFolder({ commit, getters }, data) {
      commit("set_folder_change_order", data);
      commit("change_flag_for_last_chat");
    },
    SOCKET_OUT_RemoveFolder({ commit, getters }, data) {
      commit("set_remove_folder", data);
      commit("set_remove_folder_user", data);
      commit("change_flag_for_last_chat");
    },
    SOCKET_OUT_ChangeEmailUser({ commit }, data) {
      commit("setChangeUserData2", data);
      // EventEmitter.$emit('ChangeEmailUser', true)
    },
    SOCKET_OUT_ChangeNameUser({ commit }, data) {
      commit("setChangeUserData2", data);
      // EventEmitter.$emit('ChangeEmailUser', true)
    },
    SOCKET_OUT_ChangeImageUser({ commit }, data) {
      commit("setChangeUserData2", data);
      // EventEmitter.$emit('ChangeEmailUser', true)
    },
    render_lists({ commit, getters }, data) {
      // commit("setProjects", []);
      // console.log(getters)
      // commit("setProjects", getters.getProjects);
      commit("set_lists", getters.getProjects);
      commit("change_flag_for_last_chat");
      // commit("set_folder_lists", data.folder);
    },
    remove_section_this_user() {
      remove_section = false;
    },
    SOCKET_OUT_RemoveSection({ commit }, data) {
      if (remove_section) {
        commit("remove_section", data);
        commit("change_task_section", data);
        EventEmitter.$emit("removeSection", data.section_id);
      }
    },
    SOCKET_OUT_TaskMove({ commit }, data) {
      setTimeout(() => {
        // commit("set_last_chat", projects);
        commit("change_flag_for_last_chat");
      }, 2000);
    },
    add_folder_render_lists({ commit, getters }, data) {
      commit(
        "add_folder_lists",
        getters.getUserData.folder[getters.getUserData.folder.length - 1]
      );
      commit("change_flag_for_last_chat");
    },
    remove_folder({ commit, getters }, data) {
      commit("setRemoveFolder", data);
      commit("change_flag_for_last_chat");
    },
    lists_reorder({ commit }, data) {
      commit("set_lists_reorder", data);
    },
  },
  mutations: {
    set_lists_reorder(state, data) {
      state.projects = data;
    },
    setSortedLists(state, { type, value }) {
      state.sortedLists[type] = value;
      const { lists, folders } = state.sortedLists;
      let listsInFolders = [];
      folders.forEach(({ lists }) => {
        listsInFolders = listsInFolders.concat(lists);
      });

      state.sortedLists.all = [...lists, ...listsInFolders];
    },
    setNumberOfTasks(state, newVal) {
      state.numberOfTasks = newVal;
    },
    setUsersByProject(state, { project_id, users }) {
      state.usersByProjects[project_id] = users;
      // console.log(project_id, users, state.usersByProjects, 'setUsersByProject')
    },
    removeUserFromProject(state, { emails, project_id }) {
      let projectUsers = state.usersByProjects[project_id];
      for (let i = 0; i < emails.length; i++) {
        // console.log(emails[i], 'email???')
        projectUsers = projectUsers.filter((user) => user.email !== emails[i]);
      }
      state.usersByProjects[project_id] = projectUsers;
      // console.log(projectUsers, 'users?????')
    },
    add_user_in_list(s, v) {
      s.projects.forEach((e, i) => {
        if (e._id == v.list_id) {
          s.projects[i].users.push(v.user);
        }
      });
    },
    open_folder(s, v) {
      let folder = s.lists;
      folder["folder"].forEach((e, i) => {
        if (e._id == v._id) {
          e.open = !e.open;
        }
      });
      s.lists = folder;
    },
    lists_order(s, v) {
      change_order_list = false;
      s.lists["folder"] = v.folder;
      s.lists["list"] = v.list;
    },
    clearLists(s, v) {
      s.projects = [];
      s.lists = [];
    },
    setProjects(s, v) {
      if (v.length > 0) {
        v.forEach((pr, i) => {
          this.commit("add_color_to_hue", {
            color: this._vm.$w_hue_color[s.colorIndex],
            length: this._vm.$w_hue_color.length,
          });
          pr.hue = s.hue_colors[i];
          let users = pr.users;
          for (let i = 0; i < users.length; i++) {
            users[i]["@" + users[i].name] = users[i].email;
          }
          s.usersByProjects[pr._id] = pr.users;
        });
        // if (this.getters.getUserData.folder) {
        //     this.getters.getUserData.folder.forEach((folder) => {
        //         if (folder.lists) {
        //             folder.lists.forEach((list_id) => {
        //                 v.forEach((pr, i) => {
        //                     if (pr._id == list_id) {
        //                         pr.folder = true
        //                     }
        //                 })
        //             })
        //         }
        //     })
        //     // console.log(this.state)
        //     // if (s.user.folder) {
        //     //     s.user.folder.forEach((e, i) => {
        //     //         if (e.lists) {
        //     //             if (e.lists.length > 0) {
        //     //                 e.lists.forEach((list_id, a) => {
        //     //                     v.forEach((list) => {
        //     //                         if (list._id == list_id) {
        //     //                             s.user.folder[i].lists.splice(a, 1)
        //     //                             s.user.folder[i].lists[a].push(list)
        //     //                         }
        //     //                     })
        //     //                 })
        //     //             }
        //     //         }
        //     //     })
        //     // }
        // }
      }
      // console.log(v, 'setProjects++++++++++++')
      s.projects = v;
    },
    set_remove_folder(s, v) {
      s.lists["folder"].forEach((folder, i) => {
        if (folder._id == v.folder_id) {
          s.lists["folder"].splice(i, 1);
        }
      });

      s.lists["folder"].forEach((folder, i) => {
        folder.order = i;
        s.lists["folder"][i] = folder;
      });
    },
    rename_folder(s, v) {
      s.lists["folder"].forEach((e, i) => {
        if (e._id == v.folder_id) {
          s.lists["folder"][i].name = v.folder_name;
        }
      });
    },
    add_folder_lists(s, v) {
      v.folder = true;
      v.open = false;
      s.lists["folder"].push(v);
      // s.projects.push(v)
    },
    setRemoveFolder(s, v) {
      let lists = "";

      s.lists["folder"].forEach((folder, i) => {
        if (folder._id == v._id) {
          lists = folder.lists;
          s.lists["folder"].splice(i, 1);
        }
      });

      s.lists["folder"].forEach((folder, i) => {
        folder.order = i;
        s.lists["folder"][i] = folder;
      });

      if (lists.length > 0) {
        lists.forEach((e, i) => {
          e.users.forEach((user, user_index) => {
            if (user._id == this.getters.getUserData._id) {
              e.users[user_index].order =
                Number(s.lists["list"].length) + Number(i);
            }
          });
          s.lists["list"].push(e);
        });
      }

      let new_order_lists = [];
      let new_order_folder = [];

      s.lists["folder"].forEach((e, i) => {
        new_order_folder.push({
          _id: e._id,
          order: i,
        });
      });

      s.lists["list"].forEach((e, i) => {
        new_order_lists.push({
          list_id: e._id,
          order: i,
        });
      });

      this._vm.$socket.emit("IN_RemoveFolder", {
        folder_id: v._id,
      });

      setTimeout(() => {
        this._vm.$socket.emit("IN_ChangeOrderLists", {
          new_order_lists: new_order_lists,
        });

        this._vm.$socket.emit("IN_ChangeOrderFolder", {
          new_order_folder: new_order_folder,
        });
      }, 1000);
    },
    set_remove_list(s, v) {
      // s.projects = s.projects.filter((project) => project._id != v.project_id);
      let folder_bool = true;
      s.lists["list"].forEach((list, i) => {
        if (list._id == v._id) {
          folder_bool = false;
          s.lists["list"].splice(i, 1);
        }
      });
      if (folder_bool) {
        let lists = [];
        let folder_id = "";
        s.lists["folder"].forEach((folder, folder_index) => {
          folder.lists.forEach((list, list_index) => {
            if (list._id == v._id) {
              s.lists["folder"][folder_index].lists.splice(list_index, 1);
              lists = folder.lists;
              folder_id = folder._id;
            }
          });
        });

        // console.log('lists 1', lists)

        if (lists.length > 0) {
          // console.log('lists', lists)
          let list_id = [];
          lists.forEach((e) => {
            list_id.push(e._id);
          });
          if (list_id.length > 0) {
            this._vm.$socket.emit("IN_AddList_toFolder", {
              folder_id: folder_id,
              list_id: list_id,
            });
          } else {
            this._vm.$socket.emit("IN_AddList_toFolder", {
              folder_id: folder_id,
              list_id: [],
            });
          }
        } else {
          this._vm.$socket.emit("IN_AddList_toFolder", {
            folder_id: folder_id,
            list_id: [],
          });
        }
      }
    },
    set_folder_change_order(s, v) {
      this.getters.getUserData.folder.forEach((folder) => {
        v.forEach((e) => {
          if (folder._id == e._id) {
            s.lists["folder"][e.order] = [];
            s.projects.forEach((list) => {
              folder.lists.forEach((list_id, i) => {
                if (list_id == list._id) {
                  folder.lists[i] = [];
                  folder.lists[i] = list;
                }
              });
            });
            folder.order = e.order;
            s.lists["folder"][e.order] = folder;
          }
        });
      });
    },
    set_new_order_list(s, v) {
      let new_order_lists = [];

      s.lists["list"].forEach((e, i) => {
        new_order_lists.push({
          list_id: e._id,
          order: i,
        });
      });

      this._vm.$socket.emit("IN_ChangeOrderLists", {
        new_order_lists: new_order_lists,
      });
    },
    set_lists_change_order(s, v) {
      // console.log(s.projects)
      if (v.new_order_lists) {
        let lists = [];
        s.projects.forEach((list, i) => {
          v.new_order_lists.forEach((e) => {
            if (list._id == e.list_id) {
              list.users.forEach((user, user_index) => {
                if (user._id == this.getters.getUserData._id) {
                  s.projects[i].users[user_index].order = e.order;
                  // lists[e.order] = []
                  list.users[user_index].order = e.order;
                  // lists[e.order] = list
                }
              });
              lists[e.order] = [];
              lists[e.order] = list;
            }
          });
        });
        s.lists["list"] = lists;
      }
      if (v.folder_id) {
        let array = [];
        let lists = [];
        if (v.list_id.length == 0) {
          lists = s.lists["folder"].find((e) => e._id == v.folder_id).lists;
        }
        // console.log(s.lists['folder'].find(e => e._id == v.folder_id).lists, v.list_id)
        v.list_id.forEach((list_id) => {
          lists = s.lists["folder"]
            .find((e) => e._id == v.folder_id)
            .lists.filter((e) => e._id != list_id);
          s.lists["list"] = s.lists["list"].filter((e) => e._id != list_id);
          s.projects.forEach((e) => {
            if (list_id == e._id) {
              array.push(e);
            }
          });
        });

        if (lists.length != 0) {
          s.lists["list"] = s.lists["list"].concat(lists);
        }
        // console.log(lists)
        s.lists["folder"].forEach((folder, i) => {
          if (folder._id == v.folder_id) {
            s.lists["folder"][i].lists = array;
          }
        });
      }
    },
    add_list_lists(s, v) {
      if (s.lists["list"]) {
        if (s.lists["list"].length > 0) {
          if (v.users.length > 0) {
            v.users.forEach((e, i) => {
              if (e._id == this.getters.getUserData._id) {
                v.users[i].order = s.lists["list"].length - 1;
              }
            });
          }
        }
      }
      s.lists["list"].push(v);
      // console.log(v)
    },
    setLists(s, v) {
      // s.lists = []
      s.projects = [];

      let lists = [];

      v["list"].forEach((e, index) => {
        lists.push(e);
      });

      v["folder"].forEach((e, i) => {
        e.lists.forEach((list) => {
          lists.push(list);
        });
      });

      s.projects = lists;

      // console.log(s.projects)
    },
    set_lists(s, v) {
      // if (global_render) {
      //     global_render = false
      s.lists = [];

      let arr = [];
      let arrayListsFolder = [];
      let equalsOrderList = [];

      // let new_order_lists = [] // ----

      arr["folder"] = [];
      arr["list"] = [];
      v = v.filter((e) => e._id != this.getters.getUserData.shortcut_inbox);
      if (v.length > 0) {
        // let list_order = 0
        v.forEach((list, list_index) => {
          if (list._id != this.getters.getUserData.shortcut_inbox) {
            // new_order_lists.push({
            //     list_id: list._id,
            //     order: list_order
            // })
            // list_order++
            if (this.getters.getUserData.folder.length > 0) {
              this.getters.getUserData.folder.forEach((e) => {
                e.folder = true;
                e.open = false;
                e.lists = e.lists.filter((e) => e != null);
                e.lists.forEach((list_id, i) => {
                  if (list_id == list._id) {
                    e.lists[i] = "";
                    e.lists[i] = list;
                    arrayListsFolder.push(list_id);
                  }
                  // if (i == 0) {
                  // this._vm.$socket.emit('IN_AddList_toFolder', {
                  //     folder_id: e._id,
                  //     list_id: []
                  // })
                  // }
                });
                arr["folder"][e.order] = e;
              });
            } else {
              list.users.forEach((user) => {
                if (user._id == this.getters.getUserData._id) {
                  // console.log(list)
                  // arr['list'][user.order] = list
                  if (arr["list"][user.order]) {
                    if (arr["list"][user.order]._id != list._id) {
                      equalsOrderList.push(list);
                      arr["list"][Number(user.order) + list_index] = list;
                    }
                  } else {
                    arr["list"][user.order] = list;
                  }
                }
              });
            }

            const even = (e) => e == list._id;

            if (!arrayListsFolder.some(even)) {
              list.users.forEach((user) => {
                if (user._id == this.getters.getUserData._id) {
                  if (arr["list"][user.order]) {
                    equalsOrderList.push(list);
                    if (arr["list"][user.order]._id != list._id) {
                      arr["list"][Number(user.order) + list_index] = list;
                    }
                  } else {
                    arr["list"][user.order] = list;
                  }
                }
              });
            }
          }
        });
      } else {
        if (this.getters.getUserData.folder.length > 0) {
          this.getters.getUserData.folder.forEach((e) => {
            e.folder = true;
            e.open = false;
            arr["folder"][e.order] = e;
          });
        }
      }

      // clear empty items
      let new_list = [];
      arr["list"].forEach((list) => {
        if (list) new_list.push(list);
      });

      arr["list"] = new_list;
      s.lists = arr;
      // }
    },
    set_folder_lists(s, v) {
      let arr = [];
      // this.getters.getUserData.folder.forEach((folder) => {
      //     arr[folder._id] = []
      //     folder.lists.forEach((list_id) => {
      //         v[folder._id].forEach((e, i) => {
      //             if (e._id == list_id) {
      //                 if (e.order) {
      //                     arr[folder._id][e.order] = e
      //                 } else {
      //                     arr[folder._id].push(e)
      //                 }
      //             }
      //         })
      //     })
      // })
      // console.log(arr)

      this.getters.getUserData.folder.forEach((folder) => {
        v[folder._id].forEach((e, i) => {
          if (e.order) {
            arr[folder._id][e.order] = e;
          } else {
            arr[folder._id].push(e);
          }
        });
      });

      s.folder_lists = arr;
    },
    add_section(s, v) {
      s.projects.forEach((e, i) => {
        if (e._id == v.project_id) {
          let bool = true;
          if (s.projects[i].sections) {
            s.projects[i].sections.forEach((a, i2) => {
              if (a._id == v.section_id) {
                bool = false;
              }
            });
          } else {
            s.projects[i].sections = [];
            s.projects[i].sections.forEach((a, i2) => {
              if (a._id == v.section_id) {
                bool = false;
              }
            });
          }
          if (bool) {
            s.projects[i].sections.push({
              _id: v.section_id,
              name: v.section_name,
              order: v.section_order,
              new: v.new,
            });
          }
        }
      });
    },
    remove_section(s, v) {
      let lists = s.projects;
      lists.forEach((e, i) => {
        if (e._id == v.project_id) {
          e.sections.forEach((a, i2) => {
            if (a._id == v.section_id) {
              e.sections.splice(i2, 1);
            }
          });
        }
      });
      s.projects = lists;
    },
    order_section(s, v) {
      s.projects.forEach((e, i) => {
        if (e._id == v.project_id) {
          s.projects[i].sections.forEach((a, i2) => {
            v.sections.forEach((section) => {
              if (a._id == section._id) {
                s.projects[i].sections[i2].order = section.order;
              }
            });
          });
        }
      });
    },
    rename_section(s, v) {
      s.projects.forEach((e, i) => {
        if (e._id == v.project_id) {
          s.projects[i].sections.forEach((a, i2) => {
            if (a._id == v.section_id) {
              s.projects[i].sections[i2].name = v.section_name;
            }
          });
        }
      });
    },
    reorder_tasks(s, v) {
      s.projects.forEach((e, i) => {
        if (e._id == v.list_id) {
          s.projects[i].order_by = v.order_by;
        }
      });
    },
    mute_project(s, v) {
      let oldMutedObject = window.localStorage.getItem("mutedObjects");

      if (v.muted) {
        if (oldMutedObject) {
          oldMutedObject += "," + v.project_id;
        } else {
          oldMutedObject = v.project_id;
        }
      } else {
        if (oldMutedObject) {
          oldMutedObject = ("," + oldMutedObject)
            .replaceAll("," + v.project_id, "")
            .substr(1);
        }
      }

      window.localStorage.setItem("mutedObjects", oldMutedObject);
    },
    mute_chat(s, v) {
      // console.log('mute/unmute chat');
      // console.log(v);

      let oldMutedObjects = window.localStorage.getItem("mutedObjectsChat");

      if (v.muted) {
        if (oldMutedObjects) {
          oldMutedObjects += "," + v.chat_id;
        } else {
          oldMutedObjects = v.chat_id;
        }
      } else if (oldMutedObjects) {
        oldMutedObjects = ("," + oldMutedObjects)
          .replaceAll("," + v.chat_id, "")
          .substr(1);
      }

      localStorage.setItem("mutedObjectsChat", oldMutedObjects);
      EventEmitter.$emit("chatMuted", v);
    },
    add_new_project(s, v) {
      this.commit("add_color_to_hue", {
        color: this._vm.$w_hue_color[s.colorIndex],
        length: this._vm.$w_hue_color.length,
      });
      v.hue = s.hue_colors[s.hue_colors.length - 1];
      s.projects.push(v);
    },
    delete_project(s, v) {
      s.projects = s.projects.filter((project) => project._id != v);
    },
    rename_project(s, v) {
      s.projects = s.projects.map((project) => {
        if (v._id == project._id) {
          project.name = v.name;
        }
        return { ...project };
      });

      let bool = true;

      s.lists["list"].forEach((e, i) => {
        if (e._id == v._id) {
          bool = false;
          s.lists["list"][i].name = v.name;
        }
      });

      if (bool) {
        s.lists["folder"].forEach((e, i) => {
          e.lists.forEach((list, list_index) => {
            if (list._id == v._id) {
              s.lists["folder"][i].lists[list_index].name = v.name;
            }
          });
        });
      }
    },
    add_color_to_hue(s, v) {
      if (s.colorIndex + 1 == v.length) {
        s.colorIndex = -1;
      }
      s.hue_colors.push(v.color);
      s.colorIndex += 1;
    },
    set_last_chat(s, v) {
      v.forEach((project) => {
        if (project.last_chat) {
          let last_chat = { ...project.last_chat };
          if (
            last_chat.message == "" &&
            last_chat.file_id &&
            last_chat.file_id.length != 0
          ) {
            last_chat.message = "attachment";
          }
          s.last_chat[project._id] = {
            project_id: project._id,
            last_chat,
          };
        } else {
          s.last_chat[project._id] = null;
        }
      });
    },
    set_new_last_chat(s, v) {
      const project = s.projects.find((project) => project._id == v.project_id);
      if (project) {
        project.last_chat = v.last_chat;
      }
    },
    change_flag_for_last_chat(s) {
      s.flag_for_last_chat = !s.flag_for_last_chat;
    },
    set_users_to_add_in_project(s, v) {
      s.user_to_add_in_project[v.project_id] = v.users;
    },
    change_user_key_in_project(s, v) {
      // const project_id = v.project_id ? v.project_id : v._id;
      // s.projects.find((project) => project._id == project_id).users = v.users;
    },
    add_user_from_user_key_in_project(s, v) {
      const project_id = v.project_id ? v.project_id : v._id;
      s.projects
        .find((project) => project._id == project_id)
        .users.push(v.user);
    },
    delete_user_from_user_key_in_project(s, v) {
      const project_id = v.project_id ? v.project_id : v._id;
      const project = s.projects.find((project) => project._id == project_id);
      project.users = project.users.filter((user) => user.email != v.email);

      let bool = true;

      s.lists["list"].forEach((list, list_index) => {
        if (list._id == project_id) {
          bool = false;
          list.users.forEach((user, user_index) => {
            if (user.email == v.email) {
              s.lists["list"][list_index].users.splice(user_index, 1);
            }
          });
        }
      });

      if (bool) {
        s.lists["folder"].forEach((folder, folder_index) => {
          folder.lists.forEach((list, list_index) => {
            if (list._id == project_id) {
              bool = false;
              list.users.forEach((user, user_index) => {
                if (user.email == v.email) {
                  s.lists["folder"][folder_index].lists[
                    list_index
                  ].users.splice(user_index, 1);
                }
              });
            }
          });
        });
      }
    },
    setChangeUserData2(s, v) {
      if (v.type == "email") {
        s.lists["list"].forEach((list) => {
          list.users.forEach((user) => {
            if (user._id == v.user_id) {
              user.email = v.email;
            }
          });
        });
        s.lists["folder"].forEach((folder) => {
          folder.lists.forEach((list) => {
            list.users.forEach((user) => {
              if (user._id == v.user_id) {
                user.email = v.email;
              }
            });
          });
        });
        s.projects.forEach((list) => {
          list.users.forEach((user) => {
            if (user._id == v.user_id) {
              user.email = v.email;
            }
          });
        });
      }
      if (v.type == "name") {
        s.lists["list"].forEach((list) => {
          list.users.forEach((user) => {
            if (user._id == v.user_id) {
              user.name = v.name;
            }
          });
        });
        s.lists["folder"].forEach((folder) => {
          folder.lists.forEach((list) => {
            list.users.forEach((user) => {
              if (user._id == v.user_id) {
                user.name = v.name;
              }
            });
          });
        });
        s.projects.forEach((list) => {
          list.users.forEach((user) => {
            if (user._id == v.user_id) {
              user.name = v.name;
            }
          });
        });
      }
      if (v.type == "change_image") {
        s.lists["list"].forEach((list) => {
          list.users.forEach((user) => {
            if (user._id == v.user_id) {
              user.image = v.image;
            }
          });
        });
        s.lists["folder"].forEach((folder) => {
          folder.lists.forEach((list) => {
            list.users.forEach((user) => {
              if (user._id == v.user_id) {
                user.image = v.image;
              }
            });
          });
        });
        s.projects.forEach((list) => {
          list.users.forEach((user) => {
            if (user._id == v.user_id) {
              user.image = v.image;
            }
          });
        });
      }
      // console.log('project', s.projects)
      // console.log('folder', s.lists['folder'])
      // console.log('list', s.lists['list'])
    },
  },
  getters: {
    getProjects: (s) => s.projects,
    getLists: (s) => s.lists,
    getFolderLists: (s, getters) => {
      let arr = [];
      getters.getUserData.folder.forEach((folder) => {
        arr[folder._id] = [];
        if (folder.lists) {
          folder.lists.forEach((list_id) => {
            s.projects.forEach((e, i) => {
              if (e._id == list_id) {
                if (e.order) {
                  arr[folder._id][e.order] = e;
                } else {
                  arr[folder._id].push(e);
                }
              }
            });
          });
        }
      });
      return arr;
    },
    getLastChat: (s) => s.last_chat,
    get_flag_for_last_chat: (s) => s.flag_for_last_chat,
    get_users_to_add_in_project: (s) => s.user_to_add_in_project,
    getUsersByProject: (s) => s.usersByProjects,
    getNumberOfTasks: (s) => s.numberOfTasks,
    getSortedLists: (s) => s.sortedLists,
  },
};
