export default function (file_name) {
  let a = file_name;
  let b = "";
  let i = a.length - 1;
  while (i >= 0) {
    if (a[i] == ".") break;
    b += a[i];
    i--;
  }
  b = b == "gepj" ? "gpj" : b;
  return b.split("").reverse().join("").substr(0, 3);
}
