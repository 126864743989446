<template>
    <div class="auth" ref="auth">
        <el-row>
            <el-col :span="span" class="form" style="width: 450px">
                <el-form label-position="top" label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm">

                    <el-form-item label="New password:" prop="password">
                        <el-input v-model="ruleForm.password" type="password"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <div class="form_footer">
                            <el-button type="primary" @click="submitForm('ruleForm')" class="w-btn">Send</el-button>
                        </div>
                    </el-form-item>

                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    import { mapMutations, mapActions, mapGetters } from "vuex"

    export default {
        props: {
            span: [Number],
        },
        data() {
            return {
                ruleForm: {
                    password: ""
                },
                rules: {
                     password: [
                        {
                            required: true,
                            message: "Enter password.",
                            trigger: "blur",
                        },
                    ],
                },
            };
        },
        mounted() {

            setTimeout(() => {
                document.querySelector(".auth .form").classList.add("show")
            }, 500)

        },
        methods: {
            ...mapActions(["NewPassword"]),
            ...mapMutations(["setMessage"]),
            async submitForm(formName) {
                if (this.$route.params.code) {
                    this.$refs[formName].validate(async (valid) => {
                        if (valid) {

                            const res = {
                                url: this.$w_api.NewPassword,
                                body: {
                                    password: this.ruleForm.password,
                                    code: this.$route.params.code
                                }
                            }

                            const req = await this.NewPassword(res)

                            if (req) {

                                this.ruleForm.password = ""
                                setTimeout(() => {
                                    window.location.href = '/'
                                }, 500)

                            } else {

                                setTimeout(() => {
                                    document.querySelector(".auth .form").classList.add("show")
                                }, 10)

                            }

                        }
                    })
                }
            }
        }
    }

</script>
