export default function (size = 0) {
  if (+size > 1024 ** 4) {
    return `${+(+size / 1024 ** 4).toFixed(3).slice(0, -1)} Gb`;
  } else if (+size > 1024 ** 2) {
    return `${+(+size / 1024 ** 2).toFixed(3).slice(0, -1)} Mb`;
  } else if (+size > 1024 ** 1) {
    return `${+(+size / 1024 ** 1).toFixed(3).slice(0, -1)} Kb`;
  } else if (+size < 0) {
    return '0 byte' 
  } else {
    return `${size} byte`;
  }
}
