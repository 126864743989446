<template>
  <li class="add_project_drawer">
    <a href="#" @click.prevent="showAddProjectWindow">
      <div class="project_thumbnail" style="background-color: #e3e1e1">+</div>
      <div class="project_name_body no_messages">
        <div class="project_name">Add new...</div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  name: "AddProject",
  methods: {
    showAddProjectWindow() {
      this.$root.$emit("addRenameProjectAddUser");
    },
  },
};
</script>
