import router from "@/router";
import { EventEmitter } from "../../../main";
import pushNotification from "../../../utils/pushNotificationCustom";
import foregroundLocalNotification from "../../../utils/foreground_local_notification";

let storage_messages = [];
let array_msg = [];
let msg_error_after_send_msg = false;
let msg_num_error = 0;
let error_msg_id = "";
let this_user_delete_msg = true;
let change_size = true;
let change_size_delete = true;
let edit_msg = true;

function mentionedEmail(email, message) {
  const temp = message.replace(/\n\r?/g, " ").split(" ");
  // console.log(email, temp, temp.includes(email))
  return temp.includes("@" + email);
}

function clone(o) {
  if (!o || "object" !== typeof o) {
    return o;
  }

  var c = "function" === typeof o.pop ? [] : {};

  var p, v;

  for (p in o) {
    if (o.hasOwnProperty(p)) {
      v = o[p];
      if (v && "object" === typeof v) {
        c[p] = clone(v);
      } else {
        c[p] = v;
      }
    }
  }
  return c;
}

export default {
  state: {
    comments: [],
    commentLoading: true,
    commentAdd: true,
    unreaded_messages_in_project: {}, //массив с непрочитанными сообщениями для отображения в проектах
    unreaded_messages_in_tasks: {}, //массив с непрочитанными сообщениями для отображения в проектах
    flag_for_unreaded: false,
    unreaded_messages_for_chat: {},
    not_mine_messages_for_zeroing: {},
    local_reply_message: {},
    flag_for_reply_message: false,
    local_edit_message: {},
    flag_for_edit_message: false,
    users_typing: {},
    flag_for_typing_users: false,
    listsMentions: {},
    chatsMentions: {},
    fetchedComments: {},
    recievedMessageWhileUpdating: false,
    chatUnreadMessagesWhileUpdating: {},
    chatThatGotNewMessages: {},
    log: "",
  },
  actions: {
    addChatUnreadMessagesRecievedWhileUpdating(
      { state: s, getters, commit },
      v
    ) {
      for (let chat_id in s.chatUnreadMessagesWhileUpdating) {
        getters.getChats.forEach((chat) => {
          if (chat._id !== chat_id) return;

          const commentsById = {};
          getters.getTempChatsIdsWithMessages[chat_id].forEach(
            ({ _id }) => (commentsById[_id] = true)
          );

          chat.users.forEach((user) => {
            let ids = {};
            if (user.user_id == getters.getUserData._id) {
              let numberToAdd = 0;
              s.chatUnreadMessagesWhileUpdating[chat_id].forEach(
                (val, comment_id) => {
                  ids[comment_id] = true;
                  if (!(comment_id in commentsById)) {
                    numberToAdd++;
                  }
                }
              );
              user.unread_number =
                Number(user.unread_number) + Number(numberToAdd);
              // console.log('commentsById', commentsById)
              // console.log('ids', ids)
            }
          });
        });
      }

      s.chatUnreadMessagesWhileUpdating = {};
      commit("clearTempChatsIdsWithMessages");
      commit("render");
    },
    socket_fetch_comments({ state, commit, getters, dispatch }, { task_id }) {
      if (!state.comments[task_id]) state.comments[task_id] = [];
      if (!this.state.files[task_id]) this.state.files[task_id] = [];
      // this._vm.$socket.emit("IN_CommitAll", task_id);
      // менять флаг нужно для обнуления чата,
      // если в нем так и нет сообщений - то SOCKET_OUT_CommitAll не придет и чат не очистится
      if (getters.getAppLoader) {
        setTimeout(() => {
          if (getters.getHistoryFromBack.length > 0) {
            dispatch("generateHistoryBodyAndAdd", getters.getHistoryFromBack);
          }
          commit("offAppLoader");
          commit("changeCommentLoading");
          commit("change_flag_for_files");
        }, 500);
      }
    },
    SOCKET_OUT_ConnectServer({ commit, getters }, msg) {
      let task_message = localStorage.getItem("task_messages");

      if (task_message) {
        let task_message_array = JSON.parse(task_message);

        if (task_message_array.length > 0) {
          array_msg = task_message_array;

          task_message_array.forEach((e) => {
            const local_msg = {
              date: new Date(),
              file_id: e.file_id,
              message: e.message,
              user_name: getters.getUserData.name,
              read: false,
              task_id: e.task_id,
              user_id: getters.getUserData._id,
              _id: e._id,
              reply: {},
              send: false,
            };
            commit("setNewComment", { chats: local_msg, files: [] });
            commit("changeCommentLoading");
          });

          setTimeout(() => {
            EventEmitter.$emit("noSendMessages", array_msg);
            commit("changeCommentLoading");
          }, 5000);

          setTimeout(() => {
            this._vm.$socket.emit("IN_CommitAdd", task_message_array[0]);
          }, 10000);
        }
      }
    },
    // SOCKET_OUT_ProjectAdd({ commit, getters, dispatch }, msg) {
    //     let data = msg.messages
    //     if (data) {
    //         console.log("-----------------");
    //         console.log(new Date());
    //         console.log("SOCKET_OUT_CommitAll", data);
    //         console.log("-----------------");
    //         // commit("addUnreadMessage_TwoArrow", data.chats);
    //         commit("setNewComment", data);
    //         commit("setFiles", data);
    //         commit("changeCommentLoading");
    //         commit("change_flag_for_files");
    //     }
    // },
    socket_repead_send({ commit, getters }, data) {
      let task_message = localStorage.getItem("task_message");

      if (task_message) {
        let task_message_array = JSON.parse(task_message);

        if (task_message_array.length > 0) {
          array_msg = task_message_array;
          task_message_array.forEach((e) => {
            if (e._id == data) {
              // e.project_id = "BDZUIXTaMfNoHzRbrKGfQWI="
              this._vm.$socket.emit("IN_CommitAdd", e);
            }
          });
        }
      }
    },
    socket_add_comment({ commit, getters }, { data, send, type, tags = [] }) {
      change_size = false;
      msg_num_error = 0;

      if (send) {
        let data2 = clone(data);

        if (data2.reply) {
          if (data2.reply.files) {
            if (data2.reply.files.length > 0) {
              data2.reply.files[0].base64 = "";
              data2.reply.files[0].src_url = "";
            }
          }
        }

        data2.tags = tags;

        if (data2.files.length > 0) {
          data2.files[0].base64 = "";
          data2.files[0].src_url = "";
        }

        array_msg.forEach((e, i) => {
          if (e._id == data2._id) {
            array_msg.splice(i, 1);
          }
        });

        array_msg.push(data2);
        delete data2.user;

        localStorage.setItem(`${type}_messages`, JSON.stringify(array_msg));

        if (array_msg.length == 1) {
          setTimeout(() => {
            if (!storage_messages.some((e) => e == data2._id)) {
              storage_messages.push(data2._id);
              if (type == "chat") {
                commit("setChangeReadLastMessage", {
                  chat_id: data2.chat_id,
                  user_id: getters.getUserData._id,
                  _id: data2._id,
                });
                this._vm.$socket.emit("IN_AddMessages", data2, data2.chat_id);
              } else if (type == "list") {
                this._vm.$socket.emit("IN_CommitAdd", data2);
              }
            }
          }, 500);
        }

        if (msg_error_after_send_msg) {
          if (!storage_messages.some((e) => e == data2._id)) {
            storage_messages.push(data2._id);
            if (type == "chat") {
              this._vm.$socket.emit("IN_AddMessages", data2, data2.chat_id);
            } else if (type == "list") {
              this._vm.$socket.emit("IN_CommitAdd", data2);
            }
          }
          msg_error_after_send_msg = false;
        }
      } else {
        if (type == "chat") {
          let message = {
            chat_id: data.chat_id,
            message: data,
          };
          commit("setAddMessages", message);
          commit("render");
          commit("setCommentAdd");
        } else if (type == "list") {
          commit("addUnreadMessage_TwoArrow", data);
          commit("setNewComment", { chats: data, files: data.files });
          commit("changeCommentLoading");
          commit("change_flag_for_files");
          commit("change_flag_for_last_chat");
          commit("setCommentAdd");
          EventEmitter.$emit("noSendMessages", [data]);
        }
      }
    },
    SOCKET_OUT_CommitAddError({ commit, getters }, data) {
      msg_num_error = msg_num_error + 1;

      if (msg_num_error <= 2) {
        array_msg.forEach((e, i) => {
          if (e._id == data) {
            // array_msg.splice(i, 1)
            // localStorage.setItem('task_message', JSON.stringify(array_msg))
            this._vm.$socket.emit("IN_CommitAdd", e);
          }
        });
      } else {
        error_msg_id = data;
        EventEmitter.$emit("errorSendMessages", array_msg);
      }

      msg_error_after_send_msg = true;

      EventEmitter.$emit("noSendMessages", array_msg);
      commit("changeCommentLoading");
    },
    SOCKET_OUT_AddMessagesError({ commit, getters }, data) {
      msg_num_error = msg_num_error + 1;

      if (msg_num_error <= 2) {
        array_msg.forEach((e, i) => {
          if (e._id == data) {
            this._vm.$socket.emit("IN_AddMessages", e, e.chat_id);
          }
        });
      } else {
        error_msg_id = data;
        EventEmitter.$emit("errorSendMessages", array_msg);
      }

      msg_error_after_send_msg = true;

      EventEmitter.$emit("noSendMessages", array_msg);
      commit("render");
    },
    SOCKET_OUT_CommitAdd({ commit, getters }, data) {
      const { task_id, _id: comment_id, user_id, message, reply } = data.chats;

      const hasRepliedMe =
        reply && reply._id && reply.user_id == getters.getUserData._id;

      const taskIsOpenAndIsVisible =
        document.location.href.indexOf(data.chats.task_id) != -1;

      const hasMentionedMe =
        task_id &&
        comment_id &&
        user_id &&
        message &&
        message.length &&
        mentionedEmail(getters.getUserData.email, message);

      msg_num_error = 0;
      if (
        data &&
        !hasRepliedMe &&
        !hasMentionedMe &&
        (!taskIsOpenAndIsVisible || getters.getIsAppInForeground) &&
        data.chats.user_id != getters.getUserData._id
      ) {
        let showNotification = false;
        if (window.localStorage.getItem("mutedObjects")) {
          let mutedObjects = window.localStorage
            .getItem("mutedObjects")
            .split(",");
          if (!mutedObjects.includes(data.project_id)) {
            showNotification = true;
          }
        } else {
          showNotification = true;
        }
        if (showNotification) {
          let task_name = "";

          let all_projects = getters.getProjects;

          if (getters.getTasks[data.project_id]) {
            if (
              getters.getTasks[data.project_id].find(
                (task) => task._id == data.chats.task_id
              )
            ) {
              task_name = getters.getTasks[data.project_id].find(
                (task) => task._id == data.chats.task_id
              ).name;
            }
          }

          if (getters.getCompletedTasks[data.project_id]) {
            if (
              getters.getCompletedTasks[data.project_id].find(
                (task) => task._id == data.chats.task_id
              )
            ) {
              task_name = getters.getCompletedTasks[data.project_id].find(
                (task) => task._id == data.chats.task_id
              ).name;
            }
          }

          let message_settings = {
            body: data.chats.message,
            dir: "ltr",
            lang: "en-US",
            tag: "chat_" + data.chats._id + "_notification",
            renotify: true,
          };

          let play_sound = false;
          if (window.isElectron) {
            message_settings.silent = true;
          } else {
            message_settings.vibrate = [100, 50, 200];
          }

          let welcome_message = "<Username> shared a list with you",
            new_message = data.chats.user_name + " in " + task_name;

          if (data.files) {
            if (data.files.length == 1) {
              new_message = "You received an attachment";
              message_settings.body = data.files[0].filename;
            } else if (data.files.length > 1) {
              new_message = "You received attachments";
              message_settings.body = data.files[0].filename + ", ...";
            }
          }

          if (data.chats.user_name) {
            welcome_message = welcome_message.replace(
              "<Username>",
              data.chats.user_name
            );
          } else {
            welcome_message = welcome_message.replace(
              "<Username>",
              data.chats[0].user_name
            );
          }
          
          let message_notification;
          let url = "";
          if (data.chats.task_id) {
            url = "/project/" + data.project_id + "/task/" + data.chats.task_id;
          } else {
            url = "/project/" + data.project_id;
          }
          if (data.chats.user_name) {

            foregroundLocalNotification({
              title: new_message,
              body: message_settings.body,
              url,
            });
          } else if (data.files.length) {
            foregroundLocalNotification({
              title: new_message,
              body: message_settings.body,
              url,
            });
            // console.log('attachment notification')
          } else if (data.chats.length) {
            foregroundLocalNotification({
              title: welcome_message,
              body: message_settings.body,
              url,
            });
          } else {
            play_sound = false;
          }
        }
      }

      if (data.chats.length > 0) {
        data.chats.forEach((e) => {
          commit("addUnreadMessage_TwoArrow", e);
          if (change_size) {
            if (data.no_change_size) {
              if (e.user_id == getters.getUserData._id) {
                e.files.forEach((f) => {
                  commit("setUserUsedFileSize", +f.size);
                });
              }
            }
          }
        });
      } else {
        commit("addUnreadMessage_TwoArrow", data.chats);
        if (change_size) {
          if (data.chats.user_id == getters.getUserData._id) {
            data.chats.files.forEach((e) => {
              commit("setUserUsedFileSize", +e.size);
            });
          }
        }
      }

      getters.getSubTask.forEach((e) => {
        if (e._id == data.chats.task_id) {
          localStorage.setItem(e.parent, true);
        }
      });

      EventEmitter.$emit("openSubtask", data.chats.task_id);
      EventEmitter.$emit(
        "scrollChatBody",
        data.chats.task_id,
        data.chats.user_id,
        data.chats._id
      );

      change_size = true;
      commit("setNewComment", data);
      commit("setFiles", data);
      commit("set_flag_for_unreaded");

      array_msg.forEach((e, i) => {
        if (e._id == data.chats._id) {
          array_msg.splice(i, 1);
          localStorage.setItem("list_messages", JSON.stringify(array_msg));
        }
      });

      if (error_msg_id == data.chats._id) {
        EventEmitter.$emit("errorSendMessages", []);
      }

      EventEmitter.$emit("noSendMessages", array_msg);

      if (array_msg.length > 0) {
        this._vm.$socket.emit("IN_CommitAdd", array_msg[0]);
      }

      const project_id = data.project_id;

      if (hasMentionedMe && user_id !== getters.getUserData._id) {
        commit("setMentionForList", {
          project_id,
          task_id,
          comment_id,
          user_id,
        });
        if (!taskIsOpenAndIsVisible) {
          const msg = comment_parse(
            message,
            getters.getProjects.find((list) => list._id == data.project_id)
              .users
          );
          pushNotification({
            type: "mentionedInTask",
            task_id,
            comment: msg,
            getters,
            project_id,
          });
        }
      }

      if (
        hasRepliedMe &&
        !hasMentionedMe &&
        user_id !== getters.getUserData._id &&
        !taskIsOpenAndIsVisible
      ) {
        const msg = comment_parse(
          message,
          getters.getProjects.find((list) => list._id == data.project_id).users
        );
        pushNotification({
          type: "replyInTask",
          task_id,
          comment: msg,
          getters,
          project_id,
        });
      }

      commit("changeCommentLoading");
      commit("setCommentAdd");
    },
    SOCKET_OUT_AddMessages({ commit, getters, dispatch }, messages) {
      commit("setLastMessage", messages);
      commit("setAddMessages", messages);
      commit("setChangeReadLastMessage", {
        chat_id: messages.chat_id,
        user_id: messages.user_id,
        _id: messages.message._id,
      });

      EventEmitter.$emit(
        "scrollChatBody",
        messages.chat_id,
        messages.user_id,
        messages.message._id
      );

      array_msg.forEach((e, i) => {
        if (e._id == messages.message._id) {
          array_msg.splice(i, 1);
          localStorage.setItem("chat_messages", JSON.stringify(array_msg));
        }
      });

      if (error_msg_id == messages.message._id) {
        EventEmitter.$emit("errorSendMessages", []);
      }

      EventEmitter.$emit("noSendMessages", array_msg);

      if (array_msg.length > 0) {
        this._vm.$socket.emit(
          "IN_AddMessages",
          array_msg[0],
          array_msg[0].chat_id
        );
      }

      const {
        chat_id,
        message,
        user_id,
        _id: comment_id,
        reply,
      } = messages.message;
      const chatName = getters.getChats.find(
        (chat) => chat._id == chat_id
      ).name;
      const current_chat_id = router.app.$route.params.chat_id;
      const hasMentionedMe = mentionedEmail(getters.getUserData.email, message);
      const hasRepliedMe = reply && reply.user_id == getters.getUserData._id;

      const taskIsOpenAndIsVisible =
        !document.hidden && document.location.href.includes(messages.chat_id);
      // const taskIsOpenAndIsVisible = !(document.location.href.indexOf(messages.chat_id) === -1 || ! document.hasFocus())

      if (
        !hasMentionedMe &&
        !hasRepliedMe &&
        !taskIsOpenAndIsVisible &&
        user_id != getters.getUserData._id
      ) {
        // prevent self sending
        let showNotification = false;
        if (window.localStorage.getItem("mutedObjectsChat")) {
          let oldMutedObjects = window.localStorage
            .getItem("mutedObjectsChat")
            .split(",");

          if (!oldMutedObjects.includes(messages.chat_id)) {
            showNotification = true;
          }
        } else {
          showNotification = true;
        }

        if (showNotification) {
          let message_settings = {
            body: messages.message.message,
            tag: "chats_" + messages.chat_id + "_notification",
          };

          let welcome_message =
            messages.message.user_name + " shared a chat with you";
          let new_message = messages.message.user_name + " in " + chatName;

          foregroundLocalNotification({
            title: new_message,
            body: message_settings.body,
            url: "/chats/" + messages.chat_id,
          });
        }
      }

      if (hasMentionedMe && user_id !== getters.getUserData._id) {
        commit("setMentionForChat", { chat_id, message, user_id, comment_id });
        if (!taskIsOpenAndIsVisible) {
          const allUsers = getters.getUsersByChat[chat_id];
          const msg = comment_parse(message, allUsers);
          pushNotification({ type: "mentionedInChat", chat_id, comment: msg });
        }
      }

      if (
        hasRepliedMe &&
        !hasMentionedMe &&
        !taskIsOpenAndIsVisible &&
        user_id !== getters.getUserData._id
      ) {
        const allUsers = getters.getUsersByChat[chat_id];
        const msg = comment_parse(message, allUsers);
        pushNotification({
          type: "replyInChat",
          chat_id,
          comment: msg,
          getters,
        });
      }

      // console.log('2', getters.getSocketId, this._vm.$socket.id)

      if (
        getters.getApplicationStatus.toLowerCase() == "updating" &&
        getters.getUserData._id !== messages.user_id
      ) {
        commit("setChatUnreadMessagesWhileUpdating", {
          chat_id: messages.chat_id,
          comment_id,
        });
      }

      commit("render");
      commit("setCommentAdd");
    },
    SOCKET_OUT_CommitLast({ commit }, data) {
      commit("set_last_chat", [
        { _id: data.project_id, last_chat: data.last_chat },
      ]);
      commit("set_new_last_chat", data);
      commit("change_flag_for_last_chat");
    },
    // метод для пуш уведомления. Бэк присылает мне таск
    SOCKET_OUT_CommitUnreadMessage({ getters, commit }, data) {
      // console.log("OUT_CommitUnreadMessage", data);
      /* console.log("--------------");
      console.log(new Date());
      console.log("OUT_CommitUnreadMessage", data);
      console.log("--------------"); */
      /* в data приходит 1 таск в котором поменялся ключ unread_messages в массиве users */
      /* set_unreaded_messages_in_task меняет unread_messages внутри пришедщего таска */
      commit("set_unreaded_messages_in_task", {
        data,
        user: getters.getUserData,
      });
      /* 
      set_unreaded_messages добавляет непрочитанные сообщения в новые переменные 
      для отображения непрочитанных сообщений в тасках и проектах
      */

      let tasks = [];

      getters.getProjects.forEach((e) => {
        if (getters.getTasks[e._id]) {
          getters.getTasks[e._id].forEach((t) => {
            tasks.push(t);
          });
        }
        if (getters.getCompletedTasks[e._id]) {
          getters.getCompletedTasks[e._id].forEach((t) => {
            tasks.push(t);
          });
        }
      });

      commit("set_unreaded_messages", tasks);

      commit("change_read_flag", {
        user: getters.getUserData,
        tasks: getters.getTasks,
        need_task: data,
      });

      if (getters.getApplicationStatus == "updating") {
        commit("setRecievedMessageWhileUpdating", true);
      }

      // методы ниже меняют флаги что бы vue взял новые значения из стора в компонентах
      commit("change_flag_for_tasks");
      commit("set_flag_for_unreaded");
      //сделать проверку кто присылает сообщение
      if (data.userID != getters.getUserData._id) {
        commit("changeCommentLoading");
        commit("change_flag_for_files");
      }
    },
    SOCKET_OUT_CommitNotif({ getters }, data) {
      data.users.forEach((user) => {
        if (
          user.id == getters.getUserData._id &&
          getters.getUserData._id != data.message.user &&
          (!getters.get_active_task_window ||
            getters.get_active_task_window != data.message.task)
        ) {
          if (Notification.permission == "granted") {
            notify(data);
          } else if (Notification.permission != "denied") {
            Notification.requestPermission((permission) => {
              if (!("permission" in Notification)) {
                Notification.permission = permission;
              }
              if (permission == "granted") {
                notify(data);
              }
            });
          }
        }
      });
      // метод для пуш уведомления. Бэк присылает мне сообщение
    },
    socket_read_message(ctx, data) {
      this._vm.$socket.emit("IN_CommitReadMessage", data);
    },
    SOCKET_OUT_CommitReadMessage({ commit, getters }, data) {
      if (data.chat_id) {
        commit("comment_read_users", data);
        commit("setRealUnreadMessagesToZero", data);
        commit("set_unreaded_messages_in_task", {
          data,
          user: getters.getUserData,
        });

        let tasks = [];

        getters.getProjects.forEach((e) => {
          if (getters.getTasks[e._id]) {
            getters.getTasks[e._id].forEach((t) => {
              tasks.push(t);
            });
          }
          if (getters.getCompletedTasks[e._id]) {
            getters.getCompletedTasks[e._id].forEach((t) => {
              tasks.push(t);
            });
          }
        });

        commit("set_unreaded_messages", tasks);

        commit("change_read_flag", {
          user: getters.getUserData,
          tasks: getters.getTasks,
          need_task: data,
        });

        commit("set_flag_for_unreaded");
        commit("change_flag_for_tasks");
        commit("changeCommentLoading");
        commit("setCommentsTasksOnRead", data);

        let found = false;
        if (data.user_id == getters.getUserData._id) {
          getters.getTasks[data.project_id].some((task) => {
            if (task._id == data.task_id) {
              task.unread_message = 0;
              task.real_unread_message = 0;
              found = true;
              return true;
            }
          });

          if (!found) {
            getters.getCompletedTasks[data.project_id].some((task) => {
              if (task._id == data.task_id) {
                task.unread_message = 0;
                task.real_unread_message = 0;
                return true;
              }
            });
          }
        }
      }
    },
    socket_edit_message({ commit }, data) {
      edit_msg = false;
      if (data.chats_id) {
        let messages = {
          chat_id: data.chats_id,
          message_id: data.chat_id,
          message: data.message,
        };
        commit("setRenameMessages", messages);
        commit("render");
        this._vm.$socket.emit(
          "IN_RenameMessages",
          data.chats_id,
          data.chat_id,
          data.message
        );
      } else {
        commit("edit_message", data);
        // commit("set_last_chat", [{ _id: data.project_id, last_chat: data }]);
        // commit("set_new_last_chat", {
        //     last_chat: data,
        //     project_id: data.project_id,
        // });
        commit("changeCommentLoading");
        commit("change_flag_for_last_chat");
        this._vm.$socket.emit("IN_CommitRename", {
          chat_id: data.chat_id,
          message: data.message,
          task_id: data.task_id,
          project_id: data.project_id,
        });
      }
    },
    SOCKET_OUT_CommitRename({ commit, getters }, data) {
      // console.log('SOCKET_OUT_CommitRename')
      if (edit_msg) {
        commit("edit_message", data);
        // commit("set_last_chat", [{ _id: data.project_id, last_chat: data }]);
        // commit("set_new_last_chat", {
        //   last_chat: data,
        //   project_id: data.project_id,
        // });
        commit("changeCommentLoading");
      }
      edit_msg = true;
    },
    socket_delete_message({ commit, getters }, data) {
      // console.log('del')

      /*commit("deleteOrChangeLastMessage", data)*/

      this_user_delete_msg = false;
      let comment = data.comment;

      if (
        data.comment.files_body &&
        data.comment.files_body.length > 0 &&
        getters.get_files[data.task_id]
      ) {
        let filesSize = 0;

        getters.get_files[data.task_id]
          .filter((file) => file.chat_id == data.chat_id)
          .forEach((file) => {
            filesSize += +file.size;
          });

        this.commit("setUserUsedFileSize", filesSize * -1);

        commit("delete_files", {
          task_id: data.task_id,
          chat_id: data.chat_id,
        });

        commit("change_flag_for_files");
      }

      delete data.comment;

      commit("delete_message", data);
      commit("changeCommentLoading");
      commit("change_flag_for_files");
      commit("setCommentAdd");

      if (comment.error) {
        let task_message = localStorage.getItem("task_message");

        if (task_message) {
          let task_message_array = JSON.parse(task_message);

          if (task_message_array.length > 0) {
            task_message_array.forEach((e, i) => {
              if (e._id == comment._id) {
                task_message_array.splice(i, 1);
              }
            });
          }

          array_msg = task_message_array;
          localStorage.setItem(
            "task_message",
            JSON.stringify(task_message_array)
          );
        }
      } else {
        this._vm.$socket.emit("IN_CommitDelete", data);
      }
    },
    SOCKET_OUT_CommitDelete({ commit, getters }, data) {
      // console.log("SOCKET_OUT_CommitDelete", data);
      let task = getters.getTasks[data.project_id].filter(
        (e) => e._id == data.task_id
      );

      /*commit("set_unreaded_messages_in_task", {
            data: task[0],
            user: getters.getUserData,
        });*/
      commit("set_unreaded_messages2", data);
      commit("set_flag_for_unreaded");
      commit("setCommentAdd");

      if (this_user_delete_msg) {
        if (getters.getTasks[data.project_id]) {
          getters.getTasks[data.project_id].forEach((task) => {
            if (getters.getComments[task._id]) {
              getters.getComments[task._id].forEach((msg) => {
                if (msg._id == data.chat_id) {
                  if (getters.getUserData._id == msg.user_id) {
                    if (msg.files.length > 0) {
                      msg.files.forEach((f) => {
                        commit("setUserFileSize_minus", +f.size);
                      });
                    }
                  }
                }
              });
            }
          });
        }

        /*commit("deleteOrChangeLastMessage", data);*/

        if (getters.get_files[data.task_id]) {
          commit("delete_files", {
            task_id: data.task_id,
            chat_id: data.chat_id,
          });
          commit("change_flag_for_files");
        }

        commit("delete_message", data);
        /*commit("renderNumberUnread", data)*/

        let tasks = [];

        getters.getProjects.forEach((e) => {
          if (getters.getTasks[e._id]) {
            getters.getTasks[e._id].forEach((t) => {
              tasks.push(t);
            });
          }
          if (getters.getCompletedTasks[e._id]) {
            getters.getCompletedTasks[e._id].forEach((t) => {
              tasks.push(t);
            });
          }
        });

        commit("change_read_flag", {
          user: getters.getUserData,
          tasks: getters.getTasks,
          need_task: task[0],
        });

        commit("changeCommentLoading");
        commit("change_flag_for_files");
      }
      this_user_delete_msg = true;
    },
    socket_start_typing(ctx, data) {
      this._vm.$socket.emit("IN_CommitStartTyping", {
        task_id: data.task_id,
        project_id: data.project_id,
      });
    },
    SOCKET_OUT_CommitStartTyping({ commit, getters }, data) {
      // console.log('CommitStartTyping')
      EventEmitter.$emit("isTypingStatusChanged", true);
      if (getters.getUserData._id != data.users._id) {
        commit("add_typing_user", data);
        commit("change_flag_for_typing_users");
      }
    },
    socket_end_typing(ctx, data) {
      this._vm.$socket.emit("IN_CommitEndTyping", {
        task_id: data.task_id,
        project_id: data.project_id,
      });
    },
    SOCKET_OUT_CommitEndTyping({ commit, getters }, data) {
      // console.log('CommitEndTyping')
      EventEmitter.$emit("isTypingStatusChanged", false);
      if (getters.getUserData._id != data.users._id) {
        commit("delete_typing_user", data);
        commit("change_flag_for_typing_users");
      }
    },
    SOCKET_out_file(ctx, data) {
      // console.log("SOCKET_out_file", data);
    },
    SOCKET_OUT_ChangeNameUser({ commit }, data) {
      setTimeout(() => {
        commit("changeCommentLoading");
        commit("change_flag_for_files");
        commit("change_flag_for_last_chat");
      }, 2000);
      commit("setChangeUserData3", data);
      // EventEmitter.$emit('ChangeEmailUser', true)
    },
    SOCKET_OUT_ChangeImageUser({ commit }, data) {
      setTimeout(() => {
        commit("changeCommentLoading");
        commit("change_flag_for_files");
        commit("change_flag_for_last_chat");
      }, 2000);
      commit("setChangeUserData3", data);
      // EventEmitter.$emit('ChangeEmailUser', true)
    },
    SOCKET_OUT_TaskMove({ commit }, res_data) {
      if (res_data.task) {
        // return
        res_data.task.forEach((task) => {
          task.messages.forEach((msg) => {
            let data = {
              project_id: task.project_id,
              chats: msg,
              files: [],
            };

            if (data.chats.length > 0) {
              data.chats.forEach((e) => {
                commit("addUnreadMessage_TwoArrow", e);
              });
            } else {
              commit("addUnreadMessage_TwoArrow", data.chats);
            }
            commit("setNewComment", data);
            commit("setFiles", data);
            commit("set_flag_for_unreaded");
          });
        });
      }
    },
    SOCKET_OUT_ListMakeIsRead({ commit, getters }, data) {
      const { list_id } = data;
      commit("set_clear_unreaded_messages_list", { list_id });
      // commit("set_real_unread_messages", )

      let tasks = [];

      getters.getProjects.forEach((list) => {
        if (getters.getTasks[list._id]) {
          getters.getTasks[list._id].forEach((task) => {
            if (list._id == list_id) {
              if (task.real_unread_message) {
                task.real_unread_message += task.unread_message;
              } else {
                task.real_unread_message = task.unread_message;
              }
              task.unread_message = 0;
            }
            tasks.push(task);
          });
        }
        if (getters.getCompletedTasks[list._id]) {
          getters.getCompletedTasks[list._id].forEach((task) => {
            if (list._id == list_id) {
              if (task.real_unread_message) {
                task.real_unread_message += task.unread_message;
              } else {
                task.real_unread_message = task.unread_message;
              }
              task.unread_message = 0;
            }
            tasks.push(task);
          });
        }
      });

      commit("set_unreaded_messages", tasks);

      // tasks.forEach(task => {
      //   commit('set_clear_unreaded_messages_task', task._id)
      // })
    },
  },
  mutations: {
    setChatUnreadMessagesWhileUpdating(s, { chat_id, comment_id }) {
      if (!s.chatUnreadMessagesWhileUpdating[chat_id]) {
        s.chatUnreadMessagesWhileUpdating[chat_id] = new Map();
      }
      s.chatUnreadMessagesWhileUpdating[chat_id].set(comment_id, true);
      // console.log(s.chatUnreadMessagesWhileUpdating[chat_id])
    },
    setRecievedMessageWhileUpdating(s, v) {
      s.recievedMessageWhileUpdating = v;
    },
    // comment_read_users(s, v) {
    // if (s.comments[v.task_id]) {
    //   s.comments[v.task_id].forEach((e, i) => {
    //     // if (v.user_id != this.getters.getUserData._id) {
    //       if (!e.users) {
    //           e.users = []
    //           e.users.push(v.user_id)
    //       } else {
    //           let is_user = e.users.filter(e => e == v.user_id)
    //           if (is_user.length == 0) {
    //               e.users.push(v.user_id)
    //           }
    //       }
    //     // }
    //   })
    // }
    // },
    setCommentsTasksOnRead(s, v) {
      const allCommnets = s.comments[v.task_id];
      if (allCommnets) {
        const temp = allCommnets.map((commnet) => {
          commnet.read = true;
          return commnet;
        });
        s.comments[v.task_id] = temp;
      }
    },
    clear_comments_task(s, v) {
      s.comments[v] = [];
    },
    setMentionForChat(state, { chat_id, user_id, comment_id }) {
      if (!state.chatsMentions[chat_id]) {
        state.chatsMentions[chat_id] = {};
      }

      if (!state.chatsMentions[chat_id].mentions) {
        state.chatsMentions[chat_id].mentions = 0;
      }

      if (!state.chatsMentions[chat_id].messages) {
        state.chatsMentions[chat_id].messages = {};
      }

      if (!(comment_id in state.chatsMentions[chat_id].messages)) {
        state.chatsMentions[chat_id].mentions++;
      }

      state.chatsMentions[chat_id].messages[comment_id] = true;

      // console.log(state.chatsMentions, 'chat mentions?????')
    },
    setMentionForList(state, { project_id, task_id, comment_id, user_id }) {
      // console.log(this.getters.getUserData._id, user_id, 'hello?????')
      // if (this.getters.getUserData._id == user_id) return
      if (!state.listsMentions[project_id]) {
        state.listsMentions[project_id] = {};
      }

      if (!state.listsMentions[project_id][task_id]) {
        state.listsMentions[project_id][task_id] = {};
      }

      if (!state.listsMentions[project_id].mentions) {
        state.listsMentions[project_id].mentions = 0;
      }

      if (!state.listsMentions[project_id][task_id].mentions) {
        state.listsMentions[project_id][task_id].mentions = 0;
      }

      if (!state.listsMentions[project_id][task_id][comment_id]) {
        state.listsMentions[project_id][task_id].mentions++;
        state.listsMentions[project_id].mentions++;
      }

      state.listsMentions[project_id][task_id][comment_id] = true;
      // console.log('setMentionForList', state.listsMentions[project_id])
      // console.log(state.listsMentions, 'metions??')
    },
    removeMentionForList(state, { project_id, task_id, comment_id }) {
      if (
        !(
          state.listsMentions[project_id] &&
          state.listsMentions[project_id][task_id] &&
          state.listsMentions[project_id][task_id][comment_id]
        )
      )
        return;
      delete state.listsMentions[project_id][task_id][comment_id];
      state.listsMentions[project_id][task_id].mentions--;
      state.listsMentions[project_id].mentions--;
    },
    removeMentionForChat(state, { comment_id, chat_id }) {
      // console.log(state.chatsMentions, 'before')
      if (
        !(
          state.chatsMentions[chat_id] &&
          state.chatsMentions[chat_id].messages &&
          state.chatsMentions[chat_id].messages[comment_id]
        )
      )
        return;
      delete state.chatsMentions[chat_id].messages[comment_id];
      state.chatsMentions[chat_id].mentions--;
      // console.log(state.chatsMentions, 'after')
    },
    setMentionsetMentionsForTask(state, { task_id }) {
      const comments = state.comments[task_id];
      this.tasks;
    },
    // Seting comments by id in key
    setCommentsTasks(s, v) {
      if (s.comments[v.task_id]) {
        s.comments[v.task_id] = s.comments[v.task_id].filter(
          (e) => e._id != v.comment._id
        );
        if (!isNaN(v.comment.date)) {
          v.comment.date = new Date(+v.comment.date).toISOString();
        }
        s.comments[v.task_id].push(v.comment);
      } else {
        s.comments[v.task_id] = [];
        if (!isNaN(v.comment.date)) {
          v.comment.date = new Date(+v.comment.date).toISOString();
        }
        s.comments[v.task_id].push(v.comment);
      }
    },
    setTaskComments(state, { task_id, comments }) {
      state.comments[task_id] = comments;
    },
    setAllTaskComments(state, { task_id, comments }) {
      let comments2 = state.comments;
      comments2[task_id] = mergeTwoCommentsWihtUniqueIds({
        task_id,
        currentComments: state.comments[task_id],
        incomingComments: comments,
      });
      state.comments = comments2;
      // this.commit("setFiles", state.comments[task_id])
      state.fetchedComments[task_id] = true;
    },
    setComments(s, v) {
      if (v.chats.length > 0) {
        s.comments[v.chats[0].task_id] = [];
        v.chats.forEach((comment) => {
          // comment.send = true
          if (!isNaN(comment.date)) {
            comment.date = new Date(+comment.date).toISOString();
          }
          this.commit("addToComments", {
            comments: s.comments,
            comment,
            files: v.files,
            vue: this._vm,
            tags: v.tags,
          });
        });
      }
    },
    setChangeUserData3(s, v) {
      if (v.type == "change_image") {
        this.getters.getProjects.forEach((list) => {
          if (this.getters.getTasks[list._id]) {
            this.getters.getTasks[list._id].forEach((task) => {
              if (s.comments[task._id]) {
                s.comments[task._id].forEach((msg) => {
                  if (msg.user_id == v.user_id) {
                    msg.user_image = v.image;
                  }
                });
              }
            });
          }
          if (this.getters.getCompletedTasks[list._id]) {
            this.getters.getCompletedTasks[list._id].forEach((task) => {
              if (s.comments[task._id]) {
                s.comments[task._id].forEach((msg) => {
                  if (msg.user_id == v.user_id) {
                    msg.user_image = v.image;
                  }
                });
              }
            });
          }
        });
      } else {
        this.getters.getProjects.forEach((list) => {
          if (this.getters.getTasks[list._id]) {
            this.getters.getTasks[list._id].forEach((task) => {
              if (s.comments[task._id]) {
                s.comments[task._id].forEach((msg) => {
                  if (msg.user_id == v.user_id) {
                    msg.user_name = v.name;
                  }
                });
              }
            });
          }
          if (this.getters.getCompletedTasks[list._id]) {
            this.getters.getCompletedTasks[list._id].forEach((task) => {
              if (s.comments[task._id]) {
                s.comments[task._id].forEach((msg) => {
                  if (msg.user_id == v.user_id) {
                    msg.user_name = v.name;
                  }
                });
              }
            });
          }
        });
      }
      // console.log(s.comments)
    },
    clearComments(s) {
      s.comments = [];
    },
    clearCommentsInLocalModel(s, task_id) {
      delete s.comments[task_id];
    },
    setNewComment(s, v) {
      if (v.chats.length > 0) {
        v.chats.forEach((e) => {
          if (!s.comments[e.task_id]) s.comments[e.task_id] = [];
          s.comments[e.task_id].push(e);
        });
      } else {
        if (!s.comments[v.chats.task_id]) s.comments[v.chats.task_id] = [];
        const findComent = s.comments[v.chats.task_id].find(
          (com) => com._id == v.chats._id
        );
        if (findComent) {
          s.comments[v.chats.task_id].forEach((e) => {
            if (e._id == v.chats._id) {
              e.send = true;
            }
          });
        } else {
          let msgs = s.comments;
          msgs[v.chats.task_id].push(v.chats);
          s.comments = [];
          s.comments = msgs;
        }
      }
      // if (v.chats.length > 0) {
      //   v.chats.forEach((e) => {
      //     this.commit("addToComments", {
      //       comments: s.comments,
      //       comment: e,
      //       files: v.files,
      //       tags: v.tags,
      //       vue: this._vm
      //     })
      //   })
      // } else {
      //   this.commit("addToComments", {
      //     comments: s.comments,
      //     comment: v.chats,
      //     files: v.files,
      //     tags: v.tags,
      //     vue: this._vm
      //   })
      // }
    },
    changeCommentLoading(s) {
      s.commentLoading = !s.commentLoading;
    },
    setCommentLoading(s) {
      s.commentLoading = true;
    },
    clearCommentLoading(s) {
      s.commentLoading = false;
    },
    set_clear_unreaded_messages_list(s, { list_id }) {
      let temp = { ...s.unreaded_messages_in_project };
      temp[list_id] = 0;
      s.unreaded_messages_in_project = temp;
    },
    set_clear_unreaded_messages_task(s, task_id) {
      const temp = { ...s.unreaded_messages_in_tasks };
      temp[task_id] = 0;
      s.unreaded_messages_in_tasks = temp;
    },
    /* set_unreaded_messages добавляет в:
     - unreaded_messages_in_tasks ключ с id таска и значением количество непрочитанных сообщений
     - unreaded_messages_in_project ключ с id проекта и значением количество непрочитанных 
     всех сообщений из тасков связанных с этим проектом
     */
    set_unreaded_messages2(s, v) {
      if (s.unreaded_messages_in_project[v.project_id] > 0) {
        let unreaded_messages_in_project = s.unreaded_messages_in_project;
        unreaded_messages_in_project[v.project_id] =
          s.unreaded_messages_in_project[v.project_id] - 1;
        s.unreaded_messages_in_project = [];
        s.unreaded_messages_in_project = unreaded_messages_in_project;
      }
      if (s.unreaded_messages_in_tasks[v.task_id] > 0) {
        let unreaded_messages_in_tasks = s.unreaded_messages_in_tasks;
        unreaded_messages_in_tasks[v.task_id] =
          s.unreaded_messages_in_tasks[v.task_id] - 1;
        s.unreaded_messages_in_tasks = [];
        s.unreaded_messages_in_tasks = unreaded_messages_in_tasks;
      }

      if (this.getters.getComments[v.task_id]) {
        this.getters.getComments[v.task_id].forEach((msg) => {
          if (msg._id == v.chat_id) {
            if (msg.user_id != this.getters.getUserData._id) {
              if (msg.users) {
                let unread = msg.users.filter(
                  (e) => e == this.getters.getUserData._id
                );
                if (unread.length == 0) {
                  // s.unreaded_messages_in_project[v.project_id] = s.unreaded_messages_in_project[v.project_id] - 1
                  // s.unreaded_messages_in_tasks[v.task_id] = s.unreaded_messages_in_tasks[v.task_id] - 1
                  this.commit("renderNumberUnread", v);
                }
              }
            }
          }
        });
      }
    },
    set_unreaded_messages(s, v) {
      // v - это объект с массивами тасков
      s.unreaded_messages_in_project = {};
      s.unreaded_messages_in_tasks = {};
      // for (const task in v) {
      // if (Object.hasOwnProperty.call(v, pr_id)) {
      // const element = v[pr_id]
      v.forEach((task) => {
        if (task.unread_message) {
          s.unreaded_messages_in_tasks[task._id] = Number(task.unread_message);
          if (!s.unreaded_messages_in_project[task.project_id]) {
            s.unreaded_messages_in_project[task.project_id] = 0;
          }
          s.unreaded_messages_in_project[task.project_id] += Number(
            task.unread_message
          );
        }
      });
      // }
      // }
    },
    set_flag_for_unreaded(s) {
      s.flag_for_unreaded = !s.flag_for_unreaded;
    },
    //change_read_flag - метод отвечаюший за отслеживание непрочитанных сообщений
    change_read_flag(s, { user, tasks, need_task }) {
      //user => данные текущего пользователя
      //tasks => объект с массивами из тасков
      /* need_task => нужный таск, используется для фильтрации тасков в проекте 
      (ниже переменная task_in_project) */

      if (need_task) {
        sortMessagesByReadUnred(
          need_task,
          s.comments[need_task._id],
          user,
          s.unreaded_messages_for_chat,
          s.not_mine_messages_for_zeroing,
          need_task._id
        );
      } else {
        for (const task_id in s.comments) {
          if (Object.hasOwnProperty.call(s.comments, task_id)) {
            for (const pr_id in tasks) {
              if (Object.hasOwnProperty.call(tasks, pr_id)) {
                if (tasks[pr_id].some((task) => task._id == task_id)) {
                  let task_in_project = tasks[pr_id].find(
                    (task) => task._id == task_id
                  );
                  sortMessagesByReadUnred(
                    task_in_project,
                    s.comments[task_id],
                    user,
                    s.unreaded_messages_for_chat,
                    s.not_mine_messages_for_zeroing,
                    task_id
                  );
                }
              }
            }
          }
        }
      }
    },
    addUnreadMessage_TwoArrow(s, v) {
      if (!s.unreaded_messages_for_chat[v.task_id]) {
        s.unreaded_messages_for_chat[v.task_id] = [];
      }
      if (
        !s.unreaded_messages_for_chat[v.task_id].some((msg) => msg._id == v._id)
      ) {
        s.unreaded_messages_for_chat[v.task_id].push(v._id);
      }
    },
    set_new_local_reply_message(s, comment) {
      const key = comment.task_id
        ? comment.task_id
        : router.app.$route.params.chat_id;
      s.local_reply_message[key] = comment;
    },
    delete_local_reply_message(s, comment) {
      const key = comment.task_id
        ? comment.task_id
        : router.app.$route.params.chat_id;
      delete s.local_reply_message[key];
    },
    change_flag_for_reply_message(s) {
      s.flag_for_reply_message = !s.flag_for_reply_message;
    },
    set_new_local_edit_message(s, comment) {
      const key = comment.task_id
        ? comment.task_id
        : router.app.$route.params.chat_id;
      s.local_edit_message[key] = comment;
    },
    delete_local_edit_message(s, comment) {
      const key = comment.task_id
        ? comment.task_id
        : router.app.$route.params.chat_id;
      delete s.local_edit_message[key];
    },
    change_flag_for_edit_message(s) {
      s.flag_for_edit_message = !s.flag_for_edit_message;
    },
    edit_message(s, v) {
      if (s.comments[v.task_id]) {
        s.comments[v.task_id].find(
          (c) => c._id == v.chat_id || c._id == v._id
        ).message = v.message;
      }
    },
    edit_files(s, v) {
      if (s.comments[v.task_id]) {
        s.comments[v.task_id].find(
          (c) => c._id == v.chat_id || c._id == v._id
        ).files = [];
        s.comments[v.task_id].find(
          (c) => c._id == v.chat_id || c._id == v._id
        ).remove_file = true;
      }
    },
    delete_message(s, v) {
      if (s.comments[v.task_id]) {
        s.comments[v.task_id] = s.comments[v.task_id].filter(
          (comment) => comment._id != v.chat_id
        );
      } else {
        s.comments[v.task_id] = [];
      }
      EventEmitter.$emit("deleteComment", v.chat_id);
    },
    add_typing_user(s, v) {
      if (v.task) {
        if (!s.users_typing[v.task._id]) s.users_typing[v.task._id] = [];
        if (
          !s.users_typing[v.task._id].some(
            (typing_user) => typing_user.users._id == v.users._id
          )
        ) {
          s.users_typing[v.task._id].push(v);
        }
      } else {
        if (!s.users_typing[v.chat_id]) s.users_typing[v.chat_id] = [];
        if (
          !s.users_typing[v.chat_id].some(
            (typing_user) => typing_user.users._id == v.user_id
          )
        ) {
          let data = {
            users: this._vm.$w_get_know_user_data(
              this.getters.getKnownUsers,
              this.getters.getUserData,
              v.user_id
            ),
          };
          s.users_typing[v.chat_id].push(data);
        }
      }
    },
    delete_typing_user(s, v) {
      if (v.task) {
        if (!s.users_typing[v.task._id]) s.users_typing[v.task._id] = [];
        s.users_typing[v.task._id] = s.users_typing[v.task._id].filter(
          (typing_user) => typing_user.users._id != v.users._id
        );
      } else {
        if (!s.users_typing[v.chat_id]) s.users_typing[v.chat_id] = [];
        s.users_typing[v.chat_id] = s.users_typing[v.chat_id].filter(
          (typing_user) => typing_user.users._id != v.user_id
        );
      }
    },
    change_flag_for_typing_users(s) {
      s.flag_for_typing_users = !s.flag_for_typing_users;
    },
    addToComments(s, { comments, comment, files, vue }) {
      const task_id = comment.task_id;
      let chats = comments;

      if (!chats[task_id]) {
        chats[task_id] = [];
      }

      const findComent = chats[task_id].find((com) => com._id == comment._id);

      if (findComent) {
        chats[task_id].forEach((e) => {
          if (e._id == comment._id) {
            e.send = true;
          }
        });
      } else {
        this.commit("addFilesBodyToComment", { comment, files, vue });
        chats[task_id].push(comment);
      }

      comments = chats;
    },
    addFilesBodyToComment(s, { comment, files, vue }) {
      comment.files_body = [];
      // comment.files = []

      if (files.length > 0) {
        files.forEach((file) => {
          // const file_body = files.find((file) => file._id == file_id);
          if (file) {
            this.commit("addSrcUrlToFile", { file: file });
            this.commit("changeExtInFile", { file: file, vue });
            // comment.files_body.push(file);
            comment.files = files;
          }
        });
      }

      // comment.files_body = [];
      // console.log('files', files)
      // console.log('comment', comment)
      // if (files.length > 0 && comment.file_id.length > 0) {
      //     comment.file_id.forEach((file_id) => {
      //         const file_body = files.find((file) => file._id == file_id);
      //         if (file_body) {
      //             this.commit("addSrcUrlToFile", { file: file_body });
      //             this.commit("changeExtInFile", { file: file_body, vue });
      //             comment.files_body.push(file_body);
      //         }
      //     });
      // }
    },
    deleteOrChangeLastMessage(s, v) {
      const id = v.task_id ? v.task_id : router.app.$route.params.chat_id;
      const prev_message = s.comments[id][s.comments[id].length - 2];
      const last_message = s.comments[id][s.comments[id].length - 1];
      if (last_message._id == v.chat_id) {
        this.commit("set_last_chat", [
          {
            _id: v.project_id,
            last_chat: prev_message ? prev_message : null,
          },
        ]);
        this.commit("set_new_last_chat", {
          last_chat: prev_message ? prev_message : null,
          project_id: v.project_id,
        });
        this.commit("change_flag_for_last_chat");
      }
    },
    setCommentAdd(s, v) {
      s.commentAdd = !s.commentAdd;
    },
  },
  getters: {
    getCommentAdd: (s) => s.commentAdd,
    getComments: (s) => s.comments,
    getCommentLoading: (s) => s.commentLoading,
    get_unreaded_messages_in_project: (s) => s.unreaded_messages_in_project,
    get_unreaded_messages_in_tasks: (s) => s.unreaded_messages_in_tasks,
    get_flag_for_unreaded: (s) => s.flag_for_unreaded,
    get_unreaded_messages_for_chat: (s) => s.unreaded_messages_for_chat,
    get_not_mine_messages_for_zeroing: (s) => s.not_mine_messages_for_zeroing,
    get_local_reply_message: (s) => s.local_reply_message,
    get_flag_for_reply_message: (s) => s.flag_for_reply_message,
    get_local_edit_message: (s) => s.local_edit_message,
    get_flag_for_edit_message: (s) => s.flag_for_edit_message,
    get_users_typing: (s) => s.users_typing,
    get_flag_for_typing_users: (s) => s.flag_for_typing_users,
    getListsMentions: (s) => s.listsMentions,
    getChatsMentions: (s) => s.chatsMentions,
    getFetchedComments: (s) => s.fetchedComments,
    getRecievedMessageWhileUpdating: (s) => s.recievedMessageWhileUpdating,
    getChatUnreadMessagesWhileUpdating: (s) =>
      s.chatUnreadMessagesWhileUpdating,
  },
};

function notify(data) {
  var notification = new Notification(
    `${data.message.name} написал сообщение в "${data.taskName}"`,
    {
      body: data.message.message,
    }
  );
}

function sortMessagesByReadUnred(
  task_in_project,
  comments,
  user,
  unreaded_messages_for_chat,
  not_mine_messages_for_zeroing,
  task_id
) {
  //обнуляем или, если нету, создаём массивы с ключом id таска
  if (task_id) unreaded_messages_for_chat[task_id] = [];

  /* 
  unreaded_messages_for_chat[task_id] -> массив для проверки какому моему сообщению ставить две галочки
  если в этом массиве оказывается сообщение - значит оно еще не прочитано другим пользователем и у него 
  должна быть одна галочка
   */
  if (task_id) not_mine_messages_for_zeroing[task_id] = [];
  /* 
    s.not_mine_messages_for_zeroing[task_id] -> массив для обнуления сообщений
    В него запишуться сообщения которые не прочитал собеседник          
    */
  let count_not_mine_unread_messages_array = [];
  let count_mine_unread_messages;
  /* 
  count_not_mine_unread_messages_array - массив с числами не моих непрочитанных сообщений                
  count_mine_unread_messages - число моих нерпрочитанных сообщений
  */
  task_in_project.users.forEach((user_in_task) => {
    if (user_in_task._id != user._id) {
      count_not_mine_unread_messages_array.push(user_in_task.unread_message);
    } //записываем количество написанных другими пользователями сообщений которые я еще не прочитал
    else
      count_mine_unread_messages =
        task_in_project.unread_message >= 0 &&
        task_in_project.unread_message != undefined
          ? task_in_project.unread_message
          : user_in_task.unread_message;
  });

  let count_not_mine_unread_messages = Math.min(
    ...count_not_mine_unread_messages_array
  );
  /* 
  count_not_mine_unread_messages - количество непрочитанных МОИХ сообщений у других пользователей
  */

  let mine_messages = [];
  let not_mine_messages = [];

  if (!comments) comments = [];
  //фильтрую на мои и на не мои сообщений
  if (comments.length > 0) {
    comments.forEach((comment) => {
      if (comment.user_id == user._id) mine_messages.push(comment);
      else not_mine_messages.push(comment);
    });
  } else not_mine_messages.push(0);
  /* 
  добавляю в массив с моими, непрочитанными другими пользователями, сообщениями  
  id моих сообщений
  */
  mine_messages.forEach((mm, mm_i, mm_arr) => {
    /* 
    проверяю мои сообщения - если есть непрочитанные мои сообщения у других пользователей
    и индекс текущего сообщения в итерации больше чем ( количество моих сообщений - количество
    моих непрочитанных сообщений у других пользователей ), то можно работать дальше
    */
    if (
      count_not_mine_unread_messages >= 0 &&
      mm_i >= mm_arr.length - count_not_mine_unread_messages
    ) {
      if (!unreaded_messages_for_chat[mm.task_id])
        unreaded_messages_for_chat[mm.task_id] = [];
      if (!unreaded_messages_for_chat[mm.task_id].includes(mm._id))
        unreaded_messages_for_chat[mm.task_id].push(mm._id);
    }
  });
  /* console.log("--------------");
  console.log(new Date());
  console.log(
    "count_mine_unread_messages in change_order",
    count_mine_unread_messages
  );
  console.log("--------------"); */
  not_mine_messages.forEach((mm, mm_i, mm_arr) => {
    if (
      count_mine_unread_messages >= 0 &&
      mm_i >= mm_arr.length - count_mine_unread_messages
    ) {
      if (!not_mine_messages_for_zeroing[mm.task_id])
        not_mine_messages_for_zeroing[mm.task_id] = [];
      if (!not_mine_messages_for_zeroing[mm.task_id].includes(mm._id))
        not_mine_messages_for_zeroing[mm.task_id].push(mm);
    }
  });
}

function comment_parse(message, users) {
  users = usersByEmail(users);
  let mentions = {};
  let messageArr = message.replace(/\n\r?/g, " ").split(" ");
  for (let i = 0; i < messageArr.length; i++) {
    if (messageArr[i] in users) {
      mentions[messageArr[i]] = users[messageArr[i]];
    }
  }
  for (let email in mentions) {
    message = message.replaceAll(email, mentions[email]);
  }
  // console.log('mentions: ', mentions)
  // console.log('messageAr: ', messageArr)
  // console.log('message: ', message)

  return message;
}

function usersByEmail(allUsers) {
  let users = {};
  for (let i = 0; i < allUsers.length; i++) {
    const name = allUsers[i].name
      ? allUsers[i].name
      : allUsers[i].user_data.name;
    const email = allUsers[i].user_data
      ? allUsers[i].user_data.email
      : allUsers[i].email;
    users["@" + email] = name;
  }
  return users;
}

function mergeTwoCommentsWihtUniqueIds({
  task_id,
  currentComments,
  incomingComments,
}) {
  let commentsToAdd = [];
  let allCommentsById = new Map();
  incomingComments.forEach((comment) => {
    allCommentsById.set(comment._id, true);
    comment.task_id = task_id;
  });

  if (currentComments && currentComments.length) {
    currentComments.forEach((comment) => {
      const { _id } = comment;
      if (!allCommentsById.has(_id)) commentsToAdd.push(comment);
    });
  }

  return incomingComments.concat(commentsToAdd);
}
