<template>
    <div>
        <div style="overflow: hidden;position: relative;">
            <div class="sidebar" ref="sidebar" id="left_sidebar" :class="showSideBar ? 'show' : ''">

                <i class="el-icon-close close_sidebar" @click="toggle_collapse(false)"></i>
                <div style="display: flex; align-items: center;padding: 5px 0 4px 4px;">
                    <div class="folder_add" v-if="tab == 'Lists' && add_folder_show" @click="addFolder" title="Add folder">
                        <img :src="require('@/assets/static/images/add_folder.svg')" alt="" />
                    </div>
                    <SearchTasks :class="tab == 'Lists' && add_folder_show ? 'width' : ''" />
                </div>
                <i class="el-icon-more-outline collapse_toggle" @click="toggle_collapse(true)"></i>

                <!-- <div class="projects_title">
                    {{tab}}
                </div> -->

                <ProjectsView :toggleCollapse="toggleCollapse" />

                <div v-if="getShowSmalActivityLog" class="sidebar__activity_log">
                    <ActivityLog :is_hidden="false" />
                </div>

                <div class="sidebar__info">
                    <div class="sidebar__tab">
                        <ul>
                            <!-- <li @click="changeTab('Shortcuts')" :class="tab == 'Shortcuts' ? 'active' : ''">Shortcuts</li> -->
                            <li @click="changeTab('Lists')" :class="tab == 'Lists' ? 'active' : ''">Lists<span v-if="unread_number_tasks > 0">{{unread_number_tasks}}</span></li>
                            <li @click="changeTab('Chats')" :class="tab == 'Chats' ? 'active' : ''">Chats<span v-if="unread_number_chats > 0">{{unread_number_chats}}</span></li>
                        </ul>
                    </div>
                    <div class="sidebar_info_btn" @click.prevent="showAddProjectWindow">
                        <button>
                            <img :src="require('@/assets/static/images/plus.svg')" alt="" />
                        </button>
                    </div>
                        <!-- sssss -->
                    <div class="sidebar_call_to_action">
                        <div class="sidebar_call_to_action__item" @click="openMenuSettings">
                            <span>
                                <div class="sidebar__history_change new_vertion" v-if="new_vertion"></div>
                                <span class="user_first_word">{{ getUserData.name[0] }}</span>
                            </span>
                        </div>
                        <div class="sidebar_call_to_action__item" @click="showSideBar = true">
                            <span>
                                <div class="sidebar__history_change" v-if="changeHistory"></div>
                                <img :src="require('@/assets/static/images/bell2.svg')" alt="" />
                            </span>
                        </div>
                    </div>
                    <div class="project_filse_size" v-if="getUserData && getUserData.show_disk_usage">
                        <span class="project_filse_size__info">
                            {{ $w_parse_doc_size(getUserUsedFileSize) }} out of
                            {{ $w_parse_doc_size(getUserAllFileSize) }} used
                            <img class="project_filse_size__info__question" :src="require('@/assets/static/images/quetion.svg')" alt="" @mouseover="showQuetionPopup = true" />
                
                            <!-- <span class="project_filse_size__info__question">?</span> -->
                        </span>
                        <el-progress :percentage="usedSizeBar"></el-progress>
                    </div>
                </div>
            </div>
            <div class="sidebar__activity_log_hidden vertical_scrollbar" :class="showSideBar ? 'show' : ''">
                <ActivityLog :is_hidden="true" @hide="showSideBar = false" />
            </div>
        </div>
        <SettingsMenu :show="show"></SettingsMenu>
        <div class="quetion_popup" v-if="showQuetionPopup">
            <div class="quetion_popup__text">
                Tip: You can either delete your old projects or tasks to empty space or <span @click="openSettingPlan">upgrade your plan</span>
            </div>
            <div class="quetion_popup__icon" @click="showQuetionPopup = false">
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.47266 0.296875C3.57266 0.296875 0.472656 3.50472 0.472656 7.54039C0.472656 11.5761 3.57266 14.7839 7.47266 14.7839C11.3727 14.7839 14.4727 11.5761 14.4727 7.54039C14.4727 3.50472 11.3727 0.296875 7.47266 0.296875ZM7.47266 13.7491C4.17266 13.7491 1.47266 10.9552 1.47266 7.54039C1.47266 4.12559 4.17266 1.33166 7.47266 1.33166C10.7727 1.33166 13.4727 4.12559 13.4727 7.54039C13.4727 10.9552 10.7727 13.7491 7.47266 13.7491Z" fill="#929292"/>
                    <path d="M9.81819 10.6775L7.50391 8.28268L5.18962 10.6775L4.50391 9.96791L6.81819 7.57311L4.50391 5.17832L5.18962 4.46875L7.50391 6.86354L9.81819 4.46875L10.5039 5.17832L8.18962 7.57311L10.5039 9.96791L9.81819 10.6775Z" fill="#929292"/>
                </svg>
            </div>
        </div>
        <ChatContextMenu />
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import ProjectsView from "@/components/projects/ProjectsView";
import SearchTasks from "@/components/tasks/SearchTasks";
import ActivityLog from "@/components/history/ActivityLog";
import SettingsMenu from './SettingsMenu.vue'
import { EventEmitter } from '../../main'
import ChatContextMenu from "../chats/ChatContextMenu.vue";

export default {
  name: "LeftSidebar",
  data() {
    return {
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      toggleCollapse: false,
      usedSizeBar: 0,
      showSideBar: false,
      show: false,
      tab: 'Lists',
      changeHistory: false,
      showQuetionPopup: false,
      unread_number_tasks: 0,
      unread_number_chats: 0,
      new_vertion: false,
      add_folder_show: true
    };
  },
  mounted() {

    EventEmitter.$on('SearchInputFocus', (data) => {
        this.add_folder_show = data
    })

    EventEmitter.$on('new_vertion', () => {
        this.new_vertion = true
    })
    
    if (window.location.href.split('/').indexOf('project') > -1) {
        this.tab = 'Lists'
    }

    if (window.location.href.split('/').indexOf('chats') > -1) {
        this.tab = 'Chats'
    }

    if (window.location.href.split('/').indexOf('shortcuts') > -1) {
        this.tab = 'Lists'
    }
    
    this.showProgressSize();

    document.querySelector('body').onclick = () => {
        if (this.show) {
            this.show = false
        }
    }

    EventEmitter.$on('closeMenuSettings', (e) => {
        this.show = false
    })
    EventEmitter.$on('changeSidebarTabs', (e) => {
        this.tab = e
    })

    let unread_number = 0
    Object.values(this.get_unreaded_messages_in_project).forEach(count => {
        unread_number += count
    })
    this.unread_number_tasks = unread_number
    window.unread_msg_tasks = unread_number

    let unread_number2 = 0
    this.getChats.forEach((chat, i) => {
        chat.users.forEach((user, user_i) => {
            if (user.user_id == this.getUserData._id) {
                unread_number2 = parseInt(user.unread_number) + parseInt(unread_number2)
            }
        })
    })
    this.unread_number_chats = unread_number2
    window.unread_msg_chats = unread_number2

    this.changeHistory = this.getUserData.activity_log_new
    this.handleUnreadNumberInTab(this.unread_number_tasks, this.unread_number_chats)

  },
  methods: {
    ...mapActions([
        "add_folder_render_lists",
    ]),
    ...mapMutations([
        "activity_log_new",
        "setChangeUserData",
        "setMessage"
    ]),
    openSettingPlan() {
        this.showQuetionPopup = false
        EventEmitter.$emit('openSettingPlan', true)
    },
    openMenuSettings(e) {
        e.stopPropagation()
        this.show = !this.show
    },
    changeTab(e) {
        EventEmitter.$emit('changeTab', e)
        this.tab = e
    },
    toggle_collapse(value) {
      this.toggleCollapse = value;
      this.$emit("toggle_collapse");
    },
    showAddProjectWindow() {
        this.$root.$emit("openEditLists", this.tab);
    },
    showProgressSize() {
        // setTimeout(() => {
            // console.log(this.getUserAllFileSize, this.getUserUsedFileSize)
            let size = this.getUserUsedFileSize / (this.getUserAllFileSize / 100);
            this.usedSizeBar = size < 1 && size > 0
                ? 1
                : size < 1
                ? 0
                : Math.ceil(
                    this.getUserUsedFileSize / (this.getUserAllFileSize / 100)
                );
        // }, 2000);
    },
    getTitle(title = document.title) {
        title = title.split(' ')
        let last = title[title.length-1]
        last = last.replaceAll('(', '')
        last = last.replaceAll(')', '')

        if (!isNaN(last)) {
            title.pop()
        }
        return title.join(' ')
    },
    handleUnreadNumberInTab(firstVal, secondVal) {
        let count = secondVal + firstVal
        const title = this.getTitle()
        count = count > 0 ? `(${count})` : ''
        document.title = title + ' ' + count
        window.unread_msg_tasks = firstVal
        window.unread_msg_chats = secondVal
    },
    addFolder() {
        let w_bool = false

        this.$w_plan(this, this.getPlan, 'folder', () => {
            this.setMessage({
                key_message: "warning",
                message: "You can't add more folders. ",
                plan: true
            })
            w_bool = true
        })

        if (w_bool) {
            return
        }

        this.setChangeUserData({
            type: 'add_folder',
            field: {
                _id: this.$w_decrypt(this.getUserData._id),
                name: '',
                lists: [],
                order: this.getLists['folder'].length > 0 ? this.getLists['folder'].length : 0
            }
        })
        setTimeout(() => {
            this.add_folder_render_lists()
        }, 100);
        setTimeout(() => {
            document.querySelector('.folder__flex input').focus()
        }, 500);
    }
  },
  computed: {
    ...mapGetters([
        "getUserData",
        "getUserUsedFileSize",
        "getUserAllFileSize",
        "getShowSmalActivityLog",
        "getChats",
        "getTasks",
        "getProjects",
        "getChangeHistory",
        "get_unreaded_messages_in_project",
        "get_flag_for_unreaded",
        "getRender",
        "getPlan",
        "getLists"
    ])
  },
  watch: {
    get_flag_for_unreaded() {
        let unread_number = 0
        Object.values(this.get_unreaded_messages_in_project).forEach(count => {
            unread_number += count
        })
        this.unread_number_tasks = unread_number
        this.handleUnreadNumberInTab(this.unread_number_tasks, this.unread_number_chats)
    },
    get_unreaded_messages_in_project(newVal) {
        this.unread_number_tasks = 0
        let unread_number = 0
        Object.values(newVal).forEach(count => {
            unread_number += count
        })
        this.unread_number_tasks = unread_number
    },
    getChangeHistory() {
        this.changeHistory = this.getUserData.activity_log_new
    },
    getUserUsedFileSize() {
        this.showProgressSize()
    },
    getShowSmalActivityLog(e) {
        console.log(e)
    },
    showSideBar(e) {
        if (e) {
            this.changeHistory = false
            this.activity_log_new(false)
            this.$socket.emit('IN_HistoryRead', false)
        }
    },
    unread_number_tasks(newVal) {
        this.handleUnreadNumberInTab(newVal, this.unread_number_chats)
    },
    unread_number_chats(newVal) {
        this.handleUnreadNumberInTab(newVal, this.unread_number_tasks)
    },
    $route() {
        this.handleUnreadNumberInTab(this.unread_number_tasks, this.unread_number_chats)
    },
    getRender() {
        let unread_number2 = 0
        this.getChats.forEach((chat, i) => {
            chat.users.forEach((user, user_i) => {
                if (user.user_id == this.getUserData._id) {
                    unread_number2 = parseInt(user.unread_number) + parseInt(unread_number2)
                }
            })
        })
        this.unread_number_chats = unread_number2
        this.handleUnreadNumberInTab(this.unread_number_tasks, this.unread_number_chats)
        // window.unread_msg_chats = unread_number2
    }
  },
  components: {
    ProjectsView,
    SearchTasks,
    ActivityLog,
    SettingsMenu,
    ChatContextMenu
},
};
</script>
