<template>
    <div class="bg-listContextMenu" @click.prevent="closeContextMenu">
        <div class="listContextMenu" :style="style" v-if="show">
            <!-- <div class="listContextMenu__text" @click="contextMenu('add_folder')" v-if="bool == true"><span>Add folder</span></div> -->
            <div class="listContextMenu__text" @click="contextMenu('mark_as_read')" v-if="mark_read"><span>Mark as read</span></div>
            <div class="listContextMenu__text" @click="contextMenu('rename')" v-if="data"><span>Rename</span></div>
            <!-- <div class="listContextMenu__text" @click="contextMenu('import')" v-if="data">Import</div> -->
            <div class="listContextMenu__text red" @click="contextMenu('delete')" v-if="!author"><span>Delete</span></div>
            <div class="listContextMenu__text red" @click="contextMenu('leave')" v-if="author"><span>Leave</span></div>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions, mapMutations } from "vuex";

    import {EventEmitter} from "../../main"

    export default {
        data() {
            return {
                style: '',
                data: '',
                bool: '',
                author: false,
                show: false,
                mark_read: false
            }
        },
        computed: {
            ...mapGetters([
                "getProjects",
                "getChats",
                "getUserData",
                "getLists",
                "getFolderLists",
                "getPlan",
                "get_unreaded_messages_in_project",
            ]),
        },
        mounted() {

            EventEmitter.$on('listContextMenu_open', (position_menu) => {
                this.style = `top: ${position_menu.y}px; left: ${position_menu.x}px`
                this.bool = position_menu.bool
                this.data = position_menu.data
                this.show = position_menu.show
                if (this.data.date) {
                    this.mark_read = this.get_unreaded_messages_in_project[this.data._id] > 0 || true
                }
                if (position_menu.data.author) {
                    this.author = this.getUserData._id == position_menu.data.author ? false : true
                }
                setTimeout(() => {
                    if (position_menu.bool == true) {
                        let list = document.querySelectorAll('.listContextMenu__text')
                        if (list.length == 2) {
                            list.forEach((e, i) => {
                                if (e.textContent == 'Delete') {
                                    e.remove()
                                }
                            })
                        }
                    }
                }, 1);
            })

        },
        methods: {
            ...mapActions([
                "add_folder_render_lists",
                "socket_delete_project",
                "remove_folder"
            ]),
            ...mapMutations([
                "setChangeUserData",
                "setMessage"
            ]),
            closeContextMenu() {
                EventEmitter.$emit('listContextMenu_close', true)
            },
            contextMenu(action) {
                if (action == 'add_folder') {

                    let w_bool = false

                    this.$w_plan(this, this.getPlan, 'folder', () => {
                        this.setMessage({
                            key_message: "warning",
                            message: "You can't add more folders. ",
                            plan: true
                        })
                        w_bool = true
                    })

                    if (w_bool) {
                        return
                    }

                    this.setChangeUserData({
                        type: 'add_folder',
                        field: {
                            _id: this.$w_decrypt(this.getUserData._id),
                            name: '',
                            lists: [],
                            order: this.getLists['folder'].length > 0 ? this.getLists['folder'].length : 0
                        }
                    })
                    setTimeout(() => {
                        this.add_folder_render_lists()
                    }, 100);
                    setTimeout(() => {
                        document.querySelector('.folder__flex input').focus()
                    }, 500);
                } else if (action == 'delete') {
                    if (!this.data.folder) {
                        // EventEmitter.$emit('DeleteLists', this.data, this.bool)
                        let data = {
                            type: 'list',
                            list: this.data,
                            bool: this.bool,
                            data: {
                                text: 'Do you want to delete this list?',
                                cancel: 'Cancel',
                                delete: 'Delete'
                            }
                        }
                        EventEmitter.$emit('OpenPopup', data)
                    } else {
                        let data = {
                            type: 'folder',
                            folder: this.data,
                            data: {
                                text: 'Do you want to delete this folder?',
                                cancel: 'Cancel',
                                delete: 'Delete'
                            }
                        }
                        EventEmitter.$emit('OpenPopup', data)
                        // this.remove_folder(this.data)
                    }
                } else if (action == 'leave') {
                    let data = {
                        type: 'leave',
                        list: this.data,
                        data: {
                            text: 'Do you want to leave this list?',
                            cancel: 'Cancel',
                            delete: 'Leave'
                        }
                    }
                    EventEmitter.$emit('OpenPopup', data)
                } else if (action == 'rename') {
                    if (!this.data.folder) {
                        EventEmitter.$emit('renameList', this.data)
                    } else {
                        EventEmitter.$emit('renameFolder', this.data)
                    }
                } else if (action == 'mark_as_read') {
                    this.$socket.emit('IN_ListMakeIsRead', {list_id: this.data._id})
                }
            }
        }
    }

</script>