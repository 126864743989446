export default function (str = "") {
  var id = new Date().getTime().toString().substr(4) + "_" + str;
  var guidBytes = guidToBytes(id);
  var new_id = arrayBufferToBase64(guidBytes)
  return new_id.replace('+', '')
}

function guidToBytes(guid) {
  var bytes = [];
  guid.split("_").map((number, index) => {
    var bytesInChar =
      index < 3 ? number.match(/.{1,2}/g).reverse() : number.match(/.{1,2}/g);
    bytesInChar.map((byte) => {
      bytes.push(parseInt(byte, 16));
    });
  });
  return bytes;
}

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  let id = btoa(binary)
  let res = id.replaceAll('/', '')
  return res
}
