<template>
    <div>
        
    </div>
</template>

<script>

    import { mapMutations, mapActions, mapGetters } from "vuex"
    import router from "@/router"

    export default {
        methods: {
            ...mapActions(["getToken"])
        },
        async mounted() {

            if (this.$route.params) {
                if (this.$route.params.code) {
                    
                    let data = {
                        url: this.$w_api.GET_TOKEN,
                        body: {
                            code: this.$route.params.code
                        }
                    }

                    let res = await this.getToken(data)

                    localStorage.setItem("w_clone_token", res.email)
                    localStorage.setItem("w_token", res.token)

                    if (localStorage.getItem('app_web_auth'))
                    {
                        // redirect to the open app page for app users
                        localStorage.removeItem('app_web_auth')
                        router.push({
                            path: '/app_auth',
                            query: {
                                useremail: res.user_email
                            }
                        })
                    }
                    else 
                    {
                        // redirect to inbox for web users
                        setTimeout(() => {
                            window.location.href = '/'
                        }, 100)
                    }


                }
            }

        }
    }

</script>