import { LocalNotifications } from "@capacitor/local-notifications";

export default function foregroundLocalNotification(params) {
  const { title = "", body = "", url = "" } = params;

  LocalNotifications.schedule({
    notifications: [
      {
        id: 1,
        title,
        body,
        schedule: { at: new Date(Date.now() + 400 * 1) },
        extra: { url },
        sound:'./public/sounds/notification.mp3'
      },
    ],
  });
}
