export default function (type, tasks, change_complete, evt = {}) {
  let oldTasks = [],
    newOrderedTask = [];
  if (tasks) {
    oldTasks = tasks.map((e, i) => (i = { ...e }));
  }
  /* 
  
  
  */
  if (type == "add") {
    /* 
    
    
    */
    oldTasks.forEach((task, taskIdx, taskArr) => {
      if (taskIdx == 0 && task.order > 0) {
        return;
      } else if (taskIdx == 0 && task.order == 0) {
        task.order += 1;
        newOrderedTask.push({
          order: task.order,
          task_id: task._id,
        });
      } else if (
        taskArr[taskIdx + 1] &&
        taskArr[taskIdx + 1].order - task.order >= 1
      ) {
        task.order =
          taskArr[taskIdx + 1].order == 0
            ? taskArr[taskIdx - 1].order + 1
            : taskArr[taskIdx + 1].order;
        newOrderedTask.push({
          order: task.order,
          task_id: task._id,
        });
      } else if (
        taskArr[taskIdx - 1] &&
        task.order - taskArr[taskIdx - 1].order == 0
      ) {
        task.order = taskArr[taskIdx - 1].order + 1;
        newOrderedTask.push({
          order: taskArr[taskIdx - 1].order + 1,
          task_id: task._id,
        });
      }
    });
    /* 
    
    
    
    */
  } else if (type == "draggable") {
    let newOrder, oldOrder;
    if (evt.oldIndex > evt.newIndex) {
        oldTasks.forEach((task, taskIdx, taskArr) => {
            if (taskIdx == evt.newIndex) {
                oldOrder = task.order;
                task.order = taskArr[taskIdx + 1].order;
            }
            if (taskIdx > evt.newIndex && taskIdx < evt.oldIndex) {
                task.order = taskArr[taskIdx + 1].order;
            }
            if (taskIdx == evt.oldIndex) {
                task.order = oldOrder;
            }
        });
    } else {
        oldTasks.forEach((task, taskIdx, taskArr) => {
            if (taskIdx == evt.oldIndex) {
                newOrder = task.order;
                task.order = taskArr[evt.newIndex].order;
            }
            if (taskIdx > evt.oldIndex && taskIdx <= evt.newIndex) {
                oldOrder = task.order;
                task.order = task.order > newOrder ? newOrder : task.order + 1;
                newOrder = oldOrder;
            }
        });
    }
    newOrderedTask = oldTasks.map((e) => ({
        task_id: e._id,
        order: e.order,
    }));
  } else if (type == "done") {
    /* 
    
    
    
    
    */
    let newOrder, oldOrder;
    const completed_task = oldTasks.find(
      (task) => task._id == change_complete.task_id
    );
    if (change_complete.complete) {
      oldTasks.forEach((task) => {
        if (task.local_id == completed_task.local_id) {
          newOrder = completed_task.order;
        }
        if (task.order > completed_task.order) {
          oldOrder = task.order;
          task.order = newOrder;
          newOrder = oldOrder;
          newOrderedTask.push({
            task_id: task._id,
            order: task.order,
          });
        }
      });
    } else {
      let max_order_arr = oldTasks.map((task) => task.order);

      newOrderedTask.push({
        task_id: change_complete.task_id,
        order: Math.max(...max_order_arr) + 1,
      });
    }
    /* 
    
    
    
    */
  } else if (type == "delete") {
    /* 
    
    
    
    
    */
    let newOrder, oldOrder;
    const deleted_task = oldTasks.find(
      (task) => task._id == change_complete.task_id
    );
    oldTasks.forEach((task) => {
      if (task._id == deleted_task._id) {
        newOrder = deleted_task.order;
      }
      if (task.order > deleted_task.order) {
        oldOrder = task.order;
        task.order = newOrder;
        newOrder = oldOrder;
        newOrderedTask.push({
          task_id: task._id,
          order: task.order,
        });
      }
    });

    /* 
    
    
    
    */
  }

  return newOrderedTask;
}
