import File from './aws'

export default async function (vue, axios, url, name, id, a) {
    const cancelTokenSource = axios.CancelToken.source()

    if (a) {
        const file = new File()
        url = await file.read(a)
    }

    // let reject = false;
    axios.get(url, {
        headers: {
            "Content-Type": "application/octet-stream"
        },
        cancelToken: cancelTokenSource.token,
        responseType: "blob",
        onDownloadProgress: (downloadEvent) => {

            const precent = Math.round(
                (downloadEvent.loaded / downloadEvent.total) * 100
            )

            if (localStorage.getItem(`stop_${id}`)) {
                localStorage.removeItem(`stop_${id}`)
                cancelTokenSource.cancel()
            }

            const precent_loader = {}
            precent_loader[id] = {
                precent: precent,
                loaded: downloadEvent.loaded
            }

            vue.$root.$emit("file_onload", precent_loader)
            // console.log("onDownloadProgress", precent)

        }
    })
    .then((response) => {
        
        const a = document.createElement("a")
        const url = window.URL.createObjectURL(response.data)
        a.href = url
        a.download = name
        a.id = 'download_' + id
        a.click()

        const precent_loader = {}
        precent_loader[id] = {
            precent: 101
        }

        vue.$root.$emit("file_onload", precent_loader)

    })
    .catch((err) => {

        const precent_loader = {}
        precent_loader[id] = 0

        vue.$root.$emit("file_onload", precent_loader)

        const error_req = {}
        error_req[id] = true

        vue.$root.$emit("error_req_attachment", error_req)

        setTimeout(() => {
            error_req[id] = false
            vue.$root.$emit("error_req_attachment", error_req)
        }, 2000)

    })
}
