<template>
    <div class="search">
        <span v-if="!activeFlag && searchText == ''" class="search_img">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.7088 17.7083L14.6458 14.4683C15.8641 13.2065 16.5787 11.5427 16.6551 9.79041C16.7314 8.03813 16.1641 6.31845 15.0602 4.95549C13.9563 3.59254 12.3919 2.68046 10.662 2.39116C8.93208 2.10185 7.15608 2.45532 5.66876 3.38492C4.18143 4.31453 3.0855 5.75607 2.58754 7.43784C2.08957 9.1196 2.22396 10.9254 2.96538 12.515C3.70681 14.1045 5.00404 15.3679 6.61259 16.0671C8.22114 16.7663 10.0299 16.853 11.6979 16.3108" stroke="#c0c4cc" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </span>
        <el-input
            :class="activeFlag || searchText != '' ? 'active' : ''"
            :placeholder="activeFlag ? 'Search tasks' : 'Search'"
            v-model="searchText"
            @focus="focus"
            @blur="inputBlur"
        >
        </el-input>
        <div @click="clearSearch" class="search__clear" v-if="searchText">
            <i class="el-icon-close"></i>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from "vuex"
    import {EventEmitter} from '../../main'

    export default {
        name: "SearchTasks",
        data() {
            return {
                searchText: "",
                activeFlag: false,
            }
        },
        mounted() {
            EventEmitter.$on('SearchInputClear', (data) => {
                if (data) {
                    this.searchText = ''
                    this.activeFlag = false
                }
            })
        },
        methods: {
            clearSearch(e) {
                this.searchText = ''
                this.activeFlag = false
                this.$router.push('/no-lists').catch(() => true)
                EventEmitter.$emit('SearchInputFocus', true)
            },
            inputBlur() {
                this.activeFlag = false
                if (this.searchText == '') {
                    this.$router.push('/no-lists').catch(() => true)
                    EventEmitter.$emit('SearchInputFocus', true)
                }
            },
            focus() {
                this.activeFlag = true
                EventEmitter.$emit('SearchInputFocus', false)
            }
        },
        watch: {
            searchText(value) {
                EventEmitter.$emit('SearchTasksProject', value)
                if (value) {
                    this.$router.push('/search').catch(() => true)
                }
            }
        },
        computed: {
            ...mapGetters(["getTasks", "getProjects"])
        }
    }

</script>
