import Vue from "vue";
import Vuex from "vuex";
import key from "./modules/token/key";
import register from "./modules/token/register";
import projects from "./modules/projects";
import tasks from "./modules/tasks";
import comments from "./modules/comments";
import files from "./modules/files";
import history from "./modules/history";
import chats from "./modules/chats";
import { EventEmitter } from "../main";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    message: {
      key_message: "",
      message: "",
    },
    reconnection_count: 0,
    app_loader: true,
    applicationStatus: "updated",
    isAppInForeground: false,
  },
  mutations: {
    setMessage(s, v) {
      s.message = v;
    },
    clearMessage(s) {
      s.message = "";
    },
    changeAppInForeground(s, v) {
      s.isAppInForeground = v;
    },
    changeReconnectionCount(s, v) {
      s.reconnection_count = v;
    },
    offAppLoader(s, v) {
      setTimeout(() => {
        s.app_loader = false;
      }, 500);
    },
    authLoaderCheck(s) {
      s.app_loader = false;
    },
    onAppLoader(s, v) {
      s.app_loader = true;
    },
    setApplicationStatus(s, v) {
      s.applicationStatus = v;
    },
  },
  actions: {
    async fetch_request(
      { dispatch, getters, commit },
      { token, method, url, body }
    ) {
      // setup AbortController
      const controller = new AbortController();
      // signal to pass to fetch
      const signal = controller.signal;
      let headers = {};
      if (token) {
        headers = {
          Code: "web777",
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("w_token"),
        };
      } else {
        headers = {
          "Content-Type": "application/json",
          Code: "web777",
        };
      }

      if (url.includes("/api/users/data")) {
        EventEmitter.$emit("fetchRequestController", controller);
      }

      body = body ? JSON.stringify(body) : null;
      const res = await fetch(url, {
        method,
        headers,
        body,
        signal,
      });
      if (res.ok) {
        if (!res.url.includes("/autorisation")) {
          commit("changeReconnectionCount", 0);
        }
        return res;
      } else {
        if (token) {
          if (getters.getReconnectionCount == 2) {
            commit("changeReconnectionCount", 0);
            return res;
          }
          commit("changeReconnectionCount", getters.getReconnectionCount + 1);
          let has_new_token = await dispatch("check_token");
          if (has_new_token) {
            const double_res = await dispatch("fetch_request", {
              token,
              method,
              url,
              body,
            });
            return double_res;
          }
        } else {
          commit("changeReconnectionCount", 0);
          return res;
        }
      }
    },
    SOCKET_OUT_Message({ commit }, data) {
      // console.log("SOCKET_OUT_Message in store: ", data);
      const er = {
        key_message: "warning",
        message: data.Message ? data.Message : data,
        plan: data.plan ? true : false,
      };
      if (data.key == "ProjectAll") {
        commit("setProjects", []);
      }
      if (data.key == "TaskAll") {
        commit("setTasks", []);
      }
      if (data.key == "TaskAdd") {
        er.key_message = "success";
      }
      if (data.key == "ProjectAdd") {
        er.key_message = "success";
      }
      commit("setMessage", er);
    },
  },
  getters: {
    getMessage: (s) => s.message,
    getReconnectionCount: (s) => s.reconnection_count,
    getAppLoader: (s) => s.app_loader,
    getApplicationStatus: (s) => s.applicationStatus,
    getIsAppInForeground: (s) => s.isAppInForeground,
  },
  modules: {
    key,
    register,
    projects,
    tasks,
    comments,
    files,
    history,
    chats,
  },
});
