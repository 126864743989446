export default [
  "#b86a6f",
  "#f2a7a7",
  "#e3b5b5",
  "#fc8785",
  "#e8cca2",
  "#b86a6f",
  "#f2a7a7",
  "#e3b5b5",
  "#fc8785",
  "#e8cca2",
  "#b86a6f",
  "#f2a7a7",
  "#e3b5b5",
  "#fc8785",
  "#e8cca2"
];
