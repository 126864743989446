import axios from 'axios'

export default class File {
  constructor (file) {
    this.file = file
  }
  write(key) {

    const [type, id] = key.split('/')
    const file_type = this.file.name ? this.file.type : 'image/png'

    const request_data = JSON.stringify({
      key: key,
      list_id: type === 'Lists' ? id : undefined,
      chat_id: type === 'Chats' ? id : undefined,
      file_type
    })

    return ajax(`${process.env.VUE_APP_API_BASE_URL2}/api/file/upload-url`, request_data)
      .then(async ({data, status}) => {
        if (status !== 200) return
  
        let body
        if (this.file.name) {
          body = this.file
        } else {
          body = Buffer.from(this.file.replace(/^data:image\/\w+;base64,/, ''), 'base64')
        }
      
        const upload = await uploadFileWithPresignedUrl(data.url, body, this.file.name ? undefined : 'base64')
        await sleep(10)
        return await upload
      })

  }
  async read(key) {
    if (!key) return;
    
    const [type, id, image] = key.split('/');
    
    const { data, status } = await axios.post(`${process.env.VUE_APP_API_BASE_URL2}/api/file/download-url`, {
      key,
      list_id: type === 'Lists' ? id : undefined,
      chat_id: type === 'Chats' ? id : undefined
    }, {
      headers: {
        Authorization: localStorage.getItem('w_token'),
        Code: 'web777'
      }
    });
    
    if (status === 200) {
      return data.url;
    }
  }
  remove() {}
  removeFolder() {}
}

async function ajax(url, body) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Authorization': localStorage.getItem('w_token'),
      'Code': 'web777',
      'Content-Type': 'application/json'
    },
    body: body
  }
  
  const response = await fetch(url, requestOptions)
  
  const data = await response.json()
  const status = response.status
  return {
    data,
    status
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function uploadFileWithPresignedUrl(PRESIGNED_URL, FILE, encoding = false) {

    var xhr = new XMLHttpRequest()
    var progressCallback = null
    var completeCallback = null

    xhr.open('PUT', PRESIGNED_URL, true)
  
    // Устанавливаем заголовок Content-Type для файла
    if (encoding) {
        xhr.setRequestHeader('Content-Encoding', encoding)
        xhr.setRequestHeader('Content-Type', 'image/png')
    } else {
        xhr.setRequestHeader('Content-Type', FILE.type)
    }
  
    // Устанавливаем обработчик события загрузки
    xhr.onload = function() {
      if (xhr.status === 200) {
        if (completeCallback) {
          completeCallback()
        }
      } else {
        completeCallback('error')
      }
    }
  
    // Устанавливаем обработчик прогресса загрузки
    xhr.upload.onprogress = function(event) {
      if (progressCallback) {
        progressCallback(event)
      }
    }
  
    // Функция для прерывания загрузки файла
    function abort() {
      xhr.abort()
    }
  
    // Функция для установки обработчика прогресса загрузки
    function onUploadProgress(type, callback) {
      progressCallback = callback
    }
  
    // Функция для установки обработчика завершения загрузки
    function onUploadComplete(callback) {
      completeCallback = callback
    }
  
    // Отправляем файл на сервер через Presigned URL
    xhr.send(FILE)
  
    // Возвращаем объект с двумя функциями и функцию для прерывания загрузки
    return {
      on: onUploadProgress,
      send: onUploadComplete,
      abort: abort
    }
}
