export default function (file_type) {
  switch (true) {
    case !!file_type.match(/\.sheet|\.xlsx|xlsx|xls|she/):
      return "file-excel";
    case !!file_type.match(/\.document|\.docx|docx|doc/):
      return "file-word";
    case !!file_type.match(/\.pdf|pdf/):
      return "file-pdf";
    case !!file_type.match(/\.pptx|\.presentation|pptx|ppt/):
      return "file-powerpoint";
    default:
      return "file";
  }
}
