import foregroundLocalNotification from "./foreground_local_notification";

export default function pushNotifications({
  data,
  getters,
  message,
  type = "assign",
  force = true,
}) {
  if (!force) return;
  if (
    data.user_id !== getters.getUserData._id &&
    !data.hasOwnProperty("priority")
  )
    return;
  if (data.hasOwnProperty("priority") && data.priority !== "High") return;
  const tasks = getters.getTasks;
  let task;
  const projects = Object.values(tasks);
  projects.some((project) => {
    task = project.find((task) => task._id == data.task_id);
    if (task) return true;
  });

  let new_message = message;

  new_message = new_message.replace(
    "<Username>",
    type == "assign" ? data.author : JSON.parse(data.author).name
  );
  new_message = new_message.replace("<Taskname>", task.name);

  if (type == "assign" && getters.getUserData.name == data.author) {
    console.log("self assign");
  } else {
    foregroundLocalNotification({
      title: new_message,
      url: "/project/" + data.project_id + "/task/" + data.task_id,
    });
  }
}
