async function resizeImageToSpecificWidth(max, file, cb) {
    if (max == 'full') {
        if (file) {
            await cb(file)
        }
    } else {
        var data
        if (file) {
            var reader = new FileReader()
            reader.onload = function(event) {
                var img = new Image()
                img.onload = async function() {
                    if (img.width > max) {
                        var oc = document.createElement('canvas'), octx = oc.getContext('2d')
                        oc.width = img.width
                        oc.height = img.height
                        octx.drawImage(img, 0, 0)
                        if( img.width > img.height) {
                            oc.height = (img.height / img.width) * max
                            oc.width = max
                        } else {
                            oc.width = (img.width / img.height) * max
                            oc.height = max
                        }
                        octx.drawImage(oc, 0, 0, oc.width, oc.height)
                        octx.drawImage(img, 0, 0, oc.width, oc.height)
                        data = oc.toDataURL()
                    } else {
                        data = file
                    }
                    await cb(data)
                }
                img.src = event.target.result
            }
            reader.readAsDataURL(file)
        }
    }
}

module.exports = resizeImageToSpecificWidth