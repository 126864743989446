let names = ['MM', 'WW', 'MW', 'WM']

export default function (str = "") {
  let newStr = "";
  str.trim().split(" ").forEach((e, i) => {
    if (i < 2) {
      newStr += e[0];
    }
  });
  if (names.some(e => e == newStr.toUpperCase().replaceAll('-', ''))) {
    return str[0].replaceAll('-', '')
  } else {
      return newStr.toUpperCase().replaceAll('-', '')
  }
}
