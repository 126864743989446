<template>
  <div class="light_box" v-if="show">
    <div class="light_box__close_button" @click="show = false">
      <i class="el-icon-close"></i>
    </div>
    <!-- :initial-index="activeItem" -->
    <el-carousel
      v-if="files.length > 0"
      :autoplay="false"
      arrow="always"
      :initial-index="activeItem"
      :loop="true"
      @change="changeSlider"
      ref="carousel"
    >
      <el-carousel-item v-for="(file, index) in files" :key="index">
        <div class="light_box__bg" @click="show = false"></div>
        <img :src="file.file_url" :alt="file.filename" :data="activeItem" />
        <div
          v-if="
            precent_loader[file._id] &&
            typeof precent_loader[file._id] == 'number'
          "
          class="light_box__file_loading"
        >
          <el-progress
            type="circle"
            :percentage="+precent_loader[file._id].toFixed()"
            color="#447692"
          >
            {{ +precent_loader[file._id].toFixed() }}%
          </el-progress>
        </div>
      </el-carousel-item>
    </el-carousel>

    <div
      class="light_box__download_button"
      @click="
        download_file(activeFile, activeFile.filename, activeFile._id)
      "
    >
      <span>Download</span>
      <span>
        <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 9L0.669872 0L9.33013 0L5 9Z" fill="#C4C4C4"/>
        </svg>
      </span>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import File from '../../utils/aws'

export default {
  name: "LightBox",
  data() {
    return {
      files: [],
      activeFile: null,
      activeItem: null,
      show: false,
      precent_loader: {},
      url: '',
      type: '',
      download_index: 0
    }
  },
  mounted() {

    document.onkeyup = (e) => {
      if (e.key == 'Escape') {
        this.show = false
      }
      if (e.key == 'ArrowRight') {
        this.setActiveItem(this.activeItem + 1)
      }
      if (e.key == 'ArrowLeft') {
        this.setActiveItem(this.activeItem - 1)
      }
      if (e.key == 'ArrowDown') {
        document.querySelector('.light_box__download_button').click()
      }
    }

    this.$root.$on("showLightBox", (fileId, comment_id, chat_id, type) => {

      this.type = type
      let chats
        
      if (chat_id) {
        this.getChats.forEach((e) => {
          if (e._id == chat_id) {
            chats = e.messages
          }
        })
      } else {
        chats = this.getComments[this.$route.params.task_id]
      }

      chats.forEach((chat, i) => {
        if (chat.files) {
          chat.files.forEach(async (file, index) => {
            if (file.ext.toLowerCase() == 'png' || file.ext.toLowerCase() == 'jpg' || file.ext.toLowerCase() == 'jpeg' || file.ext.toLowerCase() == 'web') {
              file.file_url = await this.pathUrl(file)
              this.files.push(file)
            }
          })
        } else {
          chat.files_body.forEach(async (file, index) => {
            if (file.ext.toLowerCase() == 'png' || file.ext.toLowerCase() == 'jpg' || file.ext.toLowerCase() == 'jpeg' || file.ext.toLowerCase() == 'web') {
              file.file_url = await this.pathUrl(file)
              this.files.push(file)
            }
          })
        }
      })

      let item = this.files.findIndex((file) => file._id == fileId)

      if (item >= 0) {
        this.activeItem = item
        this.activeFile = this.files[this.activeItem]
        this.show = true
      }

    })
    this.$root.$on("file_onload", (data) => {
      if (this.activeFile) {
        if (typeof data[this.activeFile._id] == "number" && data[this.activeFile._id] <= 100) {
          this.precent_loader = data
        } else {
          this.precent_loader[this.activeFile._id] = null
        }
      }
    })

    // window.addEventListener("keyup", this.$w_throttle(this.downloadFileWithKeyUp, 1000))
  
  },
  methods: {
    setActiveItem(index) {
      if (this.$refs.carousel) {
        this.$refs.carousel.setActiveItem(index)
      }
    },
    async pathUrl(file) {
      if (this.type == 'chat') {
        if (file.ext.toLowerCase() == 'png' || file.ext.toLowerCase() == 'jpg' || file.ext.toLowerCase() == 'jpeg') {
          const files = new File()
          if (file.imgWidth > 200 && file.size > 10000) {
            return await files.read('Chats' + '/' + this.$route.params.chat_id + '/' + 'full' + '__' + file._id + '__' + file.filename)
          } else {
            return await files.read('Chats' + '/' + this.$route.params.chat_id + '/' + file._id + '__' + file.filename)
          }
        } else {
          const files = new File()
          return await files.read('Chats' + '/' + this.$route.params.chat_id + '/' + file._id + '__' + file.filename)
        }
      } else {
        if (file.ext.toLowerCase() == 'png' || file.ext.toLowerCase() == 'jpg' || file.ext.toLowerCase() == 'jpeg') {
          const files = new File()
          if (file.imgWidth > 200 && file.size > 10000) {
            return await files.read('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + 'full' + '__' + file._id + '__' + file.filename)
          } else {
            return await files.read('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + file._id + '__' + file.filename)
          }
        } else {
          const files = new File()
          return await files.read('Lists' + '/' + this.$route.params.project_id + '/' + this.$route.params.task_id + '/' + file._id + '__' + file.filename)
        }
      }
    },
    async download_file(file, name, id) {
      let url = await this.pathUrl(file)
      this.$w_downloadFile(this, this.axios, url, name, id)
    },
    changeSlider(newIndex) {
      this.activeItem = newIndex
      this.activeFile = this.files[newIndex]
    },
    downloadFileWithKeyUp(e) {
      if (e.key == "ArrowDown") {
        if (typeof this.precent_loader[this.activeFile._id] != "number") {
          this.download_file(
            this.activeFile,
            this.activeFile.filename,
            this.activeFile._id
          )
        }
      }
    }
  },
  computed: {
    ...mapGetters(["get_files", "getComments", "getChats"])
  }
}
</script>
