import File from './aws'

function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function() {
        var reader = new FileReader()
        reader.onloadend = function() {
            callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
}

export default function (user,  f_size = 70, bool = true) {
    if (!localStorage.getItem(user._id)) {
        if (user) {
            const aws = new File()
            const url = aws.read('Users/' + user._id + '/' + f_size + '/' + user.image)
            toDataUrl(url, function(myBase64) {
                localStorage.setItem(user._id, myBase64)
            })
            return url
        }
    } else {
        return localStorage.getItem(user._id)
    }
}