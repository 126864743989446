export default [
    "#b54a4a",
    "#b5684a",
    "#e86431",
    "#d9974c",
    "#c4a04d",
    "#4dbac4",
    "#5e97bd",
    "#647ab3",
    "#7d6fb0",
    "#996fb0",
    "#b06fab",
    "#b06f8b",
    "#b06f78",
    "#855151"
]
  