export default function () {
  let d, v
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
    d = {
      status: true,
      version: parseInt(v[1], 10) , 
      info: parseInt(v[1], 10)+'.'+parseInt(v[2], 10)+'.'+parseInt(v[3] || 0, 10)
    }
  } else {
    d = {status:false, version: false, info:''}
  }
  return d
}