<template>
  <div @touchstart="startDownPress" @touchend="upPress" @touchmove="onTouchMove" 
    class="link" 
    :class="$route.params.chat_id == chat._id ? 'router-link-active' : ''"
  >
    <div class="item-left">
      <div class="item-left-block">
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path 
            d="M15.0313 0.710938H1.96877C1.69319 0.710938 1.4289 0.82041 1.23404 1.01527C1.03918 1.21013 0.929703 1.47442 0.929703 1.75V13.625C0.928629 13.8231 0.984698 14.0173 1.09119 14.1844C1.19769 14.3514 1.35009 14.4842 1.53013 14.5668C1.66759 14.6306 1.81724 14.6638 1.96877 14.6641C2.21355 14.6642 2.45037 14.5771 2.63673 14.4184L2.64341 14.4125L5.0288 12.3217C5.05552 12.3003 5.08882 12.2887 5.12306 12.2891H15.0313C15.3068 12.2891 15.5711 12.1796 15.766 11.9847C15.9609 11.7899 16.0703 11.5256 16.0703 11.25V1.75C16.0703 1.47442 15.9609 1.21013 15.766 1.01527C15.5711 0.82041 15.3068 0.710938 15.0313 0.710938ZM15.1797 11.25C15.1797 11.2894 15.1641 11.3271 15.1362 11.355C15.1084 11.3828 15.0706 11.3984 15.0313 11.3984H5.12306C4.87861 11.3987 4.6421 11.4852 4.45509 11.6426L4.44841 11.6486L2.06154 13.7393C2.03981 13.7567 2.01363 13.7676 1.98599 13.7709C1.95834 13.7741 1.93035 13.7695 1.90519 13.7576C1.88004 13.7457 1.85875 13.7269 1.84374 13.7035C1.82874 13.68 1.82062 13.6528 1.82033 13.625V1.75C1.82033 1.71063 1.83597 1.67288 1.8638 1.64504C1.89164 1.6172 1.9294 1.60156 1.96877 1.60156H15.0313C15.0706 1.60156 15.1084 1.6172 15.1362 1.64504C15.1641 1.67288 15.1797 1.71063 15.1797 1.75V11.25Z" 
            fill="#0077FF"
          ></path>
        </svg>
      </div>
      <div class="item-left-proj">
        <h4 v-if="!change_chat_name">{{ chat.name }}</h4>
        <input class="input" v-else type="text" v-model="chat_name" v-focus @blur="renameChat"
          @keypress.enter="renameChat">
      </div>
      <div class="item-right" v-show="chat.users.find(e => e.user_id == user._id).unread_number > 0">
        {{ chat.users.find(e => e.user_id == user._id).unread_number }}
      </div>
    </div>
  </div>
</template>

<script>

const focus = {
  inserted(el) {
    el.focus()
  },
}

import { mapGetters } from "vuex"

export default {
  name: "ChatItem",
  props: ['chat'],
  directives: { focus },
  data() {
    return {
      change_chat_name: false,
      chat_name: '',
      pressTime: '',
      isFolderMoved: false,
      isShowMenu: false,
      startTime: 0,
    }
  },
  methods: {
    getLastMessage(messages) {
      let last_message = messages[messages.length - 1]
      return last_message.message
    },
    getLastMessageAuthor(chat) {
      if (chat.users.length > 1) {
        let author = ''
        let message_count = chat.messages.length
        chat.users.map((cur) => {
          if (cur.user_id === chat.messages[message_count - 1].user_id) {
            author = cur.user_data.name
          }
        })
        return author
      }
      return 'You'
    },
    startDownPress(e) {
      this.startTime = Date.now();
      this.isShowMenu = true;
      this.pressTime = setTimeout(() => {
        if (this.isShowMenu && !this.isFolderMoved) {
          let position_menu = {
            data: this.chat,
            bool: false,
            show: true,
            type: 'chat'
          }
          this.$root.$emit('listContextMenu_open', position_menu)
        }
      }, 400)
    },
    upPress() {
      const duration = Date.now() - this.startTime;

      if (!this.isFolderMoved && duration < 400) {
        if (this.$route.params.chat_id != this.chat._id) {
          document.querySelector('#app').classList.remove('menu-open')
          this.$root.$emit('closeLeftBar')
          this.$router.push('/chats/' + this.chat._id)
        }
      }
      clearTimeout(this.pressTime);
      this.isFolderMoved = false;
    },
    onTouchMove() {
      if (!this.isFolderMoved) {
        this.isFolderMoved = true;
      }
    },
    renameChat() {
      this.change_chat_name = false

      if (this.chat.name == this.chat_name) {
        return
      }
      this.$socket.emit("IN_RenameChats", {
        chat_name: this.chat_name,
        chat_id: this.chat._id,
      })
      this.chat_name = ''
    }
  },
  mounted() {
    this.$root.$on('RenameChatFromContext', (chat_id) => {
      if (this.chat._id == chat_id) {
        this.chat_name = this.chat.name
        this.change_chat_name = true
      }
    })
  },
  computed: {
    ...mapGetters(['get_flag_for_last_chat', 'getChats', 'getRender']),
    user() {
      return this.$store.state.key.user
    }
  }
}

</script>


<style scoped>
.input {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 3px;
  font-weight: normal;
  border: 0;
  outline: none;
}
</style>