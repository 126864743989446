export default function (global, plan, type, callback, id) {

    switch (type) {
        case 'list':
            // console.log(global.getProjects.length + 1)
            const lists = global.getProjects.filter(e => e._id != global.getUserData.shortcut_inbox)
            // const lists1 = lists.filter(e => e.author == global.getUserData._id)
            // console.log(lists)
            if (plan.lists_max_amount < lists.length + 1) {
                callback()
            }
            break;
        case 'task':
            let num1 = 0
            let lists2 = global.getProjects.filter(e => e._id != global.getUserData.shortcut_inbox)
            lists2.forEach((list) => {
                if (list._id == id) {
                    if (global.getTasks[list._id]) {
                        let tasks = global.getTasks[list._id].filter(e => e.parent == '')
                        // console.log(tasks.length)
                        num1 = num1 + tasks.length
                    }
                    if (global.getCompletedTasks[list._id]) {
                        num1 = num1 + global.getCompletedTasks[list._id].length
                    }
                }
            })
            if (global.getUserData.shortcut_inbox == id) {
                if (global.getTasks[id]) {
                    num1 = num1 + global.getTasks[id].length
                }
                if (global.getCompletedTasks[id]) {
                    num1 = num1 + global.getCompletedTasks[id].length
                }
                if (5000 < num1 + 1) {
                    callback()
                }
            } else {
                if (plan.tasks_max_amount < num1 + 1) {
                    callback()
                }
            }
            break;
        case 'folder':
            // console.log(global.getUserData.folder)
            if (plan.folders_max_amount < global.getUserData.folder.length + 1) {
                callback()
            }
            break;
        case 'section':
            let num = 0
            global.getProjects.forEach((list) => {
                if (list._id == id) {
                    if (list.sections) {
                        num = num + list.sections.length
                    }
                }
            })
            if (plan.sections_max_amount < num + 1) {
                callback()
            }
            break;
        case 'subtask':
            let list_id = ''
            global.getProjects.forEach(list => {
                if (global.getTasks[list._id]) {
                    global.getTasks[list._id].forEach(task => {
                        if (task._id == id) {
                            list_id = list._id
                        }
                    })
                }
            })
            let subtasks = global.getSubTask.filter(e => e.parent == id)
            if (list_id == global.getUserData.shortcut_inbox) {
                if (10 < subtasks.length + 1) {
                    callback()
                }
            } else {
                if (plan.subtasks_max_amount < subtasks.length + 1) {
                    callback()
                }
            }
            break;
        case 'chat':
            if (plan.chats_max_amount < global.getChats.length + 1) {
                callback()
            }
            break;
    
        default:
            break;
    }

}