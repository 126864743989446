<template>
    <div class="auth" ref="auth">
        <el-row>
            <el-col :span="span" class="form" style="width: 450px">
                <div v-if="response" style="text-align: center">
                    {{response}}
                </div>
                <el-form label-position="top" label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm" v-else>

                    <el-form-item label="Email:" prop="email">
                        <el-input v-model="ruleForm.email" type="email"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <div class="form_footer">
                            <el-button type="primary" @click="submitForm('ruleForm')" class="w-btn">Send</el-button>
                            <el-button type="success" class="w-btn">
                                <router-link to="/login">Sign in</router-link>
                            </el-button>
                        </div>
                    </el-form-item>

                </el-form>
            </el-col>
        </el-row>
    </div>
</template>

<script>

    import { mapMutations, mapActions, mapGetters } from "vuex"

    export default {
        props: {
            span: [Number],
        },
        data() {
            return {
                response: '',
                ruleForm: {
                    email: ""
                },
                rules: {
                    email: [
                        {
                            required: true,
                            message: "Enter mail.",
                            trigger: "blur",
                        },
                        {
                            type: "email",
                            message: "Enter your currently email.",
                            trigger: "blur",
                        },
                    ],
                },
            };
        },
        mounted() {

            setTimeout(() => {
                document.querySelector(".auth .form").classList.add("show")
            }, 500)

        },
        methods: {
            ...mapActions(["ForGotPassword"]),
            ...mapMutations(["setMessage"]),
            async submitForm(formName) {
                this.$refs[formName].validate(async (valid) => {
                    if (valid) {

                        const res = {
                            url: this.$w_api.ForGotPassword,
                            body: {
                                email: this.ruleForm.email
                            }
                        }

                        const req = await this.ForGotPassword(res)

                        if (req) {
                            this.response = req
                            this.ruleForm.email = ""
                            // this.$w_alert(req, [
                            //     {
                            //         buttonText: 'ok',
                            //         className: 'blue',
                            //         callback: (close) => {
                            //             close()
                            //         }
                            //     }
                            // ])
                            // window.location.href = '/login'
                        } else {

                            setTimeout(() => {
                                document.querySelector(".auth .form").classList.add("show")
                            }, 10)

                        }

                    }
                })
            }
        }
    }

</script>
