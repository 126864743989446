import {EventEmitter} from '../../../main'

export default {
  state: {
    history: [],
    historyFromBack: [],
    jumpToMessage: null,
    showSmalActivityLog: false,
    changeHistory: false
  },
  actions: {
    SOCKET_OUT_HistoryAll({ commit, dispatch }, data) {
        if (data) {
            commit("setHistoryFromBack", data.reverse());
            commit('setSrcHistory')
        }
        // dispatch("generateHistoryBodyAndAdd", [{ new: true, ...data.reverse() }]);
    },
    SOCKET_OUT_HistoryRead({commit}, data) {
        commit('activity_log_new', false)
        commit('changeHistory')
    },
    SOCKET_OUT_HistoryAdd({ dispatch, commit, getters }, data) {
        // console.log(data)

        data.new = true
        commit('activity_log_new', true)
        commit("addHistory", data)
        commit('setSrcHistory')
        commit('changeHistory')

        if (getters.getUserData.show_sidebar) {
            setTimeout(() => {
                commit('activity_log_new', false)
                // this.$socket.emit('IN_HistoryRead', false)
                commit('changeHistory')
            }, 1000)
        }
        // dispatch("generateHistoryBodyAndAdd", [{ new: true, ...data }]);
    },
    SOCKET_OUT_ShowSidebar({ dispatch, commit }, data) {
        // console.log(data)
        // commit('setShowSmalActivityLog', data)
        // EventEmitter.$emit('OUT_ShowSidebar', data)
        let json = {
            type: 'show_sidebar',
            show_sidebar: data
        }
        commit('setChangeUserData', json)
    },
    generateHistoryBodyAndAdd({ commit, getters }, data) {
      data.forEach((log) => {
        // const color =
        //   log.color == "green"
        //     ? "#F7FFF6"
        //     : log.color == "red"
        //     ? "#FAD0D0"
        //     : log.color == "blue"
        //     ? "#F0FBFF"
        //     : "#FAFAFA";
        // log.color = color;
        if (log.type) {
          if (log.type.type == "tasks") {
            if (
              (getters.getTasks[log.type.project_id] &&
                getters.getTasks[log.type.project_id].some(
                  (task) => task._id == log.type._id
                )) ||
              (getters.getCompletedTasks[log.type.project_id] &&
                getters.getCompletedTasks[log.type.project_id].some(
                  (task) => task._id == log.type._id
                ))
            )
              log.src = `/project/${log.type.project_id}/task/${log.type._id}`;
          } else if (log.type.type == "chat") {
            if (log.type.project_id) {
              if (
                (getters.getTasks[log.type.project_id] &&
                  getters.getTasks[log.type.project_id].some(
                    (task) => task._id == log.type.task_id
                  )) ||
                (getters.getCompletedTasks[log.type.project_id] &&
                  getters.getCompletedTasks[log.type.project_id].some(
                    (task) => task._id == log.type.task_id
                  ))
              ) {
                log.src = `/project/${log.type.project_id}/task/${log.type.task_id}`;
                log.toChatId = log.type._id;
              } 
            } else if (getters.getChats.some(e => e._id == log.type._id)) {
              log.src = `/chats/${log.type._id}`;
            }
          } else if (log.type.type == "project") {
            if (
              getters.getProjects.some((project) => project._id == log.type._id)
            )
              log.src = `/project/${log.type._id}`;
          }
        }
        commit("addHistory", log);
      });

      commit("setHistoryFromBack", []);
    },
    SOCKET_OUT_ActivityLogRemoveMessage({commit, getters}, data) {
      if (data) {
        commit('ActivityLogRemoveMessage', data)
      }
    }
  },
  mutations: {
    setSrcHistory(s, v) {
        setTimeout(() => {
            s.history.forEach((history, i) => {
                if (history.type) {
                    if (history.type.type == 'project') {
                        if (this.getters.getProjects.some((project) => project._id == history.type._id)) {
                            s.history[i].src = '/project/' + history.type._id
                        }
                    } else if (history.type.type == 'tasks') {
                        if (this.getters.getTasks[history.type.project_id]) {
                            if (this.getters.getTasks[history.type.project_id].some((task) => task._id == history.type._id)) {
                                s.history[i].src = '/project/' + history.type.project_id + '/task/' + history.type._id
                            }
                        }
                        if (this.getters.getCompletedTasks[history.type.project_id]) {
                            if (this.getters.getCompletedTasks[history.type.project_id].some((task) => task._id == history.type._id)) {
                                s.history[i].src = '/project/' + history.type.project_id + '/task/' + history.type._id
                            }
                        }
                    } else if (history.type.type == 'chat') {
                        if (this.getters.getTasks[history.type.project_id]) {
                            if (this.getters.getTasks[history.type.project_id].some((task) => task._id == history.type.task_id)) {
                                s.history[i].src = '/project/' + history.type.project_id + '/task/' + history.type.task_id
                                s.history[i].toChatId = history.type._id;
                            }
                        }
                        if (this.getters.getCompletedTasks[history.type.project_id]) {
                            if (this.getters.getCompletedTasks[history.type.project_id].some((task) => task._id == history.type.task_id)) {
                                s.history[i].src = '/project/' + history.type.project_id + '/task/' + history.type.task_id
                                s.history[i].toChatId = history.type._id;
                                // console.log(0)
                            }
                        }
                        if (this.getters.getChats.some(e => e._id == history.type._id)) {
                          s.history[i].src = `/chats/${history.type._id}`;
                        }
                    }
                }
            })
        }, 10000)
    },
    setHistoryFromBack(s, v) {
        s.history = v;
    },
    addHistory(s, v) {
      s.history.unshift(v);
    },
    setJumpToMessage(s, v) {
      s.jumpToMessage = ''
      s.jumpToMessage = v;
    },
    changeHistory(s, v) {
        s.changeHistory = !s.changeHistory
    },
    setShowSmalActivityLog(s, v) {
        s.showSmalActivityLog = v;
    },
    ActivityLogRemoveMessage(state, data) {
      let logs = state.history
      logs.forEach((e, i) => {
        if (e._id == data.activity_log) {
          console.log(e._id)
          state.history[i].remove_message = true
        }
      })
      state.history = []
      state.history = logs
    }
  },
  getters: {
    getHistory: (s, getters) => s.history.slice(0, getters.getPlan.log_max_amount),
    getJumpToMessage: (s) => s.jumpToMessage,
    getHistoryFromBack: (s) => s.historyFromBack,
    getShowSmalActivityLog: (s) => s.showSmalActivityLog,
    getChangeHistory: (s) => s.changeHistory
  },
};
