<template>
  <!-- :close-on-click-modal="true" -->
  <!-- v-model="watch_data" -->
  <el-dialog
    :visible.sync="watch_data"
    :show-close="false"
    width="320px"
    top="0"
    id="link-popup"
    :class="['projects-list-dialog', 'link-popUp']"
    ref="AddRenameProjectAddUser"
    title="Choose a list to link to your chat"
    @close="close"
  >
    <div class="link-popUp__body projects-list-popup scroll-bar-2">
      <!--      <ul>-->
      <!--        <Lists/>-->
      <!--      </ul>-->

      <!-- <div class="project_list-bg" @click="close"></div> -->
      <div class="projects projects--on-chat">
        <ul class="projects-list-popup__lists-wrapper">
          <li
            v-for="project in lists.filter((e) => e.author == getUserData._id)"
            :key="project._id"
          >
            <div
              @click="(e) => checkProject(project, e)"
              class="project-item"
              v-if="project._id != getUserData.shortcut_inbox"
            >
              <div
                class="project_thumbnail"
              >
                <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 6.28261C17 6.38062 16.9611 6.47462 16.8918 6.54393C16.8224 6.61324 16.7284 6.65217 16.6304 6.65217H0.369565C0.27155 6.65217 0.17755 6.61324 0.108243 6.54393C0.0389363 6.47462 0 6.38062 0 6.28261C0 6.18459 0.0389363 6.09059 0.108243 6.02129C0.17755 5.95198 0.27155 5.91304 0.369565 5.91304H16.6304C16.7284 5.91304 16.8224 5.95198 16.8918 6.02129C16.9611 6.09059 17 6.18459 17 6.28261ZM0.369565 0.73913H16.6304C16.7284 0.73913 16.8224 0.700195 16.8918 0.630888C16.9611 0.561581 17 0.46758 17 0.369565C17 0.27155 16.9611 0.17755 16.8918 0.108243C16.8224 0.0389363 16.7284 0 16.6304 0H0.369565C0.27155 0 0.17755 0.0389363 0.108243 0.108243C0.0389363 0.17755 0 0.27155 0 0.369565C0 0.46758 0.0389363 0.561581 0.108243 0.630888C0.17755 0.700195 0.27155 0.73913 0.369565 0.73913ZM16.6304 11.8261H0.369565C0.27155 11.8261 0.17755 11.865 0.108243 11.9343C0.0389363 12.0036 0 12.0976 0 12.1957C0 12.2937 0.0389363 12.3877 0.108243 12.457C0.17755 12.5263 0.27155 12.5652 0.369565 12.5652H16.6304C16.7284 12.5652 16.8224 12.5263 16.8918 12.457C16.9611 12.3877 17 12.2937 17 12.1957C17 12.0976 16.9611 12.0036 16.8918 11.9343C16.8224 11.865 16.7284 11.8261 16.6304 11.8261Z" fill="#0077FF"/>
                </svg>
              </div>
              <div class="project_name_body no_messages" :data-id="project._id">
                <div class="project_name">
                  <p>
                    {{ project.name }}
                  </p>
                  <!-- <span>{{ getNumberOfTasks.length }}</span>
                  <p class="last_comment_in_project__user_name num_task">
                    {{
                      getTasksCount(project._id)
                        ? getTasksCount(project._id)
                        : 'No'
                    }}
                    tasks
                  </p> -->
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="project_list__bottom">
            <button @click="close">Cancel</button>
            <button @click="save">Save</button>
        </div> -->
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

import { EventEmitter } from '../../main';
import projects from '@/store/modules/projects';

export default {
  props: {
    chat_id: [String],
    project_id: [String],
    chat_users: [Array],
  },
  data() {
    return {
      list_id: '',
      watch_data: false,
    };
  },
  computed: {
    ...mapGetters([
      'getProjects',
      'getChats',
      'getUserData',
      'getKnownUsers',
      'getUsersByProject',
      'getUsersByChat',
      'getLists',
      'getNumberOfTasks',
      'getSortedLists',
      'getTasks',
    ]),
    lists() {
      return this.$store.state.projects.projects;
    },
  },
  sockets: {
    OUT_CheckUserPlan(data) {
      if (data.no_valid) {
        let users = '';

        data.no_valid.forEach((a) => {
          let user = this.getKnownUsers.filter((e) => e._id == a);
          users += `<li>${user[0].email}</li>`;
        });

        let data = {
          data: {
            text: `No valid
                <ul>${users}</ul>
            `,
            cancel: 'Close',
            delete: '',
          },
        };

        EventEmitter.$emit('OpenPopup', data);
      }
    },
  },
  mounted() {
    // localStorage.setItem('project_id', this.project_id)
    this.list_id = this.project_id;
    EventEmitter.$on('openProjectsList', () => (this.watch_data = true));
    // document.querySelector('.el-dialog__title').innerHTML = 'Choose a list to link to your chat<br/>(you can link only the lists you own)'
  },
  methods: {
    ...mapMutations(['setAddListsChats']),
    ...mapActions(['socket_add_user_to_project']),
    getTasksCount(id) {
      return this.getTasks[id] ? this.getTasks[id].length : '';
    },
    close() {
      this.watch_data = false;
      EventEmitter.$emit('closeProjectLists', true);
    },
    addAllNonMembers(users, id) {
      this.list_id = id;
      this.socket_add_user_to_project({
        users,
        project_id: this.list_id,
        teams: [],
      });
      this.$socket.emit(
        'IN_AddListsChats',
        this.list_id,
        this.$route.params.chat_id
      );

      setTimeout(() => {
        this.close();
      }, 100);
    },
    checkProject(project, e) {
      e.preventDefault();
      const id = project._id;

      if (id == this.list_id) {
        this.list_id = '';
      } else {
        const chatUsers = this.getChats.find(
          (chat) => chat._id == this.$route.params.chat_id
        ).users;
        const listUsers = this.getProjects.find((list) => list._id == id).users;

        const chatUsersEmails = {};
        const listUsersEmails = {};
        for (let i = 0; i < chatUsers.length; i++) {
          chatUsersEmails[chatUsers[i].user_data.email] =
            chatUsers[i].user_data._id;
        }

        for (let i = 0; i < listUsers.length; i++) {
          listUsersEmails[listUsers[i].email] = listUsers[i]._id;
        }

        let nonMembers = [];
        for (let email in chatUsersEmails) {
          if (email in listUsersEmails) continue;

          nonMembers.push({ email, _id: chatUsersEmails[email] });
        }

        if (nonMembers.length == 0) {
          this.list_id = id;
          this.$socket.emit(
            'IN_AddListsChats',
            this.list_id,
            this.$route.params.chat_id
          );
          setTimeout(() => {
            this.watch_data = false;
          }, 100);
        } else {
          let data = {
            type: 'addNonMemberUsers',
            data: {
              text: 'If you link this list to the current chat, some of the members of your chat will be added to this list automatically',
              cancel: 'Cancel',
              accept: 'Proceed',
              class: {
                cancel: 'red',
                accept: 'blue',
              },
              action: this.addAllNonMembers.bind(this, nonMembers, id),
            },
            list: this.task_id,
          };
          EventEmitter.$emit('OpenPopup', data);
        }
      }
    },
  },
  components: {
    Lists: () => import('@/components/lists/Lists'),
  },
  watch: {
    project_id(newVal) {
      this.list_id = newVal;
    },
  },
};
</script>
