<template>
    <div class="import__page">
        <div>
            <p>{{$route.name}}</p>
            <el-progress :text-inside="true" :stroke-width="26" :percentage="progress"></el-progress>
            <span>{{message}}</span>
        </div>
    </div>
</template>

<script>

    import { mapActions } from "vuex";
    import { EventEmitter } from '../main'

    export default {
        name: 'Import',
        data() {
            return {
                progress: 0,
                message: ''
            }
        },
        mounted() {

            if (this.$route.query) {
                if (this.$route.query.code) {
                    // console.log(this.$socket.emit())
                    this.$socket.connect()
                    this.$socket.emit('IN_ImportData', {
                        type: this.$route.name,
                        token: this.$route.query.code,
                        option: {update: 'update'}
                    })
                }
            }

            EventEmitter.$on('SOCKET_OUT_ImportData', (data) => {
                if (data) {
                    this.progress = 100
                    setTimeout(() => {
                        this.message = 'Done'
                    }, 1000)
                    setTimeout(() => {
                        window.close()
                    }, 3000)
                } else {
                    this.message = 'Please change plan'
                }
            })

            EventEmitter.$on('SOCKET_OUT_ImportDataProgress', (data) => {
                this.progress = data
            })
            
        },
        methods: {
            ...mapActions([
                // "socket_import"
            ]),
        }
    }

</script>