export default function (date, hour12) {

    // let data = new Date(date).getTimezoneOffset()

    // console.log(Date.parse(date))

    try {

        if (!isNaN(date)) {
            date = new Date(+date).toISOString()
        }

        if (!isNaN(Date.parse(date))) {

            let time = new Intl.DateTimeFormat("ru-Ru", {
                hour: "2-digit",
                minute: "2-digit",
                hour12: hour12 == '1:00 PM' ? true : false,
            }).format(Date.parse(date));
            
            let arrayTime = time.split('')
    
            if (arrayTime[0] == 0) {
                arrayTime.splice(0, 1)
            }
    
            return arrayTime.join('')
        }
    } catch (error) {
        
    }

}
