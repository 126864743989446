<template>
  <div class="home">
    <!-- <h2 class="h2" @click="logout">Logout</h2> -->
    <!-- <input type="text" v-model="num">
        <button @click="testDB" >test</button>
        <button @click="deleteDB" >delete db</button> -->
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';

export default {
  name: 'Home',
  data() {
    return {
      num: 0,
    };
  },
  methods: {
    ...mapActions(['fetch_request']),
    ...mapMutations([
      'setProjects',
      'clearComments',
      'clearTasks',
      'clearUserUsedFileSize',
      'clearUserAllFileSize',
    ]),
    logout() {
      this.setProjects([]);
      this.clearComments();
      this.clearUserUsedFileSize();
      this.clearUserAllFileSize();
      this.clearTasks();
      this.$socket.disconnect();
      localStorage.removeItem('w_token');
      localStorage.removeItem('w_clone_token');
      this.$router.push('/login');
      console.log('User Logout');
    },
    testDB: function () {
      console.log(new Date());
      for (var i = 0; i < this.num; i++) {
        this.$socket.emit('IN_test', 1);
      }
    },
    deleteDB: function () {
      this.$socket.emit('IN_test_remove', 1);
    },
  },
  mounted() {
    if (localStorage.getItem('app_web_auth')) {
      // localStorage.removeItem('app_web_auth')
      this.$router.push({
        path: '/app_auth',
        query: {
          useremail: '',
        },
      });
    }
  },
  computed: {
    ...mapGetters(['getUserData']),
    getUserEmail() {
      return this.getUserData;
    },
  },
};
</script>
