import foregroundLocalNotification from "./foreground_local_notification";

const types = { remindMe: "Reminde Me" };
const message = (type, data) => {
  switch (type) {
    case types.remindMe:
      return `${data.name}`;
  }
};

export default function pushNotification({
  task_id,
  project_id,
  getters,
  type,
  task,
  chat_id,
  comment,
  chat,
}) {
  let message_settings = {
    body: "",
    renotify: true,
  };

  let new_message = "";

  if (type == "remindMe") {
    if (!task) {
      task = getters.getTasks[project_id].find((task) => task._id == task_id);
    }

    if (!task || (task && task.done)) return;
    new_message = message(types.remindMe, task);
    message_settings.tag = "remind_" + task_id + "_notification";
    message_settings.body = "TaskPal reminder";
  }

  if (type == "mentionedInChat") {
    new_message = "Mentioned in Chat";
    message_settings.tag = "reply_" + chat_id + "_notification";
    message_settings.body = comment;
  }

  if (type == "addedToChat") {
    new_message = "TaskPal";
    message_settings.tag = "reply_" + chat_id + "_notification";
    message_settings.body = comment;
  }

  if (type == "mentionedInTask") {
    if (!task) {
      task = getters.getTasks[project_id].find((task) => task._id == task_id);
    }
    new_message = "Mentioned in Task " + task.name;
    message_settings.tag = "reply_" + task_id + "_notification";
    message_settings.body = comment;
  }

  if (type == "replyInTask") {
    if (!task) {
      task = getters.getTasks[project_id].find((task) => task._id == task_id);
    }
    new_message = "Reply in " + task.name;
    message_settings.tag = "reply_" + task_id + "_notification";
    message_settings.body = comment;
  }

  if (type == "replyInChat") {
    if (!chat) {
      chat = getters.getChats.find((chat) => chat._id == chat_id);
    }
    new_message = "Reply in " + chat.name;
    message_settings.tag = "reply_" + chat_id + "_notification";
    message_settings.body = comment;
  }

  let url = "";
  if (
    type == "remindMe" ||
    type == "mentionedInTask" ||
    type == "replyInTask"
  ) {
    url = `/project/${project_id}/task/${task_id}`;
  } else if (
    type == "mentionedInChat" ||
    type == "addedToChat" ||
    type == "replyInChat"
  ) {
    url = `/chats/${chat_id}/`;
  }

  foregroundLocalNotification({
    title: new_message,
    body: message_settings.body,
    url,
  });
}
