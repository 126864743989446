import router from "@/router";
import {EventEmitter} from "../../../main"
import pushNotifications from '../../../utils/pushNotifications'
import pushNotificationTask from '../../../utils/pushNotificationCustom'

let order_this = 0
let done_this = true
let add_task = 0
let add_task_array = []
let add_task_array_num = null
let this_user_delete_task = true

export default {
    state: {
        subtask: [],
        assigns: [], // обьект assigns
        tasks: [], //объект с тасками
        completed_tasks: {}, // объект с выполнеными тасками
        tasks_notes: [], // массив для заметок в тасках
        flag_for_tasks: false,
        active_task_window: "",
        flag_for_add_task: false,
        shortcuts_render: false,
        flag_for_note: false,
        reminders: {}
    },
    actions: {
        // метод слушает когда с бэка придут все таски ( приходят сразу после подключения к сокетам )
        // SOCKET_OUT_Lists1({ commit, getters, dispatch }, data_task) {
        //     let data = data_task.tasks ? data_task.tasks : []

        //     const tasks = {};
        //     const completed_tasks = {};
        //     const notes = [];
        //     const reminders = [], divider = '&$&', divider2 = '!|!'

        //     // console.log(data)

        //     if (data.length > 0) {
        //         data.forEach((task) => {

        //             // console.log(task.name, task.order)

        //             task.assign.task_id = task._id

        //             if ( task.assign.remind ) {
        //                 if (!task.done) {
        //                     let x = new Date(task.assign.remind)
        //                     if (x) {
        //                         reminders.push(task.project_id + divider + task._id + divider + task.name + '_time_' + x.getTime())
        //                     }
        //                 }
        //             }

        //             commit('setAssigns', task.assign)

        //             task.users.forEach((user) => {
        //                 if (user._id == getters.getUserData._id) {
        //                     task.unread_message = user.unread_message
        //                 }
        //             })

        //             notes.push({
        //                 notes: task.notes,
        //                 task_id: task._id,
        //             });

        //             let pr_id = task.project_id

        //             task.elements = []

        //             if (!task.done) {

        //                 if (!tasks[pr_id]) tasks[pr_id] = []

        //                 if (tasks[pr_id].length == 0) {
        //                     tasks[pr_id].push(task)
        //                 } else {
        //                     let isTask = tasks[pr_id].filter(e => e._id == task._id)
        //                     if (isTask.length == 0) {
        //                         tasks[pr_id].push(task);
        //                     }
        //                 }

        //             } else {

        //                 if (!completed_tasks[pr_id]) completed_tasks[pr_id] = []

        //                 if (completed_tasks[pr_id].length == 0) {
        //                     completed_tasks[pr_id].push(task);
        //                 } else {
        //                     let isTask = completed_tasks[pr_id].filter(e => e._id == task._id)
        //                     if (isTask.length == 0) {
        //                         completed_tasks[pr_id].push(task)
        //                     }
        //                 }

        //             }

        //         })
        //     }

        //     if (reminders.length > 0) {
        //         localStorage.reminders = reminders.join(divider2)
        //     } else {
        //         localStorage.reminders = ''
        //     }

        //     commit("setCompletedTasks", completed_tasks)
        //     commit("setTasks", tasks)
        //     commit('setSubtask', data)
        //     commit("set_unreaded_messages", tasks)
        //     commit("set_notes", notes)

        //     if (!router.history.current.params.task_id && getters.getAppLoader) {
        //         setTimeout(() => {
        //             if (getters.getHistoryFromBack.length > 0) {
        //                 dispatch("generateHistoryBodyAndAdd", getters.getHistoryFromBack);
        //             }
        //             commit("set_flag_for_unreaded")
        //             commit("change_flag_for_tasks")
        //         }, 500)
        //     } else {
        //         dispatch("socket_fetch_comments", {
        //             task_id: router.history.current.params.task_id,
        //         })
        //     }
        //     commit("change_flag_for_tasks")
        // },
        // SOCKET_OUT_ProjectAdd({ commit, getters, dispatch }, task) {
        //     let data = task.tasks ? task.tasks : []
        //     /* console.log("--------------");
        //   console.log(new Date());
        //   console.log("SOCKET_OUT_TaskAdd", data);
        //   console.log("--------------"); */
        //   const data_note = [
        //     {
        //       notes: data.notes,
        //       task_id: data._id,
        //     },
        //   ];
        //   // добавляю в массив с заметками новую заметку, что бы vue знал про ее существование
        //   commit("set_notes", data_note);
        //   commit("add_task", data);
        //   if (data.user_id == getters.getUserData._id) {
        //     const new_order = [];
        //     getters.getTasks[data.project_id].forEach((task) => {
        //       if (task.order != 0)
        //         new_order.push({
        //           order: task.order,
        //           task_id: task._id,
        //         });
        //     });
        //     const new_order_position = {
        //       out: true,
        //       new_order,
        //       project_id: router.history.current.params.project_id,
        //     };
        //     if (
        //       new_order_position.new_order &&
        //       new_order_position.new_order.length > 0
        //     ) {
        //       dispatch("socket_change_order", {
        //         new_order_position,
        //         add_global: true,
        //       });
        //     }
        //   }

        //   if (data.user_id != getters.getUserData._id) {
        //     commit("change_flag_for_tasks");
        //   }
        //   commit("change_flag_for_add_task", false);
        // },
        // метод для добавления таска
        addTaskLocal({commit, getters}, task) {

            if (task.parent) {
                let bool = false
                this._vm.$w_plan(getters, getters.getPlan, 'subtask', () => {
                    commit("setMessage", {
                        key_message: "warning",
                        message: "You can't add more subtasks to this task.",
                        plan: true
                    })
                    bool = true
                }, task.parent)
                if (bool) return false
            } else {
                let bool = false
                this._vm.$w_plan(getters, getters.getPlan, 'task', () => {
                    commit("setMessage", {
                        key_message: "warning",
                        message: "You can't add more tasks to this list. ",
                        plan: true
                    })
                    bool = true
                }, task.project_id)
                if (bool) return false
            }

            if (task.parent) {
                commit('setSubtask', task)
                commit("add_task", task)
                return true
            } else {
                commit("add_task", task)
                return true
            }

        },
        socket_add_task({ getters, commit }, data) {

            if (!data.parent) {
                let a = false

                this._vm.$w_plan(getters, getters.getPlan, 'task', () => {
                    commit("setMessage", {
                        key_message: "warning",
                        message: "You can't add more tasks to this list. ",
                        plan: true
                    })
                    a = true
                }, data.project_id)

                if (a) {
                    return
                }
            }

            const local_task = {
                auth: getters.getUserData._id,
                order: data.order,
                date: new Date(),
                done: false,
                name: data.name,
                notes: "",
                project_id: data.project_id,
                _id: data._id,
                parent: data.parent ? data.parent : '',
                assign: data.assign,
                anime: 'showEazy',
                users: [
                    {
                        email: getters.getUserData.email,
                        name: getters.getUserData.name,
                        unread_message: 0,
                        _id: getters.getUserData._id,
                    },
                ],
            }

            if (data.parent) {
                commit('setSubtask', data)
                EventEmitter.$emit('addSubTask', data)
                commit("add_task", local_task)
            } else {
                commit("add_task", local_task)
            }

            add_task_array.push(data)

            // add_task = add_task + 1
            if (add_task_array.length == 1) {
                setTimeout(() => {
                    this._vm.$socket.emit("IN_TaskAdd", data)
                }, 500)
            }

            add_task_array_num = add_task_array.length

            commit("change_flag_for_tasks");
            // commit("change_flag_for_add_task", true);
        },
        // метод слушает бэк. Когда я добавлю таск бэк пришлет данные об этом таске
        SOCKET_OUT_TaskAdd({ commit, getters, dispatch }, data) {

            if (add_task == 0) {
                // добавляю в массив с заметками новую заметку, что бы vue знал про ее существование
                if (data.length > 0) {
                    let notes = []
                    data.forEach((e) => {
                        e.auth = e.auth_id
                        e.assign.task_id = e._id
                        commit('setAssigns', e.assign)

                        if (e.parent) {
                            commit('setSubtask', e)
                            EventEmitter.$emit('addSubTask', e)
                            commit("add_task", e);
                        } else {
                            if (e.done) {
                                commit("setCompletedTaskPush", e)
                            } else {
                                commit("add_task", e);
                            }
                        }

                        commit('set_clear_unreaded_messages_task', e._id)
                        notes.push({
                            task_id: e._id,
                            notes: e.notes
                        })
                    })
                    commit("set_notes", notes);
                } else {
                    data.section = ''
                    data.priority = 'Normal'
                    data.auth = data.auth_id
                    const data_note = [
                        {
                            notes: data.notes,
                            task_id: data._id,
                        },
                    ];
                    commit('set_clear_unreaded_messages_task', data._id)
                    data.assign.task_id = data._id
                    commit('setAssigns', data.assign)
                    commit("set_notes", data_note)
                    if (data.parent) {
                        commit('setSubtask', data)
                        EventEmitter.$emit('addSubTask', data)
                        commit("add_task", data);
                    } else {
                        commit("add_task", data);
                    }
                }

                commit("change_flag_for_tasks")
                commit("change_flag_for_add_task", false)

                add_task_array.forEach((e, i) => {
                    if (e._id == data._id) {
                        add_task_array.splice(i, 1)
                        add_task_array_num = add_task_array_num - 1
                    }
                })

                if (add_task_array_num == 0) {
                    EventEmitter.$emit('TaskChangeOrder', data.project_id)
                }

                if (add_task_array.length != 0) {
                    this._vm.$socket.emit("IN_TaskAdd", add_task_array[add_task_array.length - 1])
                }

            } else {
                add_task = add_task - 1
            }

        },
        // метод для удаления таска
        socket_delete_task({ commit, getters, dispatch }, { project_id, task_id, task_file_size }) {

            this_user_delete_task = false

            if (getters.getTasks[project_id]) {
                getters.getTasks[project_id].forEach((task) => {
                    if (task._id == task_id) {
                        if (getters.getComments[task_id]) {
                            getters.getComments[task_id].forEach((msg) => {
                                if (getters.getUserData._id == msg.user_id) {
                                    if (msg.files) {
                                        if (msg.files.length > 0) {
                                            msg.files.forEach((f) => {
                                                commit('setUserFileSize_minus', +f.size)
                                            })
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
            }

            if (getters.getCompletedTasks[project_id]) {
                getters.getCompletedTasks[project_id].forEach((task) => {
                    if (task._id == task_id) {
                        if (getters.getComments[task_id]) {
                            getters.getComments[task_id].forEach((msg) => {
                                if (getters.getUserData._id == msg.user_id) {
                                    if (msg.files) {
                                        if (msg.files.length > 0) {
                                            msg.files.forEach((f) => {
                                                commit('setUserFileSize_minus', +f.size)
                                            })
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
            }

            if (task_file_size > 0) {
                // this.commit("setUserUsedFileSize", task_file_size * -1);
                commit("delete_files", { task_id });
            }

            commit("delete_note", { project_id, task_id });
            // удаляю таск из массива с тасками
            commit("delete_task", { project_id, task_id });
            // меняю флаг с задержкой что бы и заметки и таски успели добавиться в нужные массивы
            let tasks = []

            getters.getProjects.forEach(e => {
                if (getters.getTasks[e._id]) {
                    getters.getTasks[e._id].forEach(t => {
                        tasks.push(t)
                    })
                }
                if (getters.getCompletedTasks[e._id]) {
                    getters.getCompletedTasks[e._id].forEach(t => {
                        tasks.push(t)
                    })
                }
            })

            commit("set_unreaded_messages", tasks);
            commit("set_flag_for_unreaded");
            if (getters.getComments[task_id]) {
                commit("clearCommentsInLocalModel", task_id);
            }
            //не знаю почему но приложение не реагировало на change_flag_for_tasks мгновенно.
            //пришлось запихнуть в settimeout
            setTimeout(() => {
                commit("change_flag_for_tasks");
                commit("change_flag_for_last_chat");
            }, 100);
            this._vm.$socket.emit("IN_TaskDelete", { project_id, task_id });
            //удаляю или меняю последнее сообщение в проекте
            dispatch("setLastChatToProjectAfterDeleteTask", project_id);

        },
        // метод слушает бэк. Когда я удалю таск бэк пришлет данные об этом таске
        SOCKET_OUT_TaskDelete({ commit, getters, dispatch }, data) {
            // console.log("--------------");
            // console.log(new Date());
            // console.log("SOCKET_OUT_TaskDelete", data);
            // console.log("--------------");
            // в data приходит id удаленного таска
            // удалю из массива с заметками заметку которая была в удаленнном таске, что бы vue знал про ее существование
            // if (data.user_id != getters.getUserData._id) {

            if (this_user_delete_task) {
                if (getters.getTasks[data.project_id]) {
                    getters.getTasks[data.project_id].forEach((task) => {
                        if (task._id == data.task_id) {
                            if (getters.getComments[data.task_id]) {
                                getters.getComments[data.task_id].forEach((msg) => {
                                    if (getters.getUserData._id == msg.user_id) {
                                        if (msg.files.length > 0) {
                                            msg.files.forEach((f) => {
                                                commit('setUserFileSize_minus', +f.size)
                                            })
                                        }
                                    }
                                })
                            }
                        }
                    })
                }

                if (getters.getCompletedTasks[data.project_id]) {
                    getters.getCompletedTasks[data.project_id].forEach((task) => {
                        if (task._id == data.task_id) {
                            if (getters.getComments[data.task_id]) {
                                getters.getComments[data.task_id].forEach((msg) => {
                                    if (getters.getUserData._id == msg.user_id) {
                                        if (msg.files.length > 0) {
                                            msg.files.forEach((f) => {
                                                commit('setUserFileSize_minus', +f.size)
                                            })
                                        }
                                    }
                                })
                            }
                        }
                    })
                }
            }

            this_user_delete_task = true

            if (getters.get_files[data.task_id])
                commit("delete_files", { task_id: data.task_id });
            commit("delete_note", data);
            // удалю таск из массива с тасками
            commit("delete_task", data);
            // меняю флаг с задержкой что бы и заметки и таски успели добавиться в нужные массивы
            let tasks = []

            getters.getProjects.forEach(e => {
                if (getters.getTasks[e._id]) {
                    getters.getTasks[e._id].forEach(t => {
                        tasks.push(t)
                    })
                }
                if (getters.getCompletedTasks[e._id]) {
                    getters.getCompletedTasks[e._id].forEach(t => {
                        tasks.push(t)
                    })
                }
            })

            commit("set_unreaded_messages", tasks);
            if (getters.getComments[data.task_id]) {
                commit("clearCommentsInLocalModel", data.task_id);
            }
            //удаляю или меняю последнее сообщение в проекте
            dispatch("setLastChatToProjectAfterDeleteTask", data.project_id);
            commit("set_flag_for_unreaded");
            commit("change_flag_for_tasks");
            commit("change_shortcuts_render")
            // console.log(router)
            if (router.history.current.params.project_id == getters.getUserData.shortcut_inbox) {
                router.push(`/inbox/${router.history.current.params.project_id}`);
            } else {
                if (router.history.current.params.task_id && router.history.current.params.task_id == data.task_id) {
                    router.push(`/project/${router.history.current.params.project_id}`);
                }
            }
            // }
        },
        setLastChatToProjectAfterDeleteTask({ commit, getters }, project_id) {
            let newLastChat = null;
            let allChatsInLocalModel = false;
            if (
                getters.getTasks[project_id] &&
                getters.getTasks[project_id].length > 0
            ) {
                const lastsChat = [];
                getters.getTasks[project_id].forEach((task) => {
                    if (getters.getComments[task._id]) {
                        let lastChatInTask =
                            getters.getComments[task._id][
                            getters.getComments[task._id].length - 1
                                ];
                        if (lastChatInTask) {
                            let lastChatInTaskDate = isNaN(+lastChatInTask.date)
                                ? new Date(lastChatInTask.date).getTime()
                                : lastChatInTask.date;
                            lastsChat.push(lastChatInTask);
                            if (newLastChat) {
                                let lastChatDate = isNaN(+newLastChat.date)
                                    ? new Date(newLastChat.date).getTime()
                                    : newLastChat.date;
                                newLastChat =
                                    +lastChatDate > +lastChatInTaskDate
                                        ? newLastChat
                                        : lastChatInTask;
                            } else {
                                newLastChat = lastChatInTask;
                            }
                            allChatsInLocalModel = true;
                        }
                    }
                });
                if (lastsChat.length != getters.getTasks[project_id].length) {
                    /* console.log("IN_CommitLast", project_id);
                    this._vm.$socket.emit("IN_CommitLast", { project_id }); */
                    allChatsInLocalModel = false;
                }
                if (!allChatsInLocalModel) {
                    newLastChat = null;
                }
            }
            commit("set_last_chat", [{ _id: project_id, last_chat: newLastChat }]);
            commit("set_new_last_chat", { last_chat: newLastChat, project_id });
        },
        // метод добавляет исполнителя в таск
        // socket_IN_AssignUser(ctx, data) {
        //   console.log("-----------------");
        //   console.log(new Date());
        //   console.log("SOCKET_IN_AssignUser", data);
        //   console.log("-----------------");
        //   this._vm.$socket.emit("IN_AssignUser", data);
        // },
        SOCKET_OUT_Note_Size({commit}, data) {
            commit('setTaskNoteSize', data)
            EventEmitter.$emit('Note_Size', data.task_id)
        },
        SOCKET_OUT_AssignUser({commit, getters}, data) {
            EventEmitter.$emit('AssignUser', data)
          
            pushNotifications({
                data,
                getters,
                message: '<Username> assigned <Taskname> to you',
                type: 'assign',
                force: true
            })
            
            commit('setAssigns_user', data)
            commit("change_shortcuts_render")
        },
        SOCKET_OUT_Priority({commit, getters}, data) {
            EventEmitter.$emit('Priority', data)
            commit('setPriority', data)
            commit("change_shortcuts_render")
        },
        SOCKET_OUT_AssignDate({commit}, data) {
            // console.log("-----------------");
            // console.log(new Date());
            // console.log("SOCKET_OUT_AssignDate", data);
            // console.log("-----------------");
            EventEmitter.$emit('AssignDate', data)
            commit('setAssigns_date', data)
            commit("change_shortcuts_render")
        },
        SOCKET_OUT_AssignRemind({commit, getters}, data) {
            // console.log("-----------------");
            // console.log(new Date());
            // console.log("SOCKET_OUT_AssignRemind", data);
            // console.log("-----------------");
            EventEmitter.$emit('AssignRemind', data)
            commit('setAssigns_remind', data)
            commit("setRemindMeNotification", {...data, getters})
        },
        SOCKET_OUT_test_remove(ctx, data) {
            // console.log("-----------------");
            // console.log(new Date());
            // console.log("OUT_test_remove", data);
            // console.log("-----------------");
            //   EventEmitter.$emit('AssignUser', data)
        },
        SOCKET_OUT_test(ctx, data) {
            // console.log("-----------------");
            // console.log(new Date());
            // console.log("OUT_test", data);
            // console.log("-----------------");
            //   EventEmitter.$emit('AssignUser', data)
        },
        SOCKET_OUT_AssignFind({commit}, data) {
            //   console.log("-----------------");
            //   console.log(new Date());
            //   console.log("SOCKET_OUT_AssignFind", data);
            //   console.log("-----------------");
            let assign = {
                assign: data,
                update: false
            }
            EventEmitter.$emit('AssignFind', assign)
            commit('setAssigns', data)
        },
        // метод добавляет/меняет заметку внутри таска
        socket_send_note({ commit }, data) {
            commit("set_notes", [data]);
            this._vm.$socket.emit("IN_TaskNote", data);
        },
        // метод слушает когда бэк пришлет заметку. Метод сработает после "socket_send_note"
        SOCKET_OUT_TaskNote({ commit, getters }, data) {

            // console.log("SOCKET_OUT_TaskNote", data)
            commit("set_notes", [data])
            commit("change_flag_for_note")

        },
        // метод отправляет данные о смене порядка тасков
        socket_change_order_after_done({ commit }, { new_order_position, add_global }) {
            if (add_global) {
                this._vm.$socket.emit("IN_TaskOrder", new_order_position)
            }
            commit("change_order", new_order_position)
            commit("change_flag_for_tasks")
        },
        socket_change_order({ commit }, { new_order_position, add_global, working_out }) {
            if (!working_out) {
                order_this = order_this + 1
            }
            if (add_global) {
                this._vm.$socket.emit("IN_TaskOrder", new_order_position)
            }
            commit("change_order", new_order_position)
            if (!new_order_position.section_id) {
                commit("change_flag_for_tasks")
            }
        },
        // метод слушает когда с бэка придут таски в которых поменялась очередь
        SOCKET_OUT_TaskOrder({ commit, getters }, data) {

            // console.log('SOCKET_OUT_TaskOrder', data)

            EventEmitter.$emit('taskOrderChangeComplate')

            if (order_this == 0) {

                EventEmitter.$emit('delete_project_filter_option', true)

                commit("change_order", data)

                setTimeout( () => {

                    let filtered_tasks_by_section = [];

                    data.new_order.forEach(task => {
                        if (!filtered_tasks_by_section[task.section_id]) {
                            filtered_tasks_by_section[task.section_id] = []
                        }

                        filtered_tasks_by_section[task.section_id][task.task_id] = task.order
                    })

                    EventEmitter.$emit("change_sections_task_order_after_filter", filtered_tasks_by_section);

                }, 1)

                commit("change_flag_for_tasks")

            } else {
                setTimeout(() => {
                    order_this = order_this - 1
                }, 500)
            }

        },
        // метод отправляет данные о выполненном таске
        socket_completed_task({ commit, getters }, { complete, task_id, project_id, bool, section }) {

            const body = {
                _id: task_id,
                done: complete,
                project_id: project_id,
                user_id: getters.getUserData._id
            }

            this._vm.$socket.emit("IN_TaskDone", body)

            if (complete) {
                commit('set_completed_tasks', body)
            } else {
                commit('set_tasks', body)
            }

            if (complete) {

                let divider = '&$&', divider2 = '!|!'

                if (localStorage.reminders) {
                    if (localStorage.reminders.includes(project_id + divider + task_id)) {
                        let tmpReminders = localStorage.reminders.split(divider2), newReminders = []
                        tmpReminders.forEach((elem, i) => {
                            if (!elem.includes(project_id + divider + task_id)) {
                                newReminders.push(elem)
                            }
                        })
                        localStorage.reminders = newReminders.join(divider2)
                    }
                }

            } else {

                if (this.getters.getCompletedTasks) {

                    let task_due_time = null, _task_name = ''

                    if (this.getters.getCompletedTasks) {
                        if (this.getters.getCompletedTasks[project_id]) {
                            this.getters.getCompletedTasks[project_id].forEach(cur_task => {
                                if (cur_task) {
                                    if (cur_task._id == task_id) {
                                        task_due_time = cur_task.assign.remind
                                        _task_name = cur_task.name
                                    }
                                }
                            })
                        }
                    }

                    if (task_due_time) {

                        let rDate = new Date(task_due_time), divider = '&$&', divider2 = '!|!'

                        if (localStorage.reminders) {
                            if (localStorage.reminders.includes(project_id + divider + task_id)) {
                                if (rDate) {
                                    let tmpReminders = localStorage.reminders.split(divider2)
                                    tmpReminders.forEach((elem, i) => {
                                        if (elem.includes(project_id + divider + task_id)) {
                                            tmpReminders[i] = project_id + divider + task_id + divider + _task_name + '_time_' + rDate.getTime()
                                        }
                                    })
                                    localStorage.reminders = tmpReminders.join(divider2)
                                }
                            } else {
                                localStorage.reminders += divider2 + project_id + divider + task_id + divider + _task_name + '_time_' +  rDate.getTime()
                            }

                        } else {
                            localStorage.reminders = project_id + divider + task_id + divider + _task_name + '_time_' +  rDate.getTime()
                        }

                    } else {
                        // console.log('due task is not found!')
                    }

                } else {
                    // console.log('no tasks')
                }

            }

        },
        // метод слушает когда с бэка придут данные о том какой таск выполнился
        SOCKET_OUT_TaskDone({ commit, getters }, data) {

            const body = {
                _id: data.task_id,
                done: data.done,
                project_id: data.project_id
            }

            if (data.done) {
                commit('set_completed_tasks', body)
            } else {
                commit('set_tasks', body)
            }

        },
        //метод отправляет на бэк объект для смены названия таска
        socket_rename_task({ commit }, data) {
            this._vm.$socket.emit("IN_TaskRename", data);
            commit("rename_task", data);
            commit("change_flag_for_tasks");
        },
        // метод слушает когда с бэка придет объект с имененным именем таска
        SOCKET_OUT_TaskRename({ commit, getters }, data) {
            //   console.log(data);
            //   if (data.user_id != getters.getUserData._id) {
            commit("rename_task", data);
            commit("change_flag_for_tasks");
            //   }
        },
        SOCKET_OUT_ChangeNameUser({commit}, data) {
            setTimeout(() => {
                commit("change_flag_for_tasks");
            }, 2000);
            commit('setChangeUserData4', data)
            // EventEmitter.$emit('ChangeEmailUser', true)
        },
        SOCKET_OUT_TaskMove({commit, getters}, data) {
            // console.log(data)
            if (data.task) {
                commit("addTask", data)

                data.task.forEach(t => {

                    if (t.parent) {
                        commit("setSubtask", t)
                    }

                    commit("removeAssign", t)

                    t.messages.forEach((msg, i) => {
                        let user_data = this._vm.$w_get_know_user_data(getters.getKnownUsers, getters.getUserData, msg.user_id)
                        t.messages[i].task_id = t._id
                        t.messages[i].user_name = user_data.name
                        t.messages[i].user_image = user_data.image
                        t.messages[i].remove_file = msg.remove_file ? msg.remove_file : false
                        let reply_user = this._vm.$w_get_know_user_data(getters.getKnownUsers, getters.getUserData, t.messages[i].reply.user_id)
                        t.messages[i].reply.name = reply_user.name
                        t.messages[i].send = true
                    })

                    let messages = {
                        chats: t.messages,
                        files: []
                    }

                    if (messages) {
                        // commit("setComments", messages)
                        // asddalskdjaskljd
                        commit("change_read_flag", {
                            user: getters.getUserData,
                            tasks: getters.getTasks
                        })
                    }
                })

                // data.subtasks.forEach((e, i) => {
                //     commit("removeAssign", e)
                // })

            } else {
                commit("removeTask", data)
                commit("removeAssign", data.task_id)
                data.subtasks.forEach((e, i) => {
                    commit("removeAssign", e)
                })
            }
            setTimeout(() => {
                commit("change_flag_for_tasks")
                commit("changeCommentLoading")
                commit("change_flag_for_files")
            }, 500)
        },
        open_subtask({commit, getters}, data) {
            commit('open_subtask', data)
            commit("change_flag_for_tasks")
        },
        handleSetRemindMeNotification({commit, getters}, {task_id, remind, project_id}) {
            commit("setRemindMeNotification", {task_id, remind, getters, project_id, getters})
        }
        // socket_assign_remind(ctx, data) {
        //   this._vm.$socket.emit("IN_AssignRemind", data);
        // },
    },
    mutations: {
        set_completed_tasks(s, v) {
            let task = s.tasks[v.project_id].find(e => e._id == v._id)
            if (task) {
                task.done = v.done
                task.completed_date = Date.now()
                if (!s.completed_tasks[v.project_id]) s.completed_tasks[v.project_id] = []

                let completed_tasks = s.completed_tasks
                completed_tasks[v.project_id].unshift(task)
                s.completed_tasks = []
                s.completed_tasks = completed_tasks

                let tasks = s.tasks
                tasks[v.project_id] = tasks[v.project_id].filter(e => e._id != v._id)
                s.tasks = []
                s.tasks = tasks
            }
        },
        set_tasks(s, v) {
            let task = s.completed_tasks[v.project_id].find(e => e._id == v._id)
            if (task) {
                task.done = v.done
                if (!s.tasks[v.project_id]) s.tasks[v.project_id] = []

                let tasks = s.tasks
                tasks[v.project_id].push(task)
                s.tasks = []
                s.tasks = tasks

                let completed_tasks = s.completed_tasks
                completed_tasks[v.project_id] = completed_tasks[v.project_id].filter(e => e._id != v._id)
                s.completed_tasks = []
                s.completed_tasks = completed_tasks
            }
        },
        comment_read_users(s, v) {

            let tasks = s.tasks[v.project_id]
            if (tasks) {
                if (tasks.some(t => t._id == v.task_id)) {
                    tasks.forEach((e, i) => {
                        if (e._id == v.task_id) {
                            tasks[i].users.forEach((user, u) => {
                                if (user._id == v.user_id) {
                                    tasks[i].users[u].read_last_message = v.chat_id
                                }
                            })
                        }
                    })
                    s.tasks[v.project_id] = tasks
                }
            }

            let completed_tasks = s.completed_tasks[v.project_id]
            if (completed_tasks) {
                if (completed_tasks.some(t => t._id == v.task_id)) {
                    completed_tasks.forEach((e, i) => {
                        if (e._id == v.task_id) {
                            completed_tasks[i].users.forEach((user, u) => {
                                if (user._id == v.user_id) {
                                    completed_tasks[i].users[u].read_last_message = v.chat_id
                                }
                            })
                        }
                    })
                    s.completed_tasks[v.project_id] = completed_tasks
                }
            }

        },
        setRemindMeNotification(state, {task_id, remind, getters, project_id}) {
            const now = new Date().getTime()
            const remindTime = new Date(remind).getTime()
            const diff = remindTime - now
            clearTimeout(state.reminders[task_id])

            if (diff >= 0) {
                state.reminders[task_id] = setTimeout(() => {
                    pushNotificationTask({task_id, project_id, getters, type: 'remindMe'})
                }, diff)
            }

        },
        open_subtask(s, v) {
            s.tasks[v.project_id].forEach((e, i) => {
                if (v.task_id == e._id) {
                    s.tasks[v.project_id][i].show_subtask = !s.tasks[v.project_id][i].show_subtask
                    localStorage.setItem(e._id, s.tasks[v.project_id][i].show_subtask)
                    EventEmitter.$emit('AnimeTask', true)
                }
            })

        },
        change_task_section(s, v) {

            if (v.remove_tasks) {
                if (s.tasks[v.project_id]) {
                    s.tasks[v.project_id] = s.tasks[v.project_id].filter(e => e.section != v.section_id)
                }
                if (s.completed_tasks[v.project_id]) {
                    s.completed_tasks[v.project_id].forEach((e, i) => {
                        if (e) {
                            if (e.section == v.section_id) {
                                s.completed_tasks[v.project_id][i].section = ''
                            }
                        }
                    })
                }
            } else {

                if (s.tasks[v.project_id]) {
                    s.tasks[v.project_id].forEach((e, i) => {
                        if (e) {
                            if (e.section == v.section_id) {
                                s.tasks[v.project_id][i].section = ''
                            }
                        }
                    })
                }

                if (s.completed_tasks[v.project_id]) {
                    s.completed_tasks[v.project_id].forEach((e, i) => {
                        if (e) {
                            if (e.section == v.section_id) {
                                s.completed_tasks[v.project_id][i].section = ''
                            }
                        }
                    })
                }

            }

            s.flag_for_tasks = !s.flag_for_tasks;

        },
        removeTask(s, v) {
            // s.tasks[v.remove_project_id].forEach((e, i) => {
            //     if (e._id == v.task_id) {
            //         s.tasks[v.remove_project_id].splice(i, 1)
            //     }
            // })
            s.tasks[v.remove_project_id] = s.tasks[v.remove_project_id].filter(e => e._id != v.task_id)
            // s.tasks[v.remove_project_id].forEach((e, i) => {
            v.subtasks.forEach((task) => {
                s.tasks[v.remove_project_id] = s.tasks[v.remove_project_id].filter(e => e._id != task)
            })
            // })
            // console.log(s.tasks[v.remove_project_id])
        },
        addTask(s, v) {
            // v.task.project_id = v.add_project_id
            if (s.tasks[v.add_project_id]) {
                v.task.forEach((t) => {
                    let user_data = this._vm.$w_get_know_user_data(this.getters.getKnownUsers, this.getters.getUserData, t.auth)
                    t.auth = user_data._id
                    t.project_id = v.add_project_id
                    // t.assign = {}
                    s.tasks[v.add_project_id].push(t)
                    s.tasks_notes.push({
                        notes: t.notes,
                        task_id: t._id
                    })
                })
            } else {
                s.tasks[v.add_project_id] = []
                v.task.forEach((t) => {
                    let user_data = this._vm.$w_get_know_user_data(this.getters.getKnownUsers, this.getters.getUserData, t.auth)
                    t.auth = user_data._id
                    t.project_id = v.add_project_id
                    // t.assign = {}
                    s.tasks[v.add_project_id].push(t)
                    s.tasks_notes.push({
                        notes: t.notes,
                        task_id: t._id
                    })
                })
            }
            // console.log('752', s.tasks)
        },
        setChangeUserData4(s, v) {
            this.getters.getProjects.forEach((list) => {
                if (s.tasks[list._id]) {
                    s.tasks[list._id].forEach((task) => {
                        if (task.auth_id == v.user_id) {
                            task.auth = v.user_id
                        }
                    })
                }
                if (s.completed_tasks[list._id]) {
                    s.completed_tasks[list._id].forEach((task) => {
                        if (task.auth_id == v.user_id) {
                            task.auth = v.user_id
                        }
                    })
                }
            })
            // console.log(s.tasks)
        },
        removeAssign(s, v) {
            if (v._id) {
                // console.log(v.assign)
                s.assigns.forEach((e, i) => {
                    if (e.task_id == v._id) {
                        s.assigns.splice(i, 1)
                    }
                })
                s.assigns.push({
                    date: v.assign.date ? v.assign.date : '',
                    user_id: v.assign.user_id ? v.assign.user_id : '',
                    task_id: v._id
                })
            } else {
                s.assigns.forEach((e, i) => {
                    if (e.task_id == v) {
                        s.assigns.splice(i, 1)
                    }
                })
            }
        },
        setAssigns(s, v) {
            s.assigns.push(v)
        },
        setAssigns_user(s, v) {
            s.assigns.forEach( (e, i) => {
                if (e.task_id == v.task_id) {
                    s.assigns[i].user_id = v.user_id
                }
            })
        },
        setAssigns_date(s, v) {
            s.assigns.forEach( (e, i) => {
                if (e.task_id == v.task_id) {
                    s.assigns[i].date = v.date
                }
            })
        },
        setAssigns_remind(s, v) {

            if (this.getters.getTasks[v.project_id]) {
                this.getters.getTasks[v.project_id].forEach((task, task_index) => {
                    if (task._id == v.task_id) {
                        task.users.forEach((user, user_index) => {
                            if (user._id == this.getters.getUserData._id) {
                                s.tasks[v.project_id][task_index].users[user_index].remind = v.remind
                            }
                        })
                    }
                })
            }

            if (this.getters.getCompletedTasks[v.project_id]) {
                this.getters.getCompletedTasks[v.project_id].forEach((task, task_index) => {
                    if (task._id == v.task_id) {
                        task.users.forEach((user, user_index) => {
                            if (user._id == this.getters.getUserData._id) {
                                s.completed_tasks[v.project_id][task_index].users[user_index].remind = v.remind
                            }
                        })
                    }
                })
            }

            if (this.getters.getSubTask.length > 0) {
                s.subtask.forEach((subtask, subtask_index) => {
                    if (subtask._id == v.task_id) {
                        subtask.users.forEach((user, user_index) => {
                            if (user._id == this.getters.getUserData._id) {
                                s.subtask[subtask_index].users[user_index].remind = v.remind
                            }
                        })
                    }
                })
            }

        },
        clearSubtask(s, v) {
            s.subtask = []
        },
        setSubtask(s, v) {
            if (v.length > 0) {
                v.forEach((e, i) => {
                    if (e.parent) {
                        s.subtask.push(e)
                    }
                })
            } else {
                if (v.parent) {
                    s.subtask.forEach( (e, i) => {
                        if (e._id == v._id) {
                            s.subtask.splice(i, 1)
                        }
                    })
                    // EventEmitter.$emit('addSubTask', v)
                    s.subtask.push(v)
                }
            }
        },
        setTasks(s, v) {
            s.tasks = v
        },
        setTaskNoteSize(s, v) {
            s.tasks[v.project_id].forEach((task, i) => {
                if (task._id == v.task_id) {
                    s.tasks[v.project_id][i].users.forEach((user, i2) => {
                        if (user._id == v.user_id) {
                            s.tasks[v.project_id][i].users[i2].note_size = v.size
                        }
                    })
                }
            })
        },
        setPriority(s, v) {
            s.tasks[v.project_id].forEach((task, i) => {
                if (task._id == v.task_id) {
                    let tasks = s.tasks
                    tasks[v.project_id][i].priority = v.priority
                    s.tasks = []
                    s.tasks = tasks
                }
            })
        },
        add_task(s, v) {

            let pr_id = v.project_id

            if (!s.tasks[pr_id]) {
                let tasks = s.tasks
                tasks[pr_id] = []
                s.tasks = []
                s.tasks = tasks
            }

            let findTask = s.tasks[pr_id].some((task) => task._id == v._id);
            if (findTask) {
                s.tasks[pr_id] = s.tasks[pr_id].map((task) => {
                    if (task._id == v._id) {
                        task = { ...v };
                    }
                    return task;
                });
            } else {
                let tasks = s.tasks
                if (this.getters.getUserData.new_task == true) {
                    tasks[pr_id].unshift(v)
                }
                if (this.getters.getUserData.new_task == false) {
                    tasks[pr_id].push(v)
                }
                s.tasks = []
                s.tasks = tasks
            }

        },
        change_flag_for_add_task(s, v) {
            s.flag_for_add_task = v;
        },
        delete_task(s, v) {
            if (s.tasks[v.project_id] && s.tasks[v.project_id].some((task) => task._id == v.task_id)) {
                s.tasks[v.project_id] = s.tasks[v.project_id].filter((task) => task._id != v.task_id)
                s.tasks[v.project_id] = s.tasks[v.project_id].filter((task) => task.parent != v.task_id)
            }
            if (s.subtask && s.subtask.some((task) => task._id == v.task_id)) {
                // let subtask = s.subtask.filter((task) => task._id == v.task_id)
                s.subtask = s.subtask.filter((task) => task._id != v.task_id)

                // let localShow_subtask = localStorage.getItem(v.task_id)
                // let show_subtask = localShow_subtask == 'false' ? false : true

                // if (show_subtask) {
                //     let sub = []
                //     s.subtask.forEach((e, i) => {
                //         if (subtask[0].parent == e.parent) {
                //             sub.push(e)
                //         }
                //     })

                //     let w = sub.length * 38 - 1
                //     document.querySelector(`[data-id="${subtask[0].parent}"] .draggable_tasks.subtask`).style.height = w + 'px'
                // }

            }
            if (s.completed_tasks[v.project_id] && s.completed_tasks[v.project_id].some((task) => task._id == v.task_id)) {
                s.completed_tasks[v.project_id] = s.completed_tasks[v.project_id].filter((task) => task._id != v.task_id);
                s.tasks[v.project_id] = s.tasks[v.project_id].filter((task) => task.parent != v.task_id)
            }
        },
        setCompletedTasks(s, v) {
            s.completed_tasks = v;
        },
        setCompletedTasksClear(s, v) {
            s.completed_tasks[v] = []
        },
        setCompletedTaskPush(s, v) {
            if (s.completed_tasks[v.project_id]) {
                s.completed_tasks[v.project_id].push(v)
            } else {
                s.completed_tasks[v.project_id] = []
                s.completed_tasks[v.project_id].push(v)
            }
        },
        clearTasks(s) {
            s.tasks = [];
        },
        clearCompletedTasks(s) {
            s.completed_tasks = [];
        },
        task_done_from_task(s, v) {

            if (!s.completed_tasks[v.project_id]) {
                s.completed_tasks[v.project_id] = []
            }

            s.completed_tasks[v.project_id].unshift(s.tasks[v.project_id].find((task) => {
                if (task) {
                    if (task._id == v.task_id) {
                        task.done = true
                        return task
                    }
                }
            }))

            s.tasks[v.project_id] = s.tasks[v.project_id].filter((task) => {
                if (task) {
                    if (task._id != v.task_id) {
                        return task
                    }
                }
            })

            s.tasks[v.project_id] = s.tasks[v.project_id].filter((task) => {
                if (task != undefined) {
                    return task
                }
            })

            s.completed_tasks[v.project_id] = s.completed_tasks[v.project_id].filter((task) => {
                if (task != undefined) {
                    return task
                }
            })

            // console.log(s.completed_tasks[v.project_id])
            // console.log(s.tasks[v.project_id])
        },
        task_done_from_completed_tasks(s, v) {
            if (s.completed_tasks[v.project_id]) {

                s.tasks[v.project_id].push(s.completed_tasks[v.project_id].find((task) => {
                    if (task) {
                        if (task._id == v.task_id) {
                            task.done = false
                            return task
                        }
                    }
                }))

                s.completed_tasks[v.project_id] = s.completed_tasks[v.project_id].filter((task) => {
                    if (task) {
                        if (task._id != v.task_id) {
                            return task
                        }
                    }
                })

                s.tasks[v.project_id] = s.tasks[v.project_id].filter((task) => {
                    if (task != undefined) {
                        return task
                    }
                })

                s.completed_tasks[v.project_id] = s.completed_tasks[v.project_id].filter((task) => {
                    if (task != undefined) {
                        return task
                    }
                })

                // console.log(s.completed_tasks[v.project_id])
                // console.log(s.tasks[v.project_id])
            }
        },
        set_notes(s, v) {
            let tasks_notes = s.tasks_notes
            v.forEach((note) => {
                let task_id = note.task_id ? note.task_id : note.task
                if (s.tasks_notes.some((tNote) => tNote.task_id == task_id)) {
                    s.tasks_notes.forEach((e, i) => {
                        if (task_id == e.task_id) {
                            tasks_notes[i].notes = note.notes
                        }
                    })
                } else {
                    tasks_notes.push(note)
                }
                // if (s.tasks_notes.some((tNote) => tNote.task_id == task_id)) {
                //     s.tasks_notes = s.tasks_notes.filter((tNote) => tNote.task_id != task_id)
                // }
                // s.tasks_notes.push(note)
            })
            setTimeout(() => {
                s.tasks_notes = []
                s.tasks_notes = tasks_notes
            }, 500)
        },
        delete_note(s, v) {
            s.tasks_notes = s.tasks_notes.filter((t_note) => t_note != v.task_id);
        },
        change_order(s, v) {

            let project_id = v.project ? v.project : v.project_id

            let tasks = ''
            let subtask = ''

            if (v.new_order.length != 0) {

                v.new_order.forEach((e) => {
                    tasks = s.tasks[project_id].map((task) => {
                        if (task) {
                            if (e.task_id == task._id) {
                                if (e.section_id) {
                                    task.section = e.section_id
                                } else {
                                    task.section = ''
                                }
                                task.order = e.order
                                return task
                            } else {
                                return task
                            }
                        }
                    })
                    subtask = s.subtask.map((task) => {
                        if (e.task_id == task._id) {
                            task.order = e.order
                            return task
                        } else {
                            return task
                        }
                    })
                })

                // tasks.sort( function (a, b) {
                //     return a.order - b.order
                // })

                // console.log(tasks)

                s.tasks[project_id] = tasks
                s.subtask = subtask

            }

            // s.tasks[project_id].forEach((task, i) => {
            //     v.new_order.forEach((e) => {
            //         if (e.task_id == task._id) {
            //             if (e.section_id) {
            //                 s.tasks[project_id][i].section = e.section_id
            //             }
            //         }
            //     })
            // })

            // v.new_order.forEach((o_t) => {
            //     if (s.tasks[project_id].some((e) => e._id == o_t.task_id)) {
            //         s.tasks[project_id].find((e) => e._id == o_t.task_id).order = o_t.order
            //     }
            // })

            // s.tasks[project_id] = s.tasks[project_id].sort(
            //     (a, b) => a.order - b.order
            // );
        },
        change_flag_for_tasks(s) {
            s.flag_for_tasks = !s.flag_for_tasks;
        },
        change_flag_for_note(s) {
            s.flag_for_note = !s.flag_for_note
        },
        change_shortcuts_render(s) {
            s.shortcuts_render = !s.shortcuts_render;
        },
        set_tasks_project_id(s, v) {
            v.forEach((list) => {
                if (!s.tasks[list._id]) {
                    s.tasks[list._id] = []
                }
            })
        },
        set_task_project_id(s, v) {
            if (!s.tasks[v._id]) {
                s.tasks[v._id] = []
            }
        },
        renderNumberUnread(s, v) {
            let bool = true

            if (s.tasks[v.project_id]) {
                s.tasks[v.project_id].forEach((e, i) => {
                    if (e._id == v.task_id) {
                        bool = false
                        s.tasks[v.project_id][i].unread_message = s.tasks[v.project_id][i].unread_message - 1
                        e.users.forEach((u, u_i) => {
                            s.tasks[v.project_id][i].users[u_i].unread_message = s.tasks[v.project_id][i].users[u_i].unread_message - 1
                        })
                    }
                })
            }

            if (bool) {
                if (s.completed_tasks[v.project_id]) {
                    s.completed_tasks[v.project_id].forEach((e, i) => {
                        if (e._id == v.task_id) {
                            bool = false
                            s.completed_tasks[v.project_id][i].unread_message = s.completed_tasks[v.project_id][i].unread_message - 1
                            e.users.forEach((u, u_i) => {
                                s.completed_tasks[v.project_id][i].users[u_i].unread_message = s.completed_tasks[v.project_id][i].users[u_i].unread_message - 1
                            })
                        }
                    })
                }
            }
        },
        //метод добавляет или меняет ключ unread_messages в самом таске - число unread_messages после манипуляций
        // через этот метод - это количество непрочитанных сообщений в таске текущим пользователем
        set_unreaded_messages_in_task(s, v) {

            let tasks = []

            this.getters.getProjects.forEach(e => {
                if (this.getters.getTasks[e._id]) {
                    this.getters.getTasks[e._id].forEach(t => {
                        tasks.push(t)
                    })
                }
                if (this.getters.getCompletedTasks[e._id]) {
                    this.getters.getCompletedTasks[e._id].forEach(t => {
                        tasks.push(t)
                    })
                }
            })

            changeUnreadMessagesInTask(tasks, v);

            //v.data.project массив с id проектов в котором этот таск может распологаться
            //   v.data.project_id.forEach((pr_id) => {

            // let pr_id = v.data.project_id

            // // s.tasks[pr_id].forEach((e, i) => {
            // //     if (e._id == v.data.task_id) {
            // //         v.data = e
            // //     }
            // // })

            // let user_data = v.data.users.filter(e => e._id == this.getters.getUserData._id)

            // if (s.tasks[pr_id]) {
            //     changeUnreadMessagesInTask(s.tasks[pr_id], v);
            //     // s.tasks[pr_id].forEach((e, i) => {
            //     //     if (e._id == v._id) {
            //     //         e.users.forEach((user, user_i) => {
            //     //             if (user._id == this.getters.getUserData._id) {
            //     //                 s.tasks[pr_id][i].unread_message = user_data.unread_message
            //     //                 s.tasks[pr_id][i].users[user_i].unread_message = user_data.unread_message
            //     //             }
            //     //         })
            //     //     }
            //     // })
            // } else if (s.completed_tasks[pr_id]) {
            //     // changeUnreadMessagesInTask(s.completed_tasks[pr_id], v);
            //     s.completed_tasks[pr_id].forEach((e, i) => {
            //         if (e._id == v._id) {
            //             e.users.forEach((user, user_i) => {
            //                 if (user._id == this.getters.getUserData._id) {
            //                     s.completed_tasks[pr_id][i].unread_message = user_data.unread_message
            //                     s.completed_tasks[pr_id][i].users[user_i].unread_message = user_data.unread_message
            //                 }
            //             })
            //         }
            //     })
            // }
            //   });
        },
        set_active_task_window(s, v) {
            s.active_task_window = v;
        },
        set_change_task(s, v) {
            s.task[v.list_id] = v.tasks
        },
        rename_task(s, v) {
            if (s.tasks[v.project_id]) {
                s.tasks[v.project_id] = s.tasks[v.project_id].map((task) => {
                    if (task._id == v.task_id) task.name = v.name
                    return { ...task }
                })
            }
            s.subtask.forEach((e, i) => {
                if (e._id == v.task_id) {
                    s.subtask[i].name = v.name
                }
            })
            if (s.completed_tasks[v.project_id]) {
                s.completed_tasks[v.project_id] = s.completed_tasks[v.project_id].map((task) => {
                    if (task._id == v.task_id) task.name = v.name
                    return { ...task }
                })
            }
        },
        setRealUnreadMessagesToZero(state, {task_id, project_id}) {
            // const task = state.tasks.find(task => task_id == task_id)
            let tempTasks = state.tasks[project_id]
            if (tempTasks) {
                tempTasks.find(task => {
                    if (task._id == task_id) {
                        task.real_unread_messages = 0
                        return true
                    }
                })
                state.tasks[project_id] = tempTasks
            }
        }
    },
    getters: {
        getAssigns: (s) => s.assigns,
        getTasks: (s) => s.tasks,
        getSubTask: (s) => {
            if (s.subtask.length == 1) {
                return s.subtask
            } else {
                return s.subtask.filter(e => e._id != s.subtask.map(a => a._id))
            }
        },
        get_flag_for_note: (s) => s.flag_for_note,
        getCompletedTasks: (s) => s.completed_tasks,
        get_notes: (s) => s.tasks_notes,
        get_flag_for_tasks: (s) => s.flag_for_tasks,
        get_active_task_window: (s) => s.active_task_window,
        get_flag_for_add_task: (s) => s.flag_for_add_task,
        get_change_shortcuts_render: (s) => s.shortcuts_render
    },
};

function changeUnreadMessagesInTask(tasks, v) {
    // tasks - массив из тасков
    const task = tasks.find((task) => task._id == v.data._id)
    tasks = tasks.filter((task) => task._id != v.data._id)
    v.data.users.forEach((user) => {
        if (user._id == v.user._id) task.unread_message = user.unread_message
        if (task) {
            if (task.users.find((us) => us._id == user._id)) {
                task.users.find((us) => us._id == user._id).unread_message = user.unread_message
            }
        }
    })
    tasks.push(task)
}
