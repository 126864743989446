<template>
    <div class="verification__page">
        <div>{{text}}</div>
    </div>
</template>

<script>

    import { mapGetters, mapMutations, mapActions } from "vuex";

    export default {
        data() {
            return {
                text: 'Loading...'
            }
        },
        computed: {
            ...mapGetters([
                "getUserData"
            ]),
            ...mapMutations([
                "setMessage"
            ])
        },
        methods: {
            ...mapActions([
                "fetch_request"
            ])
        },
        async mounted() {

            if (this.$route.params.code) {
                if (this.getUserData.verification) {
                    this.text = 'Your email is already verified'
                } else {
                    const res = {
                        token: false,
                        method: "POST",
                        url: this.$w_api.POST_Verification,
                        body: {
                            code: this.$route.params.code
                        }
                    }

                    const req = await this.fetch_request(res)

                    if (req.ok) {
                        const reqJSON = await req.json()
                        setTimeout(() => {
                            this.text = reqJSON.message
                        }, 2000)
                    } else {
                        this.setMessage({
                            key_message: "warning",
                            message: 'Not verifed',
                        })
                    }

                }
            }

        }
    }

</script>