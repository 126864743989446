<template>
    <div class="custom_message_popup" :class="show ? 'active' : ''">
        <div>
            <div class="text" v-html="data.text"></div>
            <label v-if="leave">
                <el-checkbox v-model="delete_all_files"></el-checkbox>
                <span>Delete my attachments from this list</span>
            </label>
            <label v-if="type == 'section'">
                <el-checkbox v-model="delete_all_files"></el-checkbox>
                <span>Delete all tasks in this section</span>
            </label>
            <div class="call_to_action_footer">
                <button @click="closePopup"
                    :class="data.class && data.class.cancel ? data.class.cancel : ''">{{ data.cancel }}</button>
                <button @click="customAction" v-if="data.accept"
                    :class="data.class && data.class.accept ? data.class.accept : ''">{{ data.accept }}</button>
                <button @click="deleteData" v-if="data.delete"
                    :class="data.class && data.class.delete ? data.class.delete : 'red'">{{ data.delete }}</button>
            </div>
        </div>
    </div>
</template>

<script>

import { EventEmitter } from '../../main'
import { mapGetters, mapActions, mapMutations } from "vuex";
import File from '../../utils/aws'

export default {
    data() {
        return {
            show: false,
            folder: '',
            type: '',
            list: {
                list: '',
                bool: ''
            },
            chat: {},
            delete_all_files: false,
            leave: false,
            data: {
                text: '',
                cancel: 'Cancel',
                delete: '',
                action: () => { }
            }
        }
    },
    computed: {
        ...mapGetters([
            "getUserData",
            "getLists",
            "getProjects",
            "getTasks",
            "getComments",
            "getCompletedTasks",
            "getSubTask",
            "getChats"
        ]),
    },
    mounted() {

        EventEmitter.$on('OpenPopup', (data) => {
            this.show = true
            this.data = data.data
            this.type = data.type
            this.leave = false
            this.chat = data.chat
            if (data.type == 'list') {
                this.list = {
                    list: data.list,
                    bool: data.bool
                }
            } else if (data.type == 'folder') {
                this.folder = data.folder
            } else if (data.type == 'leave') {
                this.leave = true
                this.list = {
                    list: data.list
                }
            } else if (data.type == 'section') {
                this.list = {
                    list: data.list
                }
            } else if (data.type == 'task_remove') {
                this.list = {
                    list: data.list
                }
            } else if (data.type == 'add_list_offline') {
                this.list = {
                    list: data.list
                }
            } else if (data.type == 'msg_remove') {
                this.list = {
                    list: data.list
                }
            }
        })

    },
    methods: {
        ...mapActions([
            "remove_folder",
            "socket_remove_user_from_project",
            "socket_delete_message"
        ]),
        ...mapMutations([
            "setUserFileSize_minus",
            "set_remove_list"
        ]),
        closePopup() {
            this.show = false
            this.delete_all_files = false
            if (localStorage.getItem('new_version')) {
                localStorage.removeItem('new_version')
            }
        },
        deleteData() {
            if (this.type == 'folder') {
                this.remove_folder(this.folder)
            } else if (this.type == 'list') {
                // alert(1)

                const linkedChat = this.getChats.find(chat => chat.project_id == this.list.list._id)
                // console.log(this.list, 'projecT?????????', linkedChat)

                if (linkedChat) {
                    this.closePopup()

                    setTimeout(() => {
                        let data = {
                            type: 'listHasChat',
                            data: {
                                text: `This list is linked to ${linkedChat.name}. Unlink, leave or delete the chat and then you will be able to delete this list`,
                                cancel: 'Ok',
                                // accept: 'Proceed',
                                action: () => { }
                            },
                            list: this.task_id
                        }

                        EventEmitter.$emit('OpenPopup', data)
                    }, 300)
                    return
                }

                if (this.$route.params.project_id == this.list.list._id) {
                    let bool = true
                    if (this.getLists['list']) {
                        this.getLists['list'].forEach((e, i) => {
                            if (e._id == this.list.list._id) {
                                bool = false
                                if (this.getLists['list'][i + 1]) {
                                    this.$router.push('/project/' + this.getLists['list'][i + 1]._id)
                                } else {
                                    if (this.getLists['list'][i - 1]) {
                                        this.$router.push('/project/' + this.getLists['list'][i - 1]._id)
                                    } else {
                                        this.$router.push('/no-lists')
                                    }
                                }
                            }
                        })
                    }
                    if (bool) {
                        if (this.getLists['folder']) {
                            this.getLists['folder'].forEach((folder) => {
                                console.log(folder)
                                folder.lists.forEach((list, i) => {
                                    if (list._id == this.list.list._id) {
                                        if (folder.lists[i + 1]) {
                                            this.$router.push('/project/' + folder.lists[i + 1]._id)
                                        } else {
                                            if (folder.lists[i - 1]) {
                                                this.$router.push('/project/' + folder.lists[i - 1]._id)
                                            } else {
                                                if (this.getLists['list'].length > 0) {
                                                    this.$router.push('/project/' + this.getLists['list'][this.getLists['list'].length - 1]._id)
                                                } else {
                                                    this.$router.push('/no-lists')
                                                }
                                            }
                                        }
                                    }
                                })
                            })
                        } else {
                            this.$router.push('/no-lists')
                        }
                    }
                }
                let data = {
                    data: this.list.list,
                    bool: this.list.bool
                }

                const aws = new File()
                const remove = aws.removeFolder('Lists' + '/' + this.list.list._id)

                this.$socket.emit('IN_ProjectDelete', {
                    _id: this.list.list._id
                })

                EventEmitter.$emit('DeleteLists', data)

            } else if (this.type == 'leave') {
                // alert(2)

                const project = this.getProjects.find(project => project._id == this.list.list._id)
                const linkedChat = this.getChats.find(chat => chat.project_id == this.list.list._id)
                console.log(this.list, 'projecT?????????', project, linkedChat)

                if (linkedChat) {
                    this.closePopup()

                    setTimeout(() => {
                        let data = {
                            type: 'listHasChat',
                            data: {
                                text: `This list is linked to ${linkedChat.name}. Unlink, leave or delete the chat and then you will be able to leave this list`,
                                cancel: 'Ok',
                                // accept: 'Proceed',
                                action: () => { }
                            },
                            list: this.task_id
                        }

                        EventEmitter.$emit('OpenPopup', data)
                    }, 300)
                    return
                }

                if (this.delete_all_files) {
                    this.getProjects.forEach((list) => {
                        if (list._id == this.list.list._id) {
                            const aws = new File()
                            if (this.getTasks[list._id]) {
                                this.getTasks[list._id].forEach((task) => {
                                    if (this.getComments[task._id]) {
                                        this.getComments[task._id].forEach((msg) => {
                                            if (msg.user_id == this.getUserData._id) {
                                                if (msg.files.length > 0) {
                                                    msg.files.forEach((file) => {
                                                        // this.setUserFileSize_minus(+file.size)
                                                        if (file.ext.toLowerCase() == 'png' || file.ext.toLowerCase() == 'jpg' || file.ext.toLowerCase() == 'jpeg') {
                                                            if (file.imgWidth > 200) {
                                                                let sizes = [200, 'full']
                                                                sizes.forEach((size) => {
                                                                    let r = aws.remove('Lists' + '/' + list._id + '/' + task._id + '/' + size + '__' + file._id + '__' + file.filename)
                                                                })
                                                            } else {
                                                                let r = aws.remove('Lists' + '/' + list._id + '/' + task._id + '/' + file._id + '__' + file.filename)
                                                            }
                                                        } else {
                                                            let r = aws.remove('Lists' + '/' + list._id + '/' + task._id + '/' + file._id + '__' + file.filename)
                                                        }
                                                    })
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })
                }

                const remove_users = {
                    users: [this.getUserData],
                    project_id: this.list.list._id,
                    delete_all_files: this.delete_all_files,
                    leave: true
                }

                this.socket_remove_user_from_project(remove_users)
                // EventEmitter.$emit('DeleteLists', data)
                if (this.$route.params.project_id == this.list.list._id) {
                    let bool = true
                    if (this.getLists['list']) {
                        this.getLists['list'].forEach((e, i) => {
                            if (e._id == this.list.list._id) {
                                bool = false
                                if (this.getLists['list'][i + 1]) {
                                    this.$router.push('/project/' + this.getLists['list'][i + 1]._id)
                                } else {
                                    if (this.getLists['list'][i - 1]) {
                                        this.$router.push('/project/' + this.getLists['list'][i - 1]._id)
                                    } else {
                                        this.$router.push('/no-lists')
                                    }
                                }
                            }
                        })
                    }
                    if (bool) {
                        if (this.getLists['folder']) {
                            this.getLists['folder'].forEach((folder) => {
                                folder.lists.forEach((list, i) => {
                                    if (list._id == this.list.list._id) {
                                        if (folder.lists[i + 1]) {
                                            this.$router.push('/project/' + folder.lists[i + 1]._id)
                                        } else {
                                            if (folder.lists[i - 1]) {
                                                this.$router.push('/project/' + folder.lists[i - 1]._id)
                                            } else {
                                                if (this.getLists['list'].length > 0) {
                                                    this.$router.push('/project/' + this.getLists['list'][this.getLists['list'].length - 1]._id)
                                                } else {
                                                    this.$router.push('/no-lists')
                                                }
                                            }
                                        }
                                    }
                                })
                            })
                        } else {
                            this.$router.push('/no-lists')
                        }
                    }
                }
                this.delete_all_files = false
                this.set_remove_list({
                    _id: this.list.list._id
                })
            } else if (this.type == 'section') {
                EventEmitter.$emit('removeThisSection', {
                    section_id: this.list.list,
                    remove_tasks: this.delete_all_files
                })
            } else if (this.type == 'task_remove') {
                EventEmitter.$emit('removeTask', this.list.list)
            } else if (this.type == 'add_list_offline') {
                EventEmitter.$emit('replyAddList', this.list.list)
            } else if (this.type == 'msg_remove') {

                const aws = new File()

                if (this.list.list.files) {
                    this.list.list.files.forEach((e) => {
                        this.setUserFileSize_minus(+e.size);
                        if (e.ext.toLowerCase() == 'png' || e.ext.toLowerCase() == 'jpg' || e.ext.toLowerCase() == 'jpeg') {

                            if (e.imgWidth > 200) {
                                let sizes = [200, 'full']
                                sizes.forEach((size) => {
                                    const remove = aws.remove('Lists' + '/' + this.list.list.project_id + '/' + this.list.list.task_id + '/' + size + '__' + e._id + '__' + e.filename)
                                })
                            } else {
                                const remove = aws.remove('Lists' + '/' + this.list.list.project_id + '/' + this.list.list.task_id + '/' + e._id + '__' + e.filename)
                            }

                        } else {
                            const remove = aws.remove('Lists' + '/' + this.list.list.project_id + '/' + this.list.list.task_id + '/' + e._id + '__' + e.filename)
                        }
                    })
                } else {
                    this.list.list.files_body.forEach((e) => {
                        this.setUserFileSize_minus(+e.size);
                        if (e.ext.toLowerCase() == 'png' || e.ext.toLowerCase() == 'jpg' || e.ext.toLowerCase() == 'jpeg') {
                            if (e.imgWidth > 200) {
                                let sizes = [200, 'full']
                                sizes.forEach((size) => {
                                    const remove = aws.remove('Lists' + '/' + this.list.list.project_id + '/' + this.list.list.task_id + '/' + size + '__' + e._id + '__' + e.filename)
                                })
                            } else {
                                const remove = aws.remove('Lists' + '/' + this.list.list.project_id + '/' + this.list.list.task_id + '/' + e._id + '__' + e.filename)
                            }
                        } else {
                            const remove = aws.remove('Lists' + '/' + this.list.list.project_id + '/' + this.list.list.task_id + '/' + e._id + '__' + e.filename)
                        }
                    })
                }

                this.socket_delete_message({
                    task_id: this.list.list.task_id,
                    chat_id: this.list.list._id,
                    user_id: this.getUserData._id,
                    project_id: this.list.list.project_id,
                    comment: this.list.list
                })

            } else if (this.type == 'chat_leave') {
                this.$socket.emit('IN_RemoveUserChats', { remove_users: [], chat_id: this.chat._id })
            } else if (this.type == 'chat_delete') {
                this.$socket.emit('IN_RemoveChats', { chat_id: this.chat._id })
            }
            this.show = false
        },
        customAction() {
            if (this.data.action) {
                this.data.action()
            }
            this.closePopup()
        }
    }
}
</script>