<template>
    <div class="projects">

        <div v-if="tab == 'Lists'" style="height: 100%">
            <ul class="shortcuts__lists" v-if="shortcuts.length > 0">
                <li v-for="(shortcut, i) in shortcuts" :key="i" :class="shortcut.type">
                    <router-link :to="shortcut.type == 'inbox' ? '/inbox/' + getUserData.shortcut_inbox : '/shortcuts/' + shortcut.type" >
                        <div class="project_thumbnail shortcut_icon" :style="`background-color: ${shortcut.color}`">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="shortcut.type == 'inbox'">
                                <g clip-path="url(#clip0_0_191)">
                                    <path d="M15.7905 0.476196H4.20953C2.14767 0.476196 0.476196 2.14767 0.476196 4.20953V15.7905C0.476196 17.8523 2.14767 19.5238 4.20953 19.5238H15.7905C17.8523 19.5238 19.5238 17.8523 19.5238 15.7905V4.20953C19.5238 2.14767 17.8523 0.476196 15.7905 0.476196Z" stroke="white" stroke-miterlimit="10"/>
                                    <path d="M19.5238 12.7619H11.8095" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                                    <path d="M7.4381 12.7619H0.476196" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                                    <path d="M7.43811 12.7619C7.41835 13.0611 7.46022 13.3611 7.56112 13.6434C7.66202 13.9258 7.8198 14.1844 8.0247 14.4033C8.2296 14.6221 8.47725 14.7966 8.75232 14.9159C9.02738 15.0352 9.32401 15.0967 9.62383 15.0967C9.92364 15.0967 10.2203 15.0352 10.4953 14.9159C10.7704 14.7966 11.0181 14.6221 11.223 14.4033C11.4278 14.1844 11.5856 13.9258 11.6865 13.6434C11.7874 13.3611 11.8293 13.0611 11.8095 12.7619" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_191">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="shortcut.type == 'today'">
                                <g clip-path="url(#clip0_0_170)">
                                    <path d="M7.04761 2.49524H8.05713H12.0571H12.9524" stroke="white" stroke-miterlimit="10"/>
                                    <path d="M15.0381 2.49524H16.7048C17.0584 2.47202 17.4131 2.51901 17.7484 2.6335C18.0838 2.74799 18.3932 2.92771 18.6588 3.16232C18.9244 3.39692 19.1409 3.68176 19.2959 4.00043C19.4509 4.31909 19.5413 4.66529 19.5619 5.01905V6.80953H0.476196V5.01905C0.496797 4.66529 0.587204 4.31909 0.742207 4.00043C0.89721 3.68176 1.11374 3.39692 1.37933 3.16232C1.64492 2.92771 1.95431 2.74799 2.28967 2.6335C2.62503 2.51901 2.97974 2.47202 3.33334 2.49524H4.96191" stroke="white" stroke-miterlimit="10"/>
                                    <path d="M19.5238 6.80951V17C19.5032 17.3538 19.4128 17.6999 19.2578 18.0186C19.1028 18.3373 18.8863 18.6221 18.6207 18.8567C18.3551 19.0913 18.0457 19.271 17.7104 19.3855C17.375 19.5 17.0203 19.547 16.6667 19.5238H3.29525C2.94165 19.547 2.58695 19.5 2.25158 19.3855C1.91622 19.271 1.60683 19.0913 1.34125 18.8567C1.07566 18.6221 0.859124 18.3373 0.704121 18.0186C0.549118 17.6999 0.458711 17.3538 0.43811 17V6.80951H19.5238Z" stroke="white" stroke-miterlimit="10"/>
                                    <path d="M7.04764 1.40952V3.58095C7.04818 3.71357 7.02101 3.84484 6.96788 3.96635C6.91475 4.08786 6.83683 4.19694 6.73911 4.2866C6.64138 4.37625 6.52601 4.44451 6.40038 4.487C6.27475 4.52948 6.14163 4.54526 6.00954 4.53333C5.87671 4.54668 5.74255 4.53196 5.61578 4.49011C5.489 4.44826 5.37244 4.38023 5.27365 4.29043C5.17487 4.20062 5.09607 4.09105 5.04237 3.96883C4.98866 3.8466 4.96126 3.71445 4.96193 3.58095V1.40952C4.96126 1.27602 4.98866 1.14386 5.04237 1.02164C5.09607 0.899413 5.17487 0.789843 5.27365 0.700038C5.37244 0.610234 5.489 0.542202 5.61578 0.500356C5.74255 0.45851 5.87671 0.443785 6.00954 0.457137C6.27969 0.45561 6.54086 0.553975 6.74288 0.733328C6.84107 0.816021 6.91944 0.919715 6.97219 1.03675C7.02493 1.15379 7.05072 1.28118 7.04764 1.40952V1.40952Z" stroke="white" stroke-miterlimit="10"/>
                                    <path d="M15.0381 1.40952V3.58095C15.0133 3.84034 14.8927 4.08117 14.6999 4.25643C14.5071 4.43169 14.2558 4.5288 13.9953 4.5288C13.7347 4.5288 13.4835 4.43169 13.2906 4.25643C13.0978 4.08117 12.9772 3.84034 12.9524 3.58095V1.40952C12.9519 1.2769 12.979 1.14563 13.0322 1.02412C13.0853 0.902608 13.1632 0.79353 13.2609 0.703874C13.3587 0.614219 13.474 0.545961 13.5997 0.503474C13.7253 0.460987 13.8584 0.445206 13.9905 0.457143C14.2636 0.455061 14.5279 0.553346 14.7334 0.733334C14.8295 0.817721 14.9065 0.92174 14.9591 1.03838C15.0116 1.15503 15.0386 1.28158 15.0381 1.40952V1.40952Z" stroke="white" stroke-miterlimit="10"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_170">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="shortcut.type == 'assigned_to_me'">
                                <g clip-path="url(#clip0_0_181)">
                                    <path d="M15.7905 0.476196H4.20953C2.14767 0.476196 0.476196 2.14767 0.476196 4.20953V15.7905C0.476196 17.8523 2.14767 19.5238 4.20953 19.5238H15.7905C17.8523 19.5238 19.5238 17.8523 19.5238 15.7905V4.20953C19.5238 2.14767 17.8523 0.476196 15.7905 0.476196Z" stroke="white" stroke-miterlimit="10"/>
                                    <path d="M10 16.3238C13.4926 16.3238 16.3238 13.4925 16.3238 9.99999C16.3238 6.50744 13.4926 3.67618 10 3.67618C6.50747 3.67618 3.67621 6.50744 3.67621 9.99999C3.67621 13.4925 6.50747 16.3238 10 16.3238Z" stroke="white" stroke-miterlimit="10"/>
                                    <path d="M6.78094 9.27618L9.40952 11.9048" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M9.40955 11.9048L13.2191 8.09525" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_0_181">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="shortcut.type == 'high_priority'">
                                <path d="M17.46 0.5H3.54C1.86105 0.5 0.5 1.86105 0.5 3.54V17.46C0.5 19.1389 1.86105 20.5 3.54 20.5H17.46C19.1389 20.5 20.5 19.1389 20.5 17.46V3.54C20.5 1.86105 19.1389 0.5 17.46 0.5Z" stroke="white" stroke-miterlimit="10"/>
                                <path d="M10.51 2.52H10.5C9.70471 2.52 9.06 3.16471 9.06 3.96V12.8C9.06 13.5953 9.70471 14.24 10.5 14.24H10.51C11.3053 14.24 11.95 13.5953 11.95 12.8V3.96C11.95 3.16471 11.3053 2.52 10.51 2.52Z" stroke="white" stroke-miterlimit="10"/>
                                <path d="M10.5 18.48C11.2953 18.48 11.94 17.8353 11.94 17.04C11.94 16.2447 11.2953 15.6 10.5 15.6C9.70471 15.6 9.06 16.2447 9.06 17.04C9.06 17.8353 9.70471 18.48 10.5 18.48Z" stroke="white" stroke-miterlimit="10"/>
                            </svg>
                            <svg width="20" height="20" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="shortcut.type == 'medium_priority'">
                                <path d="M17.46 0.5H3.54C1.86105 0.5 0.5 1.86105 0.5 3.54V17.46C0.5 19.1389 1.86105 20.5 3.54 20.5H17.46C19.1389 20.5 20.5 19.1389 20.5 17.46V3.54C20.5 1.86105 19.1389 0.5 17.46 0.5Z" stroke="white" stroke-miterlimit="10"/>
                                <path d="M4.68998 12.02C5.52946 12.02 6.20998 11.3395 6.20998 10.5C6.20998 9.66052 5.52946 8.98 4.68998 8.98C3.85051 8.98 3.16998 9.66052 3.16998 10.5C3.16998 11.3395 3.85051 12.02 4.68998 12.02Z" stroke="white" stroke-miterlimit="10"/>
                                <path d="M10.5 12.02C11.3395 12.02 12.02 11.3395 12.02 10.5C12.02 9.66052 11.3395 8.98 10.5 8.98C9.66051 8.98 8.97998 9.66052 8.97998 10.5C8.97998 11.3395 9.66051 12.02 10.5 12.02Z" stroke="white" stroke-miterlimit="10"/>
                                <path d="M16.31 12.02C17.1495 12.02 17.83 11.3395 17.83 10.5C17.83 9.66052 17.1495 8.98 16.31 8.98C15.4705 8.98 14.79 9.66052 14.79 10.5C14.79 11.3395 15.4705 12.02 16.31 12.02Z" stroke="white" stroke-miterlimit="10"/>
                            </svg>
                            <span v-if="shortcut.type == 'today'">{{nowDay}}</span>
                        </div>
                        <div class="project_name_body no_messages">
                            <div class="project_name">
                                {{ shortcut.type[0].toUpperCase() }}{{ shortcut.type.replaceAll('_', ' ').replace(shortcut.type[0], '') }}
                            </div>
                            <div class="last_comment_in_project">
                                <p class="last_comment_in_project__user_name num_task">
                                    {{ shortcut.task_length == 0 ? 'No ' : shortcut.task_length }} tasks
                                </p>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
            <!-- <NotFound text="No shortcuts so far" v-else /> -->
            <NotFound text="No lists so far" v-if="lists.length == 0 && folders.length == 0 && shortcuts.length == 0" />
            <ul class="lists" v-else>

                <div>
                    <draggable :list="lists" @change="changeLists" group="list" v-bind="dragOptions" class="lists_block">
                        <!-- @contextmenu.prevent="listContextMenu(project._id, $event, true)" -->
                        <li
                            v-for="(project, i) of lists"
                            :key="i"
                            :ref="'project_' + project._id"
                            @contextmenu.prevent="listContextMenu(project, true, $event)"
                        >

                            <router-link :to="linkLists(project._id)" v-if="project._id != getUserData.shortcut_inbox && !project.folder" class="li" :data-list-id="project._id">
                                <div style="display: flex;align-items: center;" class="list_body-content">
                                    <div
                                        class="project_thumbnail"
                                        :style="`background-color: ${project.color ? project.color : '#E58A8A'}`"
                                    >
                                        {{ project_name_thumbnail(project.name) }} 
                                    </div>
                                    <div class="project_name_body" :data-id="project._id" @dragover="onDragBgColor($event)" @drop="onDragOver(project._id, $event)" :class="get_unreaded_messages_in_project[project._id] > 0 ? '' : 'no_messages'">
                                        <div class="project_name" v-if="!project.rename">
                                            {{ project.name }}
                                        </div>
                                        <div class="project_name" v-else>
                                            <input type="text" v-model="project.name" @keypress.enter="changeNameList(project)" @blur="changeNameList(project)">
                                        </div>
                                        <div class="last_comment_in_project">
                                            <p class="last_comment_in_project__user_name num_task">
                                                {{ task_length[project._id] == 0 ? 'No ' : task_length[project._id] }} tasks
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="get_unreaded_messages_in_project[project._id] > 0" class="unread-container">
                                    <p 
                                        v-if="getListsMentions[project._id] && getListsMentions[project._id].mentions > 0"
                                        class="unread-container__mention"
                                    >
                                        @
                                    </p>
                                    <p class="unread-container__count">
                                        {{ unreaded_msg[project._id] }}
                                    </p>
                                </div>
                            </router-link>

                        </li>
                    </draggable>

                    <draggable :list="folders" @change="changeLists" group="folder" v-bind="dragOptions" class="folder__lists">
                        <!-- @contextmenu.prevent="listContextMenu(project._id, $event, true)" -->
                        <li
                            v-for="(project, i) of folders"
                            :key="i"
                            :ref="'project_' + project._id"
                            @contextmenu.prevent="listContextMenu(project, false, $event)"
                        >
                            
                            <div class="folder" v-if="project.folder" :class="project.open ? 'active' : ''">
                                <div class="folder__flex" :class="project.name == '' ? 'border-none' : ''" @click.prevent="openFolder(project._id)">
                                    <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.25529 1.61394C0.744397 0.957081 1.21249 0 2.04464 0L11.9554 0C12.7875 0 13.2556 0.957083 12.7447 1.61394L7.78935 7.98512C7.38899 8.49987 6.61101 8.49986 6.21065 7.98512L1.25529 1.61394Z" fill="#A06060"/>
                                    </svg>
                                    <p v-if="project.name != '' && !project.rename">{{project.name}}</p>
                                    <input type="text" maxlength="18" v-else-if="project.rename" @keypress.enter="changeNameFolder(project)" v-model="project.name" @blur="changeNameFolder(project)">
                                    <input type="text" maxlength="18" v-else @keypress.enter="createFolder(project)" v-model="folder_name" @blur="blurFolderInput(project._id)">
                                </div>
                                <div class="folder__drag-drop" v-if="project.open && project.lists.length == 0">
                                    Drag & drop lists here
                                </div>
                                <draggable v-model="project.lists" group="list" class="folder__drag" v-if="project.open" @change="changeFolderList(project._id, $event)" v-bind="dragOptions">
                                    <li
                                        v-for="list in project.lists"
                                        :key="list._id"
                                        :ref="'project_' + list._id"
                                        @click="SearchResultNull(list._id)"
                                        @contextmenu.prevent="listContextMenu(list, false, $event)"
                                    >
                                        <router-link :to="linkLists(list._id)" v-if="list._id != getUserData.shortcut_inbox" class="li" :data-list-id="list._id">
                                            <div style="display: flex;align-items: center;" class="list_body-content">
                                                <div
                                                    class="project_thumbnail"
                                                    :style="`background-color: ${list.color ? list.color : '#E58A8A'}`"
                                                >
                                                    {{ project_name_thumbnail(list.name) }}
                                                </div>
                                                <div class="project_name_body" :data-id="list._id" @dragover="onDragBgColor($event)" @drop="onDragOver(list._id, $event)" :class="get_unreaded_messages_in_project[list._id] > 0 ? '' : 'no_messages'">
                                                    <div class="project_name" v-if="!list.rename">
                                                        {{ list.name }}
                                                    </div>
                                                    <div class="project_name" v-else>
                                                        <input type="text" v-model="list.name" @keypress.enter="changeNameList_toFolder(list)" @blur="changeNameList_toFolder(list)">
                                                    </div>
                                                    <div class="last_comment_in_project"> 
                                                        <p class="last_comment_in_project__user_name num_task">
                                                            {{ task_length[list._id] == 0 ? 'No ' : task_length[list._id] }} tasks
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="get_unreaded_messages_in_project[list._id] > 0" class="unread-container">
                                                <p 
                                                    v-if="getListsMentions[list._id] && getListsMentions[list._id].mentions > 0"
                                                    class="unread-container__mention"
                                                >
                                                    @
                                                </p>
                                                <p class="unread-container__count">
                                                    {{ unreaded_msg[list._id] }}
                                                </p>
                                            </div>
                                        </router-link>
                                    </li>
                                </draggable>
                            </div>

                        </li>
                    </draggable>
                </div>

                <li class="list_menu" @contextmenu.prevent="listContextMenu(false, true, $event)"></li>
            </ul>
        </div>

        <ChatsLists v-if="tab == 'Chats'"></ChatsLists>
        <ListContextMenu v-if="show_list_context_menu"></ListContextMenu>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import draggable from "vuedraggable";
import {EventEmitter} from "../../main"

import AddProject from "@/components/projects/AddProject";
import NotFound from '@/components/app/NotFound'
import ChatsLists from '../chats/ChatsLists.vue'

import ListContextMenu from './ListContextMenu.vue'

export default {
  name: "ProjectsView",
  props: {
    toggleCollapse: [Boolean],
  },
  data() {
    return {
      hover: {},
      unreaded_msg: {},
      projects: [],
      last_chats: {},
      shortcuts: [],
      tab: 'Lists',
      show_list_context_menu: false,
      folder_show: [],
      folder_name: '',
      lists: [],
      folders: [],
      folder_lists: [],
      delete_send_one: true,
      nowDay: new Date().getDate(),
      show: {
          chats: false,
          lists: false
      },
      task_move_bool: true,
      task_move: '',
        task_length: [],
      unread_number: []
    };
  },
  methods: {
    ...mapActions(["delete_project", "render_lists", "change_lists_order"]),
    ...mapMutations(["setProjects", "setFolder", "setLists", "setMessage", "lists_order", "addTask", "removeTask", "setNumberOfTasks", "setSortedLists"]),
    last_message_width() {
        this.getProjects.forEach((project) => {
            if (document.querySelector(`.li[data-list-id="${project._id}"]`)) {
                let unread_width = document.querySelector(`[data-list-id="${project._id}"] .unread-container`) ? document.querySelector(`.li[data-list-id="${project._id}"] .unread-container`).clientWidth : 0
                document.querySelector(`.li[data-list-id="${project._id}"] .project_name`).style.width = (223 - 60) - unread_width + 'px'
            }
        })
    },
    renderAllProject() {

        let arr = []
        let arrayListsFolder = []

        arr['folder'] = []
        arr['list'] = []

        let projects = this.getProjects.filter(e => e._id != this.getUserData.shortcut_inbox)

        try {
                
            if (projects.length > 0) {
                projects.forEach((list, list_index) => {
                    if (this.getUserData.folder.length > 0) {
                        this.getUserData.folder.forEach((e) => {
                            e.folder = true
                            e.open = false
                            e.lists.forEach((list_id, i) => {
                                if (list_id == list._id) {
                                    e.lists[i] = ''
                                    // delete list.sections
                                    // delete list.tasks
                                    // delete list.date
                                    // delete list.__v
                                    // delete list.author
                                    // delete list.order_by
                                    // list._id = list_index
                                    e.lists[i] = list
                                    arrayListsFolder.push(list_id)
                                }
                            })
                            arr['folder'][e.order] = e
                        })
                    } else {
                        list.users.forEach((user) => {
                            if (user._id == this.getUserData._id) {
                                // list.order = user.order
                                if (arr['list'][user.order]) {
                                    if (arr['list'][user.order]._id != list._id) {
                                        equalsOrderList.push(list)
                                        arr['list'][Number(user.order) + list_index] = list
                                    }
                                } else {
                                    arr['list'][user.order] = list
                                }
                            }
                        })
                    }

                    const even = (e) => e == list._id

                    if (!arrayListsFolder.some(even)) {
                        list.users.forEach((user) => {
                            if (user._id == this.getUserData._id) {
                                // list.order = user.order
                                if (arr['list'][user.order]) {
                                    equalsOrderList.push(list)
                                    if (arr['list'][user.order]._id != list._id) {
                                        arr['list'][Number(user.order) + list_index] = list
                                    }
                                } else {
                                    arr['list'][user.order] = list
                                }
                            }
                        })
                    }

                })
            } else {
                if (this.getUserData.folder.length > 0) {
                    this.getUserData.folder.forEach((e) => {
                        e.folder = true
                        e.open = false
                        arr['folder'][e.order] = e
                    })
                }
            }

            // arr['list'].sort( function (a, b) {
            //     return a.order - b.order
            // })
            
            this.lists = arr['list']
            this.folders = arr['folder']
        } catch (error) {
            // console.log(error)
        }
    },
    project_name_thumbnail(name) {

        if (name) {
            let array_name = name.trim().replaceAll('-', '').split(' ')
            array_name = array_name.filter(e => e != '')
            if (array_name.length >= 2) {
                return String(array_name[0])[0] + '' + String(array_name[1])[0]
            } else {
                return String(array_name[0])[0]
            }
        }

    },
    projectScroll() {
        setTimeout(() => {
            if (document.querySelector('.projects')) {
                if (document.querySelector('.lists_block')) {
                    let height_project = document.querySelector('.projects').clientHeight
                    let height_lists = document.querySelector('.lists_block').clientHeight
                    let folder__lists = document.querySelector('.folder__lists').clientHeight

                    if (height_project > height_lists + folder__lists) {
                        document.querySelector('.lists').classList.add('no-scroll')
                    } else {
                        document.querySelector('.lists').classList.remove('no-scroll')
                    }
                }
                if (document.querySelector('.chats')) {
                    let height_project = document.querySelector('.projects').clientHeight
                    let height_chats = document.querySelector('.chats').clientHeight

                    if (height_project > height_chats) {
                        document.querySelector('.chats').classList.add('no-scroll')
                    } else {
                        document.querySelector('.chats').classList.remove('no-scroll')
                    }
                }
            }
        }, 10)
    },
    linkLists(list_id) {
        // alert(list_id)
        // localStorage.setItem('project_id', list_id)
        return '/project/' + list_id
    },
    onDragBgColor(e) {
        // e.stopPropagation()
        document.querySelectorAll('.project_name_body').forEach((element) => {
            // console.log(element.closest("li"))
            element.closest("li").classList.remove('hover')
        })
        e.srcElement.closest("li").classList.add('hover')
    },
    listTasksLength(list_id) {

        let tasks = []
        this.task_length = []

        this.getProjects.forEach((list) => {
            tasks[list._id] = []
            if (this.getTasks[list._id]) {
                this.getTasks[list._id].forEach((e) => {
                    if (e) {
                        // if (e.parent) {
                        //     let isParent = this.getTasks[list._id].filter(a => a._id == e.parent)
                        //     if (isParent.length > 0) {
                        //         tasks[list._id].push(e)
                        //     }
                        // } else {
                            tasks[list._id].push(e)
                        // }
                    }
                })
            }
        })

        this.getProjects.forEach((list) => {
            // console.log(tasks[list._id])
            // console.log(list._id, tasks[list._id].length)
            this.task_length[list._id] = tasks[list._id].length
        })

        this.setNumberOfTasks(this.task_length)

        // this.task_length = []
        // this.task_length = tasks


        // if (this.getTasks[list_id]) {
        //     let num = 0
        //     this.getTasks[list_id].forEach((task) => {
        //         if (task) {
        //             if (!task.parent) {
        //                 num = num + 1
        //             }
        //         }
        //     })

        //     if (num == 0) {
        //         return 'No'
        //     } else {
        //         return num
        //     }
        // } else {
        //     if (this.new_task_length) {
        //         if (this.new_task_length.project_id == list_id) {
        //             return 1
        //         } else {
        //             return 'No'
        //         }
        //     } else {
        //         return 'No'
        //     }
        // }
    },
    onDragOver(id, e) {
        // e.stopPropagation()
        // console.log(this.task_move)
        if (this.task_move) {
            if (this.task_move_bool) {
                if (id != this.task_move.project_id) {

                    let a = false

                    // console.log(id, this.task_move.project_id)
            
                    this.$w_plan(this, this.getPlan, 'task', () => {
                        this.setMessage({
                            key_message: "warning",
                            message: "You can't add more tasks to this list. ",
                            plan: true
                        })
                        a = true
                    }, id)

                    if (a) {
                        return
                    }

                    const tasks = []

                    this.getTasks[id].forEach((e) => {
                        if(e) {
                            if (!e.parent && !e.section) {
                                tasks.push(e)
                            }
                        }
                    })

                    tasks.sort( function (a, b) {
                        return a.order - b.order
                    })

                    this.task_move_bool = false

                    let subtasks = this.getSubTask.filter(e => e.parent == this.task_move.task_id)
                    // console.log(subtasks)
                    let array_subtask = []

                    subtasks.forEach(e => {
                        array_subtask.push(e._id)
                    })

                    let data = {
                        remove_project_id: this.task_move.project_id,
                        add_project_id: id,
                        subtasks: array_subtask,
                        task_id: this.task_move.task_id,
                        order: tasks.length == 0 ? 0 : tasks.length + 1
                    }

                    // this.$router.push('/project/' + this.$route.params.project_id)

                    this.$socket.emit('IN_TaskMove', data)

                    EventEmitter.$emit('RemoveTaskSection', this.task_move)
                    EventEmitter.$emit('TaskMove_DeleteTask', this.task_move.task_id)

                    this.listTasksLength()

                    e.srcElement.classList.remove('hover')

                    this.task_move = ''

                    // EventEmitter.$emit('TaskMove_DeleteTask', this.task_move.task_id)

                }
            }
        }
        document.querySelectorAll('.project_name_body').forEach((element) => {
            element.closest("li").classList.remove('hover')
        })
    },
    changeFolder(id, evt) {
        // console.log('changeFolder', evt)
        let list_id = []
        this.lists.forEach((list) => {
            if (list._id == id) {
                list.lists.forEach((e) => {
                    list_id.push(e._id)
                })
            }
        })
        this.$socket.emit('IN_AddList_toFolder', {
            folder_id: id,
            list_id: list_id
        })
    },
    changeNameList(list) {
        ////////
        if (list.name != '' && list.name.length < 160) {
            this.lists.forEach((lists, i) => {
                if (lists._id == list._id) {
                    this.lists[i].rename = false
                    this.lists[i].name = list.name
                }
            })
            this.$socket.emit('IN_ProjectRename', {
                _id: list._id,
                name: list.name
            })
        }
    },
    changeNameList_toFolder(data) {
        ////////
        this.folders.forEach((folder, folder_index) => {
            folder.lists.forEach((list, list_index) => {
                if (list._id == data._id) {
                    this.folders[folder_index].lists[list_index].rename = false
                    this.folders[folder_index].lists[list_index].name = data.name
                }
            })
        })
        this.$socket.emit('IN_ProjectRename', {
            _id: data._id,
            name: data.name
        })
    },
    changeNameFolder(folder) {
        if (folder.name != '' && folder.name.length < 160) {
            this.folders.forEach((f, i) => {
                if (f._id == folder._id) {
                    this.folders[i].rename = false
                    this.folders[i].name = folder.name
                }
            })
            this.$socket.emit('IN_RenameFolder', {
                folder_id: folder._id,
                folder_name: folder.name
            })
        }
    },
    blurFolderInput(folder_id) {
        this.folders.forEach((e, i) => {
            if (e._id == folder_id) {
                if (e.name == '' && this.folder_name == '') {
                    this.folders.splice(i, 1)
                } else {
                    this.createFolder(e)
                }
            }
        })
    },
    changeFolderList(folder_id) {
        this.folders.forEach((e, i) => {

            if (e._id == folder_id) {
                let list_id = []

                e.lists.forEach((e) => {
                    let data = list_id.filter(a => a == e._id)
                    if (data.length == 0) {
                        list_id.push(e._id)
                    }
                })

                this.$socket.emit('IN_AddList_toFolder', {
                    folder_id: e._id,
                    list_id: list_id
                })

            }

        })
    },
    changeLists(evt) {

        document.querySelectorAll('.project_name_body').forEach((element) => {
            element.closest("li").classList.remove('hover')
        })

        let new_order_lists = []
        let new_order_folder = []

        this.folders.forEach((e, i) => {

            new_order_folder.push({
                _id: e._id,
                order: i
            })

        })

        this.lists.forEach((e, i) => {
            new_order_lists.push({
                list_id: e._id,
                order: i
            })
        })

        this.lists_order({
            folder: this.folders,
            list: this.lists
        })

        this.$socket.emit('IN_ChangeOrderLists', {
            new_order_lists: new_order_lists
        })

        this.$socket.emit('IN_ChangeOrderFolder', {
            new_order_folder: new_order_folder
        })

        // let data = []
        // data['list'] = this.lists
        // data['folder'] = this.folders

        // this.setLists(data)

    },
    renderOrderLists(type) {
        let lists = []
        let folder_lists = []
        if (type == 'lists') {
            this.getLists.forEach((e, i) => {
                e.order = i
                lists.push(e)
            })
            // console.log(lists)
            return lists
        }
        if (type == 'folder') {
            this.getUserData.folder.forEach((folder) => {
                folder_lists[folder._id] = []
                this.getFolderLists[folder._id].forEach((e, i) => {
                    e.order = i
                    folder_lists[folder._id].push(e)
                })
            })
            return folder_lists
        }
    },
    createFolder(folder) {
        if (this.folder_name != '' && this.folder_name.length < 18) {
            this.$socket.emit('IN_CreateFolder', {
                _id: folder._id,
                name: this.folder_name,
                order: folder.order
            })
            this.getUserData.folder.forEach((e, i) => {
                if (e.name == '') {
                    this.getUserData.folder[i].name = this.folder_name
                    this.getUserData.folder[i].open = true
                    // console.log(this.folder_show)
                    // this.folder_show.push({
                    //     num: i,
                    //     active: false
                    // })
                    // console.log(this.folder_show)
                }
            })
            this.folder_name = ''
        }
    },
    openFolder(list_id) {
        let folders = this.folders
        folders.forEach((e, i) => {
            if (e._id == list_id) {
                folders[i].open = !folders[i].open
                localStorage.setItem(`folder_${list_id}`, folders[i].open)
            }
        })
        this.folders = ''
        this.folders = folders
        setTimeout(() => {
           this.last_message_width() 
        }, 10)
        document.querySelector('.lists').classList.remove('no-scroll')
        this.projectScroll()
        // console.log(this.folder_show)
        // this.folder_show.forEach((e) => {
        //     if (e.num == i) {
        //         this.folder_show[i].active = !this.folder_show[i].active
        //     }
        // })
    },
    listContextMenu(data, bool, e) {
        e.stopPropagation()

        let position_menu = {
            x: e.x,
            y: e.y,
            data: data,
            bool: bool,
            show: true
        }
        setTimeout(() => {
            EventEmitter.$emit('listContextMenu_open', position_menu)
        }, 10)
        this.show_list_context_menu = true
    },
    // nowDay() {
    //     let now = new Date()
    //     return now.getDate()
    // },
    renderUserData () {

        if (this.getUserData.shortcuts) {
            
            let shortcuts = this.getUserData.shortcuts

            this.getUserData.shortcuts.forEach((e, i) => {
                shortcuts[i].task_length = this.shortcutsTaskLength(e.type)
            })

            if (this.getUserData.shortcuts) {
                if (this.getUserData.shortcuts.length == 0) {
                    this.show.lists = true
                }
            }

            this.shortcuts = []
            this.shortcuts = shortcuts

        }

    },
    shortcutsTaskLength(shortcut) {

        let tasks = []

        if (shortcut == 'inbox') {

            let tasks1 = []
            const tasksAll = this.getTasks[this.getUserData.shortcut_inbox] ? this.getTasks[this.getUserData.shortcut_inbox] : []

            tasksAll.forEach((e) => {
                // if (e.parent) {
                //     let isParent = this.getTasks[this.getUserData.shortcut_inbox].filter(a => a._id == e.parent)
                //     if (isParent.length > 0) {
                //         tasks1.push(e)
                //     }
                // } else {
                    tasks1.push(e)
                // }
            })

            tasks = tasks1

        } else {
            this.getProjects.forEach((project) => {
                if (this.getTasks[project._id]) {
                    if (this.getTasks[project._id].length > 0) {
                        this.getTasks[project._id].forEach((task) => {
                            if (task.done == false) {
                                if (shortcut == 'today') {
                                    if (task.assign) {
                                        if (task.assign.date) {
                                            if (this.$w_date_parse(task.assign.date) == 'Today') {
                                                tasks.push(task)
                                            }
                                        }
                                    }
                                } else if (shortcut == 'assigned_to_me') {
                                    if (task.assign && task.assign.user_id) {
                                        if (task.assign.user_id == this.getUserData._id) {
                                            tasks.push(task)
                                        }
                                    }
                                } else if (shortcut == 'medium_priority') {
                                    if (task.priority) {
                                        if (task.priority == 'Medium') {
                                            tasks.push(task)
                                        }
                                    }
                                } else if (shortcut == 'high_priority') {
                                    if (task.priority) {
                                        if (task.priority == 'High') {
                                            tasks.push(task)
                                        }
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }
        return tasks.length

    },
    open(e) {
        if (e == 'chats') {
            this.show.chats = !this.show.chats
        } else if (e == 'lists') {
            this.show.lists = !this.show.lists
        } 
    },
    show_popover(project) {
      this.$refs["project_" + project][0].classList.toggle("hover");
    },
    SearchResultNull(project_id) {
        // console.log(0)
        EventEmitter.$emit('SearchTasksProject', '')
        EventEmitter.$emit('SearchInputClear', true)
        EventEmitter.$emit('SectionProject', project_id)
    },
    setWidthMsgBody() {
        // setTimeout(() => {
        //     for (const pr_id in this.unreaded_msg) {
        //         if (Object.hasOwnProperty.call(this.unreaded_msg, pr_id)) {

        //             const pr_list_item = this.$refs[`project_${pr_id}`] ? this.$refs[`project_${pr_id}`][0] : null;
                    
        //             if (pr_list_item) {

        //                 const pr_list_item_body = pr_list_item.querySelector(".project_name_body");
        //                 const msg = pr_list_item.querySelector(".unread_messages");

        //                 if (msg) {

        //                     const width = msg.clientWidth && msg.clientWidth != 0 ? msg.clientWidth : +window.getComputedStyle(msg).width.match(/\d*/)[0] + 1;
        //                     const margin = +window.getComputedStyle(msg).marginRight.match(/\d*/)[0];

        //                     if (msg) {
        //                         const count = 57 + width + margin;
        //                         pr_list_item_body.style.width = `calc(100% - ${count}px)`;
        //                     } else {
        //                         pr_list_item_body.style.width = `calc(100% - 50px)`;
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }, 500);
    },
    set_new_last_message() {
    //   setTimeout(() => {
    //     if (Object.keys(this.last_chats).length > 0) {
    //       for (const pr_id in this.last_chats) {
    //         if (Object.hasOwnProperty.call(this.last_chats, pr_id)) {
    //           const last_message = this.last_chats[pr_id];
    //           if (this.$refs[`project_${pr_id}`]) {
    //             const project = this.$refs[`project_${pr_id}`][0];
    //             if (project) {
    //                 const project_name_body = project.querySelector(".project_name_body");

    //                 let last_message_body;
    //                 if (project_name_body.querySelector(".last_comment_in_project")) {
    //                     last_message_body = project_name_body.querySelector(
    //                     ".last_comment_in_project"
    //                     );
    //                 } else {
    //                     last_message_body = document.createElement("div");
    //                     last_message_body.classList.add("last_comment_in_project");

    //                     project_name_body.insertAdjacentElement(
    //                     "beforeend",
    //                     last_message_body
    //                     );
    //                 }
    //                 if (last_message) {
    //                     console.log(last_message.last_chat, this.getUserData._id)
    //                     project_name_body.classList.remove("no_messages");
    //                     last_message_body.innerHTML = ` <p class="last_comment_in_project__user_name">
    //                     ${
    //                     last_message.last_chat.user_id == this.getUserData._id
    //                         ? "You"
    //                         : last_message.last_chat.user_name
    //                     }:
    //                 </p>
    //                 <p class="last_comment_in_project__comment_body">
    //                     ${last_message.last_chat.message}
    //                 </p>`;
    //                 } else {
    //                     last_message_body.innerHTML = "";
    //                     project_name_body.classList.add("no_messages");
    //                 }
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }, 100);
    },
  },
  async mounted() {

    // console.log(this.$route)

    setTimeout(() => {

        this.projectScroll()
        this.listTasksLength()

        this.lists = this.getLists['list']
        this.folders = this.getLists['folder'] ? this.getLists['folder'].filter(e => e != undefined) : ''

        if (this.getLists['folder']) {
            let folders = this.folders
            folders.forEach((e, i) => {
                folders[i].open = localStorage.getItem(`folder_${e._id}`) == 'true' ? true : false
            })
            this.folders = folders
        }

        this.unread_number = this.get_unreaded_messages_in_project

    }, 100)

    window.addEventListener('resize', (event) => {
        this.projectScroll()
    }, true)

    setTimeout(() => {
        this.last_message_width()
    }, 1000)

    EventEmitter.$on('nowDate', (date) => {
        this.nowDay = date
    })

    // this.renderAllProject()

    setTimeout(() => {
        let new_arr = [];
        if (this.lists)
        {
            this.lists.forEach( (e, i) => {
                if(e) {
                    e.users.forEach((user, j) => {
                        if (user._id == this.getUserData._id) {
                            e.users[j].order = i
                        }
                    })
                    new_arr.push(e)
                }
            })
        }
        this.lists = new_arr

        this.unreaded_msg = this.get_unreaded_messages_in_project

        // if (this.getProjects.length > 0) {
        //     this.setWidthMsgBody();
        // }
    }, 50);

    if (this.getUserData.folder) {
        this.getUserData.folder.forEach((e, i) => {
            this.folder_show.push({
                num: i,
                active: false
            })
            // if (e.lists) {
            //     if (e.lists.length > 0) {
            //         e.lists.forEach((list_id, a) => {
            //             this.getProjects.forEach((list) => {
            //                 if (list._id == list_id) {
            //                     this.getUserData.folder[i].lists.splice(a, 1)
            //                     this.getUserData.folder[i].lists.push(list)
            //                 }
            //             })
            //         })
            //     }
            // }
        })
        // console.log(this.folder_show)
    }

    EventEmitter.$on('TaskMove', (data) => {
        this.task_move = data
    })

    // EventEmitter.$on('changeTaskLength', (data) => {
    //     this.new_task_length = data
    // })

    EventEmitter.$on('RedirectLists', (id) => {
        // setTimeout(() => {
            if (this.$route.params.project_id == id) {

                let bool = true
                if (this.getLists['list']) {
                    this.getLists['list'].forEach((e, i) => {
                        if (e._id == id) {
                            bool = false
                            if (this.getLists['list'][i + 1]) {
                                this.$router.push('/project/' + this.getLists['list'][i + 1]._id).catch(() => true);
                            } else {
                                if (this.getLists['list'][i - 1]) {
                                    this.$router.push('/project/' + this.getLists['list'][i - 1]._id).catch(() => true);
                                } else {
                                    this.$router.push('/no-lists').catch(() => true);
                                }
                            }
                        }
                    })
                }
                if (bool) {
                    if (this.getLists['folder']) {
                        this.getLists['folder'].forEach((folder) => {
                            folder.lists.forEach((list, i) => {
                                if (list._id == id) {
                                    if (folder.lists[i + 1]) {
                                        this.$router.push('/project/' + folder.lists[i + 1]._id).catch(() => true);
                                    } else {
                                        if (folder.lists[i - 1]) {
                                            this.$router.push('/project/' + folder.lists[i - 1]._id).catch(() => true);
                                        } else {
                                            if (this.getLists['list'].length > 0) {
                                                this.$router.push('/project/' + this.getLists['list'][this.getLists['list'].length - 1]._id).catch(() => true);
                                            } else {
                                                this.$router.push('/no-lists').catch(() => true);
                                            }
                                        }
                                    }
                                }
                            })
                        })
                    } else {
                        // alert(1)
                        this.$router.push('/no-lists').catch(() => true);
                    }
                }
            }
        // }, 500)
    })

    EventEmitter.$on('renameList', (data) => {

        let bool = true

        this.lists.forEach((list, i) => {
            if (list._id == data._id) {
                bool = false
                this.lists[i].rename = true
            }
        })

        if (bool) {
            this.folders.forEach((folder, folder_index) => {
                folder.lists.forEach((list, list_index) => {
                    if (list._id == data._id) {
                        this.folders[folder_index].lists[list_index].rename = true
                    }
                })
            })
        }

        setTimeout(() => {
            document.querySelector('.project_name input').focus()
        }, 500);
    })

    EventEmitter.$on('renameFolder', (data) => {
        this.folders.forEach((folder, i) => {
            if (folder._id == data._id) {
                this.folders[i].rename = true
            }
        })
        setTimeout(() => {
            document.querySelector('.folder__flex input').focus()
        }, 500);
    })

    EventEmitter.$on('RequestServerRemoveLists', (data) => {
        // EventEmitter.$emit('RedirectLists', data._id)
        if (this.lists) {
            let lists = this.lists.filter((project) => project._id != data._id)
            // this.lists = []
            this.lists = lists
        }
    })

    EventEmitter.$on('DeleteLists', (list_data) => {
        // alert(0)

        let data = list_data.data
        let bool = list_data.bool

        if (bool) {
            if (this.lists) {
                let lists = this.lists.filter((project) => project._id != data._id);
                let listsArray = []
                lists.forEach((e, i) => {
                    if (e.folder) {
                        e.order = i
                    } else {
                        e.users.forEach((user, index) => {
                            if (user._id == this.getUserData._id) {
                                e.users[index].order = i
                            }
                        })
                    }
                    listsArray[i] = []
                    listsArray[i] = e
                })

                this.lists = listsArray

                let new_order_lists = []

                this.lists.forEach((e, i) => {
                    new_order_lists.push({
                        list_id: e._id,
                        order: i
                    })
                })

                // this.$socket.emit('IN_ChangeOrderLists', {
                //     new_order_lists: new_order_lists
                // })

                this.change_lists_order(new_order_lists)

                // this.$socket.emit('IN_ProjectDelete', {
                //     _id: data._id
                // })
            }
        } else {

            let folder_id = ''

            this.folders.forEach((folder, folder_index) => {
                folder.lists.forEach((list, list_index) => {
                    if (list._id == data._id) {
                        folder_id = folder._id
                        this.folders[folder_index].lists.splice(list_index, 1)
                    }
                })
            })

            let list_id = []

            this.folders.forEach((folder, folder_index) => {
                if (folder._id == folder_id) {
                    folder.lists.forEach((list) => {
                        list_id.push(list._id)
                    })
                }
            })

            this.$socket.emit('IN_AddList_toFolder', {
                folder_id: folder_id,
                list_id: list_id
            })

            // this.$socket.emit('IN_ProjectDelete', {
            //     _id: data._id
            // })

        }

    })

    EventEmitter.$on('listContextMenu_close', (data) => {
        this.show_list_context_menu = false
    })

    if (window.location.href.split('/').indexOf('project') > -1) {
        this.tab = 'Lists'
    }

    if (window.location.href.split('/').indexOf('chats') > -1) {
        this.tab = 'Chats'
    }

    if (window.location.href.split('/').indexOf('shortcuts') > -1) {
        this.tab = 'Lists'
    }
      
    this.last_chats = this.getLastChat;
    setTimeout(() => {
        this.renderUserData()
        if (this.getProjects.length == 0) {
            this.show.chats = true
        }
    }, 50)

    EventEmitter.$on('changeTab', (data) => {
        this.tab = data
        setTimeout(() => {
            this.last_message_width()
        }, 10)
        this.projectScroll()
    })

    EventEmitter.$on('InboxAdd', (data) => {
        this.getUserData.shortcut_inbox = data
    })
    // console.log(this.getProjects)
    // setTimeout(() => {
        // this.set_new_last_message();
    // }, 500);
    /* window.addEventListener("click", (e) => {
      if (!e.target.classList.contains("project_more_icon")) {
        for (const key in this.$refs) {
          if (Object.hasOwnProperty.call(this.$refs, key)) {
            const element = this.$refs[key][0];
            if (element) element.classList.remove("hover");
          }
        }
      }
    }); */
    // console.log('Filter projects');
    // this.getProjects.map(project => {
    //   if (project.order_by) {
    //     console.log('filter');

    //     let all_task_data = [];
    //     let tasks = document.querySelectorAll('.draggable_tasks_group .task_box');
    //     console.log(tasks);
    //     if (tasks) {
    //       let lists = document.querySelectorAll('.draggable_tasks_group');
    //       lists.forEach((span) => {
    //         span.classList.add('filtered');
    //       });

    //     }

    //     if (project.order_by == 'name') {
    //       // name
    //       query

    //     } else if (project.order_by == 'date') {
    //       // date

    //     } else {
    //       // prior
          
    //     }
    //   }
    // });
  },
  computed: {
    ...mapGetters([
        "getPlan",
        "getProjects",
        "getChats",
        "get_unreaded_messages_in_project",
        "get_flag_for_unreaded",
        "getLastChat",
        "get_flag_for_last_chat",
        "getUserData",
        "getRenderUserData",
        "getLists",
        "getFolderLists",
        "get_flag_for_tasks",
        "getTasks",
        "getSubTask",
        "getCompletedTasks",
        "get_change_shortcuts_render",
        "getListsMentions"
    ]),
    unread_list_number() {
        return this.$store.state.comments.unreaded_messages_in_tasks
    },
    getToggleCollapse() {
      return this.toggleCollapse;
    },
    dragOptions() {
        return {
            animation: 300,
            group: "description",
            disabled: false,
            ghostClass: "ghost",
            removeCloneOnHide: true,
        };
    },
  },
  watch: {
    lists(value) {
        this.setSortedLists({ type: 'lists', value })
    },
    folders(value) {
        this.setSortedLists({ type: 'folders', value })
    },
    get_unreaded_messages_in_project(newVal) {
        this.unreaded_msg = newVal
    },
    get_change_shortcuts_render() {
        setTimeout(() => {
            this.renderUserData()
        }, 500)
    },
    get_flag_for_tasks() {
        this.task_move_bool = true
        setTimeout(() => {
            this.renderUserData()
            this.listTasksLength()
        }, 100)
    }, 
    unreaded_msg(e) {
        this.last_message_width()
        // const projects = this.getProjects
        // projects.forEach((project) => {
        //     if(e[project._id] == 0){
        //         const block = document.querySelector(`.lists .project_name_body[data-id="${project._id}"]`)
        //         if (block) {
        //             block.style.width = `calc(100% - 50px)`;
        //         }
        //     }
        // })
    },
    getRenderUserData() {
        setTimeout(() => {
            this.renderUserData()
            this.getUserData.folder.forEach((e, i) => {
                if (e.name == '') {
                    this.folder_show.push({
                        num: i,
                        active: false
                    })
                }
            })
        }, 500)
        // this.getUserData = this.getUserData
    },
    $route(to) {
        // if (to.params.project_id) {
        //     // alert(to.params.project_id)
        //     localStorage.setItem('project_id', to.params.project_id)
        // } else {
        //     localStorage.setItem('project_id', '')
        // }
        setTimeout(() => {
            this.listTasksLength()
            // this.last_message_width()
            if (to.params.project_id) {
                let is_list = this.getProjects.filter(e => e._id == to.params.project_id)
                if (is_list.length == 0) {
                    this.$router.push('/remove_list')
                }
            }
        }, 100)
    },
    getProjects(data) {
        if (Object.keys(this.unreaded_msg).length > 0) {
            this.setWidthMsgBody();
        }
        setTimeout(() => {
            this.listTasksLength()
        }, 100)
    },
    get_flag_for_last_chat(e) {
        // this.lists = []
        this.lists = this.getLists['list'].filter(e => e._id != undefined)
        this.folders = this.getLists['folder']

        let folders = this.folders
        folders.forEach((e, i) => {
            folders[i].open = localStorage.getItem(`folder_${e._id}`) == 'true' ? true : false
        })
        this.folders = folders

        // this.folders = []
        // this.renderAllProject()

        // this.last_chats = this.getLastChat;
        // this.set_new_last_message();
    },
    get_flag_for_unreaded(e) {
        setTimeout(() => {
            this.unreaded_msg = []
            this.unreaded_msg = this.get_unreaded_messages_in_project;
        }, 1)
      if (this.getProjects.length > 0) {
        this.setWidthMsgBody();
      }
    },
    getToggleCollapse(e) {
      if (e) {
        this.set_new_last_message();
        this.setWidthMsgBody();
      }
    },
  },
  components: {
    AddProject,
    ChatsLists,
    ListContextMenu,
    draggable,
    NotFound
  },
};
</script>
