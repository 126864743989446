import Vue from "vue";
import App from "./App.vue";

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faReply,
  faPen,
  faTasks,
  faListUl,
  faFileDownload,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFilePdf,
  faFile,
  faExclamationCircle,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import "./assets/styles/main.scss";

import router from "./router";
import store from "./store";

import Loader from "./components/app/Loader";

import Clipboard from "v-clipboard";

import request_links from "./utils/api_links";
import alert_messages from "./utils/alert_messages";
import change_order from "./utils/change_order";
import parse_message from "./utils/parse_message";
import time_parse from "./utils/time_parse";
import date_parse from "./utils/date_parse";
import decrypt from "./utils/decrypt";
import icon_doc_file from "./utils/iconDocFile";
import parse_doc_size from "./utils/parseDocSize";
import parse_file_type from "./utils/parseFileType";
import hue_color from "./utils/hue_color";
import name_reduction from "./utils/name_reduction";
import throttle from "./utils/throttle";
import email_validate from "./utils/mail_validate";
import downloadFile from "./utils/downloadFile";
import user_color from "./utils/user_color";
import user_image from "./utils/user_avatar";
import resizeImg from "./utils/resizeImg";
import taskData from "./utils/task_data";
import get_image_url from "./utils/get_image_url";
import get_know_user_data from './utils/get_know_user_data'
import plan from './utils/plan'
import password from './utils/password'
import alert from './utils/alert'
import ios_version from './utils/ios-version'

import axios from "axios";
import VueAxios from "vue-axios";

import VueRandomColor from "vue-randomcolor";

import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketIO(process.env.VUE_APP_API_BASE_URL2, {
      autoConnect: false,
      reconnection: false,
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: localStorage.getItem("w_token"),
            accept: 'web'
          },
        },
      },
    }),
    vuex: {
      store,
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);

Vue.use(VueAxios, axios);

Vue.use(VueRandomColor);

Vue.use(Clipboard);

Vue.use(ElementUI);
locale.use(lang);

library.add(
  faReply,
  faPen,
  faTasks,
  faListUl,
  faFileDownload,
  faFileWord,
  faFileExcel,
  faFilePdf,
  faFile,
  faFilePowerpoint,
  faExclamationCircle,
  faClock
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.component("Loader", Loader);

Vue.prototype.$w_api = request_links;
Vue.prototype.$w_alert = alert_messages;
Vue.prototype.$w_change_order = change_order;
Vue.prototype.$w_parse_message = parse_message;
Vue.prototype.$w_time_parse = time_parse;
Vue.prototype.$w_date_parse = date_parse;
Vue.prototype.$w_decrypt = decrypt;
Vue.prototype.$w_icon_doc_file = icon_doc_file;
Vue.prototype.$w_parse_doc_size = parse_doc_size;
Vue.prototype.$w_parse_file_type = parse_file_type;
Vue.prototype.$w_hue_color = hue_color;
Vue.prototype.$w_name_reduction = name_reduction;
Vue.prototype.$w_throttle = throttle;
Vue.prototype.$w_email_validate = email_validate;
Vue.prototype.$w_downloadFile = downloadFile;
Vue.prototype.$w_user_color = user_color
Vue.prototype.$w_user_image = user_image
Vue.prototype.$w_get_image_url = get_image_url
Vue.prototype.$w_resizeImg = resizeImg
Vue.prototype.$w_taskData = taskData
Vue.prototype.$w_get_know_user_data = get_know_user_data
Vue.prototype.$w_plan = plan
Vue.prototype.$w_password = password
Vue.prototype.$w_alert = alert
Vue.prototype.$w_ios_version = ios_version

Vue.config.productionTip = false;

export const EventEmitter = new Vue()

import AutoReload from '@/plugins/AutoReload';

new Vue({
  router,
  store,
  created() {
    Vue.use(AutoReload, {
      config: {
        Enabled: true,
        CheckInterval: 60,
      },
      router: this.$router,
      vm: this,
    });
  },
  render: (h) => h(App),
}).$mount("#app");
