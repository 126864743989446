<template>
  <div style="width: 100%;height: 100vh;background-color: #fff;"></div>
</template>

<script>

  export default {
    name: 'TokenLayout',
    mounted() {
      if (this.$route.query.token && this.$route.query.clone) {
        localStorage.setItem('w_token', this.$route.query.token)
        localStorage.setItem('w_clone_token', this.$route.query.clone)
        window.location.href = '/'
      }
    }
  }

</script>