import File from './aws'

function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function() {
        var reader = new FileReader()
        reader.onloadend = function() {
            callback(reader.result)
        }
        reader.readAsDataURL(xhr.response)
    }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
}

let names = ['MM', 'WW', 'MW', 'WM']

export default function (getUserData,  f_size = 70, bool = true) {
    if (!localStorage.getItem(getUserData._id)) {
        if (getUserData.image_url) {
            return `<img class="avatar_img" src="${getUserData.image_url}" alt="">`
        } else {
            if (bool) {
                let newStr = "";
                if (getUserData.name) {
                    let name = getUserData.name.trim()
                    name.split(" ").forEach((e, i) => {
                        if (i < 2) {
                            newStr += e[0]
                        }
                    })
                    if (names.some(e => e == newStr.toUpperCase().replaceAll('-', ''))) {
                        return name[0].replaceAll('-', '')
                    } else {
                        return newStr.toUpperCase().replaceAll('-', '')
                    }
                }
            } else {
                if (getUserData.name) {
                    let name = String(getUserData.name).trim()
                    return name[0].replaceAll('-', '')
                }
            }
        }
    } else {
        return `
            <img class="avatar_img" src="${localStorage.getItem(getUserData._id)}" alt="">
        `
    }
}