<template>
    <div style="height: 100%">
        <ul class="chats" v-if="chats.length > 0">
            <draggable :list="chats" @change="reorderChats" group="chat" v-bind="dragOptions">
                <li v-for="chat of chats" :key="chat._id" @contextmenu.prevent="(e) => contextMenu(e, chat)">
                    <ChatItem :key="chat._id + 1" :chat="chat" :editing="editingChatId == chat._id" :renameLocaly="renameLocaly(chat)" />
                </li>
            </draggable>
        </ul>
        <NotFound text="No chats so far" v-else />
    </div>
</template>

<script>

    import { mapGetters } from "vuex"
    import { EventEmitter } from "../../main"
    import NotFound from '@/components/app/NotFound'
    import ChatItem from "./ChatItem.vue"
    import draggable from "vuedraggable"

    export default {
        name: 'ChatsLists',
        computed: {
            ...mapGetters([
                "getChats",
                "getRender",
                "getUserData",
                "getKnownUsers",
                "getChatsMentions"
            ]),
            chats: {
                get() {
                    return this.$store.state.chats.chats
                },
                set(value) {
                    this.$store.dispatch("chats/ChatsReorder", value)
                }
            },
            dragOptions() {
                return {
                    animation: 300,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost",
                    removeCloneOnHide: true
                }
            },
        },
        data() {
            return {
                editingChatId: ''
            }
        },
        mounted() {

            window.addEventListener('resize', (event) => {
                this.projectScroll()
            }, true)

            EventEmitter.$on("RenameChatFromContext", id => {
                this.editingChatId = id
                
                if (id) {
                    setTimeout(() => {
                        document.getElementById('renameChatInput').focus()
                    }, 0)
                }
                
            })

            this.projectScroll()

        },
        methods: {
            projectScroll() {
                setTimeout(() => {
                    if (document.querySelector('.projects')) {
                        if (document.querySelector('.lists')) {
                            let height_project = document.querySelector('.projects').clientHeight
                            let height_lists = document.querySelector('.lists').clientHeight

                            if (height_project > height_lists) {
                                document.querySelector('.lists').classList.add('no-scroll')
                            } else {
                                document.querySelector('.lists').classList.remove('no-scroll')
                            }
                        }
                        if (document.querySelector('.chats')) {
                            let height_project = document.querySelector('.projects').clientHeight
                            let height_chats = document.querySelector('.chats').clientHeight

                            if (height_project > height_chats) {
                                document.querySelector('.chats').classList.add('no-scroll')
                            } else {
                                document.querySelector('.chats').classList.remove('no-scroll')
                            }
                        }
                    }
                }, 1)
            },
            contextMenu(e, chat) {

                let position_menu = {
                    x: e.x,
                    y: e.y,
                    chat,
                }

                setTimeout(() => {
                    EventEmitter.$emit('ChatContextMenu_open', position_menu)
                }, 10)

            },
            renameLocaly(chat) {
                return (newName) => {
                    chat.name = newName
                }
            },
            reorderChats() {
                let new_order = []
                this.chats.forEach((e, i) => {
                    new_order.push({
                        chat_id: e._id,
                        order: i
                    })
                })
                this.$socket.emit('IN_ReorderChats', {
                    new_order: new_order
                })
            }
        },
        components: {
            NotFound,
            ChatItem,
            draggable
        }
    }

</script>