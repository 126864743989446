import router from "@/router";
import { EventEmitter } from "../../../main";
import pushNotificationTask from "../../../utils/pushNotificationCustom";
import AWS from "../../../utils/aws";

let get_data = true;

function clone(o) {
  if (!o || "object" !== typeof o) {
    return o;
  }

  var c = "function" === typeof o.pop ? [] : {};

  var p, v;

  for (p in o) {
    if (o.hasOwnProperty(p)) {
      v = o[p];
      if (v && "object" === typeof v) {
        c[p] = clone(v);
      } else {
        c[p] = v;
      }
    }
  }
  return c;
}

function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

async function get_image_url(user) {
  if (user.image) {
    const aws = new AWS();
    const url = await aws.read(`Users/${user._id}/70/${user.image}`);
    if (url) {
      toDataUrl(url, function (myBase64) {
        localStorage.removeItem(user._id);
        localStorage.setItem(user._id, myBase64);
      });
      return url;
    }
  } else return "";
}

export default {
  state: {
    user: {
      email: "",
      name: "",
      id: "",
      date_format: {
        time: "",
      },
    },
    userUsedFileSize: 0,
    userAllFileSize: 0,
    flag_for_send_firebase_token: false,
    knwown_users: [],
    teams: [],
    renderKnownUsers: false,
    renderUserData: false,
    request_data: true,
    plan: "",
    allPlans: [],
    change_password: false,
    reloadData: false,
    move: false,
    background_image: "Big Ben.jpg",
    background_images: [
      {image_name: 'Light Blue', title: 'Light Blue', default: true, color: '#DEE8F2'},
      {image_name: 'Big Ben.jpg', title: 'Big Ben'},
      {image_name: 'Eiffel Tower.jpg', title: 'Eiffel Tower'},
      {image_name: 'Kalon.jpg', title: 'Kalon'},
      {image_name: 'Statue of Liberty.jpg', title: 'Statue of Liberty'},
      {image_name: 'Taj Mahal.jpg', title: 'Taj Mahal'},
      {image_name: 'The Shard.jpg', title: 'The Shard'},
    ],
    background_image_url: "",
  },
  actions: {
    async fetchKey({ dispatch, commit }, { url, body }) {
      try {
        const res = {
          token: false,
          method: "POST",
          url,
          body,
        };

        const req = await dispatch("fetch_request", res);

        if (req.ok) {
          const reqJSON = await req.json();
          localStorage.setItem("w_token", reqJSON.token);
          localStorage.setItem("w_clone_token", reqJSON.email);

          // detect if it is an electron app suggest open TaskPal app
          const URLParams = new URLSearchParams(window.location.search);
          console.log("URLParams.get(app)", URLParams.get("app"));
          if (URLParams.get("app")) {
            //window.location.href = 'taskpal://clone_token='+ reqJSON.email +'&token='+ reqJSON.token
            router.push({
              path: "/app_auth",
              query: {
                useremail: body.email,
              },
            });
          } else {
            return true;
          }
        } else {
          const reqJSON = await req.json();

          const e = {
            key_message: "auth_error",
            message: reqJSON.message.En,
          };

          throw e;
        }
      } catch (error) {
        commit("setMessage", error);
        return false;
      }
    },
    async ForGotPassword({ dispatch, commit }, { url, body }) {
      try {
        const res = {
          token: false,
          method: "POST",
          url,
          body,
        };

        const req = await dispatch("fetch_request", res);

        if (req.ok) {
          const reqJSON = await req.json();
          // commit("setMessage", {
          //     key_message: "warning",
          //     message: reqJSON.message.En
          // })
          return reqJSON.message;
        } else {
          const reqJSON = await req.json();

          const e = {
            key_message: "auth_error",
            message: reqJSON.message.En,
          };

          throw e;
        }
      } catch (error) {
        commit("setMessage", error);
        return false;
      }
    },
    async getToken({ dispatch, commit }, { url, body }) {
      try {
        const res = {
          token: false,
          method: "POST",
          url,
          body,
        };

        const req = await dispatch("fetch_request", res);

        if (req.ok) {
          const reqJSON = await req.json();
          return reqJSON;
        } else {
          return false;
        }
      } catch (error) {
        commit("setMessage", error);
        return false;
      }
    },
    async sendStripeQuery({ dispatch, commit }, { url, body }) {
      try {
        const res = {
          token: false,
          method: "POST",
          url,
          body,
        };

        const req = await dispatch("fetch_request", res);

        if (req.ok) {
          const reqJSON = await req.json();
          return reqJSON;
        } else {
          return false;
        }
      } catch (error) {
        commit("setMessage", error);
        return false;
      }
    },
    async NewPassword({ dispatch, commit }, { url, body }) {
      try {
        const res = {
          token: false,
          method: "POST",
          url,
          body,
        };

        const req = await dispatch("fetch_request", res);

        if (req.ok) {
          const reqJSON = await req.json();
          commit("setMessage", {
            key_message: "warning",
            message: reqJSON.message.En,
          });
          return true;
        } else {
          const reqJSON = await req.json();

          const e = {
            key_message: "auth_error",
            message: reqJSON.message.En,
          };

          throw e;
        }
      } catch (error) {
        commit("setMessage", error);
        return false;
      }
    },
    async fetchUserData({ dispatch, commit }, url) {
      try {
        const res = {
          token: true,
          method: "GET",
          url,
        };

        const req = await dispatch("fetch_request", res);

        if (req.ok) {
          const reqJSON = await req.json();

          commit("setShowSmalActivityLog", reqJSON.user.show_sidebar);
          commit("setUserData", reqJSON);

          localStorage.removeItem(reqJSON.user._id);

          commit("setRenderUserData");
          commit(
            "setUserAllFileSize",
            reqJSON.user.full_size ? +reqJSON.user.full_size : 15000000
          );
          commit(
            "setUserUsedFileSize",
            reqJSON.user.disk_space ? +reqJSON.user.disk_space : 0
          );
          commit("edit_flag_for_send_firebase_token");
        } else {
          const reqJSON = await req.json();

          const e = {
            key_message: "other",
            message: reqJSON.message,
          };

          throw e;
        }
      } catch (error) {
        commit("setMessage", error);
        return false;
      }
    },
    async check_token({ dispatch, commit }) {
      if (localStorage.getItem("w_clone_token")) {
        try {
          const req = {
            token: false,
            method: "POST",
            url: this._vm.$w_api.AUTHORIZATION,
            body: {
              check_token: localStorage.getItem("w_clone_token"),
            },
          };

          const res = await dispatch("fetch_request", req);

          if (res.ok) {
            let resJSON = await res.json();
            localStorage.setItem("w_token", resJSON.token);
            return resJSON.token;
          } else {
            return false;
          }
        } catch (error) {
          console.log(error);
          return false;
          // commit("changeReconnectionCount", 0);
          // router.push(`/login?message=login`);
        }
      } else {
        return false;
        // commit("changeReconnectionCount", 0);
        // router.push(`/login?message=login`);
      }
    },
    async InvitationDecrypt({ dispatch, commit }, code) {
      try {
        const req = {
          token: false,
          method: "POST",
          url: this._vm.$w_api.POST_InvitationDecrypt,
          body: {
            code: code,
          },
        };

        const res = await dispatch("fetch_request", req);

        if (res.ok) {
          let resJSON = await res.json();
          if (resJSON.email) {
            return resJSON;
          } else {
            const message = {
              key_message: "success",
              message: reqJSON.message.En,
            };

            commit("setMessage", message);
            return false;
          }
        } else {
          throw new Error();
        }
      } catch (error) {
        const message = {
          key_message: "success",
          message: error.message,
        };

        commit("setMessage", message);
        return false;
        // commit("changeReconnectionCount", 0);
        // router.push(`/login?message=login`);
      }
    },
    // getDataWithoutPromise
    async getDataWithoutPromise({ dispatch, commit, getters }) {
      try {
        if (get_data) {
          get_data = false;

          const res = {
            token: true,
            method: "GET",
            url: this._vm.$w_api.GET_DATA,
          };

          const req = await dispatch("fetch_request", res);

          if (req.ok) {
            let tasks = [];
            let messages = [];

            let reqJSON = await req.json();

            console.log("Connect");

            commit("clearUserUsedFileSize");
            commit("clearUserAllFileSize");
            commit("clearChat");
            commit("clearLists");
            // commit('clearComments')
            commit("clearTasks");
            commit("clearSubtask");

            if (reqJSON.know_users) {
              dispatch("know_users", reqJSON.know_users);
            }

            if (reqJSON.teams) {
              dispatch("teams", reqJSON.teams);
            }

            if (reqJSON.user) {
              dispatch("user", reqJSON);
            }

            if (reqJSON.all_plan) {
              dispatch("all_plan", reqJSON.all_plan);
            }

            if (reqJSON.lists) {
              // setTimeout(() => {
              let lists = clone(reqJSON.lists);
              dispatch("lists", lists);
              // commit('clearComments')
              reqJSON.lists.forEach((list) => {
                // console.log(list.tasks.filter(e => e._id == 'B2GShgYilkxyBdazZNaUh2I='))

                let tasks_data = clone(list.tasks);

                list.tasks.forEach((task, i) => {
                  if (task.messages) {
                    list.tasks[i].hasMessage =
                      task.messages.length > 0 ? true : false;
                  } else {
                    list.tasks[i].hasMessage = false;
                  }
                  // let user_data = this._vm.$w_get_know_user_data(getters.getKnownUsers, getters.getUserData, task.auth)
                  list.tasks[i].project_id = list._id;
                  // list.tasks[i].auth = user_data.name
                  // list.tasks[i].auth_id = task.auth
                  list.tasks[i].show_subtask =
                    localStorage.getItem(task._id) == "false" ? false : true;
                  list.tasks[i].messages = [];
                });

                // console.log(tasks_data)

                tasks = tasks.concat(list.tasks);

                tasks_data.forEach((task) => {
                  if (task._id in getters.getFetchedComments) {
                    commit("setTaskComments", {
                      task_id: task._id,
                      comments: task.messages,
                      name: task.name,
                    });
                  }
                  // if (task.messages) {
                  //     for await (let msg of task.messages) {
                  //         let user_data = this._vm.$w_get_know_user_data(getters.getKnownUsers, getters.getUserData, msg.user_id)
                  //         msg.task_id = task._id
                  //         msg.user_name = user_data.name
                  //         msg.user_image = user_data.image
                  //         msg.remove_file = msg.remove_file ? msg.remove_file : false
                  //         let reply_user = this._vm.$w_get_know_user_data(getters.getKnownUsers, getters.getUserData, msg.reply.user_id)
                  //         msg.reply.name = reply_user.name
                  //         msg.send = true
                  //         commit('setCommentsTasks', {
                  //             task_id: task._id,
                  //             comment: msg
                  //         })
                  //     }
                  //     messages = messages.concat(task.messages)
                  // }
                });
              });

              if (tasks.length > 0) {
                dispatch("tasks", tasks);
              }

              // if (messages.length > 0) {
              //     dispatch('messages', {
              //         chats: messages,
              //         files: []
              //     })
              // }
              // }, 2000)
            }

            if (reqJSON.chats) {
              dispatch("chats", reqJSON.chats);
            }

            if (reqJSON.plan) {
              commit("plan", reqJSON.plan);
            }

            setTimeout(() => {
              const userEmail = getters.getUserData.email;

              // console.log('comments::::', getters.getComments, 'jdjkfsdjk')
              // console.log('tasks:::::::', getters.getTasks, 'jdjkfsdjk')
              // console.log('tasks:::::::', getters.getProjects, 'jdjkfsdjk')
              getters.getProjects.forEach((project) => {
                const { _id: project_id } = project;
                const tasks = getters.getTasks[project_id];
                tasks.forEach((task) => {
                  const { _id: task_id } = task;
                  const comments = getters.getComments[task_id];
                  const users = task.users;

                  users.forEach((user) => {
                    const { _id: user_id } = user;
                    // console.log(user_id, 'user??', getters.getUserData._id)
                    if (user_id != getters.getUserData._id) return;
                    const unreadNumber =
                      user.unread_message + user.real_unread_message;
                    if (!comments) return;
                    const start = comments.length - unreadNumber;
                    const unreads = comments.slice(start);
                    unreads.forEach((comment) => {
                      const { _id: comment_id } = comment;
                      if (comment.message.includes(userEmail)) {
                        // console.log(comment.message, userEmail, '[[[[[[[[[[')
                        commit("setMentionForList", {
                          task_id,
                          project_id,
                          user_id,
                          comment_id,
                        });
                        // console.log(comment.message, 'mention?????????')
                      }
                    });
                    // console.log(unreads, unreadNumber, 'unread?', task.name, start, 'unread: ' + user.unread_message, 'real: ' + user.real_unread_message)
                  });
                });
                // console.log('mentions:::::::', getters.getListsMentions, '8888888888888')
              });
              getters.getChats.forEach((chat) => {
                const { messages, unread_messages, _id: chat_id } = chat;
                // if (getters.getUserData._id != user_id) return
                const unreadMessage = messages.slice(
                  messages.length - unread_messages
                );
                // console.log(unreadMessage, 'unread????????????', messages)
                unreadMessage.forEach(
                  ({ message, user_id, _id: comment_id }) => {
                    if (message.includes(userEmail)) {
                      commit("setMentionForChat", { chat_id, comment_id });
                    }
                  }
                );
              });
            }, 1000);

            commit("request_data", false);
            commit("offAppLoader");

            reqJSON = "";
          }
        }

        setTimeout(() => {
          get_data = true;
        }, 2000);
      } catch (error) {
        // commit('clearUserUsedFileSize')
        // commit('clearUserAllFileSize')
        // commit('clearChat')
        // commit('clearLists')
        // commit('clearComments')
        // commit('clearTasks')
        // commit('clearSubtask')
        // localStorage.clear()

        // setTimeout(() => {
        //     window.location.href = '/login'
        // }, 1000)

        commit("setMessage", error);
        return false;
      }
    },
    // getDataWithPromise
    async getData({ dispatch, commit, getters }) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = {
            token: true,
            method: "GET",
            url: this._vm.$w_api.GET_DATA,
          };

          const req = await dispatch("fetch_request", res);

          // commit('setApplicationStatus', 'updating')

          if (!req.ok) reject("Network Error");

          let tasks = [];
          let messages = [];

          let reqJSON = await req.json();

          console.log("Get Data");

          commit("clearUserUsedFileSize");
          commit("clearUserAllFileSize");
          commit("clearChat");
          commit("clearLists");
          commit("clearTasks");
          commit("clearSubtask");

          if (reqJSON.know_users) {
            dispatch("know_users", reqJSON.know_users);
          }

          if (reqJSON.teams) {
            dispatch("teams", reqJSON.teams);
          }

          if (reqJSON.user) {
            if (
              !reqJSON.user.time_zone ||
              reqJSON.user.time_zone.time_zone !=
                Intl.DateTimeFormat().resolvedOptions().timeZone
            ) {
              this._vm.$socket.emit("IN_TimeZone", {
                time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                time_zone_offset: new Date().getTimezoneOffset() / -60,
              });
            }
            if (reqJSON.user.image) {
              reqJSON.user.image_url = await get_image_url(reqJSON.user);
            }
            dispatch("user", reqJSON);
          }

          if (reqJSON.all_plan) {
            dispatch("all_plan", reqJSON.all_plan);
          }

          if (reqJSON.lists) {
            let lists = clone(reqJSON.lists);
            dispatch("lists", lists);
            reqJSON.lists.forEach((list) => {
              let tasks_data = clone(list.tasks);

              list.tasks.forEach((task, i) => {
                if (task.messages) {
                  list.tasks[i].hasMessage =
                    task.messages.length > 0 ? true : false;
                } else {
                  list.tasks[i].hasMessage = false;
                }
                list.tasks[i].project_id = list._id;
                list.tasks[i].show_subtask =
                  localStorage.getItem(task._id) == "false" ? false : true;
                list.tasks[i].messages = [];
              });

              tasks = tasks.concat(list.tasks);

              tasks_data.forEach((task) => {
                if (task._id in getters.getFetchedComments) {
                  commit("setAllTaskComments", {
                    task_id: task._id,
                    comments: task.messages,
                  });
                }
              });
            });

            if (tasks.length > 0) {
              dispatch("tasks", tasks);
            }
          }

          if (reqJSON.chats) {
            dispatch("chats", reqJSON.chats);
          }

          if (reqJSON.plan) {
            commit("plan", reqJSON.plan);
          }

          // commit('setApplicationStatus', 'updated')

          setTimeout(() => {
            const userEmail = getters.getUserData.email;

            getters.getProjects.forEach((project) => {
              const { _id: project_id } = project;
              const tasks = getters.getTasks[project_id];
              tasks.forEach((task) => {
                const { _id: task_id } = task;
                const comments = getters.getComments[task_id];
                const users = task.users;

                users.forEach((user) => {
                  const { _id: user_id } = user;
                  if (user_id != getters.getUserData._id) return;
                  const unreadNumber =
                    user.unread_message + user.real_unread_message;
                  if (!comments) return;
                  const start = comments.length - unreadNumber;
                  const unreads = comments.slice(start);
                  unreads.forEach((comment) => {
                    const { _id: comment_id } = comment;
                    if (comment.message.includes(userEmail)) {
                      commit("setMentionForList", {
                        task_id,
                        project_id,
                        user_id,
                        comment_id,
                      });
                    }
                  });
                });
              });
            });
            getters.getChats.forEach((chat) => {
              const { messages, unread_messages, _id: chat_id } = chat;
              const unreadMessage = messages.slice(
                messages.length - unread_messages
              );
              unreadMessage.forEach(({ message, user_id, _id: comment_id }) => {
                if (message.includes(userEmail)) {
                  commit("setMentionForChat", { chat_id, comment_id });
                }
              });
            });
          }, 1000);

          commit("request_data", false);
          commit("offAppLoader");
          commit("set_reloadData");

          reqJSON = "";
          resolve("success");
        } catch (error) {
          commit("setMessage", error);
          reject(error);
        }
      });
    },

    SOCKET_OUT_ChangeBackgroundImage({ commit }, data) {
      commit("userBackgoundImageChange", data.image_name);
    },

    all_plan({ commit, getters, dispatch }, data) {
      commit("setAllPlan", data);
    },
    know_users({ commit, getters, dispatch }, data) {
      commit("clearKnownUser");

      if (data) {
        if (data.users.length > 0) {
          data.users.forEach((user) => {
            localStorage.removeItem(user._id);
            commit("addKnownUser", user);
          });
        }
      }

      commit("renderKnownUsers");
    },
    teams({ commit, getters, dispatch }, data) {
      commit("clearTeamsUser");

      if (data) {
        if (data.users.length > 0) {
          data.users.forEach((user) => {
            localStorage.removeItem(user._id);
            commit("addTeamsUser", user);
          });
        }
      }

      // commit('renderKnownUsers')
    },
    lists({ dispatch, commit, getters }, data) {
      let projects = data ? data : [];

      projects.forEach((project, project_i) => {
        let users = [];
        project.users.forEach((user, user_i) => {
          // projects[project_i].users.splice(user_i, 1)
          let user_data = this._vm.$w_get_know_user_data(
            getters.getKnownUsers,
            getters.getUserData,
            user._id
          );
          users.push({
            _id: user_data._id,
            name: user_data.name,
            email: user_data.email,
            image: user_data.image,
            color: user_data.color,
            muted: user.muted,
            order: user.order,
          });
          if (getters.getUserData._id == user._id) {
            projects[project_i].order = user.order;
          }
        });
        project.users = users;
        projects[project_i].tasks = [];
      });

      // console.log(projects)

      commit("set_lists", projects);

      if (projects.length == 0) {
        setTimeout(() => {
          if (getters.getHistoryFromBack.length > 0) {
            dispatch("generateHistoryBodyAndAdd", getters.getHistoryFromBack);
          }
        }, 500);
      } else {
        commit("setProjects", []);
        commit("setProjects", projects);
        // commit("set_last_chat", projects)

        setTimeout(() => {
          commit("set_tasks_project_id", projects);
        }, 1000);

        let old_muted_objs = window.localStorage.getItem("mutedObjects");

        projects.forEach((project) => {
          project.users.forEach((user) => {
            if (getters.getUserData._id == user._id) {
              if (user.muted) {
                // if (old_muted_objs) {
                //     let array = old_muted_objs.split(',')
                //     let has_list = array.filter(e => e == project._id)
                //     console.log(has_list, old_muted_objs)
                //     if (has_list.length == 0) {
                //         old_muted_objs = old_muted_objs + ',' + project._id
                //     }
                // } else {
                //     old_muted_objs = project._id
                // }
                if (old_muted_objs) {
                  if (!old_muted_objs.includes(project._id)) {
                    old_muted_objs += "," + project._id;
                  }
                } else {
                  old_muted_objs = project._id;
                }
              }
            } else {
              commit("addKnownUser", user);
            }
          });
        });
        window.localStorage.setItem("mutedObjects", old_muted_objs);
      }

      commit("change_flag_for_last_chat");

      if (localStorage.getItem("first_register")) {
        router.push("/project/" + projects[0]._id);
        localStorage.removeItem("first_register");
      }

      if (router.history.current.params.project_id) {
        let is_list = getters.getProjects.filter(
          (e) => e._id == router.history.current.params.project_id
        );
        if (is_list.length == 0) {
          router.push("/remove_list");
        }
      }
    },
    tasks({ dispatch, commit, getters }, tasks_data) {
      let data = tasks_data ? tasks_data : [];

      // console.log(data.filter(e => e._id == 'AzkChwYilkxyBdazZNaUh2I='))

      const tasks = {};
      const completed_tasks = {};
      const notes = [];
      // const reminders = [], divider = '&$&', divider2 = '!|!'
      const reminders = {};

      if (data.length > 0) {
        data.forEach((task) => {
          if (task.assign) {
            task.assign.task_id = task._id;

            if (!task.priority) {
              task.priority = "Normal";
            }

            task.users.forEach((user) => {
              if (user._id == getters.getUserData._id) {
                if (user.remind) {
                  if (!task.done) {
                    const now = new Date();
                    let remindDate = new Date(+user.remind);
                    const diff = remindDate.getTime() - now.getTime();
                    if (diff >= 0) {
                      dispatch("handleSetRemindMeNotification", {
                        task_id: task._id,
                        remind: +user.remind,
                        project_id: task.project_id,
                      });
                    } else if (diff > -1000 * 60) {
                      console.log("call push notification type: remind me");
                      pushNotificationTask({
                        task_id: task._id,
                        project_id: task.project_id,
                        type: "remindMe",
                        task,
                      });
                    }
                  }
                }
              }
            });

            commit("setAssigns", task.assign);

            task.users.forEach((user) => {
              if (user._id == getters.getUserData._id) {
                task.unread_message = user.unread_message;
                task.real_unread_message = user.real_unread_message;
              }
            });

            notes.push({
              notes: task.notes,
              task_id: task._id,
            });

            let pr_id = task.project_id;

            task.elements = [];

            if (!task.done) {
              if (!tasks[pr_id]) tasks[pr_id] = [];

              if (tasks[pr_id].length == 0) {
                tasks[pr_id].push(task);
              } else {
                let isTask = tasks[pr_id].filter((e) => e._id == task._id);
                if (isTask.length == 0) {
                  tasks[pr_id].push(task);
                }
              }
            } else {
              if (!completed_tasks[pr_id]) completed_tasks[pr_id] = [];

              if (completed_tasks[pr_id].length == 0) {
                completed_tasks[pr_id].push(task);
              } else {
                let isTask = completed_tasks[pr_id].filter(
                  (e) => e._id == task._id
                );
                if (isTask.length == 0) {
                  completed_tasks[pr_id].push(task);
                }
              }
            }
          }
        });
      }

      if (reminders.length > 0) {
        localStorage.reminders = reminders.join(divider2);
      } else {
        localStorage.reminders = "";
      }

      commit("setCompletedTasks", completed_tasks);
      commit("setTasks", tasks);
      commit("setSubtask", data);
      // let all_task = tasks.concat(completed_tasks)
      if (!getters.getRecievedMessageWhileUpdating) {
        commit("set_unreaded_messages", data);
      } else {
        commit("setRecievedMessageWhileUpdating", false);
      }
      commit("set_notes", notes);

      if (!router.history.current.params.task_id && getters.getAppLoader) {
        setTimeout(() => {
          if (getters.getHistoryFromBack.length > 0) {
            dispatch("generateHistoryBodyAndAdd", getters.getHistoryFromBack);
          }
          commit("set_flag_for_unreaded");
          commit("change_flag_for_tasks");
        }, 500);
      } else {
        dispatch("socket_fetch_comments", {
          task_id: router.history.current.params.task_id,
        });
      }

      commit("change_flag_for_tasks");
      commit("set_flag_for_unreaded");
    },
    messages({ dispatch, commit, getters }, messages) {
      let data = messages;

      if (data) {
        commit("setFiles", data);
        // commit("setComments", data)

        commit("change_read_flag", {
          user: getters.getUserData,
          tasks: getters.getTasks,
        });

        commit("changeCommentLoading");
        commit("change_flag_for_files");
      }
    },
    chats({ dispatch, commit, getters }, data) {
      commit("setChats", { v: data, getters });
      commit("render");

      let cur_user_id = this.getters.getUserData._id;
      let cur_data = window.localStorage.getItem("mutedObjectsChat");

      data.forEach((chat) => {
        if (chat.users.length) {
          chat.users.forEach((user) => {
            if (user.user_id == cur_user_id && user.muted) {
              if (cur_data && !cur_data.includes(chat._id)) {
                cur_data += "," + chat._id;
              } else {
                cur_data = chat._id;
              }
            }
          });
        }
      });

      window.localStorage.setItem("mutedObjectsChat", cur_data);
    },
    user({ dispatch, commit }, data) {
      commit("setShowSmalActivityLog", data.user.show_sidebar);
      commit("setUserData", data);
      commit("backgoundImage", data.user.background_image);

      localStorage.removeItem(data.user._id);

      commit("setRenderUserData");
      commit(
        "setUserAllFileSize",
        data.user.full_size ? +data.user.full_size : 15000000
      );
      commit(
        "setUserUsedFileSize",
        data.user.disk_space ? +data.user.disk_space : 0
      );
      commit("edit_flag_for_send_firebase_token");
      // commit("change_shortcuts_render")
    },

    async fetchComment(
      { dispatch, commit },
      { task_id, list_id, chat_id, type }
    ) {
      try {
        const config = {
          token: true,
          method: "GET",
          url:
            type == "chat"
              ? this._vm.$w_api.FETCH_COMMENTS_CHAT + `?chat_id=${chat_id}`
              : this._vm.$w_api.FETCH_COMMENTS +
                `?list_id=${list_id}&task_id=${task_id}`,
        };

        const req = await dispatch("fetch_request", config);
        const res = await req.json();

        if (type == "chat") {
          commit("setAllChatMessages", { chat_id, comments: res.comments });
        } else {
          commit("setAllTaskComments", {
            list_id,
            task_id,
            comments: res.comments,
          });
        }
      } catch (error) {
        console.log(error, "error while getting comments");
      }
    },

    SOCKET_OUT_Verification({ commit }, data) {
      commit("setChangeUserData", data);
      EventEmitter.$emit("OUT_Verification");
      commit("setRenderUserData");
    },
    SOCKET_OUT_NewVersion({ commit }, data) {
      console.log("SOCKET_OUT_NewVersion", data);
      let a = {
        type: "version",
        data: data,
      };

      commit("setChangeUserData", a);
      commit("setRenderUserData");
    },
    SOCKET_OUT_AddPasswordUser({ commit }, data) {
      commit("set_add_password", data);
    },
    SOCKET_OUT_ImportData({}, data) {
      EventEmitter.$emit("SOCKET_OUT_ImportData", data);
    },
    SOCKET_OUT_ImportDataProgress({}, data) {
      EventEmitter.$emit("SOCKET_OUT_ImportDataProgress", data);
    },
    SOCKET_OUT_ChangePlan({ dispatch, commit, getters }, data) {
      if (getters.getPlan._id != data._id) {
        EventEmitter.$emit("SOCKET_OUT_PlanChanged", data);
      }
      commit("clearUserAllFileSize");

      commit("setUserAllFileSize", +data.disk_space * 1048576);
      commit("plan", data);
      commit("userDowngradeChange", {
        plan_id: "",
        date: 0,
      });

      console.log("SOCKET_OUT_ChangePlan", data);
    },
    socket_send_firebase_token(ctx, data) {
      console.log("Token firebase", data);
      this._vm.$socket.emit("IN_UserToken", data);
    },
    SOCKET_IN_Company(ctx, user_id) {
      this._vm.$socket.emit("IN_Company", user_id);
    },
    async SOCKET_OUT_Company({ commit, getters, dispatch }, data) {
      console.log("SOCKET_OUT_Company", data);

      commit("clearKnownUser");

      if (data) {
        if (data.users.length > 0) {
          data.users.forEach((user) => {
            commit("addKnownUser", user);
          });
        }
      }

      commit("renderImageTeamsAndKnow");

      commit("renderKnownUsers");
    },
    SOCKET_OUT_Shortcuts({ commit }, data) {
      console.log("SOCKET_OUT_Shortcuts", data);
      commit("setShortcuts", data);
      commit("setRenderUserData");
    },
    SOCKET_OUT_UserGeneralSettings({ commit }, data) {
      // console.log('SOCKET_OUT_UserGeneralSettings', data)
      commit("setChangeUserData", data);
      commit("setRenderUserData");
      commit("setMessage", {
        key_message: "warning",
        message: "Saved",
      });
    },
    SOCKET_OUT_RenameFolder({ commit }, data) {
      commit("rename_folder", data);
    },
    SOCKET_OUT_CreateFolder({ commit }, data) {
      commit("setAddFolder", data);
    },
    SOCKET_OUT_ChangeEmailUser({ commit }, data) {
      if (this.getters.getUserData._id == data.user_id) {
        EventEmitter.$emit("ChangeEmailUser", true);
        commit("setChangeUserData", data);
      }
    },
    SOCKET_OUT_ChangeNameUser({ commit }, data) {
      setTimeout(() => {
        commit("setRenderUserData");
      }, 2000);

      if (this.getters.getUserData._id == data.user_id) {
        commit("setChangeUserData", data);
      }
    },
    SOCKET_OUT_ChangeImageUser({ commit }, data) {
      setTimeout(() => {
        commit("setRenderUserData");
      }, 2000);

      localStorage.removeItem(data.user_id);

      if (this.getters.getUserData._id == data.user_id) {
        commit("setChangeUserData", data);
      }
    },
    SOCKET_OUT_Backups({ commit }, data) {
      let s = {
        type: "backup",
        backup: data,
      };
      commit("setChangeUserData", s);
      setTimeout(() => {
        EventEmitter.$emit("SOCKET_OUT_Backups");
        commit("setRenderUserData");
      }, 2000);
    },
    SOCKET_OUT_AddList_toFolder({ commit, getters }, data) {
      commit("setAddList_folder", data);
    },
    change_user_data({ commit }, data) {
      commit("setChangeUserData", data);
    },
    SOCKET_OUT_ChangePasswordUser({ commit }, data) {
      if (data.message) {
        EventEmitter.$emit("ChangeEmailUser");
      }
      commit("set_add_password");
      commit("setRenderUserData");
    },
    SOCKET_OUT_CheckUserOnline() {
      this._vm.$socket.emit("IN_LastSeen", "online");
    },
    SOCKET_OUT_ShowDiskUsage({ commit, state }, data) {
      if (state.user.show_disk_usage == !data.show_disk_usage) return;
      commit("setChangeUserData", {
        type: "showDiskUage",
        value: !data.show_disk_usage,
      });
      EventEmitter.$emit(
        "onShowDiskUsageInSidebarChange",
        !data.show_disk_usage
      );
    },
  },
  mutations: {
    async backgroundImageUrl(state) {
      state.background_image_url = ''
      if (!state.background_images.some(e => e.image_name == state.user.background_image)) {
        if (localStorage.getItem('background_image_' + state.user.background_image)) {
          state.background_image_url = localStorage.getItem('background_image_' + state.user.background_image)
        } else {
          const aws = new File()
          state.background_image_url = await aws.read(`Users/${state.user._id}/background_image/${state.user.background_image}`)
          toDataUrl(state.background_image_url, (myBase64) => {
            localStorage.removeItem('background_image_' + state.user.background_image)
            localStorage.setItem('background_image_' + state.user.background_image, myBase64)
          })
        }
      }
    },
    backgoundImage(state, data) {
      if (data) {
        state.background_image = data;
      } else {
        state.background_image = "Light Blue";
        state.user.background_image = "Light Blue";
      }
      this.commit("backgroundImageUrl");
    },
    userBackgoundImageChange(state, data) {
      localStorage.removeItem('background_image_' + state.user.background_image)
      state.user.background_image = data;
      state.background_image = data;
      this.commit("backgroundImageUrl");
    },
    userDowngradeChange(state, data) {
      state.user.downgrade_plan = data;
    },
    setMove(s, v) {
      s.move = v;
      setTimeout(() => (s.move = false), 2000);
    },
    renderImageTeamsAndKnow(s, data) {
      if (s.knwown_users) {
        s.knwown_users.forEach(async (user, i) => {
          if (user.image) {
            user.image_url = await get_image_url(user);
            s.knwown_users[i] = user;
          }
        });
      }

      if (s.teams) {
        s.teams.forEach(async (user, i) => {
          if (user.image) {
            user.image_url = await get_image_url(user);
            s.teams[i] = user;
          }
        });
      }
    },
    set_reloadData(state) {
      state.reloadData = !state.reloadData;
    },
    plan(s, v) {
      s.plan = v;
    },
    request_data(s, v) {
      s.request_data = v;
    },
    set_remove_folder_user(s, v) {
      s.user.folder.forEach((e, i) => {
        if (e._id == v.folder_id) {
          s.user.folder.splice(i, 1);
        }
      });
    },
    setRenderUserData(s, v) {
      s.renderUserData = !s.renderUserData;
    },
    rename_folder(s, v) {
      s.user.folder.forEach((e, i) => {
        if (e._id == v.folder_id) {
          s.user.folder[i].name = v.folder_name;
        }
      });
    },
    set_change_password(s, v) {
      s.change_password = true;
    },
    set_add_password(s, v) {
      s.user.password = true;
    },
    activity_log_new(s, v) {
      s.user.activity_log_new = v;
    },
    setUserData(s, v) {
      if (v.user.expand_subtask == "Enabled") {
        v.user.expand_subtask = true;
      }
      if (v.user.expand_subtask == "Disabled") {
        v.user.expand_subtask = false;
      }
      if (v.user.new_task == "top") {
        v.user.new_task = true;
      }
      if (v.user.new_task == "bottom") {
        v.user.new_task = false;
      }
      if (!v.user.date_format.time) {
        v.user.date_format.time = "";
      }
      s.user = v.user;
      if (!localStorage.getItem("first_register")) {
        if (router.history.current.name == "Home") {
          if (v.user.homepage) {
            let redirect = "";
            if (v.user.homepage.type == "chat") {
              redirect = "/chats/" + v.user.homepage._id;
            } else if (v.user.homepage.type == "project") {
              redirect = "/project/" + v.user.homepage._id;
            } else if (v.user.homepage.type == "inbox") {
              redirect = "/inbox/" + v.user.homepage.project_id;
            } else {
              if (v.user.shortcuts.length > 0) {
                redirect = "/shortcuts/" + v.user.homepage.type;
              }
            }
            if (redirect) {
              router.push({ path: redirect, query: { isQuit: "true" } });
            }
          }
        }
      }
    },
    setAddList_folder(s, v) {
      s.user.folder.forEach((e, i) => {
        if (e._id == v.folder_id) {
          s.user.folder[i].lists = v.list_id;
        }
      });
    },
    setAddFolder(s, v) {
      let folder = s.user.folder.filter((e) => e._id == v._id);
      if (folder.length == 0) {
        v.lists = [];
        s.user.folder.push(v);
      }
    },
    setFoderLists(s, v) {
      if (s.user.folder) {
        s.user.folder.forEach((e, i) => {
          if (e.lists) {
            if (e.lists.length > 0) {
              e.lists.forEach((list_id, a) => {
                this.getters.getProjects.forEach((list) => {
                  if (list._id == list_id) {
                    s.user.folder[i].lists.splice(a, 1);
                    s.user.folder[i].lists[a].push(list);
                  }
                });
              });
            }
          }
        });
      }
    },
    async setChangeUserData(s, v) {
      if (v.type == "homepage") {
        s.user.homepage = v.field;
      }
      if (v.type == "backup") {
        s.user.backups = v.backup;
      }
      if (v.type == "version") {
        s.user.new_version = v.data;
      }
      if (v.type == "name") {
        s.user.name = v.name;
      }
      if (v.type == "email") {
        s.user.email = v.email;
        s.user.verification = false;
      }
      if (v.type == "date_format") {
        s.user.date_format.date = v.field;
      }
      if (v.type == "time_format") {
        s.user.date_format.time = v.field;
      }
      if (v.type == "start_of_the_week") {
        s.user.date_format.start_of_the_week = v.field;
      }
      if (v.type == "expand_subtask") {
        s.user.expand_subtask = v.field;
      }
      if (v.type == "new_task") {
        s.user.new_task = v.field;
      }
      if (v.type == "change_image") {
        let user = s.user;
        user.image = v.image;
        user.image_url = await get_image_url(user);
        s.user = user;
      }
      if (v.type == "add_folder") {
        if (s.user.folder) {
          s.user.folder.push(v.field);
        } else {
          s.user.folder = [];
          s.user.folder.push(v.field);
        }
        // console.log(s.user)
        this.commit("setRenderUserData");
      }
      if (v.type == "show_sidebar") {
        s.user.show_sidebar = v.show_sidebar;
      }
      if (v.type == "verification") {
        s.user.verification = v.data;
      }
      if (v.type == "showDiskUage") {
        s.user.show_disk_usage = v.value;
      }
    },
    setShortcuts(s, v) {
      let user = s.user;
      user.shortcuts = v.shortcuts;
      s.user = user;
    },
    setUserUsedFileSize(s, v) {
      s.userUsedFileSize += v;
    },
    setUserFileSize_minus(s, v) {
      s.userUsedFileSize -= v;
    },
    clearUserUsedFileSize(s) {
      s.userUsedFileSize = 0;
    },
    setUserAllFileSize(s, v) {
      s.userAllFileSize += v;
    },
    clearUserAllFileSize(s) {
      s.userAllFileSize = 0;
    },
    clearUserData(s, v) {
      s.user = {
        email: "",
        name: "",
        id: "",
      };
    },
    edit_flag_for_send_firebase_token(s) {
      s.flag_for_send_firebase_token = !s.flag_for_send_firebase_token;
    },
    clearKnownUser(s, v) {
      s.knwown_users = [];
    },
    clearTeamsUser(s, v) {
      s.teams = [];
    },
    // addKnownUser(s, v) {
    //     s.knwown_users.push(v)
    // },
    // addTeamsUser(s, v) {
    //     s.teams.push(v)
    // },
    addKnownUser(s, v) {
      let users = s.knwown_users;
      if (!s.knwown_users.some((u) => u._id == v._id) && s.user._id != v._id) {
        users.push(v);
        s.knwown_users = users;
      }
    },
    addTeamsUser(s, v) {
      if (!s.teams.some((u) => u._id == v._id) && s.user._id != v._id) {
        s.teams.push(v);
      }
      let res = s.teams.reduce((o, i) => {
        if (i._id) {
          if (!o.find((v) => v._id == i._id)) {
            o.push(i);
          }
        } else {
          o.push(i);
        }
        return o;
      }, []);
      s.teams = res;
    },
    setFolder(s, v) {
      s.user.folder.forEach((e, i) => {
        if (e._id == v.folder_id) {
          s.user.folder[i].lists = v.lists;
        }
      });
    },
    renderKnownUsers(s, v) {
      s.renderKnownUsers = !s.renderKnownUsers;
    },
    setAllPlan(s, v) {
      s.allPlans = v;
    },
  },
  getters: {
    getUserData: (s) => s.user,
    getUserUsedFileSize: (s) => s.userUsedFileSize,
    getUserAllFileSize: (s) => s.userAllFileSize,
    get_flag_for_send_firebase_token: (s) => s.flag_for_send_firebase_token,
    getKnownUsers: (s) => s.knwown_users,
    getTeams: (s) => s.teams,
    getRenderUserData: (s) => s.renderUserData,
    getRenderKnownUsers: (s) => s.renderKnownUsers,
    get_request_data: (s) => s.request_data,
    getPlan: (s) => s.plan,
    getAllPlan: (s) => s.allPlans,
    get_reloadData: (s) => s.reloadData,
  },
};
