module.exports = function (e, list_id) {

    let array1 = []
    let array2 = []

    if (e.getTasks[list_id]) {
        array1 = e.getTasks[list_id]
    }

    if (e.getCompletedTasks[list_id]) {
        array2 = e.getCompletedTasks[list_id]
    }

    let array3 = array1.concat(array2)

    return array3

}