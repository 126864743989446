<template>
    <div class="bg-listContextMenu" @click.prevent="closeContextMenu" v-if="show">
        <div class="listContextMenu" :style="style">
            <div class="listContextMenu__text" @click="handleRenameChat"><span>Rename Chat</span></div>
            <!-- <div class="listContextMenu__text" @click="copy">Copy link to task</div> -->
            <div class="listContextMenu__text red" @click="() => remove({removeText: 'Delete', text: 'Do you want to delete the chat?'})" v-if="chat.auth == getUserData._id"><span>Delete Chat</span></div>
            <div class="listContextMenu__text red" @click="() => remove({removeText: 'Leave', text: 'Do you want to leave the chat'})" v-else><span>Leave Chat</span></div>
        </div>
    </div>
</template>

<script>

    import { mapActions, mapGetters, mapMutations } from "vuex";
    import { EventEmitter } from "../../main"

    export default {
        data() {
            return {
                style: '',
                show: false,
                chat: {},
                type: ''
            }
        },
        computed: {
            ...mapGetters([
                "getUserData"
            ]),
        },
        mounted() {

            EventEmitter.$on('ChatContextMenu_open', (position_menu) => {
                this.style = `top: ${position_menu.y}px; left: ${position_menu.x}px`
                this.show = true
                this.chat = position_menu.chat
            })

        },
        methods: {
            handleRenameChat() {
                // this.$socket.emit('IN_ChatsRename', {_id: this.chat._id, name: 'hello world??'})
                EventEmitter.$emit('RenameChatFromContext', this.chat._id)
            },
            closeContextMenu() {
                this.show = false
                // EventEmitter.$emit('listContextMenu_close', true)
            },
            copy() {
                let url = ''
                if (this.getUserData.shortcut_inbox == this.list_id) {
                    url = window.location.origin + '/inbox/' + this.list_id + '/task/' + this.task_id
                } else {
                    url = window.location.origin + '/project/' + this.list_id + '/task/' + this.task_id
                }
                this.$clipboard(url)
            },
            remove({removeText, text}) {
                // EventEmitter.$emit('removeTask', this.task_id)
                let data = {
                    type: removeText == 'Delete' ? 'chat_delete' : 'chat_leave',
                    data: {
                        text,
                        cancel: 'Cancel',
                        delete: removeText,
                    },
                    chat: this.chat,
                }
                EventEmitter.$emit('OpenPopup', data)
            },
        }
    }

</script>