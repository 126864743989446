import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    meta: { layout: "main", auth: true },
    component: Home,
  },
  {
    path: "/token",
    name: "Home",
    meta: { layout: "token", auth: false },
    component: Home,
  },
  {
    path: "/project/:project_id",
    name: "Project",
    meta: { layout: "main", auth: true },
    component: () => import("../views/Project.vue"),
    props: true,
    children: [
      {
        path: "task/:task_id",
        name: "TaskBody",
        meta: { layout: "main", auth: true },
        component: () => import("../views/TaskBody.vue"),
      },
    ],
  },
  {
    path: "/setting",
    name: "Setting",
    meta: { layout: "main", auth: true },
    component: () => import("../views/Setting.vue"),
  },
  {
    path: "/inbox/:project_id",
    name: "Inbox",
    meta: { layout: "main", auth: true, shortcut: true },
    component: () => import("../views/Project.vue"),
    props: true,
    children: [
      {
        path: "task/:task_id",
        name: "InboxTaskBody",
        meta: { layout: "main", auth: true },
        component: () => import("../views/TaskBody.vue"),
      },
    ],
  },
  {
    path: "/chats/:chat_id",
    name: "Chats",
    meta: { layout: "main", auth: true },
    component: () => import("../views/Chats.vue"),
    props: true,
    children: [
      {
        path: "project/:project_id/task/:task_id",
        name: "TaskBodys",
        meta: { layout: "main", auth: true },
        component: () => import("../views/TaskBody.vue"),
      },
    ],
  },
  {
    path: "/search",
    name: "Search",
    meta: { layout: "main", auth: true },
    component: () => import("../components/tasks/Tasks.vue"),
  },
  {
    path: "/remove_list",
    name: "Remove",
    meta: { layout: "main", auth: true },
    component: () => import("../views/Remove.vue"),
  },
  {
    path: "/no-lists",
    name: "NoLists",
    meta: { layout: "main", auth: true },
    component: () => import("../views/NoLists.vue"),
  },
  {
    path: "/file/:file_id",
    name: "RouteFile",
    meta: { layout: "main", auth: true },
    component: () => import("../views/RouteFile.vue"),
  },
  {
    path: "/shortcuts/:shortcuts",
    name: "shortcuts",
    meta: { layout: "main", auth: true, shortcut: true },
    component: () => import("../views/Project.vue"),
    props: true,
    children: [
      {
        path: "project/:project_id",
        name: "Project_shortcuts",
        meta: { layout: "main", auth: true },
        component: () => import("../views/TaskBody.vue"),
        props: true,
        children: [
          {
            path: "task/:task_id",
            name: "TaskBody_shortcuts",
            meta: { layout: "main", auth: true },
            component: () => import("../views/TaskBody.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/todoist",
    name: "todoist",
    meta: { layout: "import", auth: true },
    component: () => import("../layouts/ImportLayout.vue"),
  },
  {
    path: "/microsoft",
    name: "microsoft",
    meta: { layout: "import", auth: true },
    component: () => import("../layouts/ImportLayout.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "auth", auth: false },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/app_auth",
    name: "AppAuth",
    meta: { layout: "dAppAuth", auth: true },
    component: () => import("../views/AppAuth.vue"),
  },
  {
    path: "/registration",
    name: "Registration",
    meta: { layout: "auth", auth: false },
    component: () => import("../views/Registration.vue"),
  },
  {
    path: "/verification/:code",
    name: "verification",
    meta: { layout: "verification", auth: false },
  },
  {
    path: "/auth/password/",
    name: "ForGotPassword",
    meta: { layout: "ForGotPassword", auth: false },
  },
  {
    path: "/new_password/:code",
    name: "NewPassword",
    meta: { layout: "NewPassword", auth: false },
  },
  {
    path: "/auth-token/:code",
    name: "AuthToken",
    meta: { layout: "AuthToken", auth: false },
  },
  {
    path: "/delete-cache/:id",
    name: "Cache",
    meta: { layout: "main", auth: false },
    component: () => import("../views/Cache.vue"),
  },
  {
    path: "/success",
    name: "Success",
    meta: { layout: "payment", auth: false },
    component: () => import("../views/PaymentSuccess.vue"),
  },
  {
    path: "/error",
    name: "Error",
    meta: { layout: "payment", auth: false },
    component: () => import("../views/PaymentError.vue"),
  },
  {
    path: "/offline",
    name: "Offline",
    meta: { layout: "main", auth: false },
    component: () => import("../views/OfflinePage.vue"),
  },
  {
    path: "*/*",
    name: "page not found",
    meta: { layout: "main", auth: false },
    component: () => import("../views/404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  //возьмем ключ из локал сторэджа, посмотрим на ключ мета и его ключ auth
  // если есть ключ ил локал сторэджа и он совподает с getter стэфта ключа
  // из стора и ключ auth имеет значение true то тогда нас будет перекидывать в приложения
  // иначе будет редиректить на авторизацию
  const curUser = localStorage.getItem("w_token");
  const requireAuth = to.matched.some((r) => r.meta.auth);

  const URLParams = new URLSearchParams(window.location.search);

  if (to.name !== "Offline" && !navigator.onLine) {
    next({ name: "Offline" });
  } else if (!curUser && requireAuth) {
    if (URLParams.get("app")) {
      next({
        path: "/login",
        query: {
          app: 1,
        },
      });
    } else {
      next("/login?message=login");
    }
  } else {
    if (URLParams.get("app")) {
      localStorage.setItem("app_web_auth", 1);
    }
    next();
  }
});

export default router;
