export default {
  state: {
    files: {},
    flag_for_files: true,
  },
  actions: {
    async fetch_download_file({ dispatch }, { url, body }) {
      try {
        const res = {
          token: false,
          method: "POST",
          url,
          body,
        };

        const req = await dispatch("fetch_request", res);

        if (req.ok) {
          window.open(req.url);
          return true;
        } else {
          const e = {
            key_message: "auth_error",
            message: req,
          };
          throw e;
        }
      } catch (error) {
        // commit("setMessage", error);
        console.log("catch", error);
        return false;
      }
    },
  },
  mutations: {
    setFiles(s, v) {
      if (v.files[0]) {
        if (!s.files[v.files[0].task_id]) s.files[v.files[0].task_id] = [];
        v.files.forEach((file) => {
          if (!isNaN(file.date)) {
            file.date = new Date(+file.date).toISOString()
          }
          this.commit("addToFiles", { files: s.files, file });
        });
      }
    },
    change_flag_for_files(s) {
      s.flag_for_files = !s.flag_for_files;
    },
    addToFiles(s, { files, file }) {
      if (!files[file.task_id]) files[file.task_id] = [];
      if (files[file.task_id].some((f) => f._id == file._id)) {
        files[file.task_id] = files[file.task_id].filter(
          (f) => f._id != file._id
        );
      }

      this.commit("addSrcUrlToFile", { file });
      this.commit("changeExtInFile", { file, vue: this._vm });
      files[file.task_id].push(file);
    },
    addSrcUrlToFile(s, { file }) {
      if (file) {
        file.src_url = file.base64
          ? file.base64
          : `${process.env.VUE_APP_API_BASE_URL2}/api/file/${file._id}`;
      }
    },
    changeExtInFile(s, { file, vue }) {
      if (file && vue) {
        file.ext = vue.$w_parse_file_type(file.filename);
      }
    },
    delete_files(s, v) {
      if (v.chat_id) {
        s.files[v.task_id] = s.files[v.task_id].filter(
          (file) => file.chat_id != v.chat_id
        );
      } else {
        delete s.files[v.task_id];
      }
    },
  },
  getters: {
    get_files: (s) => s.files,
    get_flag_for_files: (s) => s.flag_for_files,
  },
};
