<template>
  <div id="bell-log" class="bell-log" :class="show ? 'active' : ''">
    <div class="bell-log__top">
      <h5>Activity Log</h5>
      <div @click="showActivityLog" class="bell-log__top__back">
        <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.04883 9.18207C0.737793 9.49311 0.731445 10.0454 1.05518 10.3691C1.38525 10.6928 1.9375 10.6865 2.24219 10.3818L6 6.62397L9.75146 10.3754C10.0688 10.6928 10.6147 10.6928 10.9385 10.3691C11.2622 10.039 11.2622 9.49946 10.9448 9.18207L7.19336 5.43061L10.9448 1.6728C11.2622 1.35541 11.2686 0.809515 10.9385 0.485785C10.6147 0.162054 10.0688 0.162054 9.75146 0.479437L6 4.2309L2.24219 0.479437C1.9375 0.168402 1.37891 0.155707 1.05518 0.485785C0.731445 0.809515 0.737793 1.36811 1.04883 1.6728L4.80029 5.43061L1.04883 9.18207Z" fill="#3C3C43" fill-opacity="0.6"/>
        </svg>
      </div>
    </div>
    <ul class="activity_log__list vertical_scrollbar" v-if="getHistory.length > 0">
      <li
        v-for="history in getHistory"
        :key="history._id"
        :id="history._id"
        :data-author="history.author"
        :data-color="history.color"
        class="activity_log__item"
        :class="setClass(history)"
        :style="`background-color: ` + history.color == 'green'
          ? '#F7FFF6'
          : history.color == 'red'
          ? '#FAD0D0'
          : history.color == 'blue'
          ? '#F0FBFF'
          : '#FAFAFA'"
        @click="toActiveEssence(history)"
      >
        <span class="activity_log__item__avatar" :style="'background-color: ' + getUser(history.author).color" v-html="$w_user_image(getUser(history.author))"></span>
        <div>
          <h5 class="activity_log__item__title" v-html="textHyphenated(history.message)"></h5>
          <p v-if="history.chat_message">{{ history.chat_message }}</p>
          <span class="activity_log__item__date">{{ last_time(+history.date) }}
            <i v-if="createDate(+history.date) != 'Yesterday'">{{ createDate(+history.date) + ' at ' + $w_time_parse(new Date(+history.date), getUserData.date_format.time) }}</i>
            <i v-else>{{ createDate2(+history.date) + ' at ' + $w_time_parse(new Date(+history.date), getUserData.date_format.time) }}</i>
          </span>
        </div>
        <span style="width: 15px;display: flex;align-items: center;justify-content: end;">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 7C7.99219 6.72656 7.89062 6.49219 7.67969 6.28125L1.60156 0.335938C1.42188 0.164062 1.21094 0.0703125 0.953125 0.0703125C0.429688 0.0703125 0.0234375 0.476562 0.0234375 1C0.0234375 1.25 0.125 1.48438 0.304688 1.66406L5.77344 7L0.304688 12.3359C0.125 12.5156 0.0234375 12.7422 0.0234375 13C0.0234375 13.5234 0.429688 13.9297 0.953125 13.9297C1.20312 13.9297 1.42188 13.8359 1.60156 13.6641L7.67969 7.71094C7.89844 7.50781 8 7.27344 8 7Z" fill="#3C3C43" fill-opacity="0.6"/>
          </svg>
        </span>
      </li>
    </ul>
    <p v-else class="no_logs">No log messages</p>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import { EventEmitter } from '../../main'
import { hyphenated } from 'hyphenated'
import NotFound from '@/components/app/NotFound'
import moment from "moment/moment";

export default {
  name: "ActivityLog",
  props: {
    show: Boolean,
  },
  data() {
    return {
      activityLogSwitcher: false,
      cur_date: new Date()
    };
  },
  components: {
    NotFound
  },
  computed: {
    ...mapGetters([
      "getHistory",
      "getShowSmalActivityLog",
      "getUserData",
      "getComments",
      "getKnownUsers",
      "getTasks",
      "getCompletedTasks",
      "getProjects",
      "getChats"
    ]),
  },
  mounted() {
    
    this.activityLogSwitcher = this.getUserData.show_sidebar

    let update_time = () => {
      if (new Date().getTime() - this.cur_date.getTime() > 50 * 1000) {
        this.cur_date = new Date()
      }
      setTimeout(() => {
        update_time()
      }, 60 * 1000)
    }

    update_time()
  },
  methods: {
    ...mapMutations([
      "setJumpToMessage",
      "setShowSmalActivityLog",
      "setMessage",
    ]),
    showActivityLog() {
      this.$emit('showActivityLog')
    },
    last_time(e) {
      let
          result = '',
          date = this.cur_date,
          now = date.getTime(),
          time = parseInt((now - e)/1000),
          minutes = Math.ceil(time/60),
          hours = parseInt(minutes/60),
          prefix = ' ago',
          difference = date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds();
      // console.log(difference)

      let days = Math.round((time)/3600/24 );
      // console.log('day ' + days)

      if (days >= 92)
      {
        result = 'a few months'
      }
      else if (days >= 62)
      {
        result = '2 months'
      }
      else if (days >= 31)
      {
        result = 'a month'
      }
      else if (days >= 21) (
          result = '3 weeks'
      )
      else if (days >= 14)
      {
        result = '2 weeks'
      }
      else if (days >= 7)
      {
        result = '1 week'
      }
      else if (days >= 2)
      {
        result = days + ' days'
      }
      else if (hours >= 24 )
      {
        result = '1 day'
      }
      else if (hours >= 2)
      {
        result = hours + ' hours'
      }
      else if ( hours == 1)
      {
        result = '1 hour'
      }
      else if (minutes > 2)
      {
        result = minutes + ' minutes'
      }
      else if (time > 60)
      {
        result = '1 minute'
      }
      else
      {
        prefix = '';
        result = 'just now'
      }

      return result + prefix;
    },
    projectScroll() {
      setTimeout(() => {
        if (document.querySelector('.projects')) {
          if (document.querySelector('.lists')) {
            let height_project = document.querySelector('.projects').clientHeight
            let height_lists = document.querySelector('.lists').clientHeight

            if (height_project > height_lists) {
              document.querySelector('.lists').classList.add('no-scroll')
            } else {
              document.querySelector('.lists').classList.remove('no-scroll')
            }
          }
          if (document.querySelector('.chats')) {
            let height_project = document.querySelector('.projects').clientHeight
            let height_chats = document.querySelector('.chats').clientHeight

            if (height_project > height_chats) {
              document.querySelector('.chats').classList.add('no-scroll')
            } else {
              document.querySelector('.chats').classList.remove('no-scroll')
            }
          }
        }
      }, 1)
    },
    getComment(type) {
      if (this.getComments[type.task_id]) {
        let data = this.getComments[type.task_id].filter((e) => e._id == type._id)
        if (data) {
          if (data[0]) {
            return data[0].message
          } else {
            return ''
          }
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    createDate(e) {
      const fullDate = this.$w_date_parse(e, "history")

      let date = fullDate.split('/')

      if (this.getUserData.date_format) {
        if (this.getUserData.date_format.date) {
          if (this.getUserData.date_format.date == 'DD/MM/YYYY') {
            return fullDate
          }
          if (this.getUserData.date_format.date == 'MM/DD/YYYY') {
            if (date[2]) {
              return date[1] + '/' + date[0] + '/' + date[2]
            }
            if (date[0]) {
              return date[0]
            }
          }
        }
      }

    },
    createDate2(e) {
      let date = new Date(e);

      let day = date.getDate() > 9 ? date.getDate(): '0' + date.getDate()
      if (this.getUserData.date_format.date) {
        if (this.getUserData.date_format.date == 'DD/MM/YYYY') {
          return day + '/' + (date.getMonth() + 1) + '/' + (date.getFullYear() % 100)
        }
        if (this.getUserData.date_format.date == 'MM/DD/YYYY') {
          return (date.getMonth() + 1) + '/' + day + '/' + (date.getFullYear() % 100)
        }
      }

    },
    handleOpenParentTask({type}) {
      const project_id = type.project_id
      // Some logs do not have task_id property and the ids are given as just _id
      let task_id = type._id

      if (type.task_id) {
        task_id = type.task_id
      }

      let task = this.getTasks[project_id].find(task => task._id == task_id)

      if (!task) {
        task = this.getCompletedTasks[project_id].find(task => task._id == task_id)
      }

      if (task.parent) {
        EventEmitter.$emit('openSubtask', task._id)
      }
    },
    toActiveEssence(history) {
      if (history.type.type != 'delete') {

        const hasData = (type, id) => {

          if (type == 'task') {
            if (history.type.project_id) {
              let getTasks = this.getTasks[history.type.project_id] ? this.getTasks[history.type.project_id].filter(e => e._id == id) : []
              let getCompletedTasks = this.getCompletedTasks[history.type.project_id] ? this.getCompletedTasks[history.type.project_id].filter(e => e._id == id) : []
              if (getTasks.length > 0 || getCompletedTasks.length > 0) {
                return true
              } else {
                this.setMessage({
                  key_message: "warning",
                  message: "Sorry, this entity no longer exists.",
                })
                return false
              }
            }
          }

          if (type == 'list') {
            let getProjects = this.getProjects.filter(e => e._id == id)
            if (getProjects.length > 0) {
              return true
            } else {
              this.setMessage({
                key_message: "warning",
                message: "Sorry, this entity no longer exists.",
              })
              return false
            }
          }

          if (type == 'msg') {
            let getComments = this.getComments[history.type.task_id] ? this.getComments[history.type.task_id].filter(e => e._id == id) : []
            if (getComments.length > 0) {
              return true
            } else {
              this.setMessage({
                key_message: "warning",
                message: "Sorry, this entity no longer exists.",
              })
              return false
            }
          }

        }

        if (history.type.type == "chat") {
          if (history.remove_message) {
            this.setMessage({
              key_message: "warning",
              message: "Sorry, this entity no longer exists.",
            })
          } else {
            if (history.type.task_id) {
              if (hasData('task', history.type.task_id)) {
                if (this.$route.params.task_id != history.type.task_id) {
                  this.$router.push(`/project/${history.type.project_id}/task/${history.type.task_id}`)
                }
                setTimeout(() => {
                  this.setJumpToMessage(history.type._id)
                }, 500)
                this.handleOpenParentTask(history)
              }
            } else if (this.getChats.some(e => e._id == history.type._id)) {
              this.$router.push(history.src)
            } else {
              this.setMessage({
                key_message: "warning",
                message: "Sorry, this entity no longer exists.",
              })
            }
          }
        }

        if (history.type.type == "tasks") {
          if (hasData('task', history.type._id)) {
            if (this.$route.params.task_id != history.type._id) {
              this.$router.push(history.src)
            }
            this.handleOpenParentTask(history)
          }
        }

        if (history.type.type == "project") {
          if (hasData('list', history.type._id)) {
            if (this.$route.params.project_id != history.type._id) {
              this.$router.push(history.src)
            }
          }
        }

        this.showActivityLog()

      }
    },
    setClass(history) {
      let newClass = "";
      newClass += history.type && history.type.type != "delete" ? "has_link" : "";
      newClass += history.new ? " leftToRight" : "";
      return newClass;
    },
    changeShowSmallActivityLog() {
      this.$socket.emit('IN_ShowSidebar', this.activityLogSwitcher)
      this.setShowSmalActivityLog(this.activityLogSwitcher)
      this.projectScroll()
    },
    getUser(user_id) {

      let user = ''

      if (user_id == this.getUserData._id) {
        user = this.getUserData
      }

      this.getKnownUsers.forEach(e => {
        if (e._id == user_id) {
          user = e
        }
      })

      return user

    },
    textHyphenated(text) {
      return hyphenated(text)
    }
  },
  watch: {
    show(e) {
      if (e) document.head.querySelector("[name~=theme-color][content]").content = '#fff'
      else document.head.querySelector("[name~=theme-color][content]").content = '#c78495'
    }
  }
};
</script>
